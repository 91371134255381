import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
function Casepaper(props) {
  console.log("CASEPAPER", props);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  return (
    <div>
      <div
        className="d-flex justify-content-between px-3 align-items-center"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: isTabScreen ? "24px" : "32px",
            color: "#004AAD",
            fontFamily: "Poppins",
            fontWeight: "600",
            color: "#004AAD",
          }}
        >
          Case Paper
        </div>
        <div>
          <img src="./Communication/downloadarrow.png"></img>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          background: "rgba(240, 250, 248, 0.502)",
          border: "1px solid #E8E7E7",
          borderRadius: "12px",
          margin: "0px auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: "#D9D9D9",
            opacity: "0.6",
            border: "1.12709px solid #BBBBBB",
            width: "92%",
            height: "85%",
            margin: "20px auto",
            position: "relative",
          }}
        >
          <div className="pt-2 ps-2">
            <div
              style={{
                color: "#F0588B",
                fontSize: isTabScreen ? "16px" : "24px",
                fontWeight: "600",
              }}
            >
              General lifestyle habits & History
            </div>
            <div className="d-flex justify-content-between my-1">
              <div className={`que ${isTabScreen ? "smallfont" : "largefont"}`}>
                1. What is Your height ?
              </div>
              <div
                style={{
                  marginRight: "60px",
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <div className="boxin">{props?.casepaperdata1st?.height}</div>
                <span className="ps-2">cm</span>
              </div>
            </div>
            <div className="d-flex justify-content-between my-1">
              <div className={`que ${isTabScreen ? "smallfont" : "largefont"}`}>
                2. What is Your Age ?
              </div>
              <div
                style={{
                  marginRight: "60px",
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <div className="boxin">{props?.casepaperdata1st?.age}</div>
                <span className="ps-2">year</span>
              </div>
            </div>
            <div className="d-flex justify-content-between my-1">
              <div className={`que ${isTabScreen ? "smallfont" : "largefont"}`}>
                3. What is Your weight ?
              </div>
              <div
                style={{
                  marginRight: "60px",
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <div className="boxin">{props?.casepaperdata1st?.weight}</div>
                <span className="ps-2">Kg</span>
              </div>
            </div>
          </div>
          {/* <img src="./Communication/cam.png"></img> */}
          <div style={{ position: "absolute", right: "6px", bottom: "6px" }}>
            <img
              src="./Communication/zoombtn.png"
              style={{ height: "16px", width: "16px" }}
              onClick={() => props.onClick()}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Casepaper;
