const myreadbooklist = [
  {
    id: 1,
    testname: "what is garbh sanskar?",
    testname2: "Garbh Sanskar means “Education in the womb",
    img: "/GarbhaSanskar/garbhasanskar.png",
    info: "Have you ever considered the difference in productivity of two farmers that use the same land, water, sunshine, and weather conditions? One is ripping a prosperous production and the other is fighting even for his survival. Why so?",
    info1:
      "This is simply because a perfect farmer understands the key to good productivity that other farmers are unaware of or refuse to learn.Let us explore the secret of procuring the best progeny by some examples and let's try to understand the greatness of GARBH SANSKAR.",
    info2:
      "80% of the child's brain develops in the womb of mother. Garbh means 'the womb' and Sanskar means 'the ethics' or values. The nurturing of your child and teaching the ethics inside mother's womb can be the most blissful work any parent can dream of. Garbhsanskar is getting 90 years of work done in 9 months.",
    subheading: "GARBHSANSKAR IS A VEDIC SCIENCE",
    infosubheaing:
      "Our ancient past is the best evidence of the GARBHSANSKAR's spiritual journey. Abhimanyu, the son of famous Archer Arjuna, had mastered military tactics from his mother's womb. Many such occurrences demonstrate that the link between mother and child is way beyond our conception and comprehension.",
    subsubheading: "SIXTEEN RITES",
    info3:
      "Our religious culture is built around rituals. These ceremonies were founded by our sages and monks in their tireless attempts to make us cultured, religious, and sociable, and it is because of these rites that Indian culture is unique in the world. They are scientifically advantageous not just for the construction of sacraments, but also for human existence. In ancient times, every work started from the rite itself. At that time the number of rites was also around 40. 40 types of rites are mentioned in Gautam Smriti, but as the busyness increased as time changed, some rites disappeared and Maharishi Angira differentiated them into 25 rites. Sixteen rituals have been described in Vyas Smriti. Only sixteen rituals are described in our holy texts. The first is the ceremony of conception before birth, and the final is the ceremonial of burial after death.",
    p1: "Garbhadhan Sanskar : This is the first rite. The main purpose of household life is to have the best progeny. Parents desiring the best child should perform this ritual for the purity of their body and mind before conception, so that a good child is born. Various pujas and rituals performed after marriage are part of this.",
    p2: "Punsawan Sanskar : This rite is performed after conception (confirming pregnancy). In this rite, the future parents are physically and mentally explained to give birth to a superior and perfect child, and all efforts are made to make the pregnant's diet, thoughts and emotions all perfect, enjoyable and balanced so that they may have the best child.",
    p3: "Simantonnayan Sanskar : This rite is performed in the seventh or eighth month of pregnancy. This rite is celebrated as a function, the main purpose of which is to please the pregnant woman. Other married women apply sindur in middle line of hair of pregnant woman and give their blessings for the protection of the pregnant and the baby.",
    p4: "Jaatkarm Sanskar : This sacrament is performed before the neonatal obstruction. In which a mixture of two drops of ghee and 6 drops of honey is fed to the child with Guru Mantra, and the child is prayed to have a healthy, wise and long life. After that. the mother breast feeds the child.",
  },
  {
    id: 2,
    testname: "5 Sense Activities",
    img: "/GarbhaSanskar/5senseactivity.png",
    info1:
      "During pregnancy, around 80% of the baby's brain grows, and the five senses provide message input to the brain.",
    info2:
      "These five senses are the eye (the seeing), the ear (the hearing), the nose (the smell), the tongue (the taste), the skin (the touch).",
    info3:
      "If the mother's actions linked to these five senses are effectively done, the development of these organs within the infant is likely to be excellent.",
    que: "By what activities can these five senses be developed?",
    ans1:"1) Eye (viewing -observation) : Find anything in your house today that has never been on your radar. Try to see different shapes of animals in clouds visible in the sky.",
   ans2:"2) Ear (Listening): : Close your eyes and listen to the sounds on the balcony or gallery. Outside the house, try to hear the sound of birds shouting and identify the birds by the noise.",
   ans3:"3) Nose (sniff) : Close your eyes and try to identify the flower by smelling different types of flowers. Close your eyes and try to identify different fruits by the aroma.",
   ans4:"4) Tongue (taste) : Taste foods with different flavours such as Acrid, salty, tart, salty, bitter, radish. Try to identify a dish without looking at it by tasting it.",
    ans5:"5) Skin (touches) : Close your eyes and try to identify various objects by touching them. To make the infant feel the touch, parents might place their hands on the womb.",
  },
  {
    id: 3,
    testname: "Garbh Yatra",
    img: "/GarbhaSanskar/garbhyatra.jpg",
    info: "complete blood count info",
  },
];
export default myreadbooklist;