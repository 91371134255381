import React, { useState } from "react";
import configData from "../../../config.json";
import CheckIcon from '@mui/icons-material/Check';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import axios from "axios";
function BrainActivity({
  setExpand,
  setSelected,
  handleChange,
  value,
  open,
  userId,
  pregday,
}) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
  };
  const [question, setQue] = useState([]);
  const [answer, setAns] = useState([]);
  const [id, setId] = useState("");
  const [read,setRead]=useState(false);
  useEffect(() => {
    const day = pregday;
    // const week = Math.floor(day / 7);
    // alert(week);
    fetchbrainactivity(day);
  }, [pregday]);
  const fetchbrainactivity = async (day) => {
    const apiUrl = `${configData.SERVER_API_URL}/patient/get_brain_activity_data_date_wise`;
    try {
      const response = await axios.post(apiUrl, { day: day });
      if (response.data) {
        console.log("BRAIN ACTIVITY", response.data.data[0]);
        const que = response.data.data[0].question;
        setQue(que);
        const ans = response.data.data[0].answer;
        setAns(ans);
        const activityid = response.data.data[0].id;
        setId(activityid);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("Error in fetching API ");
    }
  };
  useEffect(()=>{
    getstatus();
  },[id])
  const getstatus=async()=>{
    const apiUrl = `${configData.SERVER_API_URL}/patient/getBrainStatus`;
    try {
      const response = await axios.post(apiUrl, {
        user_id: userId,
        activity_id: id,
      });
      if (response.data) {
        console.log("READ",response.data.status[0].is_read);
        if(response.data.status.length>0)
          {
            if(response.data.status[0].is_read===1)
              setRead(true);
             
          }
          else{
            setRead(false);
          }
       
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("Error in fetching API ");
    }
  }
  const readstatus = async () => {
    console.log("@@",userId,id)
    const apiUrl = `${configData.SERVER_API_URL}/patient/update_brain_activity_read_status`;
    try {
      const response = await axios.post(apiUrl, {
        user_id: userId,
        activity_id: id,
      });
      if (response.data) {
        alert("Read answer successfully");
        setRead(true);
        getstatus();
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("Error in fetching API ");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: window.innerHeight,
      }}
    >
      <div style={wrapper}>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.PRIMARY,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelected(false);
            setExpand(true);
          }}
        >
          Back
        </div>

        <div
          style={{
            marginTop: "10px",
            fontSize: isTabScreen ? "20px" : "24px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
          }}
        >
          Brain Activity
        </div>

        <Box sx={{ width: "100%", marginTop: "10px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="one" label="QUESTION" />
            <Tab value="two" label="ANSWER" />
          </Tabs>
        </Box>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          {value === "one" && (
            // <div
            //   style={{
            //     width: "20px",
            //     height: "20px",
            //     backgroundColor: "#25B7D3",
            //     borderRadius: "50%",
            //     marginRight: "14px",
            //     // display: "flex",
            //     alignItems: "center",
            //     justifyContent: "center",
            //   }}
            // >
            //   {/* <img
            //     src={"/GarbhaSanskar/Vector (9).png"}
            //     style={{
            //       resizeMode: "contain",
            //       height: "13.41px",
            //       width: "8.86px",
            //     }}
            //   /> */}
            // </div>
            <div></div>
          )}
          {value === "one" && (
            <>
              <div
                style={{
                  fontSize: isTabScreen ? "12px" : "16px",
                  fontWeight: "600",
                }}
              >
                {question}
              </div>
              <br></br>
            </>
          )}
          {value === "two" && open === false && (
            <>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginLeft: "18px",
                }}
              >
                {answer}
                <br></br>
                <br></br>
                <div className="w-100 pe-3">
                  {read===false ? (<button
                    onClick={readstatus}
                    style={{
                      width: "130px",
                      height: "35px",
                      border: "none",
                      backgroundColor: "#EB6391",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  >
                    Mark as done
                  </button>):(<button
                    style={{
                     
                      color: "#EB6391",
                      borderRadius: "10px",
                    }}
                  >
                  <CheckIcon style={{fontSize:"55px"}}/>
                  </button>)}
                 
                </div>
              </div>
              <br></br>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrainActivity;
