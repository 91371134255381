import React from "react";
import { useMediaQuery } from "react-responsive";
import configData from "../config.json";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import StarBorderIcon from "@mui/icons-material/StarBorder";
let filteroption;
let typename = "sonography";
function Reports(props) {

  console.log("RREEPPOOORRTTT", props);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1240px)",
  });
  const [filter, setFilter] = useState();
  const [delet, setDelet] = useState(false);
  const [innermenu, setInnermenu] = useState(false);
  const [radio, setRadio] = useState(0);
  const [clr, setclr] = useState(false);
  function handleChange(e) {
    // Grab the nodeName and value from
    // the clicked element
    const { nodeName, value } = e.target;

    if (nodeName === "INPUT") {
      setRadio(value);
    }
  }
  const showfilter = () => {
    setFilter(true);
  };
  const showinnermenu = () => {
    setInnermenu(!innermenu);
  };
  const addtofav = () => {
    setclr(!clr);
  };
  //  setGender(event) {
  //   console.log(event.target.value);
  // }
  function handleClick(e) {
    setDelet(false);
    console.log("handleClick",radio);
    filteroption = radio;
  }
  const delfilter = () => {
    setDelet(true);
  };
  return (
    <>
      {/* <div>{props.report.id}</div> */}
      <div
        className="d-flex justify-content-between px-3 align-items-center"
        style={{ paddingTop: "30px", position: "relative" }}
        onChange={handleChange}
      >
        <div
          style={{
            fontSize: isTabScreen ? "24px" : "32px",
            width: "50%",
            textAlign: "left",
            marginBottom: "20px",
            fontSize: isTabScreen ? "24px" : "32px",
            fontWeight: "600",
            fontFamily: "Poppins",
            color: configData.THEME_COLORS.PRIMARY,
          }}
        >
          Reports
        </div>

        {filter === true ? (
          <>
            <div
              className="d-flex justify-content-between align-items-center px-2"
              style={{
                width: "50%",
                border: "0.7px solid #C0CCDA",
                height: "35px",
              }}
            >
              <div
                style={{ color: "#004AAD", opacity: "0.5", fontSize: "16px" }}
              >
                Filter
              </div>
              <div
                style={{
                  color: "#004AAD",
                  fontSize: "12px",
                  fontWeight: "600",
                  cursor: "pointer",
                  background: filter === false ? "#ECECEC" : "white",
                  boxShadow:
                    filter === false
                      ? "inset 0px 4px 2px rgba(51, 51, 51, 0.2)"
                      : "none",
                }}
                onClick={() => {
                  handleClick();
                  setFilter(false);
                }}
              >
                Done
              </div>

              <div
                style={{
                  position: "absolute",
                  width: "45%",
                  top: "54px",
                  top: "100%",
                  right: "5%",
                }}
              >
                <div
                  style={{
                    border: "0.7px solid #C0CCDA",
                    boxShadow: "0px 6px 4px rgba(0, 0, 0, 0.078)",
                    borderRadius: "4px",
                    backgroundColor: "white",
                    zIndex: "10",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "161%",
                    color: "#565656",
                  }}
                >
                  <input
                    type="radio"
                    value="bookmark"
                    name="g"
                    style={{ margin: "10px" }}
                  />{" "}
                  Bookmark
                  <br></br>
                  <div>
                    {innermenu === true ? (
                      <RemoveIcon onClick={() => setInnermenu(false)} />
                    ) : (
                      <AddIcon onClick={() => showinnermenu()} />
                    )}
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        margin: "10px",
                        fontSize: "14px",
                        lineHeight: "161%",
                        color: "#565656",
                      }}
                    >
                      {" "}
                      Consultation
                    </span>
                  </div>
                  {innermenu === true ? (
                    <div
                      style={{
                        backgroundColor: "rgba(217, 217, 217, 0.25)",
                        borderRadius: "2px",
                        marginLeft: "5px",
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: isTabScreen ? "10px" : "12px",
                        lineHeight: "161%",
                        color: "#565656",
                      }}
                    >
                      <input
                        type="radio"
                        value="Gynocogy"
                        name="g2"
                        style={{ margin: "10px" }}
                      />{" "}
                      Gynocogy
                      <br></br>
                      <input
                        type="radio"
                        value="M.D.medicine"
                        name="g2"
                        style={{ margin: "10px" }}
                      />{" "}
                      M.D.medicine
                      <br></br>
                      <input
                        type="radio"
                        value="Pediatrician"
                        name="g2"
                        style={{ margin: "10px" }}
                      />{" "}
                      M.D.medicine
                      <br></br>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <input
                    type="radio"
                    value="pathology"
                    name="g"
                    style={{ margin: "10px" }}
                  />{" "}
                  Pathology
                  <br></br>
                  <input
                    type="radio"
                    value="sonography"
                    name="g"
                    style={{ margin: "10px" }}
                  />{" "}
                  Sonography
                  <br></br>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              boxShadow:
                filter === false
                  ? "rgba(51, 51, 51, 0.2) 0px 4px 2px inset"
                  : "none",
              background: filter === false ? "#ECECEC" : "none",
            }}
          >
            <img
              src="./Communication/filter.png"
              onClick={() => showfilter()}
            ></img>
          </div>
        )}
      </div>
      {filter === false && delet === false ? (
        <div
          className="d-flex justify-content-between align-items-center mx-3"
          style={{
            background: "rgba(217, 217, 217, 0.5)",
            border: "1px solid #C0CCDA",
            width: "38%",
            color: "#004AAD",
            fontSize: "14px",
            fontWeight: "400",
            height: "30px",
            margin: "6px",
            fontFamily: "poppins",
          }}
        >
          <div className="ps-2"> {filteroption} </div>
          <div className="pe-2">
            <img
              src="./Communication/bluecross.png"
              onClick={() => delfilter()}
            ></img>
          </div>
        </div>
      ) : delet === true ? (
        <div></div>
      ) : (
        <div></div>
      )}
      <div
        style={{
          border: "1px solid #E8E7E7",
          marginTop: "38px",
          width: "92%",
          margin: "0px auto",
          borderRadius: "12px",
          backgroundColor: "#F0FAF8",
        }}
      >
        <div
          className="d-flex justify-content-between pb-3"
          style={{ paddingTop: "22px", color: "#004AAD" }}
        >
          <div
            style={{
              paddingLeft: isTabScreen ? "10px" : "30px",
              fontSize: isTabScreen ? "10px" : "14px",
            }}
          >
            {/* {date} */}
            {"2022"}
          </div>
          <div
            style={{
              paddingRight: isTabScreen ? "10px" : "30px",
              fontSize: isTabScreen ? "10px" : "14px",
            }}
          >
            {/* {week} */}
            {"WEEK 1"}
          </div>
        </div>
        <div
          className="text-center"
          style={{
            fontSize: isTabScreen ? "16px" : "20px",
            fontWeight: "500",
            color: "#F0588B",
          }}
        >
          {/* {title} */}
          {"1st Trimester Test"}
        </div>
        <div
          style={{
            fontSize: isTabScreen ? "30px" : "35px",
            fontWeight: "600",
            paddingTop: "16px",
            textAlign: "center",
            color: "#004AAD",
          }}
        >
          11
        </div>
        <div
          style={{
            fontSize: isTabScreen ? "30px" : "35px",
            fontWeight: "600",
            paddingBottom: "24px",
            textAlign: "center",
            color: "#004AAD",
          }}
        >
          AUG
        </div>
        <div
          style={{
            fontSize: isTabScreen ? "13px" : "16px",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Report indicates<br></br>no pathogens.<br></br>Patient is healthy.
        </div>

        <div
          className="d-flex justify-content-center py-3 align-items-center"
          style={{ fontSize: isTabScreen ? "12px" : "14px" }}
        >
          <div>Prescription</div>
          <img
            src="./Communication/downloadarrow.png"
            className="ps-2"
            style={{
              height: isTabScreen ? "13px" : "18px",
              width: isTabScreen ? "22px" : "28px",
            }}
          ></img>
        </div>
        <div
          className="d-flex justify-content-center py-3 align-items-center"
          style={{ fontSize: isTabScreen ? "12px" : "14px" }}
        >
          <div>Report</div>

          <img
            src="./Communication/downloadarrow.png"
            className="ps-2"
            style={{
              height: isTabScreen ? "13px" : "18px",
              width: isTabScreen ? "22px" : "28px",
            }}
          ></img>

          <div style={{ position: "absolute", right: "24px" }}>
            {clr === true ? (
              <img
                src="./Communication/yellowstar.png"
                onClick={() => addtofav()}
              ></img>
            ) : (
              <img
                src="./Communication/star.png"
                onClick={() => addtofav()}
              ></img>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
