import React, { useState } from "react";
import configData from "../../config.json";
import EventTypeHeader from "./EventTypeHeader";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom/dist";
function EventComponent({
  doctorName,
  doctorType,
  title,
  scheduled,
  eventDate,
  onClick,
  eventToday,
  consultation_no,
  doctor_timing_id,
  consultation_type,
  drname,
  drid,
  drpost,
  timing,
  medical_expertise,
  id
}) {
  
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });
  // const history = useHistory();
  const navigate = useNavigate();
  const gotoSchedule=()=>{
    console.log("##@##",medical_expertise,id);
    if(medical_expertise==='Gynecologist')
    {
      navigate("/patient/Doctor_consultation", {
              state: {
                id: 2,
                doctorName: doctorName,
                title: title,
                eventDate: eventDate,
                consultation_no: consultation_no,
                doctor_timing_id: doctor_timing_id,
                consultation_type: consultation_type,
                drname: drname,
                drid: drid,
                drpost: drpost,
                timing: timing,
              },
            })
    }
    if(medical_expertise==='Pathology Lab')
    {
    
      navigate("/patient/Pathology", {
      state: {
        stateid:2,
        id:id,
      }
      })
         
    }
  }
  return (
    <>
      <div
        style={{
          width: "97%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "18px",
        }}
      >
        <div
          className="mx-3"
          style={{ backgroundColor: "#F5F5F5", borderRadius: "12px" }}
        >
          <div
            style={{
              marginTop: isTabScreen ? "10px" : "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: isTabScreen ? "9px" : "12px",
              fontWeight: "600",
              fontFamily: "Poppins",
              backgroundColor: "white",
              width: isTabScreen ? "95px" : "105px",
              height: isTabScreen ? "20px" : "34px",
              display: "flex",
              alignItems: "center",
              margin: "10px auto",
              borderRadius: "8px",
              color: configData.THEME_COLORS.PRIMARY,
            }}
          >
            {eventDate}
          </div>

          <div
            className="px-1"
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
              fontSize: isTabScreen ? "12px" : "15px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            {title}
          </div>

          <div
            style={{
              display: "flex",
              marginTop: isTabScreen ? "10px" : "14px",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              <div
                style={{
                  width: isTabScreen ? "30px" : "50px",
                  height: isTabScreen ? "30px" : "50px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/Dashboard/test1.png"}
                  style={{
                    marginTop: "5px",
                    resizeMode: "contain",
                    height: "30px",
                    width: "30px",
                  }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "15px" }}>
              <div
                style={{
                  fontSize: isTabScreen ? "12px" : "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  color: "#464E5F",
                }}
              >
                {doctorName}
              </div>
              <div
                style={{
                  fontSize: isTabScreen ? "10px" : "13px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#B5B5C3",
                }}
              >
                {drpost}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "20px",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {scheduled && (
              <div
                style={{
                  color: "#58CF99",
                  fontSize: isTabScreen ? "10px" : "13px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                SCHEDULED
              </div>
            )}
            {scheduled && medical_expertise !='Pathology Lab' &&(
              <div
                style={{
                  width: isTabScreen ? "70px" : "100px",
                  height: isTabScreen ? "20px" : "32px",
                  backgroundColor: configData.THEME_COLORS.PRIMARY,
                  borderRadius: "6px",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: isTabScreen ? "8px" : "12px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                }}
              //  {medical_expertise==='Gynecologist'?():()} 
              //  onClick={() =>
              //     navigate("/patient/Doctor_consultation", {
              //       state: {
              //         id: 2,
              //         doctorName: doctorName,
              //         title: title,
              //         eventDate: eventDate,
              //         consultation_no: consultation_no,
              //         doctor_timing_id: doctor_timing_id,
              //         consultation_type: consultation_type,
              //         drname: drname,
              //         drid: drid,
              //         drpost: drpost,
              //         timing: timing,
              //       },
              //     })
              //   }
              onClick={()=>gotoSchedule()}
              >
                Re-Schedule
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventComponent;
