import React, { useState } from "react";
import configData from "../../../config.json";
import motivationlist from "../Motivationlist";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import axios from "axios";

let testdata;
function Dailymotivation({ setExpand, setSelected }) {
  const [testinfo, settestinfo] = useState(false);
  const [patientdata, setPatientdata] = useState([]);
 
  function backtotestlist() {
    settestinfo(false);
  }
  useEffect(() => {
    getsinglepatientdetail();
  }, []);
  const getsinglepatientdetail = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: localStorage.getItem("userId"),
      });

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);
        setPatientdata(response.data.user);
        console.log("<><><><>", patientdata);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const back = {
    fontWeight: "600",
    fontSize: "12px",
    color: "#004AAD",
    cursor: "pointer",
  };
  const heading = {
    color: "#F0588B",
    fontWeight: "600",
    fontSize: isTabScreen ? "20px" : "24px",
    paddingTop: "10px",
  };
  const para = {
    paddingBottom: "66px",
    fontFamily: "poppins",
    fontSize: "14px",
    fontWeight: "400",
    color: "#333333",
  };
  const parahead = {
    paddingBottom: "10px",
    marginTop: "30px",
    fontFamily: "poppins",
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333",
  };
  const testimg = {
    width: "585px",
    height: "400px",
    margin: "0px auto",
    paddingBottom: "100px",
  };
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
    overflowY: "scroll",
  };

  return (
    <div style={wrapper}>
      <div>
        {testinfo === true ? (
          <>
            <div style={back} onClick={() => backtotestlist()}>
              BACK
            </div>
            <div style={heading}>{testdata.testname}</div>
            <div style={parahead}>{testdata.infohead}</div>
            <div style={para}>{testdata.info}</div>
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <div style={parahead}>{testdata.infohead}</div>
                  <div style={para}>{testdata.info}</div>
                </div>
                <div className="col-4">
                  {/* <div className="w-75"> */}
                  <img src={testdata.img} className="img-fluid" alt="..."></img>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={back}
              onClick={() => {
                setSelected(false);
                setExpand(true);
              }}
            >
              BACK
            </div>
            <div style={heading}>Daily motivation</div>
            {/* <div className="container">
              <div className="row" style={innerrow}>
                {meditationlist.map((r) => (
                  <div
                    className="col-md-4 col-lg-3"
                    style={innercol}
                    onClick={() => handletestinfo(r)}
                  >
                    <div className="card text-white" style={{ border: "none" }}>
                      <img src={r.img} className="card-img" alt="..."></img>
                      <div className="card-img-overlay d-flex justify-content-center align-items-center">
                        <div style={testnm}>
                          <div className="card-title text-center">
                            {r.testname}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
            <div className="py-4">
              {motivationlist.map((i) => {
                if (patientdata.pregnancy_days === i.pregnancy_day) {
                  return <div>{i.info}</div>;
                }
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default Dailymotivation;
