import Typography from '@mui/material/Typography'
import { useMediaQuery } from 'react-responsive'
function HealthVitalHistory ({
  item,
  date,
  type1,
  type2,
  type3,
  type4,
  value1,
  value2,
  value2_d,
  value3,
  value4,
  unit1,
  unit2,
  unit3,
  unit4
}) {
  const isTabScreen = useMediaQuery({
    query: '(min-width: 768px)' && '(max-width: 1023px)'
  })
console.log("@@@@",item,value2,value2_d);
  return (
    <div
      className='container'
      style={{
        marginTop: isTabScreen ? '14px' : '18px',
        borderRadius: '12px',
        width: '76%',
        margin: '0px auto',
        // backgroundColor: ' rgba(240, 250, 248, 0.501961)',
        border: '1px solid #CBC6C6',
        marginBottom: '35px'
      }}
    >
      <div className='row'>
        {/* <div style={{}}> */}
        <Typography
          style={{
            marginTop: isTabScreen ? '12px' : '16px',
            marginBottom: isTabScreen ? '12px' : '16px',
            textAlign: 'center',
            fontSize: isTabScreen ? '12px' : '16px',
            fontWeight: '500',
            fontFamily: 'Poppins'
          }}
        >
          {date}
        </Typography>

        <div className='col-6 mb-3'>
          <div className='h-t-box'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                background: '#F8DEBD',
                minHeight:"100px",
              }}
            >
              <Typography
                style={{
                  marginTop: '11px',
                  alignSelf: 'center',
                  fontSize: isTabScreen ? '26px' : '34px',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  color: '#E79B38'
                }}
              >
                {value1}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: 'center',
                  fontSize: isTabScreen ? '12px' : '16px',
                  fontWeight: '400',
                  fontFamily: 'Poppins',
                  color: '#818181',
                  marginBottom: '10px'
                }}
              >
                {unit1}
              </Typography>
            </div>
            <span className='h-v-title'>Sugar Level</span>
            <div
              style={{
                marginTop: '4px',
                marginLeft: '8px',
                fontFamily: 'Poppins',
                fontSize: isTabScreen ? '9px' : '12px',
                fontWeight: '500',
                textAlign: 'center'
              }}
            >
              {type1}
            </div>
          </div>
        </div>
        <div className='col-6 mb-3'>
          <div className='h-t-box'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                background: '#D0FBFF',
                minHeight:"100px",
              }}
            >
              <Typography
                style={{
                  marginTop: '11px',
                  alignSelf: 'center',
                  fontSize: isTabScreen ? '26px' : '34px',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  color: '#478F96'
                }}
              >
                {item.systolic_bp}/{item.diastolic_bp}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: 'center',
                  marginBottom: '10px',
                  fontSize: isTabScreen ? '12px' : '16px',
                  fontWeight: '400',
                  fontFamily: 'Poppins',
                  color: '#818181'
                }}
              >
                {unit2}
              </Typography>
            </div>
            <span  className='h-v-title'>Blood Pressure</span>
            <div
              style={{
                marginTop: '4px',
                marginLeft: '8px',
                fontFamily: 'Poppins',
                fontSize: isTabScreen ? '9px' : '12px',
                fontWeight: '500',
                textAlign: 'center'
              }}
            >
              {type1}
            </div>
          </div>
        </div>

        <div className='col-6 mb-3'>
          <div className='h-t-box'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                background: '#FBF0F3',
                minHeight:"100px",
              }}
            >
              <Typography
                style={{
                  marginTop: '11px',
                  alignSelf: 'center',
                  fontSize: isTabScreen ? '26px' : '34px',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  color: '#CA6B6E'
                }}
              >
                {value3}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: 'center',
                  fontSize: isTabScreen ? '12px' : '16px',
                  fontWeight: '400',
                  fontFamily: 'Poppins',
                  color: '#818181',
                  marginBottom: '10px'
                }}
              >
                {unit3}
              </Typography>
            </div>
            <span  className='h-v-title'>Temperature</span>
            <div
              style={{
                marginTop: '4px',
                marginLeft: '8px',
                fontFamily: 'Poppins',
                fontSize: isTabScreen ? '9px' : '12px',
                fontWeight: '500',
                textAlign: 'center'
              }}
            >
              {type3}
            </div>
          </div>
        </div>
        <div className='col-6 mb-3'>
          <div className='h-t-box' >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'rgba(123, 151, 165, 0.25098)',
                minHeight:"100px",
              }}
            >
              <Typography
                style={{
                  marginTop: '11px',
                  alignSelf: 'center',
                  fontSize: isTabScreen ? '26px' : '34px',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  color: '#707070'
                }}
              >
                {value4}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: 'center',
                  marginBottom: '10px',
                  fontSize: isTabScreen ? '12px' : '16px',
                  fontWeight: '400',
                  fontFamily: 'Poppins',
                  color: '#818181'
                }}
              >
                {unit4}
              </Typography>
            </div>
            <span className='h-v-title'>Weight</span>
            <div
              style={{
                marginTop: '4px',
                marginLeft: '8px',
                fontFamily: 'Poppins',
                fontSize: isTabScreen ? '9px' : '12px',
                fontWeight: '500',
                textAlign: 'center'
              }}
            >
              {type4}
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </div>
  )
}

export default HealthVitalHistory
