const Users = [
  {
    index: "1",
    title: "Dr. Jane Doe",
    post: "Gynaecologist",
    imgdr: "/DoctorConsultation/jondoe2.png",
    imgclinic: "/DoctorConsultation/Clinic.png",
    date: "Sep 21",
    consultationcompleted: "3/15",
    tip: "Women face a lot of issues from puberty to menopause which affect them These diseases are managed by a health professional known as a Gynaecologist.",
  },
  {
    index: "2",
    title: "Dr. Jane Doe",
    post: "Doctor of Medicine",
    imgdr: "/DoctorConsultation/jondoe1.png",
    imgclinic: "/DoctorConsultation/Clinic.png",
    date: "Oct 2",
    consultationcompleted: "2/13",
    tip: "The main duties of MD are performing diagnostic tests, recommending specialists for patients and document patient's medical history.",
  },
  {
    index: "3",
    title: "Dr. Jane Doe",
    post: "Gynaecologist",
    imgdr: "/DoctorConsultation/jondoe2.png",
    imgclinic: "/DoctorConsultation/Clinic.png",
    date: "Sep 21",
    consultationcompleted: "3/15",
    tip: "The main duties of MD are performing diagnostic tests, recommending specialists for patients and document patient's medical history.",
  },
  {
    index: "4",
    title: "Dr. Jane Doe",
    post: "Doctor of Medicine",
    imgdr: "/DoctorConsultation/jondoe1.png",
    imgclinic: "/DoctorConsultation/Clinic.png",
    date: "Oct 2",
    consultationcompleted: "2/13",
    tip: "The main duties of MD are performing diagnostic tests, recommending specialists for patients and document patient's medical history.",
  },
];
export default Users;
