import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import { PieChart, Pie, Cell } from "recharts";

function PathologynextPage(props) {
  console.log("props",props);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1240px)",
  });
  const gaugedata = [
    { label: "Completed Consultations", value: parseInt(props.per) },
    { label: "Incomplete Consultations", value: 100 - props.per },
  ];

  return (
    <>
      <div
        className="px-0 "
        onClick={() => props.onClick()}
        style={{
          cursor: "pointer",
          width: isTabScreen ? "380px" : isTabScreen1 ? "280px" : "330px",
          boxShadow:
            props?.info?.doctorDetails?.doctor_id === props?.selectedcard
              ? "0px 1px 24px rgba(0, 0, 0, 0.1608)"
              : "none",
        }}
      >
        <div style={{ border: "" }}>
          <div style={{ paddingTop: "0px" }}>
            <div
              style={{
                paddingLeft: "0px",
                border: "1px solid #E8E7E7",
                backgroundColor: "white",
                borderRadius: "12px",
                marginTop: "19px",
              }}
            >
              <div className="d-flex">
                <img
                  style={{
                    marginLeft: "21px",
                    marginTop: "19px",
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                  }}
                  src={props.imgdr}
                  alt="doctorImage"
                />
                <div style={{ paddingLeft: "20px", paddingTop: "21px" }}>
                  <div
                    className="mb-0"
                    style={{
                      font: "Poppins",
                      fontWeight: "600",
                      fontSize: isTabScreen ? "16px" : "20px",
                      lineHeight: "30px",
                      color: "#333333",
                    }}
                  >
                    {" "}
                    {props.title}{" "}
                  </div>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: isTabScreen ? "12px" : "16px",
                      // lineHeight: "24px",
                      color: "#333333",
                      height: "25px",
                      lineHeight: "20px",
                    }}
                  >
                    {" "}
                    {props.post}
                  </div>
                </div>
              </div>
              <div
                className="d-flex"
                style={{ marginTop: "14px", marginLeft: "11px" }}
              >
                <div>
                  <img
                    src={props.imgclinic}
                    style={{
                      height: "30px",
                      width: "30px",
                    }}
                    alt="ClinicImage"
                  ></img>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: "8px",
                      lineHeight: "12px",
                      color: "#707070",
                    }}
                  >
                    clinic
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "100",
                      fontSize: isTabScreen ? "12px" : "16px",
                      lineHeight: "24px",
                      paddingLeft: "22px",
                      color: "#333333",
                      paddingRight: "3px",
                    }}
                  >
                    {props.address}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: isTabScreen ? "10px" : "40px",
                  marginTop: "10px",
                }}
              >
                <Row className="me-lg-5" style={{ height: "200px" }}>
                  <div className="col text-center">
                    {props.info.upcomingConsultationDate?.scheduled_date ? (
                      <div
                        style={{
                          font: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: isTabScreen ? "10px" : "13px",
                          lineHeight: "24px",
                          color: " #333333",
                          textAlign: "center",
                        }}
                      >
                        {props.info?.upcomingConsultationDate?.scheduled_date &&
                          moment(
                            props.info?.upcomingConsultationDate?.scheduled_date
                          ).format("MMM DD")}
                      </div>
                    ) : (
                      <div
                        style={{
                          font: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: isTabScreen ? "10px" : "11px",
                          lineHeight: "24px",
                          color: " #333333",
                          textAlign: "center",
                        }}
                      >
                        No event
                      </div>
                    )}

                    <div>
                      <img
                        className="mx-auto"
                        style={{
                          height: "30px",
                          width: "30px",
                          marginTop: "10px",
                          marginLeft: "8px",
                        }}
                        src={"/DoctorConsultation/calendar.png"}
                        alt="calender"
                      ></img>
                      <p
                        className="mb-0"
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                        }}
                      >
                        Upcoming
                      </p>
                      <p
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                        }}
                      >
                        Event
                      </p>
                    </div>
                  </div>
                  <div className="col text-center">
                    {props?.info?.Consultation ? (
                      <div
                        style={{
                          font: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: isTabScreen ? "10px" : "12px",
                          lineHeight: "24px",
                          color: " #333333",
                          textAlign: "center",
                        }}
                      >
                        {props?.info?.Consultation?.completed}
                        <span>/</span>
                        {props?.info?.Consultation?.total}
                      </div>
                    ) : (
                      <div
                        className="text-center"
                        style={{
                          font: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: isTabScreen ? "10px" : "12px",
                          lineHeight: "24px",
                          color: " #333333",
                          textAlign: "center",
                        }}
                      >
                        0<span>/</span>0
                      </div>
                    )}

                    <div>
                      <img
                        className="mx-auto"
                        style={{
                          height: "23px",
                          width: "23px",
                          marginTop: "10px",
                        }}
                        src={"/DoctorConsultation/tickmark.png"}
                        alt="tickmark"
                      ></img>
                      <p
                        className="mb-0"
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                          paddingTop: "6px",
                        }}
                      >
                        Consultation
                      </p>
                      <p
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                        }}
                      >
                        Completed
                      </p>
                    </div>
                  </div>
                  <div className="col text-center">
                    <div
                      style={{
                        position: "relative",
                        top: -40,
                        right: isTabScreen ? -5 : 0,
                      }}
                    >
                      <PieChart
                        height={isTabScreen ? 100 : 130}
                        width={isTabScreen ? 140 : 240}
                      >
                        <Pie
                          startAngle={180}
                          endAngle={0}
                          innerRadius="55%"
                          data={gaugedata}
                          dataKey="value"
                          labelLine={false}
                          blendStroke
                          isAnimationActive={true}
                          cy={"70%"}
                        >
                          <Cell fill="#58CF99" />
                          <Cell fill="gray" />
                        </Pie>
                      </PieChart>
                      <div
                        style={{
                          color: "#58CF99",
                          top: isTabScreen ? -50 : -70,
                          position: "relative",
                          left: 2,
                          textAlign: "center",
                          fontSize: isTabScreen ? "20px" : "26px",
                          fontWeight: "700",
                        }}
                      >
                        {props.per ? Math.floor(props.per) : 0}
                      </div>
                      <div
                        style={{
                          color: "#707070",
                          top: isTabScreen ? -50 : -75,
                          position: "relative",
                          textAlign: "center",
                          fontSize: isTabScreen ? "8px" : "8px",
                          fontWeight: "500",
                        }}
                      >
                        Pregnancy Care
                        <br /> Completed
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
              <hr className="my-0 w-100"></hr>

              <div className="d-flex my-2" style={{ marginLeft: "10px" }}>
                <div className="text-center">
                  <img
                    src="/DoctorConsultation/star.png"
                    style={{ marginTop: "12px" }}
                    alt="star"
                  ></img>
                  <p
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: "8px",
                      lineHeight: "12px",
                      color: "#707070",
                    }}
                  >
                    Importance
                  </p>
                </div>
                <div style={{ paddingLeft: "10px" }}>
                  <p
                    style={{
                      font: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: isTabScreen ? "9px" : "12px",
                      lineHeight: "18px",
                      color: "#333333",
                    }}
                  >
                    {props.tip}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PathologynextPage;
