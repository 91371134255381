import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
function Disclaimerpolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

const [userid, setUserid] = useState(0);
useEffect(() => {
  const userIdValue = localStorage.getItem("userId");
  setUserid(userIdValue);
}, [userid]);
  return (
    <div>
      <Header userid={userid} />
      <div className="container-fluid py-5">
        <div className="row pt-5 px-5">
          <div className="col-12" style={{ textAlign: "center", color: "black" }}>
            <h2 className="py-4 fw-bolder">DISCLAIMER POLICY</h2>
          </div>
          <div className="col-12" style={{ color: "black" }}>
            <div>Posted as of __ February 2021</div>
            <div>Last updated as of___ February 2021</div>
          </div>

          <div className="col-12" style={{ marginTop: "30px" }}>
            <p style={{ color: "black", fontSize: "13px" }}>
              COMPANY WILL NOT BE RESPONSIBLE FOR ANY DAMAGE SUFFERED BY FROM
              USE OF THIS PLATFORM. THIS INCLUDES BUT IS NOT LIMITED TO
              LOSS/FAILURE/DYING OF LIFE OF ANY USER OR PATIENT OR
              BABY/INFANT/FOETUS, PRICING, LOSS OF REVENUE/DATA RESULTING FROM
              DELAYS, NON-DELIVERIES, MISSED DELIVERIES, OR SERVICE
              INTERRUPTIONS AS MAY OCCUR BECAUSE OF ANY ACT/OMISSION OF PARTIES.
              THIS DISCLAIMER OF LIABILITY ALSO APPLIES TO ANY DAMAGES OR INJURY
              CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
              INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
              TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR
              DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF
              RECORD, WHETHER FOR BREACH OF CONTRACT, TORTUOUS BEHAVIOUR,
              NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.
            </p>
          </div>
          <div className="col-12" style={{ marginTop: "20px" }}>
            <p style={{ color: "black", fontSize: "13px" }}>
              THE SERVICE PROVIDED ON THE PLATFORM ARE INTENDED TO BE SUBJECT TO
              AVAILABILITY, WITHOUT ANY PROMISES OR GUARANTEES ON THE SAME BY
              THE COMPANY, AND OR THE INTERVIEWER LISTED ON THE PLATFORM AND
              WHILE CERTAIN INFORMATION AVAILABLE ON THE PLATFORM IS THE
              PROPERTY OF THE COMPANY AND THE COMPANY ENDEAVOURS TO KEEP THE
              SAID INFORMATION UPDATED AND ACCURATE, THE COMPANY SHALL NOT MAKE
              NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
              ABOUT THE COMPLETENESS, ACCURACY, RELIABILITY, SUITABILITY OR
              AVAILABILITY WITH RESPECT TO THE PLATFORM OR THE INFORMATION,
              CUSTOMERS, SERVICES, OR RELATED GRAPHICS CONTAINED ON THE PLATFORM
              FOR ANY PURPOSE. ANY RELIANCE YOU PLACE ON SUCH INFORMATION IS
              THEREFORE STRICTLY AT YOUR OWN RISK.
            </p>
          </div>

          <div className="col-12" style={{ marginTop: "20px" }}>
            <p style={{ color: "black", fontSize: "13px" }}>
              ANY AND OR ALL THE INTELLECTUAL PROPERTY OF THE COMPANY ON THE
              PLATFORM MAY HAVE BEEN TRADEMARKED OR COPYRIGHTED BY THE COMPANY
              AND OR ANY OTHER SUCH PERSON AND ANY USE, MISUSE OR MANIPULATIONS
              THAT MAY BE IN RELATION TO THE INTELLECTUAL PROPERTY SHALL BE THE
              RESPONSIBILITY OF THE USER. HOWEVER, WHEN BROUGHT TO THE NOTICE OF
              THE COMPANY, THERE SHALL BE IMMEDIATE LEGAL ACTION TAKEN.{" "}
            </p>
          </div>

          <div
            className="col-12"
            style={{ marginTop: "20px", color: "black", fontSize: "13px" }}
          >
            <p>
              THE COMPANY BEARS NO RESPONSIBILITY WHATSOEVER FOR ANY
              CONSEQUENCES ARISING FROM THE USE OF THE SERVICES ON THE PLATFORM.
              THE USE OF THE SERVICES ON THE PLATFORM FROM THE PLATFORM IS THE
              SOLE RESPONSIBILITY OF THE USER (OR LEGALLY AUTHORISED PERSON ON
              BEHALF OF THE USER), AND IN CASE OF ANY NEGLIGENCE ON THE PART OF
              THE USER IN ACTING ON THE SAME SHALL NOT BE CONSTRUED AS IMPOSING
              ANY LIABILITY, DIRECT OR INDIRECT, ON THE COMPANY/PLATFORM.
            </p>
          </div>

          <div className="col-12" style={{ color: "black", fontSize: "13px" }}>
            <p>
              THE COMPANY SHALL DISCLAIM ALL RESPONSIBILITY AND OWNS NO
              LIABILITY TO USER FOR ANY OUTCOME (INCIDENTAL, DIRECT, INDIRECT OR
              OTHERWISE) FROM THE USE OF THE SERVICES OR THE PRODUCTS PURCHASED.
              IN NO EVENT WILL THE COMPANY BE LIABLE FOR ANY LOSS OR DAMAGE
              INCLUDING WITHOUT LIMITATION, INDIRECT OR CONSEQUENTIAL LOSS OR
              DAMAGE, OR ANY LOSS OR DAMAGE WHATSOEVER ARISING FROM LOSS OF DATA
              OR PROFITS ARISING OUT OF, OR IN CONNECTION WITH, THE USE OF THE
              PLATFORM.
            </p>
          </div>

          <div className="col-12" style={{ color: "black", fontSize: "13px" }}>
            <p>
              THROUGH THIS PLATFORM, YOU MAY ABLE TO LINK TO OTHER PLATFORMS
              WHICH ARE NOT UNDER THE CONTROL OF THE COMPANY. WE HAVE NO CONTROL
              OVER NATURE, CONTENT AND AVAILABILITY OF THOSE SITES. THE
              INCLUSION OF ANY LINKS DOES NOT NECESSARILY IMPLY A RECOMMENDATION
              OR ENDORSE THE VIEWS EXPRESSED WITHIN THEM. EVERY EFFORT IS MADE
              TO KEEP THE PLATFORM UP AND RUNNING SMOOTHLY. HOWEVER, THE COMPANY
              TAKES NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE FOR, THE
              PLATFORM BEING TEMPORARILY UNAVAILABLE DUE TO TECHNICAL ISSUES
              BEYOND OUR CONTROL.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Disclaimerpolicy;
