import React, { useContext, useState } from "react";
import configData from "../config.json";
import EventTypeHeader from "./EventTypeHeader";
import { useMediaQuery } from "react-responsive";
import {  useLocation } from "react-router-dom";
import {Dashboard,medicalexpertcontext} from "../Pages/Dashboard"
function EventComponent({
  doctorName,
  doctorType,
  title,
  scheduled,
  eventDate,
  onClick,
  starttime,
  endtime,
  Age,
  pregweek,
  consultationno,
}) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });
  const medical_expert_id=useContext(medicalexpertcontext).medical_expert_id;
  console.log("/////",medical_expert_id);
  return (
    <>
      <div
        style={{
          height: "300px",
          width: "100%",
          marginTop: "10px",
          border: "0.5px solid #FBFBFB",
          display: "flex",
          flexDirection: "column",
          // backgroundColor:"red",
        }}
      >
        {/* <div className="mt-5" style={{ alignSelf: "center" }}>
          <EventTypeHeader title={"Upcoming Event"} />
        </div> */}
        <div
          className="mx-2"
          style={{
            backgroundColor: "#F5F5F5",
            borderRadius: "12px",
            width: "25w",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: isTabScreen ? "9px" : "12px",
              fontWeight: "600",
              fontFamily: "Poppins",
              backgroundColor: "white",
              width: isTabScreen ? "90px" : "105px",
              height: isTabScreen ? "24px" : "34px",
              display: "flex",
              alignItems: "center",
              margin: "10px auto",
              borderRadius: "8px",
              color: configData.THEME_COLORS.PRIMARY,
            }}
          >
            {eventDate}
          </div>
          <div
            className="px-2"
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: isTabScreen ? "8px" : "10px",
              fontWeight: "600",
              fontFamily: "Poppins",
              backgroundColor: "white",
              width: isTabScreen ? "100px" : "150px",
              height: isTabScreen ? "21px" : "30px",
              display: "flex",
              alignItems: "center",
              margin: "10px auto",
              borderRadius: "8px",
              color: configData.THEME_COLORS.PRIMARY,
            }}
          >
            {starttime} - {endtime}
          </div>
          {/* <div
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
              fontSize: "16px",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >
            {title}
          </div> */}
          <div
            style={{
              display: "flex",
              marginTop: "14px",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/test1.png"}
                  style={{
                    marginTop: "5px",
                    resizeMode: "contain",
                    height: "30px",
                    width: "30px",
                  }} alt="nt found"
                />
              </div>
            </div>
            <div style={{ marginLeft: "15px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  color: "#464E5F",
                }}
              >
                {doctorName}
              </div>
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#B5B5C3",
                }}
              >
                Age:{Age}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4 px-4">
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "14px",
                color: "#004AAD",
              }}
            >
              Pregnancy Week
            </div>
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "14px",
                color: "#004AAD",
              }}
            >
              {pregweek}
            </div>
          </div>
          <div className="d-flex justify-content-between px-4 pt-2">
          {medical_expert_id===8 || medical_expert_id===9 ?  (<div
              style={{
                fontSize: isTabScreen ? "10px" : "14px",
                color: "#004AAD",
              }}
            >
             Event Number
            </div>):(<div
              style={{
                fontSize: isTabScreen ? "10px" : "14px",
                color: "#004AAD",
              }}
            >
              Consultation Number
            </div>)
             }
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "14px",
                color: "#004AAD",
                marginBottom: "10px",
              }}
            >
              {consultationno}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventComponent;
