import React, { useEffect, useState } from "react";
import {
  includedtestlist,
  includedtestlist1,
  includedtestlist2,
  includedtestlist3,
  includedtestlist4,
} from "../Components/includedtestlist";
// import includedtestlist1 from "../Components/includedtestlist1";
import { useMediaQuery } from "react-responsive";
<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
  crossorigin="anonymous"
></link>;

let testdata;
function IncludedTest(props) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen2 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });
  const maindiv = {
    marginLeft: isTabScreen || isTabScreen2 ? "20px" : "59px",
    marginRight: isTabScreen || isTabScreen2 ? "20px" : "69px",
    backgroundColor: "#FBFBFB",
    borderRadius: "12px",
    paddingBottom: isTabScreen || isTabScreen2 ? "10px" : "20px",
    marginBottom: isTabScreen || isTabScreen2 ? "10px" : "20px",
  };
  const back = {
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingTop: "30px",
    color: "#004AAD",
    fontSize: "12px",
    fontWeight: "600",
    border: "none",
    backgroundColor: "#FBFBFB",
    borderRadius: "12px",
    cursor: "pointer",
  };
  const heading = {
    color: "#F0588B",
    fontSize: isTabScreen ? "20px" : "24px",
    fontWeight: "600",
    paddingTop: "10px",
    paddingLeft: isTabScreen ? "20px" : "50px",
  };
  const innercol = {
    marginTop: "30px",
    cursor: "pointer",
  };
  const innerrow = {
    paddingRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
  };
  const testnm = {
    fontSize: isTabScreen ? "11px" : "14px",
    fontWeight: "400",
    color: "#004AAD",
    height: isTabScreen || isTabScreen2 ? "70px" : "90px",
    width: isTabScreen || isTabScreen2 ? "70px" : "120px",
    backgroundColor: "#FBFBFB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const head = {
    paddingTop: "30px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "15px",
    fontSize: isTabScreen ? "16px" : "20px",
    fontWeight: "700",
    color: "#333333",
  };
  const para = {
    paddingBottom: "66px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    fontFamily: "poppins",
    fontSize: "16px",
    fontWeight: "400",
    color: "#333333",
  };
  const testimg = {
    width: "300px",
    height: "300px",
    margin: "0px auto",
    paddingBottom: "100px",
  };
  console.log("AAAAAA========RRRRR", props.includedtest);
  const [testinfo, settestinfo] = useState(false);
  const handletestinfo = (r) => {
    settestinfo(true);
    testdata = r;
    console.log("dkoihdghgcfch", r.testname);
  };
  function backtotestlist() {
    settestinfo(false);
  }
  let list;
  const [s, sets] = useState([]);

  useEffect(() => {
    // console.log("///////////////////////??>>>>?", props.includedtest);
    if (props.includedtest == 1) {
      sets(includedtestlist);
    } else if (props.includedtest == 2) {
      sets(includedtestlist1);
    } else if (props.includedtest == 3) {
      sets(includedtestlist2);
    } else if (props.includedtest == 4) {
      sets(includedtestlist3);
    } else {
      sets(includedtestlist4);
    }
  }, []);

  return (
    <div style={maindiv}>
      <div>
        {testinfo === true ? (
          <>
            <div style={back} onClick={() => backtotestlist()}>
              BACK
            </div>
            <div style={heading}>Tests Included</div>
            <div style={head}>{testdata.testname}</div>
            <div style={para}>{testdata.info}</div>
            <div className="text-center">
              <img style={testimg} src={testdata.img} alt="..."></img>
            </div>
          </>
        ) : (
          <>
            <div style={back} onClick={() => props.onClick()}>
              BACK
            </div>
            <div style={heading}>Tests Included</div>
            <div className="container">
              <div className="row" style={innerrow}>
                {s?.map((r) => (
                  <div
                    className="col-4"
                    style={innercol}
                    onClick={() => handletestinfo(r)}
                  >
                    <div className="card text-white" style={{ border: "none" }}>
                      <img src={r.img} className="card-img" alt="..."></img>
                      <div className="card-img-overlay d-flex justify-content-center align-items-center">
                        <div style={testnm} className="px-2">
                          <div className="card-title text-center">
                            {r.testname}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default IncludedTest;
