const Consultationbelowlist = [
  {
    index: "1",
    title: "Consultation 1",
    date: "AUG 14",
    teststatus: "COMPLETED",
    calendarimg: "/Pathology/calendar.png",
    btn1: "Prescription and Reports",
    btn2: "Follow Up",
    btn3: "Schedule Consultation",
    btn4: "Join Consultation",
    status: "In-Clinic",
    lockimg: "/DoctorConsultation/lock.png",
    drpost: "Pediatrician",
  },
  {
    index: "2",
    title: "Consultation 2",
    date: "AUG 30",
    teststatus: "COMPLETED",
    calendarimg: "Pathology/calendar.png",
    btn1: "Prescription and Reports",
    btn2: "Follow Up",
    btn3: "Schedule Consultation",
    btn4: "Join Consultation",
    status: "In-Clinic",
    lockimg: "/DoctorConsultation/lock.png",
    drpost: "Pediatrician",
  },
  {
    index: "3",
    title: "Consultation 3",
    date: "SEP 13",
    teststatus: "COMPLETED",
    calendarimg: "Pathology/calendar.png",
    btn1: "Prescription and Reports",
    btn2: "Follow Up",
    btn3: "Schedule Consultation",
    btn4: "Join Consultation",
    status: "Online",
    lockimg: "/DoctorConsultation/lock.png",
    drpost: "Pediatrician",
  },
  {
    index: "4",
    title: "Consultation 4",
    date: "SEP 28-OCT 04",
    teststatus: "SCHEDULED",
    calendarimg: "Pathology/calendar.png",
    btn1: "Prescription and Reports",
    btn2: "Follow Up",
    btn3: "Schedule Consultation",
    btn4: "Join Consultation",
    status: "In-Clinic",
    lockimg: "/DoctorConsultation/lock.png",
    drpost: "Pediatrician",
  },
  {
    index: "5",
    title: "Consultation 5",
    date: "Oct 15 - Oct 21",
    teststatus: "UNSCHEDULED",
    calendarimg: "Pathology/calendar.png",
    btn1: "Prescription and Reports",
    btn2: "Follow Up",
    btn3: "Schedule Consultation",
    btn4: "Join Consultation",
    status: "In-Clinic",
    lockimg: "/DoctorConsultation/lock.png",
    drpost: "Pediatrician",
  },
  {
    index: "6",
    title: "Consultation 6",
    date: "Nov 02 - Nov 08",
    teststatus: "UNSCHEDULED",
    calendarimg: "Pathology/calendar.png",
    btn1: "Prescription and Reports",
    btn2: "Follow Up",
    btn3: "Schedule Consultation",
    btn4: "Join Consultation",
    status: "In-Clinic",
    lockimg: "/DoctorConsultation/lock.png",
    drpost: "Pediatrician",
  },
];
export default Consultationbelowlist;
