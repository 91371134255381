import { useState } from "react";
import Calendar from "react-calendar";
import Times from "./Times.js";

import React from "react";

function Time(props) {
  console.log("[[",props);
  return (
    <div>
      {props.showTime ? (
        <Times
          date={props.date}
          trimesternm={props.title}
          drpost={props.drpost}
          drstatus={props.drstatus}
          consultData={props.consultData}
          formattedDate={props.formattedDate}
          setRenderconsult={props.setRenderconsult}
          // onClick={props.onClick()}
        />
      ) : null}
    </div>
  );
}

export default Time;
