import React, { useState, useEffect } from 'react'
import configData from '../../config.json'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
var selected
function ReportBox ({ onClick, item, isVisible }) {
  const [clicked, setClicked] = useState(false)
  // const [selected, setSelected] = useState(1)
  const isTabScreen = useMediaQuery({
    query: '(min-width: 768px)' && '(max-width: 1023px)'
  })
  return (
    <div className='col-4 '  style={{ marginTop: '20px' }}>
      {/* <div
        style={
          isVisible && selected === item.id
            ? {
                // alignSelf: 'center',
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                // justifyContent: 'center',
                // width: '97.5%',
                // textAlign: "left",
                // width: window.innerWidth / 4.75,
                // height: window.innerWidth / 4.4,
                borderRadius: '12px',
                backgroundColor: '#FFFFFF',
                border: '0.5px solid #CBC6C6',
                boxSizing: 'border-box',
                border: '0.5px solid #CBC6C6',
                boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.1608)',
                borderRadius: '12px'
              }
            : {
                alignSelf: 'center',
                display: 'flex',
                width: '97.5%',
                // margin: "0px auto",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                // height: window.innerWidth / 4.4,
                borderRadius: '12px',
                backgroundColor: '#FFFFFF',
                border: '0.5px solid #CBC6C6',
                boxSizing: 'border-box',
                border: '0.5px solid #CBC6C6',
                borderRadius: '12px'
              }
        }
      > */}
      <div className='bg-white text-center'>
        <div
          style={{
            width: '100%',
            display: 'flex',
            paddingTop: '20px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src={'/Reports/perspective_matte.png'}
            style={{
              resizeMode: 'contain',
              height: '18px',
              width: '18px',
              marginRight: '10px'
            }}
          />
          <div
            style={{
              fontSize: '16px',
              fontWeight: '500',
              color: configData.THEME_COLORS.PRIMARY,
              marginRight: '23px',
              textTransform:"uppercase"
            }}
          >
            
          {moment(item?.scheduled_date).format('DD MMM')}

          </div>
          <img
            src={'/Reports/Line 66.png'}
            style={{
              resizeMode: 'contain',
              height: '17px',
              width: '1px'
            }}
          />
          <img
            src={'/Reports/73956801.png'}
            style={{
              marginLeft: '25px',
              resizeMode: 'contain',
              height: '24px',
              width: '24px'
            }}
          />
          <div
            style={{
              fontSize: '16px',
              fontWeight: '500',
              color: configData.THEME_COLORS.PRIMARY,
              marginLeft: '2px'
            }}
          >
            week {item?.week}
          </div>
        </div>
        <div
          style={{
            marginTop: '15px',
            paddingBottom: '10px',
            fontSize: '24px',
            fontWeight: '500',
            color: configData.THEME_COLORS.SECONDARY
          }}
        >
          {/* {item?.patient_consultation_name!=null && item?.patient_consultation_name} */}
          Consultation {item?.consultation_number}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex',justifyContent:"center" }}>
            <div
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '50%'
              }}
            >
               <img className="ms-3" style={{borderRadius:"50%"}}
                src={
                  item?.doctorDetails[0].profile_pic
                    ? "https://admin.myprega.com/" +  item?.doctorDetails[0].profile_pic
                    : "/Communication/test1.png"
                } alt='nt found'></img>
              {/* <img
                src={'/Reports/sunrise-diagnostics.png'}
                style={{
                  resizeMode: 'contain',
                  borderRadius: '50%'
                }}
              /> */}
            </div>

            <div style={{ height: '60px' }}>
              <div
                style={{
                  fontSize: isTabScreen ? '14px' : '16px',
                  fontWeight: '600',
                  color: '#333333',
                  marginLeft: '20px',
                  width: '150px'
                }}
              >
                {item.doctorDetails[0].fname}{" "}{item.doctorDetails[0].lname}
              </div>
              <div
                style={{
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#333333',
                  marginLeft: '20px',
                  width: '150px'
                }}
              >
                {item?.doctorDetails[0].expert_name}
              </div>
            </div>
          </div>
        </div>
        <div 
          style={{
            margin: '60px auto',
            fontWeight: '400',
            fontSize: '12px',
            color: '#7C9CBF',
            display: 'flex',
            cursor: 'pointer',
            height: '44.41px',
            width: '119.26px',
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '35px',
            boxShadow:
              isVisible && selected === item.id
                ? ' inset 0px 4px 8px rgba(44, 39, 56, 0.078)'
                : '0px 4px 8px rgba(44, 39, 56, 0.078)',
            color: isVisible && selected === item.id ? '#F0588B' : '#7C9CBF',
            borderRadius: '10px'
          }}
          onClick={() => {
            // setSelected(item.id)
            selected = item.id
            setClicked(!clicked)
            // console.log('selct?', item.id)
            onClick()
          }}
        >
          View Details
        </div>
      </div>
    </div>
  )
}

export default ReportBox
