import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import configData from "../../config.json";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import Doctor_consultation from "../Pages/Doctor_consultation";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom/dist";
import { useState,useEffect } from "react";
import axios from "axios";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const drawerMenu = [
  "/Dashboard",
  "Import Dataset",
  "Query Editor",
  "Big Data Flow",
  "My Flows",
  "Widget Dashboard",
  "Widget Overview",
  "Dashboard Overview",
  "ML Dashboards",
  "Profile",
];
export default function MiniDrawer({ title, userName, pregnancyDay }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [patientdata, setPatientdata] = useState([]);
  useEffect(() => {
    getsinglepatientdetail();
    sessionStorage.setItem("payment",patientdata?.payment_status)
  }, []);
 
  const getsinglepatientdetail = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: localStorage.getItem("userId"),
      });

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);
        setPatientdata(response.data.user);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  // const history = useHistory();
  const { pathname } = useLocation();

  // ['Dashboard', 'My FLows', 'Quick Data Flow', 'Data Flows', 'Big Data Flow', 'Custom Output']
  const createdDate = new Date(patientdata?.created_date);
  // const createdDate="10 2024 12:27:53 GMT+0530 (India Standard Time)";
  const currentDate = new Date();

  // Calculate the difference in days
  const differenceInTime = currentDate - createdDate;
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  // Check if 7 days have passed since the created date
  const isWithinSevenDays = differenceInDays <= 7;

  function handleOnClick(index) {
    let paid=patientdata?.payment_status;
    // const createdDate = new Date(patientdata?.created_date);
   
    if (index === 0) navigate("/patient/Dashboard");
    if (index === 1)
      {
        // if(paid===true)
             navigate("/patient/Doctor_consultation");
      } 
    if (index === 2) 
    {
      if(isWithinSevenDays || paid===true) 
          navigate("/patient/Health_Tracker");
        
    }
     
         
    if (index === 3) 
    {
      if(paid===true)
        navigate("/patient/Pathology");
    }
    
    if (index === 4) 
    {
      if(paid===true)
        navigate("/patient/Sonography");
    }
    
    if (index === 5) 
    {
      if(paid===true) 
          navigate("/patient/Reports");
    }
    if (index === 6) 
      {
        if(paid===true) 
          navigate("/patient/PregnancyCare");
      }
    if (index === 7) 
    {
      if(isWithinSevenDays || paid===true) 
        navigate("/patient/Garbha_Sanskar"); 
    }
          
    if (index === 8) 
    {
      if(isWithinSevenDays || paid===true) 
        navigate("/patient/Contact");
    }
   
    if (index === 9) 
      {
        if(isWithinSevenDays || paid===true) 
          navigate("/patient/profile");
      }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <div
        style={{
          backgroundColor: "#9ec7e8",
          minWidth: "100px",
          maxWidth: "100px",
          display: "flex",
          flex: 1,
        }}
      >
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              minWidth: "100px",
              // borderTopLeftRadius: 65,
              // borderBottomLeftRadius: 65
            },
          }}
        >
          <DrawerHeader>
            <img alt="nt found"
              src={"/Dashboard/logo-removebg.png"}
              style={{
                resizeMode: "contain",
                height: "60px",
                width: "60px",
                marginTop: 38,
                marginBottom: 1.6,
                marginRight: 10,
              }}
              width={160}
            />
          </DrawerHeader>
          <List sx={{ mt: 2 }}>
            {drawerMenu.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleOnClick(index)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      marginBottom: 2,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index === 0 ? (
                      <Tooltip placement="right-start" title="Dashboard">  
                        <img alt="nt found"
                          src={
                            pathname === "/Dashboard"
                              ? "/Dashboard/dashboard2.png"
                              : "/Dashboard/dashboardlight.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      </Tooltip>
                    ) : index === 1 ? (
                      <Tooltip
                        placement="right-start"
                        title={patientdata?.payment_status===false? "This is free trial, To access this you need to purchase cherish care package":"Doctor Consultation"}
                      >
                         <div style={{ position: "relative", display: "inline-block" }}>
                        <img alt="nt found"
                          src={
                            pathname == "/Doctor_consultation"
                              ? "/Dashboard/doctorDark.png"
                              : "/Dashboard/Icon-map-doctor.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : index === 2 ? (
                      <Tooltip placement="right-start" 
                      title={patientdata?.payment_status===false && !(isWithinSevenDays)
                      ? "This is free trial, To access this you need to purchase cherish care package":"Health Tracker"}>
                         <div style={{ position: "relative", display: "inline-block" }}>
                         <img alt="nt found"
                          src={
                            pathname == "/Health_Tracker"
                              ? "/Dashboard/healthtrackerDark.png"
                              : "/Dashboard/healthtracker.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false && !(isWithinSevenDays) ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                       
                      
                      </Tooltip>
                      // isWithinSevenDays
                    ) : index === 3 ? (
                      <Tooltip placement="right-start"
                        title={patientdata?.payment_status===false? "This is free trial, To access this you need to purchase cherish care package":"Pathology"}>
                         <div style={{ position: "relative", display: "inline-block" }}>
                         <img alt="nt found"
                          src={
                            pathname == "/Pathology"
                              ? "/Dashboard/pathologyDark.png"
                              : "/Dashboard/microscope.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "30px",
                            width: "25px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : index === 4 ? (
                      <Tooltip placement="right-start"
                          title={patientdata?.payment_status===false? "This is free trial, To access this you need to purchase cherish care package":"Sonography"}>
                          <div style={{ position: "relative", display: "inline-block" }}>
                          <img alt="nt found"
                          src={
                            pathname == "/Sonography"
                              ? "/Dashboard/sonographyDark.png"
                              : "/Dashboard/sonography.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "26px",
                            width: "26px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : index === 5 ? (
                      <Tooltip placement="right-start" 
                      title={patientdata?.payment_status===false? "This is free trial, To access this you need to purchase cherish care package":"Report"}>
                          <div style={{ position: "relative", display: "inline-block" }}>
                          <img alt="nt found"
                          src={
                            pathname == "/Reports"
                              ? "/Dashboard/reportsDark.png"
                              : "/Dashboard/reports.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : index === 6 ? (
                      <Tooltip placement="right-start" 
                      title={patientdata?.payment_status===false ? "This is free trial, To access this you need to purchase cherish care package":"Pregnancy Care"}> 
                         <div style={{ position: "relative", display: "inline-block" }}>
                         <img alt="nt found"
                          src={
                            pathname == "/PregnancyCare"
                              ? "/Dashboard/summaryDark.png"
                              : "/Dashboard/caresummary.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "30px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"15px",height:"15px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : index === 7 ? (
                      <Tooltip placement="right-start"   title={patientdata?.payment_status===false && !(isWithinSevenDays)
                        ? "This is free trial, To access this you need to purchase cherish care package":"Garbhasanskar"}>
                       
                          <div style={{ position: "relative", display: "inline-block" }}>
                          <img alt="nt found"
                          src={
                            pathname == "/Garbha_Sanskar"
                              ? "/Dashboard/garbhaDark.png"
                              : "/Dashboard/garbhsanskar.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false && !(isWithinSevenDays) ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : index === 8 ? (
                      <Tooltip placement="right-start"  title={patientdata?.payment_status===false && !(isWithinSevenDays)
                        ? "This is free trial, To access this you need to purchase cherish care package":"Contact"}>
                       
                          <div style={{ position: "relative", display: "inline-block" }}>
                          <img alt="nt found"
                          src={
                            pathname == "/Contact"
                              ? "/Dashboard/contactDark.png"
                              : "/Dashboard/contactus.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false && !(isWithinSevenDays) ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : index === 9 ? (
                      <Tooltip placement="right-start"  title={patientdata?.payment_status===false && !(isWithinSevenDays)
                        ? "This is free trial, To access this you need to purchase cherish care package":"Profile"}>
                       
                         <div style={{ position: "relative", display: "inline-block" }}>
                         <img alt="nt found"
                          src={
                            pathname == "/profile"
                              ? "/Dashboard/profileDark.png"
                              : "/Dashboard/profile.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                          <div className={patientdata?.payment_status===false && !(isWithinSevenDays) ? "locknav":"locknav nolock"}>
                         <div className="d-flex justify-content-center align-item-center">
                         <img src="/DoctorConsultation/lock.png" alt="nt found" style={{width:"20px",height:"20px"}}></img>
                         </div>
                         </div>
                         </div>
                      </Tooltip>
                    ) : null}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
      {/* <Box
        component='main'
        sx={{ flexGrow: 1, p: 3, backgroundColor: '#FFF0F5' }}
      ></Box> */}
    </Box>
    // </div>
  );
}
