const sonographylist = [
  {
    index: "1",
    title: "Obstetric sonography (First time)",
    date: "AUG 14",
    teststatus: "COMPLETED",
    drimg: "/Pathology/jondoe2.png",
    drname: "Dr. Jane Doe",
    drid: "Phlebologist ID",
    mobno: "+91 9876543210",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "WEEK 1",
    drpost: "sonologist",
    msg1: "Obstetric ultrasound examination provides an accurate and safe clinical assessment of the gravid uterus throughout a woman’s pregnancy including characterizing pregnancy location, identifying the number of embryos present, and aiding in the prenatal diagnosis of fetal anomalies",
    msg2: "lorem ipsum dolor sit amet",
  },
  {
    index: "2",
    title: "Routine Sonography",
    date: "AUG 30",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    msg1: "Routine ultrasound is usually performed every three to four weeks to monitor the fetus and pregnant uterus throughout pregnancy.",
    msg2: "",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drpost: "sonologist",
  },
  {
    index: "3",
    title: "Nuchal Translucency Test",
    date: "SEP 13",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    msg1: " A nuchal translucency (NT) test is an optional ultrasound performed in the first trimester of pregnancy. It helps determine your baby's risk of congenital conditions like Down syndrome.",
    msg2: "",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drpost: "sonologist",
  },
  {
    index: "4",
    title: "Anomaly Scan",
    date: "SEP 28-OCT 04",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    msg1: "An anomaly scan is an ultrasound done to assess the physical development of the fetus and to check the presence of malformations in the growing fetus. It is done between 18 and 20 weeks because before that the organs of the fetus cannot be delineated clearly on an ultrasound.",
    msg2: "",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drpost: "sonologist",
  },
  {
    index: "5",
    title: "Fetal Echocardiography",
    date: "SEP 28-OCT 04",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    msg1: "This test is done to detect a heart problem before the baby is born. It can provide a more detailed image of the baby's heart than a regular pregnancy ultrasound. The test can show: Blood flow through the heart.",
    msg2: "",
    msg2: "",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drpost: "sonologist",
  },
  {
    index: "6",
    title: "Fetal well-being scan",
    date: "SEP 28-OCT 04",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    msg1: "This scan aims to determine the growth and health of the fetus by: Measurement of the size of the fetal head (HC) ,abdomen (AC) and femur (FL). The HC, AC, and FL measurements are then used to calculate an estimate of the baby's fetal weight ( EFW). The purpose of this scan is to assess the fetus' growth by measuring the head, abdomen and femur bone. To establish a current estimated fetal weight. To identify and measure fetal heart rate.",
    msg2: "",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drpost: "sonologist",
  },
  {
    index: "7",
    title: "Colour Doppler Ultrasound",
    date: "SEP 28-OCT 04",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    msg1: "Doppler Ultrasound gives doctors a visual or audible representation of blood movement through veins, arteries and blood vessels with sound waves. The reflected sound can be used to diagnose restricted blood flow, blood clotsand fetal health.",
    msg2: "",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drpost: "sonologist",
  },
];
export default sonographylist;

 