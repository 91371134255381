import { useState } from "react";
import Calendar from "react-calendar";
import Times1 from "./Times1.js";

import React from "react";
let scheduleddata;
function Time1(props) {
  scheduleddata = props.scheduleddata;
  console.log(
    "QQQQQQQQ23Q",
    props?.date,
    scheduleddata?.drpost,
    scheduleddata?.title,
    props?.state,
    props?.timing,
    props?.formattedDate,
    props?.doctorInfo,
    props.showTime
  );
  return (
    <div>
      {props.showTime ? (
        <Times1
          date={props.date}
          // trimesternm={props.title}
          drpost={scheduleddata?.drpost}
          // drstatus={props.drstatus}
          // onClick={props.onClick()}
          title={scheduleddata?.title}
          state={props.state}
          timings={props.timings}
          info={props.info}
          formattedDate={props.formattedDate}
          doctorInfo={props.doctorInfo}
          dateclick={props.dateclick}
          setShowTimes={props.setShowTimes}
        />
      ) : null}
    </div>
  );
}

export default Time1;
