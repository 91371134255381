import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import configData from "../../config.json";
import EventComponent from "./EventComponent";
import EventTypeHeader from "./EventTypeHeader";
import NoEventBox from "./NoEventBox";
function RightBox({
  title,
  onClick,
  onClickDay,
  date,
  dateClicked,
  upcoming,
  consultByDate,
  consults,
}) {
  if (date == "") {
    date = moment(moment().toDate()).format("Do MMMM YYYY");
  }
  // console.log("date-----",consults);
  const [value, onChange] = useState([new Date()]);
  // console.log('ops', moment(moment().toDate()).format('DD MMMM YYYY'))
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });
  return (
    <div
      style={{
        alignSelf: "flex-end",
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        width: "30vw",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          marginTop: 50,
          fontSize: isTabScreen ? "24px" : "32px",
          fontWeight: "600",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: configData.THEME_COLORS.SECONDARY,
          fontFamily: "Poppins",
        }}
      >
        {title}
      </div>
      <div style={{ marginTop: 20, alignSelf: "center" }}>
        <Calendar
          onChange={onChange}
          // selectRange={true}
          // value={value}
          value={value}
          tileClassName={({ date, view }) => {
            if (
            (upcoming?.some(item=>moment(item.scheduled_date).format("YYYY-MM-DD")===moment(date).format("YYYY-MM-DD")))
            ) {
              return "highlight-date";
            }
          }}
          onClickDay={onClickDay}
        />
      </div>
      <div
        style={{
          marginTop: 10,
          alignSelf: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NoEventBox
          title={
            date === ""
              ? ""
              : moment(moment().toDate()).format("Do MMMM YYYY") == date &&
                consults?.length > 0
              ? "Today's Event"
              : consultByDate?.length > 0
              ? "Event On"
              : "No Event On"
          }
          date={date}
          dateClicked={moment(moment().toDate()).format("Do MMMM YYYY") == date}
        />
      </div>
      <div style={{height:"70vh",overflowY:"scroll"}}>
      {consultByDate?.map((item) => (
        <EventComponent
          doctorName={`${item.doctor_fname} ${item.doctor_lname} `}
          drpost={item.drpost}
          title={`${item.consultation_type} Consultation with ${item.doctor_fname} ${item.doctor_lname} At ${item.timing} `}
          scheduled={true}
          onClick={onClick}
          eventDate={moment(item?.scheduled_date).format("MMM DD, YYYY")}
          eventToday={true}
          consultation_no={item.consultation_number}
          doctor_timing_id={item.doctor_timing_id}
          consultation_type={item.consultation_type}
          drname={`${item.doctor_fname} ${item.doctor_lname} `}
          drid={item?.doctor_id}
          timing={item?.timing}
          medical_expertise={item?.medical_expertise}
          id={item?.id}
        />
      ))}
        <div
        style={{
          alignSelf: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
       {upcoming.length>0 && <EventTypeHeader title={"Upcoming Event"} />}
      </div>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "15px",
         
        }}
      >
        {upcoming?.map((item) => (
          <EventComponent
            doctorName={`${item.doctor_fname} ${item.doctor_lname} `}
            drpost={item.drpost}
            title={`${item.consultation_type} Consultation with ${item.doctor_fname} ${item.doctor_lname} At ${item.timing}`}
            scheduled={true}
            onClick={onClick}
            eventDate={moment(item?.scheduled_date).format("MMM DD, YYYY")}
            eventToday={true}
            consultation_no={item.consultation_number}
            doctor_timing_id={item.doctor_timing_id}
            consultation_type={item.consultation_type}
            drname={`${item.doctor_fname} ${item.doctor_lname} `}
            drid={item?.doctor_id}
            timing={item?.timing}
            id={item?.id}
            medical_expertise={item?.medical_expertise}
          />
        ))}
      </div>
      </div>
    
     
    </div>
  );
}

export default RightBox;
