import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import configData from "../config.json";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CallIcon from "@mui/icons-material/Call";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import moment from "moment";


let currentDate = null; 
function Chat({
  title,
  endoption,
  drid,
  patientid,
  fromInspect,
  chatData,
  onBackClick,
  consultationnumber,
  startVideoConsultFromChat,
  onClick,
  profileData,
  followup,addPrescription,consultaionId,consultItem,setRenderagain
}) {
 
  const chatContainerRef = useRef(null);
  const [showdiv, setshowdiv] = useState(false);
  const [message, setMessage] = useState("");
  const [incomingmsg, setIncomingmsg] = useState("");
  const [prescriptionImage, setPrescriptionImage] = useState(null);
  const [linkSent, setLinkSent] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showIcons,setShowIcons]=useState(false);
  const [showendoption,setShowendoption]=useState(false);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
useEffect(()=>{
  const followUpDate= followup;
  const followUpDateTime = new Date(followUpDate).getTime();
  const currentDate = new Date().getTime();
  const dateDifference = followUpDateTime - currentDate;
  setShowIcons(dateDifference > 0 && dateDifference <= 259200000);
},[]);
useEffect(()=>{
      console.log("@$%",endoption);
},[endoption]);
  // const style = {
  //   position: "absolute",
  //   top: "60%",
  //   left: "40%",
  //   transform: "translate(-50%, -50%)",
  //   width: isTabScreen ? 200 : 500,
  //   bgcolor: "background.paper",
  //   boxShadow: 24,
  //   p: 4,
  //   border: "1px solid #D4CDCD",
  //   overflowY: "scroll",
  //   boxShadow: "0px 0px 90px rgba(90, 90, 90, 0.1)",
  //   borderRadius: "40px 12px 12px 40px",
  // };
  // const openModal = (imageUrl) => {
  //   console.log("Opening modal...");
  //   setSelectedImage(imageUrl);
  //   setIsModalOpen(true);
  //   console.log("Modal opened.", selectedImage, isModalOpen);
  // };

  // const closeModal = () => {
  //   console.log("Closing modal...");
  //   setSelectedImage(null);
  //   setIsModalOpen(false);
  //   console.log("Modal closed.", selectedImage, isModalOpen);
  // };

  useEffect(() => {
    getmsg();
  }, [chatData]);

  useEffect(() => {
    getmsg();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    });
  },[incomingmsg]);
  const scrollToBottom = () => {
    console.log("inside scrollto bottom");
    if (chatContainerRef.current) {
      console.log("Scrolled");
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const getmsg = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/inbox/get_message`, {
        sender_id: chatData.id,
        reciever_id: drid,
      });
      if (response.status === 200) {
        console.log("@@##", response.data);
        const messages = response.data.messages.map((msg) => {
          const utcTimestamp = msg.created_at;
          const dateObject = new Date(utcTimestamp);
          const istTimestamp = dateObject.toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          });

          return {
            ...msg,
            created_at_ist: istTimestamp,
          };
        });
        setIncomingmsg(messages.reverse());
      } else {
        alert("error");
      }
    } catch {
      console.log("error in sending msg");
    }
  };
 
  const modopen = (imageUrl) => {
    setOpen(true);
    setSelectedImage(imageUrl);
  };
 
  
    const consultcomplete = async () => {
      setRenderagain(true);
      onBackClick();
      console.log("#DATA",chatData,consultaionId);
      try {
        console.log("ZZZZfirst", chatData.id, consultationnumber);
        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/update_consultation_status`,
          {
            patient_id: chatData.id,
            consultation_id: consultaionId,
            is_completed: 1,
          }
        );
        if (response.data.success) {
          alert("Consultation Mark as completed");
        } else {
          console.log("Error in completing consultation");
        }
      } catch (error) {
        console.log("error");
      }
    };
       

  const sendMessage = async (id, imagedata) => {
    try {
      const payload = {
        sender_id: drid,
        reciever_id: chatData.id,
        message: id === 1 ? message : imagedata,
        is_image: id === 2 ? 1 : 0,
      };
      console.log("SENDINGURL", payload);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/inbox/send_message`,
        payload
      );
      if (response.status === 200) {
        // alert("msg sent successfully");
        setShowPrescription(true);
      
      } else {
        alert("error");
      }
    } catch (error) {
      console.log("error in sending msg", error);
    }

    getmsg();
    setMessage("");
  };
  const handleClose = () => setOpen(false);
  const formatTime = (timestamp) => {
    const dateObject = new Date(timestamp);
    return dateObject.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const showoption = () => {
    setshowdiv(!showdiv);
  };
  const consultationno = {
    color: "#004AAD",
    fontSize: isTabScreen ? "13px" : "16px",
    fontWeight: "400",
    paddingTop: "25px",
    paddingBottom: "20px",
    textAlign: "center",
    paddingLeft: "15px",
  };
  const msgbox = {
    height: "70vh",
    width: "90%",
    margin: "0px auto",
    backgroundColor: "#FFF0F5",
  };
  const msg = {
    height: "60vh",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "#FFF0F5",
  };
  const nmimg = {
    display: "flex",
    width:"100%",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  };
  const menu = {
    width: "120px",
    height: "35px",
    backgroundColor: "gray",
    position: "absolute",
    top: "15%",
    right: "10px",
  };

  const submenu = {
    margin: "5px",
    backgroundColor: "#141124",
    fontSize: "13px",
    textAlign: "center",
    color: "white",
    cursor: "pointer",
  };
  const inputbox = {
    height: "40px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "7px",
  };
  const innerinput = {
    border: "none",
    height: "40px",
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          position:"absolute",
          right:"10%",
          left:"10%",
          top:"2%",
          width:"auto",
          height:"130vh",
          zIndex:"999",
        
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              justifyContent: "space-around",
            }}
          >
            <img
              onClick={() => onBackClick()}
              src={"/Communication/right.png"}
              style={{
                resizeMode: "contain",
                height: isTabScreen ? "21px" : "30px",
                width: isTabScreen ? "14px" : "26px",
                marginLeft: "10px",
                marginTop: "2px",
                cursor: "pointer",
              }}  alt="not display"
            />

         
          </div>
  
        
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
            <img
                src={
                  chatData?.profile_pic
                    ? configData.API_URL + chatData?.profile_pic
                    : "/Communication/test1.png"
                }
                style={{
                  resizeMode: "contain",
                  height: isTabScreen ? "25px" : "45px",
                  width: isTabScreen ? "25px" : "45px",
                  marginRight: "15px",
                  borderRadius:"50%",
                }} alt="not display"
              />
          
            <div
              style={{
                fontSize: isTabScreen ? "15px" : "21.3988px",
                fontWeight: "600",
              }}
            >
              {chatData?.fname + " " + chatData?.lname + ""}
            </div>
            </div>
            <div className="d-flex align-items-center">
            {fromInspect && 
              // showIcons && 
              (
                <>
                  <CallIcon
                    style={{
                      color: "#eb6391",
                      fontSize: 30,
                      cursor: "pointer",
                      marginRight: "16px",
                    }}
                  />

                  <VideoCallIcon
                    style={{
                      color: "#eb6391",
                      fontSize: 35,
                      cursor: "pointer",
                      marginRight: "16px",
                    }}
                    onClick={() => startVideoConsultFromChat()}
                  />
                </>
              )}
                 <div>
                  <img
              className="pe-3"
              src={"/Communication/Shape.png"}
              onClick={() => showoption()}
              alt="not found"
            ></img></div>
            {showdiv && (
              <div style={menu}>
                {endoption === true ? <div style={submenu} onClick={consultcomplete}>End Consulation</div>:<div
                  style={submenu}
                  onClick={() => {
                    onClick();
                  }}
                >
                 {"Add Prescription"}
                </div>}
             
              </div>
            )}
            </div>
          </div>
          <hr className="w-100 mt-2 mb-3"></hr>
          {/* MSGBOX CODE STARTS HERE  */}
          <div style={msgbox}>
            <div
              style={{
                height: "50px",
                backgroundColor: "",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
            
            </div>
            <div style={msg} ref={chatContainerRef}>
              <div className="w-75 mx-auto defaultmsg p-2">
              Messages are end-to-end encrypted. No one outside of this chat, not even MyPrega, can read or listen to them.
              </div>
              {incomingmsg && incomingmsg.length > 0 ? (
                 incomingmsg.map((item, index) => {
                        const currentDate = moment(item.created_at).format('DD-MM-YYYY');
                 const prevDate = index > 0 ? moment(incomingmsg[index - 1].created_at).format('DD-MM-YYYY') : null;
    const shouldDisplayDate = currentDate !== prevDate;

    return (
      <div key={item.id}>
        {shouldDisplayDate && (
          <div className="mt-1 mx-auto text-white d-flex justify-content-center align-items-center" 
               style={{width:"90px",height:"30px",fontSize:"11px",backgroundColor:"rgb(240, 88, 139)",borderRadius:"14px"}}>
            {moment(item.created_at).format('DD MMM YYYY')}
          </div>
        )}
        
        {/* Render the message */}
        <div className="mx-5 my-2">
          {item.is_image ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => modopen(item.message)}
              className={`p-2 ${
                chatData.id === item.user_id
                  ? "rightsideclass1"
                  : "leftsideclassimg"
              }`}
            >
              <img
                src={item.message}
                className="imgmsg"
                alt="not found"
              />
              <div className="text-end"
                style={{
                  color:
                    chatData.id === item.user_id
                      ? "black"
                      : "#c4c4c4",
                  fontSize: "8px",
                }}
              >
                {moment(item.created_at).format('hh:mm A')}
              </div>
            </div>
          ) : 
          item.is_system_generated===1?(<div style={{width:"fit-content"}} className="mx-auto defaultmsg p-2">{item.message}</div>):(<div
            className={`p-2 ${
              chatData.id === item.user_id
                ? "rightsideclass1"
                : "leftsideclass1 mlauto"
            } ${
              item.message?.length > 18 ? "setwidth" : "removewidth"
            }`}
          >
            {item.message}
            <div className="text-end"
              style={{
                color:
                  chatData.id === item.user_id
                    ? "black"
                    : "#c4c4c4",
                fontSize: "8px",
              }}
            >
              {moment(item.created_at).format('hh:mm A')}
            </div>
          </div>)}
        </div>
      </div>
    );
  })
                ) : (
                   <p className="text-center pt-2">No messages available.</p>
                  )}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                size="lg"
              >
                <Box className="modal-container">
                  <div className="modal-content text-end w-50" style={{  backgroundColor: "#FFF0F5"}}>
                    <span className="close" onClick={handleClose} style={{color:"black"}}>
                      &times;
                    </span>
                    <div className="modal-content-scrollable">
                      <img src={selectedImage} alt="Full-size" 
                      style={{width:"100%"}}
                      />
                      {/* Add your medicine list here */}
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
            <div style={{ backgroundColor: "#fff0f5", paddingBottom: "15px" }}>
              <div className="mx-2" style={inputbox}>
                <img alt="nt found"
                  src="/Communication/Attachbutton.png"
                  className="mx-2"
                  // onClick={}
                ></img>
                <input
                  className="w-75"
                  type="text"
                  style={innerinput}
                  value={message}
                  placeholder="Type your message..."
                  onChange={(e) => setMessage(e.target.value)}
                />
                <img alt="nt found"
                  src="/Communication/sendbtn.png"
                  className="mx-2"
                  onClick={() => sendMessage(1)}
                ></img>
              </div>
            </div>
          </div>
          {/* MSGBOX CODE ENDS HERE */}
        </div>
      </div>
      {/* {showPrescription == false && ( */}
      {/* <div className="mt-5">
        <PrescriptionComponent
          medicationDetails={medicationDetails}
          profileData={profileData}
          chatData={chatData}
         
        />
      </div> 
      {/* )} */}
    </div>
  );
}

export default Chat;
