import React, { useState } from "react";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";
function NoEventBox({ title, date, dateClicked }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  return (
    <div
      style={{
        padding: "4px",
        margin: isTabScreen ? "10px" : "20px",
        fontSize: isTabScreen ? "12px" : "16px",
        fontWeight: "500",
        fontFamily: "Poppins",
        borderRadius: "12px",
        backgroundColor: configData.THEME_COLORS.SECONDARY,
        color: "white",
        flexWrap: "wrap",
        textAlign: "center",
        width: title === "No Event Today" ? "200px" : "",
        marginLeft: title === "No Event Today" ? "25%" : "10%",
      }}
    >
      {dateClicked
        ? title
        : title === ""
        ? "No Event Today"
        : title + " " + date}
    </div>
  );
}

export default NoEventBox;
