import React, { useState } from "react";
import ragalist from "./ragalist";
import { useMediaQuery } from "react-responsive";
// import Audio
let testdata;
function PregnancyRaga({ setExpand, setSelected }) {
  const [testinfo, settestinfo] = useState(false);
  const handletestinfo = (r) => {
    settestinfo(true);
    testdata = r;
  };
  function backtotestlist() {
    settestinfo(false);
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const back = {
    fontWeight: "600",
    fontSize: "12px",
    color: "#004AAD",
    cursor: "pointer",
  };
  const heading = {
    color: "#F0588B",
    fontWeight: "600",
    fontSize: isTabScreen ? "20px" : "24px",
    paddingTop: "10px",
  };
  const innercol = {
    marginTop: "30px",
    cursor: "pointer",
  };
  const innerrow = {
    paddingRight: isTabScreen ? "15px" : "50px",
  };
  const testnm = {
    fontSize: "14px",
    fontWeight: "400",
    color: "#004AAD",
    height: isTabScreen ? "50px" : "70px",
    width: "90px",
    backgroundColor: "#FBFBFB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const maindiv = {
    marginLeft: "59px",
    marginRight: "69px",
    backgroundColor: "#FBFBFB",
    borderRadius: "12px",
    paddingBottom: "20px",
    marginBottom: "20px",
  };
  const head = {
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingBottom: "15px",
    fontSize: "20px",
    fontWeight: "500",
    color: "#333333",
  };
  const para = {
    paddingBottom: "66px",
    fontFamily: "poppins",
    fontSize: "14px",
    fontWeight: "400",
    color: "#333333",
  };
  const parahead = {
    paddingBottom: "10px",
    marginTop: "30px",
    fontFamily: "poppins",
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333",
  };
  const testimg = {
    width: "585px",
    height: "400px",
    margin: "0px auto",
    paddingBottom: "100px",
  };
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
    overflowY: "scroll",
  };

  return (
    <div style={wrapper}>
      <div>
        {testinfo === true ? (
          <>
            <div style={back} onClick={() => backtotestlist()}>
              BACK
            </div>
            <div style={heading}>{testdata.testname}</div>
            <div style={parahead}>{testdata.infohead}</div>
            <div style={para}>{testdata.info}</div>
            <audio controls>
              <source src={testdata.img2} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
           
          </>
        ) : (
          <>
            <div
              style={back}
              onClick={() => {
                setSelected(false);
                setExpand(true);
              }}
            >
              BACK
            </div>
            <div style={heading}>Pregnancy Raga </div>
            <div className="container">
              <div className="row" style={innerrow}>
                {ragalist.map((r) => (
                  <div
                    className="col-md-4 col-lg-3"
                    style={innercol}
                    onClick={() => handletestinfo(r)}
                  >
                    <div className="card text-white" style={{ border: "none" }}>
                      <img src={r.img} className="card-img" alt="..."></img>
                      <div className="card-img-overlay d-flex justify-content-center align-items-center">
                        <div style={testnm}>
                          <div className="card-title text-center">
                            {r?.testname}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default PregnancyRaga;
