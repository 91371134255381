import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import configData from "../../config.json";
import Time from "./Time";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
let dateclicked;
let r;
function RightBoxofpathology({
  title,
  trimesternm,
  drpost,
  drstatus,
  onClick,
  onClick1,
  showTime,
  consultData,
  weekDates,
  setRenderconsult
}) {
  console.log("weekkkkkk",weekDates,showTime);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen2 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });
  const [value, setValue] = useState(new Date());

  const [date, setdate] = useState("02 MAY 2023");
  const [formattedDate, setFormatteddate] = useState();

  const [highlightDates, setHighlightDates] = useState([]);
  
  const [enabledDates, setEnabledDates] = useState(weekDates);
  useEffect(() => {
    console.log("check???", consultData?.scheduled_date);
    const scheduledDate = consultData?.scheduled_date;

    if (scheduledDate) {
      const initialDate = new Date(scheduledDate);
      console.log("DATE SET>", initialDate);
      let dates = [];
      dates.push(initialDate);
      setHighlightDates(dates);
      setdate(moment(scheduledDate).format("Do MMMM YYYY"));
      setValue(initialDate);

      setFormatteddate(moment(initialDate).format("YYYY-MM-DD"));

      onClick1(moment(initialDate).format("YYYY-MM-DD"));
    }
  }, [consultData]);

  const handlechange = (e) => {
    console.log("HandleChange");
    dateclicked = e;
    r = moment(dateclicked).format("Do MMMM YYYY");
    setdate(r);
    setFormatteddate(moment(e).format("YYYY-MM-DD"));
    onClick1(r);
  };

  function tileClassName({ date, view }) {
    // console.log("Checking date:", date);
    const isHighlighted = highlightDates.some((dDate) =>
      moment(dDate).isSame(moment(date), "day")
    );

    if (view === "month" && isHighlighted) {
      // console.log("Date is highlighted!", date);
      return "highlight";
    } else {
      // console.log("Date is not highlighted.");
      return "red_item";
    }
  }

  useEffect(() => {
    if (enabledDates?.length > 0) {
      const firstEnabledDate = new Date(enabledDates[0]);
      // Set the initial month to the month of the first enabled date
      setValue(firstEnabledDate);
    }
  }, [enabledDates]);

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  // Function to determine whether a tile (date) should be disabled
  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const currentDate = date;
      let isEnabled = false;
      setEnabledDates(weekDates);
   
      enabledDates?.forEach((enabledDate) => {
        const [year, month, day] = enabledDate?.split("-").map(Number);
        const enabledDateObj = new Date(year, month - 1, day); // month is 0-indexed in JavaScript dates

        if (isSameDay(currentDate, enabledDateObj)) {
          isEnabled = true;
        }
      });

      return !isEnabled;
    }

    return false;
  };
  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <div style={{ width: "30vw" }}>
          <div
            style={{
              marginTop: 50,
              fontSize: isTabScreen ? "24px" : "32px",
              fontWeight: "600",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: configData.THEME_COLORS.SECONDARY,
              fontFamily: "Poppins",
            }}
          >
            {title} 
          </div>
          <hr className="w-100"></hr>
          <h4 className="text-center fw-bolder py-2">{trimesternm}</h4>
          <div className="px-3" style={{ marginTop: 20 }}>
            <Calendar
              onChange={handlechange}
              value={value}
              // select="range"
              // maxDate={new Date(2023, 3, 14)}
              // minDate={new Date(2023, 3, 1)}
              // rangeHighlight={true}
              // showRangeLabels={true}
              // tileClassName={() => {
              //   return "red_item";
              // }}
              tileClassName={tileClassName}
              tileDisabled={tileDisabled}
              // tileClassName={({ date }) => {
              //   return date.getTime() === value.getTime()
              //     ? "highlight"
              //     : "red_item";
              // }}

              // onClick={onClick1}
              // onClickDay={() => onClick1()}
              // setShowTime={()=>props.setShowTime(true)}
            />
          </div>
          <Time
            showTime={showTime}
            value={value}
            title={trimesternm}
            drpost={drpost}
            drstatus={drstatus}
            onClick={onClick}
            date={date}
            formattedDate={formattedDate}
            consultData={consultData}
            setRenderconsult={setRenderconsult}
          />
        </div>
      </div>
    </>
  );
}

export default RightBoxofpathology;
