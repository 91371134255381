import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import configData from "../config.json";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import axios from "axios";
import HealthVitalHistory from "./HealthVitalHistory";
function RecentHistoryRightBox({ data }) {
  console.log("HEALTHVITALS DATA", data);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  var healthVitals = [
    {
      date: "Mar 23 2023 10:00:00 AM",
      value1: "80",
      value2: "102",
      value3: "98.6",
      value4: "60",
      unit1: "mg/dL",
      unit2: "72/mmhg",
      unit3: "F",
      unit4: "kg",
      type1: "Sugar Level",
      type2: "Blood Pressure",
      type3: "Temperature",
      type4: "Weight",
    },
    {
      date: "Mar 21 2023 10:00:00 AM",
      value1: "80",
      value2: "102",
      value3: "98.6",
      value4: "60",
      unit1: "mg/dL",
      unit2: "72/mmhg",
      unit3: "F",
      unit4: "kg",
      type1: "Sugar Level",
      type2: "Blood Pressure",
      type3: "Temperature",
      type4: "Weight",
    },
    {
      date: "Mar 19 2023 10:00:00 AM",
      value1: "80",
      value2: "102",
      value3: "98.6",
      value4: "60",
      unit1: "mg/dL",
      unit2: "72/mmhg",
      unit3: "F",
      unit4: "kg",
      type1: "Sugar Level",
      type2: "Blood Pressure",
      type3: "Temperature",
      type4: "Weight",
    },
    {
      date: "Mar 17 2023 10:00:00 AM",
      value1: "80",
      value2: "102",
      value3: "98.6",
      value4: "60",
      unit1: "mg/dL",
      unit2: "72/mmhg",
      unit3: "F",
      unit4: "kg",
      type1: "Sugar Level",
      type2: "Blood Pressure",
      type3: "Temperature",
      type4: "Weight",
    },
  ];

  const [desc, setDesc] = useState(true);
  const [healthvitals, setHealthVitals] = useState();
  useEffect(() => {
    getHealthVitals();
    console.log("HEALTHVIII", healthvitals);
  }, []);
  const getHealthVitals = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details`,
        {
          patient_id: data.id,
        }
      );

      if (response.data?.success) {
        console.log("healthvitals-", response.data?.health_Details);
        setHealthVitals(response.data?.health_Details);
      }
    } catch (error) {
      console.error("Error ge Healthvital:", error);
    }
  };

  const handleSort = () => {
    const data = healthvitals;
    data.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return desc ? c - d : d - c;
    });
    setHealthVitals(data);
  };
  return (
    <div
      style={{
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <Typography
        paragraph
        sx={{
          color: configData.THEME_COLORS.SECONDARY,
          marginTop: "59px",
          marginLeft: isTabScreen ? "20px" : "30px",
        }}
      >
        <div
          style={{
            fontSize: isTabScreen ? "18px" : "32px",
            fontWeight: "600",
            fontFamily: "Poppins",
          }}
        >
          {"Recent History"}
        </div>
      </Typography>

      <Typography
        paragraph
        sx={{
          color: configData.THEME_COLORS.PRIMARY,
          alignSelf: "flex-end",
          display: "flex",
        }}
      >
        <div
          style={{
            fontSize: isTabScreen ? "14px" : "20px",
            fontWeight: "500",
            fontFamily: "Poppins",
            cursor: "pointer",
          }}
          onClick={() => {
            setDesc(!desc);
            handleSort();
          }}
        >
          {desc ? "New - Old" : "Old - New"}
        </div>

        <img
          src={"/Communication/sort.png"}
          style={{
            marginTop: "8px",
            resizeMode: "contain",
            height: "18px",
            width: "18px",
          }}
        />
      </Typography>
      <div
        style={{
          overflowY: "scroll",
          height: "100vh",
        }}
      >
        {healthvitals?.map((item, index) => {
          return (
            <div style={{ marginBottom: -10 }}>
              <HealthVitalHistory
                date={moment(item.date).format("Do MMMM YY")}
                type1={item.type1}
                type2={item.type2}
                type3={item.type3}
                type4={item.type4}
                value1={item.sugar_level}
                value2={item.blood_pressure}
                value3={item.temperature}
                value4={item.weight}
                unit1={"mg / dL"}
                unit2={"72 / mmhg"}
                unit3={"F"}
                unit4={"kg"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RecentHistoryRightBox;
