import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState } from "react";
import { useEffect } from "react";
function Contact() {
  const [nm, setNm] = useState("");
  const [phno, setPhno] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [msg, setMsg] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [userid, setUserid] = useState(0);
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let flag = 0;

    // Basic email validation
    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      alert("Please enter a valid email address.");
      flag = 1;
    }

    // Basic phone number validation (assuming a 10-digit number)
    if (!/^\d{10}$/.test(contact)) {
      alert("Please enter a valid 10-digit phone number.");
      flag = 1;
    }

    if (flag === 0) {
      sentMail();
    }
  };

  const sentMail = () => {
    const mailtoLink = `mailto:info@myprega.com?subject=About&body=I'm contacting you because ${encodeURIComponent(
      nm
    )}Let's start by getting to know you better. Hi, I'm ${encodeURIComponent(
      phno
    )}You can reply to me at ${encodeURIComponent(
      email
    )}You can reply to me when I'm offline ${encodeURIComponent(
      contact
    )} Story  ${encodeURIComponent(msg)}`;

    window.location.href = mailtoLink;
  };

  const handleContactChange = (e) => {
    const inputValue = e.target.value;
    setContact(inputValue);
  };
  
  return (
    <>
      <Header userid={userid} />
      <div className="container-fluid p pb-5">
        <br />
        <div className="container weare bb">
          <h1 className="text-center pb-5 fw-bolder">
            LET'S TALK BUT YOU FIRSTS
          </h1>
          <br />
          <div className="row contact">
            <div className="col-12 col-md-5">
              <div className="w">
                <div className="form_container">
                  <form action="">
                    <div className="mb-4">
                      <label>I'm contacting you because</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="nm"
                        placeholder=""
                        value={nm}
                        onChange={(e) => {
                          const inputval = e.target.value;
                          if (/^[A-Za-z]+$/.test(inputval) || inputval === "") {
                            setNm(inputval);
                          } 
                          // else {
                          //   alert("Please enter only alphabetic characters.");
                          // }
                        }}
                      />
                    </div>
                    <div className="my-4">
                      <label>Let's start by getting know better. Hi I'm</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phno"
                        value={phno}
                        onChange={(e) => {
                          const inputval = e.target.value;
                          if (/^[A-Za-z]+$/.test(inputval) || inputval === "") {
                            setPhno(inputval);
                          } 
                          // else {
                          //   alert("Please enter only alphabetic characters.");
                          // }
                        }}
                        placeholder="(Type your name)"
                      />
                    </div>
                    <div className="my-4">
                      <label>You can reply to me at</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="(Type your email)"
                        value={email}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          setEmail(inputVal);

                          // Additional custom validation
                        }}
                      />
                    </div>
                    <div className="my-4">
                      <label>You can reply to me when I'm offline</label>
                      <input
                        style={{ height: "24px" }}
                        type="number"
                        className="form-control"
                        id="contact"
                        value={contact}
                        onChange={handleContactChange}
                        // onChange={(e) => setContact(e.target.value)}
                        placeholder="(Type your contact number)"
                      />
                    </div>
                    <div className="my-4">
                      <label>As long as you promise not to</label>
                      <select className="dropd">
                        <option value="op1">Reply like a generic robot</option>
                        <option value="op2">All of the above, LOL</option>
                      </select>
                    </div>
                    <div className="my-4">
                      <label>
                        Questions, sad or happy stories, complaints or even just
                        a quick hello , send us a note. We'll read every word.
                      </label>
                      <textarea
                        id="msg"
                        className="form-control"
                        placeholder=""
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="btn_box my-4">
                      <button
                        className="btn"
                        id="sendbtn"
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          fontWeight: "bolder",
                        }}
                        onClick={handleSubmit}
                      >
                        SEND
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-1 d-none d-md-block">
              <div className="vline mx-auto"></div>
            </div>
            <div className="col-12 col-md-6 pt-3 pt-lg-0">
              <h5>Got a question about...?</h5>
              <h5>Looking for help with...?</h5>
              <h5>Wanna share your thoughts on ..?</h5>
              <h5>Need to know who, what, where, why or when....?</h5>

              <h5 className="my-4">
                We've got all kinds of ways to help you find out,
                <br />
                Here's how you can contact us for customer service.
              </h5>
              <div className="hline mx-auto"></div>
              <div className="d-flex align-items-center">
                <img src="/mailicon.png" className="img-fluid pl-2 icon" />
                <h5 className="my-5 pl-3 d-inline">info@myprega.com</h5>
              </div>
              <div className="hline mx-auto"></div>
              <div className="d-flex align-items-center">
                <img src="/phn.png" className="img-fluid pl-2 icon" />
                <div>
                  <h5 className="pl-3 mt-5">+91 97023246288</h5>
                  <h5 className="pl-3">(9 am to 7 pm, All days)</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
