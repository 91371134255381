import React, { useState } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

function Consultationrightbox(props) {
  console.log("Consultationrightbox",props);
  const d1 = props.item.weekDates[0];
  const d2 = props.item.weekDates[6];
  const weekstart = moment(d1).format("DD MMM");
  const weekend = moment(d2).format("DD MMM");
  const currentDate = moment();
  const futureDate = moment(d1);
  const lastDate = moment(d2);
  const dateDiff = futureDate.diff(currentDate, 'days');
  const lastDateDiff =  lastDate.diff(currentDate,'days');
  const newItemData = JSON.stringify(props.item);
  console.log("props",props);
  const scheduledDate = moment(props.item?.scheduled_date).format('DD-MM-YYYY');
  const currentDateCompare  = moment().format('DD-MM-YYYY');
  console.log(weekstart ,"dateDiff",  dateDiff );
  console.log("scheduledDate",d2);
  
  if(scheduledDate === currentDateCompare ){
    console.log("scheduledDate",lastDateDiff)
  }
  console.log("scheduledDate",lastDateDiff)


  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  return (
    <>
      <div
        style={{
          paddingTop: "20px",
          paddingLeft: "25px",
          marginBottom: "12px",
        }}
      >
        {" "}
        Consultation {props.index+1}
        {/* {props.item.consultation_number}{""} */}
      </div>
      <div
        style={{
          border: "1px solid #CBC6C6",
          backgroundColor: "rgba(240, 250, 248, 0.502)",
          borderRadius: "12px",
          width: "86%",
          margin: "0px auto",
        }}
      >
        <div
          style={{
            paddingTop: "18px",
            paddingBottom: "18px",
            textAlign: "center",
            color:
              props.item.status === "SCHEDULED"
                ? configData.THEME_COLORS.PRIMARY
                : "black",
            fontSize: isTabScreen ? "16px" : "20px",
          }}
        >
          {props.item.scheduled_date ? (
            moment(props.item.scheduled_date)
              .format("DD MMM YYYY")
              .toUpperCase()
          ) : (
            <div>
              {weekstart}-{weekend}
            </div>
          )}
        </div>
        <div
          className="pb-3 text-center fw-bolder"
          style={{ fontSize: isTabScreen ? "13px" : "20px" }}
        >
          {props.isPathology ? <div>In-person</div> : props.item.consultation_type }
        </div>

        <div className="d-flex justify-content-between align-items-center px-3">
          <div className="d-flex flex-column justify-content-center">
            <div
              className="pb-1"
              style={{ fontSize: isTabScreen ? "9px" : "16px" }}
            >
              Timing
            </div>
            <div
              className="pt-1"
              style={{
                color: "#868686",
                fontSize: isTabScreen ? "9px" : "16px",
              }}
            >
              {props.item.timing == null ? "00:00" : props.item.timing}
            </div>
          </div>
          <div style={{ fontSize: isTabScreen ? "9px" : "16px" }}>
            Pregnancy week: {props.item.pregnancy_week}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center my-3 mx-md-1 mx-lg-3">
          <div
            style={{
              fontSize: isTabScreen ? "11px" : "16px",
              color:
                props.item.doctor_scheduled === 1
                  ? props.item.scheduled_date != null
                    ? "green"
                    : "#F92A2A"
                  : "#004AAD",
              textAlign: "center",
            }}
          >
            {/* {props.scheduledr === props.item.id ? "SCHEDULED" : "NOT SCHEDULED"} */}
            {props.item.doctor_scheduled === 0 ? 
            <div>NOT SCHEDULED</div> : 
            props.item.doctor_scheduled === 1 && props.item.is_completed === 0 ? 
            <div>SCHEDULED</div> : props.item.doctor_scheduled === 1 && 
            props.item.is_completed === 1 ?<div>COMPLETED</div> : null }
          </div>
          {props.update  && props.item.is_completed === 0 ? (
            <div>
              {scheduledDate !== currentDateCompare  && lastDateDiff >= 0 &&
              (
                !props.isPathology && <button 
                  style={{
                   width: "100px",
                   height: "40px",
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center",
                   background: "#004AAD",
                   border: "1px solid rgba(0, 74, 173, 0.5)",
                   fontSize: isTabScreen ? "8px" : "12px",
                   color: "white",
                   boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
                   // height: "35px",
                   borderRadius: "5px",
                   cursor : scheduledDate === currentDateCompare  ? "not-allowed" : "pointer",
                   marginBottom : "10px",
                 }}
                  type="button" 
                  data-id= {props.item.id}
                   onClick={props.consulationTypeChanege} 
                   data-type= {props.item.consultation_type} 
                   data-week={props.item.pregnancy_week} 
                   data-props = {JSON.stringify(props.item)}>Change Type</button>
              

               )}
              <button 
                style={{
                  width: "100px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#004AAD",
                  border: "1px solid rgba(0, 74, 173, 0.5)",
                  fontSize: isTabScreen ? "8px" : "12px",
                  color: "white",
                  boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
                  // height: "35px",
                  borderRadius: "5px",
                  cursor : scheduledDate === currentDateCompare  ? "not-allowed" : "pointer"
                }}
                data-patient_id = {props.item.patient_id}
                data-id= {props.item.id}
                data-last_date = {d2}
                data-item={newItemData}
                // onClick={props.onClick}
                onClick={
                  dateDiff > 7 ? props.changeWeek : 
                  scheduledDate !== currentDateCompare  && 
                  lastDateDiff >= 0 ? props.onClick : lastDateDiff < 0 ? props.getNextDates : null
                }
                

                // onClick={ dateDiff > 7 ? props.changeWeek : 
                //   scheduledDate !== currentDateCompare  && lastDateDiff > 0 ? props.onClick :
                //    lastDateDiff < 0 ? props.getNextDates : null }
              >
                {
                    dateDiff > 7 ? (
                    <div>Change Week</div>
                  ) : props.item.doctor_scheduled === 0 && lastDateDiff >= 0 ? (
                    <div>{props.isPathology ? "Re-schedule":"Schedule"}</div>
                  ) : (props.item.doctor_scheduled === 1 && scheduledDate !== currentDateCompare  && lastDateDiff >= 0 ) ? (
                    <div>Re-Schedule</div>
                  ) : scheduledDate === currentDateCompare  ? (<div>Re-Schedule</div> )  
                  : (lastDateDiff < 0 ) ? <div> Re-Schedule</div> : null
                }
              </button>
              
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

export default Consultationrightbox;
