import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import MiniDrawer from "../Components/MiniDrawer";
import configData from "../../config.json";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ConsultationCard from "../Components/ConsultationCard";
import PathologynextPage from "../Components/PathologynextPage";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "nuka-carousel";
import Rightsidedrconsultation from "../Components/Rightsidedrconsultation";
import Rightsidereport from "../Components/Rightsidereport";
import Followup from "../Components/Followup";
import Calendarpart from "../Components/Calendarpart";
import axios from "axios";
import Chatscreen from "../Components/Chatscreen";
import { slideInLeft, slideInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import moment from "moment";
import Dropdown from "../Components/Categories/Dropdown";
import { getGenratedToken } from "../api";
import MeetingNew from "./MeetingNew";
import AlertShow from "../Components/AlertShow";

const back = {
  paddingTop: "5px",
  color: "#004AAD",
  fontSize: "15px",
  fontWeight: "600",
  cursor: "pointer",
};
const maincontainer = {
  height: "350px",
  marginTop: "30px",
  overflowY: "scroll",
};
const heading = {
  paddingTop: "12px",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "16px",
  color: "#333333",
};

const styles = {
  slideIn: {
    animation: "x 1.5s",
    animationName: Radium.keyframes(slideInUp, "slideInUp"),
  },
};

const styles2 = {
  slideIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(slideInLeft, "slideInLeft"),
  },
};
const s = {
  fontWeight: "500",
  fontSize: "16px",
};
const calclinic = {
  paddingTop: "12px",
  marginLeft: "12px",
  marginRight: "6px",
};
const image = {
  height: "36px",
  width: "36px",
};
const statusbtn = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "24px",
};

const lock = {
  top: "-6px",
  left: "38%",
  position: "absolute",
  height: "40px",
  width: "40px",
};

let myprescription;
// let consultationdata;
let followupdata;
let renderchatdata;
let sdata;
let firstAddress;
// let clickindex;
export default function Doctor_consultation() {
  const [cardClick, setcardClick] = useState(false);
  const [prescription, setprescription] = useState(false);
  const [follow, setfollowup] = useState(false);
  const [schedule, setschedule] = useState(false);
  const [chatwindow,setChatwindow]=useState(false);
  const [info, setInfo] = useState(false);
  const location = useLocation();
  const [selected, setSelected] = useState(0);
  const [selectedfollowup, setSelectedfollowup] = useState(0);
  const [selectedschedule, setSelectedschedule] = useState(0);
  const [selectedchatwindow,setSelectedchatwindow]=useState(0);
  const [carouselcardclick, setcarouselcardclick] = useState(false);
  const [consultationdata, setConsultationdata] = useState(null); //shubha
  const [clickindex, setClickindex] = useState(null); //shubha

  const [doctors, setDoctors] = useState();
  const [consultations, setConsultations] = useState();
  const [consultationsData, setConsultationsData] = useState();
  const [doctorId, setDoctorID] = useState();
  const [dates, setDates] = useState();
  const [timings, setTimings] = useState();
  const [timeData, setTimeData] = useState();
  const [doctorInfo, setDoctorInfo] = useState();
  const [patientdata, setPatientdata] = useState();
  const [data, setData] = useState([]);
  const [selectedcard, setSelectedcard] = useState(false);
  const [slidercardclick, setSlidercardclick] = useState(null);
  const [activeDoctorID, setActiveDoctorID] = useState();
  const [objectData, setObjectData] = useState();
  const [viewBtn, setViewBtn] = useState(false);
  const [falseshowtime, setFalseshowtime] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [token, setToken] = useState();
  const [consultId, setConsultId] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
 const [video,setVideo]=useState(false);
 const [consultno,setConsultno]=useState();
 const [consultdata,setConsultdata]=useState([]);
 const [clickfromcard,setClickfromcard]=useState(false);
 const [clickfromcardinner,setClickfromcardinner]=useState(false);
 const [isYogaExperts , setIsYogaExperts ] = useState(false);
  let mydata;
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);

  const fetchToken = async (ID) => {
    console.log("GET TOKEN>", ID);
    try {
      const token = await getGenratedToken(ID);
      console.log("TOKEN>", token);
      setToken(token);
      if (!token) {
        var msg = "Doctor has not started the consultation!";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // alert("Doctor has not started the consultation!")
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  // useEffect(() => {
  //   // Function to fetch token using the getToken API
  //   const fetchToken = async () => {
  //     try {
  //       const token = await getGenratedToken(consultation_id);
  //       console.log("TOKEN>", token);
  //       // setToken(token);
  //     } catch (error) {
  //       console.error("Error fetching token:", error);
  //     }
  //   };

  //   // Set an interval to fetch the token every 5 minutes (adjust as needed)
  //   const tokenRefreshInterval = setInterval(fetchToken, 5 * 60 * 1000);

  //   // Fetch the initial token when the component mounts
  //   fetchToken();

  //   // Cleanup function to clear the interval when the component is unmounted
  //   return () => clearInterval(tokenRefreshInterval);
  // }, []); // Empty dependency array ensures that this effect runs only once when the component mounts

  useEffect(() => {
    if (location.state?.id == 2) {
      setcardClick(true);
      setschedule(true);
      setActiveDoctorID(location.state?.drid);
      getDoctorConsultations(location.state?.drid);
      setClickindex(location.state?.drid);
      setSelectedcard(location.state?.drid);
      getDoctorConsultations(location.state?.drid);
      doctors?.map((e) => {
        if (location.state?.drid == e?.doctorDetails.doctor_id)
          setDoctorInfo(e?.doctorDetails);
      });
      setDoctorID(location.state?.drid);
      setcardClick(true);
    }
    if(clickfromcard===false)
    {
    if (location.state?.id == 3) {
      setcardClick(true);
      setschedule(true);
      setActiveDoctorID(location.state?.drid);
      getDoctorConsultations(location.state?.drid);
      setClickindex(location.state?.drid);
      setSelectedcard(location.state?.drid);
      getDoctorConsultations(location.state?.drid);
      doctors?.map((e) => {
        if (location.state?.drid == e?.doctorDetails.doctor_id)
          setDoctorInfo(e?.doctorDetails);
      });
      setDoctorID(location.state?.drid);
      setcardClick(true);
    }
    if (location.state?.id == 4) {
      console.log("PPPOOO",location.state?.id);
      setcardClick(true);
      setschedule(true);
      setActiveDoctorID(location.state?.drid);
      getDoctorConsultations(location.state?.drid);
      setClickindex(location.state?.drid);
      setSelectedcard(location.state?.drid);
      getDoctorConsultations(location.state?.drid);
      doctors?.map((e) => {
        if (location.state?.drid == e?.doctorDetails.doctor_id)
          setDoctorInfo(e?.doctorDetails);
      });
      setDoctorID(location.state?.drid);
      setcardClick(true);
    }
  }
  }, [doctors]);

  useEffect(() => {
    getsinglepatientdetail();
  }, [activeDoctorID]);
  useEffect(() => {
    // http://192.168.1.31:9595/api/v1/patient/pregnancy_care_percentage
    showpercentage();
  });
  const showpercentage = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/pregnancy_care_percentage`,
        {
          patient_id: localStorage.getItem("userId"),
        }
      );

      if (response.data.success) {
        console.log(
          "@@preg care",
          response.data.Pregnancy_Care[0].completion_percentage
        );
        const per = response.data.Pregnancy_Care[0].completion_percentage;
        setPercentage(per);
      } else {
        console.error("Error in pregnancy care %:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const gaugedata = [
    { label: "Completed Consultations", value: percentage },
    { label: "Incomplete Consultations", value: 100 - percentage },
  ];
  useEffect(() => {}, [data]);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const handlecard = (e) => {
    console.log("main card data", e);
    setClickfromcard(true);
    setschedule(false);
    setClickindex(e.doctorDetails?.doctor_id);
    setSelectedcard(e.doctorDetails?.doctor_id);
    console.log("111", clickindex);
    getDoctorConsultations(e?.doctorDetails?.doctor_id);
    setDoctorInfo(e?.doctorDetails);
    setDoctorID(e?.doctorDetails?.doctor_id);
    setcardClick(true);
  };
  const setoriginalpage = () => {
    setcardClick(false);
      //  navigation.setParams({ state: {} });
  };

  const showprescription = (e) => {
    // consultationdata = e;
    console.log("^^",e);
    setConsultationdata(e);
    setSelected(e?.id);
    setSelectedchatwindow(0);
    setSelectedfollowup(0);
    setprescription(true);
    setSelectedschedule(0);
    setfollowup(false);
    setViewBtn(false);
  };
  const followup = (e) => {
    followupdata = e;
    setSelectedfollowup(e?.id);
    setSelectedchatwindow(0);
    setSelected(0);
    setSelectedschedule(0);
    setfollowup(true);
    setprescription(false);
  };
const renderchat=(consult_id,e)=>{
  setConsultno(consult_id);
  setConsultdata(e);
  followupdata = e;
  setSelectedfollowup(e?.id);
  setSelectedchatwindow(e?.id);
  setSelected(0);
  setSelectedschedule(0);
  setfollowup(true);
  setprescription(false);
  // if(setVideo==true)
  // {
  //       handleChangeConsultationView(consult_id,e);
  // }
  // followupdata=e;
  // setChatwindow(true);
  // setSelectedchatwindow(e?.id);
  // setSelected(0);
  // setSelectedschedule(0);
  // setSelectedfollowup(0); 
  // setschedule(false);
  //   setprescription(false);
  //   setfollowup(false);
}
useEffect(()=>{
  if(clickfromcard===false)
  {
  if (location.state?.id === 3) {
    console.log("<?<?",location?.state);
    setSelectedschedule(location.state?.cardid);
    setSelectedchatwindow(0);
    setSelected(0);
    setSelectedfollowup(0);
    setschedule(true);
    setprescription(false);
    setfollowup(false);
    getDoctorAvailability(location.state?.cardid);
    goto();
  }
}
},[location?.state?.id,consultations]);
 
// useEffect(() =>{
//   goto();

// },[])


useEffect(()=>{
  if(clickfromcard===false)
  {

  if (location.state?.id == 4) {
    console.log("<?<?",location?.state);
    setSelectedschedule(location.state?.cardid);
    setSelectedchatwindow(0);
    setSelected(0);
    setSelectedfollowup(0);
    setschedule(true);
    setprescription(false);
    setfollowup(false);
    getDoctorAvailability(location.state?.cardid);
    goto();
   
  }
}
},[location?.state?.id,consultations]);

// useEffect(() => {
//   if (consultations?.length > 0 && location.state?.id == 4) {
//     goto();
//   }
// }, [location?.state?.id,consultations]);

// useEffect(() => {
//   if (consultations?.length > 0 && location.state?.id == 3) {
//     goto();
//   }
// }, [location?.state?.id,consultations]);

const goto=()=>{
  consultations?.map((item)=>{
    if(item?.id===location.state?.cardid)
    {
      setInfo(item);
      console.log("**",item);
    }
    console.log("**@@",item?.id,location.state?.cardid);
    
})
}


  const schedulingconsult = (e) => {
    if(e.is_confirmed==1)
    {
      var msg = "you cant reschedule";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
    }
    else{
    console.log("CLICKED ON SMALL CARD", e);
    sdata = e;
    if (location.state?.id == 2) {
      setFalseshowtime(true);
    }
   
    setSelectedschedule(e?.id);
    setSelectedchatwindow(0);
    setSelected(0);
    setSelectedfollowup(0);
    setschedule(true);
    setprescription(false);
    setfollowup(false);
    getDoctorAvailability(e?.id);
    alert(e?.id);
    console.log("ConsultInfo", e);
    setInfo(e);
  }
  };

  useEffect(() => {}, [
    carouselcardclick,
    selectedcard,
    activeDoctorID,
    data,
    consultations,
    doctors,
    doctorInfo,
    clickindex,
  ]);

  // const handleClick = (e) => {
  //   console.log("CAROU ONCLICK", e);
  //   setcarouselcardclick(e?.id);
  //   setSelectedcard(e?.doctorDetails?.doctor_id);
  //   setDoctorInfo(e?.doctorDetails);
  //   mydata = e;
  //   getDoctorConsultations(e?.doctorDetails?.doctor_id);
  //   setActiveDoctorID(e?.doctorDetails?.doctor_id);
  //   func();
  // };
  const handleClick = (e) => {
    console.log("CAROU ONCLICK", e?.doctorDetails?.medicalExpertise);
    setcarouselcardclick(e?.id);
    setSelectedcard(e?.doctorDetails?.doctor_id);
    setDoctorInfo(e?.doctorDetails);
    mydata = e;
    if(e?.doctorDetails?.medicalExpertise  === "Yoga Expert"){
      getYogaBatchData();
      setIsYogaExperts(true);
    }else{
      setIsYogaExperts(false);
      getDoctorConsultations(e?.doctorDetails?.doctor_id);
    }
    setActiveDoctorID(e?.doctorDetails?.doctor_id);
    func();
  };
  const func = () => {
    console.log("clicked carousel data", mydata);
    setData(mydata);
  };

  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1240px)",
  });

  const handleMeetingLeave = () => {
    console.log("leave??");
    setToken("");
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const getDoctors = async (area) => {
    console.log("<><>{}{}{}", area);
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_doctor_list`, {
        area_id: area,
        patient_id: localStorage.getItem("userId").toString(),
      });

      if (response.data?.success) {
        console.log("Doctors-", response.data?.doctorList);
        setDoctors(response.data?.doctorList);
        console.log("Doctors123-", doctors);
      }
    } catch (error) {
      console.error("Error get areas:", error);
    }
  };
  const getsinglepatientdetail = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: localStorage.getItem("userId"),
      });

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);
        // setProfileDataMain(response.data);
        let area = response.data.user.area_id;
        setPatientdata(response.data.user);
        console.log("<><><><>", patientdata);
        getDoctors(response.data.user.area_id);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
    // }, 60000);
  };
  const getDoctorConsultations = async (DoctorID) => {
    try {
      console.log(
        "DoctorID-",
        DoctorID,
        localStorage.getItem("userId").toString()
      );
      setActiveDoctorID(DoctorID);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_doctor_consultation`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          doctor_id: DoctorID,
        }
      );

      if (response.data?.success) {
        console.log("DoctorConsults-", response.data?.patient_consultations);
        setConsultations(response.data?.patient_consultations);
      }
    } catch (error) {
      console.error("Error get consults:", error);
    }
  };

  const handleChangeConsultationView = async () => {
    try {
      console.log("consult_id",consultdata,consultno);
      setConsultId(consultdata?.id);
      fetchToken(consultdata?.id);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_consultation_by_id`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          consultation_id: consultdata?.id,
        }
      );
      if (response.data?.success) {
        console.log("consultationsData", doctorInfo);
        console.log("consultationsData", response.data.consultation_data);
        const consultData = {
          doctorName: doctorInfo.fname + " " + doctorInfo.lname,
          date: response.data.consultation_data[0].scheduled_date,
          mode: response.data.consultation_data[0].consultation_type,
          timing: response.data.consultation_data[0].timing,
        };
        setConsultationsData(consultData);
        setschedule(false);
        setprescription(false);
        setfollowup(false);
        setSelected(consultdata?.id);
        setViewBtn(true);
      }
    } catch (error) {
      console.error("Error get consults:", error);
    }
  };
  const handleChangeConsultationView1 = async (consultno,consultdata) => {
    try {
      console.log("consult_id",consultdata,consultno);
      setConsultId(consultdata?.id);
      // fetchToken(consultdata?.id);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_consultation_by_id`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          consultation_id: consultdata?.id,
        }
      );
      if (response.data?.success) {
        console.log("consultationsData", doctorInfo);
        console.log("consultationsData", response.data.consultation_data);
        const consultData = {
          doctorName: doctorInfo.fname + " " + doctorInfo.lname,
          date: response.data.consultation_data[0].scheduled_date,
          mode: response.data.consultation_data[0].consultation_type,
          timing: response.data.consultation_data[0].timing,
        };
        setConsultationsData(consultData);
        setschedule(false);
        setprescription(false);
        setfollowup(false);
        setSelected(consultdata?.id);
        setViewBtn(true);
      }
    } catch (error) {
      console.error("Error get consults:", error);
    }
  };
  const getDoctorAvailability = async (cId) => {
    if(doctorId)
    {
    try {
      console.log("check api", localStorage.getItem("userId"), doctorId, cId);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_doctor_availability`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          doctor_id: doctorId,
          consultation_id: cId,
        }
      );
      console.log("get_doctor_availability", response.data);
      if (response.data?.success) {
        const selectedDate = [];
        const dateTimeData = [];
        response.data.doctor_availability.map((item) => {
          console.log("item", item);
          selectedDate.push(item.dates);

          const formattedDateobj2 = {
            id: item?.id,
            date: item?.dates,
            timing_id: item?.timing_id,
            timings: item?.timings,
          };
          dateTimeData.push(formattedDateobj2);
        });
        setObjectData(dateTimeData);
        console.log("DoctorAvailability-", response.data);
        console.log("DoctorAvailability-", selectedDate);
        console.log("DoctorAvailability-", dateTimeData);
        setTimeData(response.data?.doctor_availability[0]);
        setDates(selectedDate);
        setTimings(response.data?.doctor_availability[0]?.timings?.split(","));
      }
    } catch (error) {
      console.error("Error get availdates:", error);
    }
  }
  else{

  }
  };
  console.log("sdata", sdata);
  console.log("doctorInfo---------", activeDoctorID);
  const addYogaBatchTiming = async (e) => {
    try{
      const userConfirmed = window.confirm("Are you sure you want to select this batch?");
      if(userConfirmed){
      console.log("Edata",e);
      const response = await axios.post(`${configData.SERVER_API_URL}/consultation/add_patient_yoga_batch_timing`,{
        "patient_id":localStorage.getItem("userId").toString(),
        "doctor_id":activeDoctorID,
        "batch_id":e.id
      });

      if (response.data?.success) {
        setConsultations(response.data?.Batches);
        var msg = "Your Yoga Batch Added SuccessFully!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // setIsYogaExperts(true);
        getsinglepatientdetail();
        getYogaBatchData();
      }
    }
    }catch(error){
      console.log("Error : ", error);
    }
  } 
  const is24HoursBefore = (scheduledDate) => {
    const startOf24HoursBefore = moment(scheduledDate).subtract(1, 'day').startOf('day');
    const startOfCurrentDay = moment().startOf('day');
    var msg = "You cant reschedule now";
    const error_color = "error";
    handleAlertMsg(error_color, msg);
    return moment(startOfCurrentDay).isAfter(startOf24HoursBefore);
  };
  
  const getYogaBatchData = async () => {
    try{
      const response = await axios.get(`${configData.SERVER_API_URL}/consultation/get_yoga_batches_timiing`);

      if (response.data?.success) {
        console.log("Doctors-", response.data?.Batches);
        // setDoctors(response.data?.Batches);
        setConsultations(response.data?.Batches);
        console.log("Doctors123-", doctors);
      }
    }catch(error){
      console.log("Error :" , error);
    }
  } 

  return (
    <>
      <div style={{ display: "flex" }}>
        <MiniDrawer />
        {/* div for right side content heading and boxes */}

        {/* <Container> */}
        {/* <div style={{ backgroundColor: "rgb(246, 231, 236)" }}> */}

        {cardClick === true && !token ? (
          <>
            <div
              style={{
                width: "70%",
                backgroundColor: configData.THEME_COLORS.BACKGROUND,
              }}
            >
              <div style={{ paddingLeft: isTabScreen ? "20px" : "59px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#FFF0F5",
                    paddingTop: "48px",
                    color: configData.THEME_COLORS.PRIMARY,
                    fontFamily: "Poppins",
                  }}
                >
                  <div
                    style={{
                      fontSize: isTabScreen ? "24px" : "32px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Doctor Consultation
                  </div>
                  <div
                    style={{
                      paddingRight: "69px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NotificationsIcon
                      sx={{
                        width: "25px",
                        height: "25px",
                        color: "#004AAD",
                      }}
                    />
                    {/* <AccountCircleIcon
                      sx={{
                        marginLeft: "16px",
                        width: "33px",
                        height: "33px",
                        color: "#004AAD",
                      }}
                    /> */}
                    <Dropdown
                      submenu={submenu}
                      onClick={() => showsubmenu()}
                      page="Doctor_consultation"
                    />
                  </div>
                </div>
                <div style={back} onClick={() => setoriginalpage()}>
                  BACK
                </div>
                <div className="container">
                  <div
                    style={{
                      flexWrap: "nowrap",
                      overflowX: "hidden",
                      flexDirection: "column",
                    }}
                  ></div>
                </div>
                <StyleRoot>
                  <div className="test" style={styles2.slideIn}>
                    <div
                      className="carouseladjust"
                      style={{
                        width: isTabScreen
                          ? window.innerWidth / 1.9
                          : window.innerWidth / 1.7,
                        // backgroundColor: "green",
                      }}
                    >
                      <Carousel
                        slidesToShow={isTabScreen ? 1 : isTabScreen1 ? 2 : 2.1}
                        infiniteLoop={true}
                        renderCenterRightControls={({ nextSlide }) => (
                          <img
                            onClick={nextSlide}
                            src={"/HealthTracker/circle-right.png"}
                            style={{
                              resizeMode: "contain",
                              height: isTabScreen ? "25px" : "34.88px",
                              width: isTabScreen ? "25px" : "34.88px",
                              cursor: "pointer",
                              position: "absolute",
                              right: isTabScreen ? "0" : "-12px",
                            }}
                          />
                        )}
                        defaultControlsConfig={{
                          pagingDotsStyle: {
                            display: "block",
                            position: "relative",
                            top: "30px",
                            fill: "#004AAD",
                          },
                        }}
                      >
                        {doctors?.map((e, index) => {
                            if(e?.clinic_address?.length>0)
                            {
                              firstAddress = e?.clinic_address?.[0].clinic_address;
                            }
                          return (
                            // const d=187
                            // activeDoctorID === 187 ?
                            activeDoctorID === e?.doctorDetails?.doctor_id ? (
                              <PathologynextPage
                                onClick={() => handleClick(e)}
                                title={
                                  e?.doctorDetails?.fname +
                                  " " +
                                  e?.doctorDetails?.lname
                                }
                                post={e?.doctorDetails?.medical_expertise}
                                imgdr={ e?.doctorDetails?.profile_pic ? "https://admin.myprega.com/"+
                                e?.doctorDetails?.profile_pic :"/profile/Group-1151.png"
                                 
                                }
                                address={typeof firstAddress === 'string' ? firstAddress : ''}
                                per={e?.completed_percentage}
                                imgclinic={"/DoctorConsultation/Clinic.png"}
                                date={e.date}
                                consultationcompleted={e.consultationcompleted}
                                // tip={e.tip}
                                // index= {activeDoctorID === e?.doctorDetails?.doctor_id ? 0 :index++}
                                index="0"
                                carouselcardclick={carouselcardclick}
                                info={e}
                                mydata={data}
                                clickindex={clickindex}
                                selectedcard={selectedcard}
                                slidercardclick={slidercardclick}
                              />
                            ) : null
                          );
                        })}
                        {doctors?.map((e, index) => {
                            if(e?.clinic_address?.length>0)
                            {
                              firstAddress = e?.clinic_address?.[0].clinic_address;
                            }
                          return activeDoctorID !==
                            e?.doctorDetails?.doctor_id ? (
                            <PathologynextPage
                              onClick={() => handleClick(e)}
                              title={
                                e?.doctorDetails?.fname +
                                " " +
                                e?.doctorDetails?.lname
                              }
                            
                              per={e?.completed_percentage}
                              post={e?.doctorDetails?.medical_expertise}
                              imgdr={ e?.doctorDetails?.profile_pic ? "https://admin.myprega.com/"+
                                e?.doctorDetails?.profile_pic :"/profile/Group-1151.png"
                       
                                 }
                              
                               address={typeof firstAddress === 'string' ? firstAddress : ''}
                              imgclinic={"/DoctorConsultation/Clinic.png"}
                              date={e.date}
                              consultationcompleted={e.consultationcompleted}
                              // tip={e.tip}
                              // index= {activeDoctorID === e?.doctorDetails?.doctor_id ? 0 :index++}
                              index={index + 1}
                              carouselcardclick={carouselcardclick}
                              info={e}
                              mydata={data}
                              clickindex={clickindex}
                              selectedcard={selectedcard}
                              slidercardclick={slidercardclick}
                            />
                          ) : null;
                        })}
                      </Carousel>
                    </div>
                  </div>
                </StyleRoot>
                <div style={{ marginRight: "20px" }}>
                  <div className="container">
                    <StyleRoot>
                      <div className="test" style={styles.slideIn}>
                        <div className="row" style={maincontainer}>
                          {isYogaExperts ? 
                           consultations?.map((e) => {
                            return (
                              <div
                                className="col-6 col-lg-4"
                                style={{
                                  backgroundColor: "white",
                                  marginRight: isTabScreen ? "10px" : "30px",
                                  width: isTabScreen ? "170px" : "210px",
                                  minHeight: "230px",
                                  border: "1px solid #E8E7E7",
                                  borderRadius: "48px 48px 12px 12px",
                                  marginTop: "20px",
                                  boxShadow:
                                    e?.id === selected ||
                                    e?.id === selectedfollowup ||
                                    e?.id === selectedschedule
                                      ? "0px 1px 50px rgba(0, 0, 0, 0.1608)"
                                      : "none",
                                  paddingBottom: "10px",
                                }}
                              >
                                <div style={heading}>
                                {
                                   e.name
                                }
                                </div>
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  style={calclinic}
                                >
                                  <div className="text-center">
                                    <img
                                      src={"/Pathology/calendar.png"}
                                      style={image} alt="calender"
                                    />
                                    <br />
                                    <span>
                                      {e.days}
                                    </span>

                                    <div
                                      style={{
                                        // paddingTop: "4px",
                                        fontWeight: "500",
                                        fontSize: isTabScreen ? "8px" : "12px",
                                        color: "#333333",
                                      }}
                                    >
                                      {e.timing}
                                    </div>
                                  </div>
                                  <div style={s}>
                                    {e.session}
                                   
                                  </div>
                                </div>
                                {/* innerdivforlock */}

                                { (
                                  <div style={{ paddingTop: "24px" }}>
                                    <div
                                      className="d-flex justify-content-between align-items-center"
                                      style={{ marginTop: "27px" }}
                                    >
                                      <button
                                        onClick={() => addYogaBatchTiming(e)}
                                        style={{
                                          width: "81px",
                                          height: "38px",
                                          fontSize: isTabScreen
                                            ? "8px"
                                            : "10px",
                                          fontWeight: "400",
                                          border: "none",
                                          backgroundColor: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color:
                                            e?.id === selected
                                              ? "#F0588B"
                                              :"#7C9CBF",
                                          boxShadow:
                                            e?.id === selected
                                              ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                              : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                          cursor: patientdata?.batch_id === e.id ? "not-allowed" : "pointer",
                                        }} 
                                          disabled={patientdata?.batch_id === e.id ? true : false}
                                      >
                                        {patientdata?.batch_id != e.id ?
                                        (patientdata?.batch_id !== null  ? "Update Batch" :"Select Batch"):("Selected")}
                                      </button>
                                      {patientdata?.batch_id === e.id && 
                                      <button
                                        style={{
                                          width: "81px",
                                          height: "38px",
                                          color: "#7C9CBF",
                                          fontSize: isTabScreen
                                            ? "8px"
                                            : "10px",
                                          fontWeight: "400",
                                          border: "none",
                                          boxShadow:
                                            "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                          backgroundColor: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color:
                                            e?.id === selectedfollowup
                                              ? "#F0588B"
                                              : "#7C9CBF",
                                          boxShadow:
                                            e?.id === selectedfollowup
                                              ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                              : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                        }}
                                        // onClick={() => {
                                        //   handleChangeConsultationView(
                                        //     e.consultation_number,
                                        //     e
                                        //   );
                                        // }}
                                        onClick={()=>renderchat(e.consultation_number,e)}
                                      >
                                        {"Join consultaion"}
                                      </button> }
                                    </div>
                                  </div>
                                ) }
                              </div>
                              // </div>
                            );
                          }):consultations?.map((e) => {
                            return (
                              <div
                                className="col-6 col-lg-4"
                                style={{
                                  backgroundColor: "white",
                                  marginRight: isTabScreen ? "10px" : "30px",
                                  width: isTabScreen ? "170px" : "210px",
                                  minHeight: "230px",
                                  border: "1px solid #E8E7E7",
                                  borderRadius: "48px 48px 12px 12px",
                                  marginTop: "20px",
                                  boxShadow:
                                    e?.id === selected ||
                                    e?.id === selectedfollowup ||
                                    e?.id === selectedschedule
                                      ? "0px 1px 50px rgba(0, 0, 0, 0.1608)"
                                      : "none",
                                  paddingBottom: "10px",
                                }}
                              >
                                <div style={heading}>
                                  {"Consultation" + " " + e.consultation_number}
                                  {e.doctorId}
                                </div>

                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  style={calclinic}
                                >
                                  <div className="text-center">
                                    <img alt="nt found"
                                      src={"/Pathology/calendar.png"}
                                      style={image}
                                    />
                                    <br />
                                    <span>
                                      {e.scheduled_date &&
                                        moment(e.scheduled_date).format(
                                          "MMM DD"
                                        )}
                                    </span>

                                    <div
                                      style={{
                                        // paddingTop: "4px",
                                        fontWeight: "500",
                                        fontSize: isTabScreen ? "8px" : "12px",
                                        color: "#333333",
                                      }}
                                    >
                                      {e.date}
                                    </div>
                                  </div>
                                  <div style={s}>
                                    {e.consultation_type}
                                    {e.scheduled_date && (
                                      <>
                                        <br />
                                        <button
                                          className={`${
                                            e?.id === selected && viewBtn
                                              ? "patient-consultation-btn-active"
                                              : "patient-consultation-btn"
                                          }`}
                                          onClick={() =>
                                            handleChangeConsultationView1(
                                              e.id,
                                              e
                                            )
                                          }
                                        >
                                          View
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {/* innerdivforlock */}

                                {e.is_completed ? (
                                  <div style={{ paddingTop: "24px" }}>
                                    <div
                                      style={{
                                        color: "#58CF99",
                                        fontSize: isTabScreen ? "12px" : "",
                                      }}
                                    >
                                      {"COMPLETED"}
                                    </div>
                                    <div
                                      className="d-flex justify-content-between align-items-center"
                                      style={{ marginTop: "27px" }}
                                    >
                                      <button
                                        onClick={() => showprescription(e)}
                                        style={{
                                          width: "81px",
                                          height: "38px",
                                          fontSize: isTabScreen
                                            ? "8px"
                                            : "10px",
                                          fontWeight: "400",
                                          border: "none",
                                          backgroundColor: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color:
                                            e?.id === selected
                                              ? "#F0588B"
                                              : "#7C9CBF",
                                          boxShadow:
                                            e?.id === selected
                                              ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                              : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                        }}
                                      >
                                        {"Prescription and Reports"}
                                      </button>
                                      <button
                                        style={{
                                          width: "81px",
                                          height: "38px",
                                          color: "#7C9CBF",
                                          fontSize: isTabScreen
                                            ? "8px"
                                            : "10px",
                                          fontWeight: "400",
                                          border: "none",
                                          boxShadow:
                                            "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                          backgroundColor: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color:
                                            e?.id === selectedfollowup
                                              ? "#F0588B"
                                              : "#7C9CBF",
                                          boxShadow:
                                            e?.id === selectedfollowup
                                              ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                              : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                        }}
                                        onClick={() => followup(e)}
                                      >
                                        {"Follow Up"}
                                      </button>
                                    </div>
                                  </div>
                                ) : e.is_completed == 0 &&
                                  e.consulted_by_doctor ? (
                                  <>
                                    <div
                                      className="position-relative"
                                      // style={{ opacity: "0.4" }}
                                    >
                                      <div style={statusbtn}>
                                        <div
                                          style={{
                                            color: " #F92A2A",
                                            fontSize: isTabScreen ? "12px" : "",
                                          }}
                                        >
                                          {e.is_completed
                                            ? "COMPLETED"
                                            : e.scheduled_date
                                            ? "SCHEDULED"
                                            : "UNSCHEDULED"}
                                        </div>
                                        <button
                                          onClick={() => schedulingconsult(e)}
                                          disabled={e.schedule_date != null && is24HoursBefore(e.scheduled_date)}
                                          style={{
                                            width: "81px",
                                            height: "38px",
                                            opacity:e.is_confirmed==1 && "0.5",
                                            fontSize: isTabScreen
                                              ? "8px"
                                              : "10px",
                                            fontWeight: "400",
                                            border: "none",
                                            backgroundColor: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color:
                                              e?.id === selectedschedule
                                                ? "#F0588B"
                                                : "#7C9CBF",
                                            boxShadow:
                                              e?.id === selectedschedule
                                                ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                                : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                          }}
                                        >
                                          {e.scheduled_date != null 
                                            ? e.is_confirmed!=1 ?"Re-Schedule":"ReSchedule"
                                            : "Schedule Consultation"}
                                        </button>
                                        
                                        
                                      </div>
                                      <div
                                        className="d-flex justify-content-center"
                                        style={{
                                          marginTop: "15px",
                                          // opacity: "0.4",
                                        }}
                                      >
                                        <button
                                          style={{
                                            width: "81px",
                                            height: "38px",
                                            fontSize: isTabScreen
                                              ? "8px"
                                              : "10px",
                                            fontWeight: "400",
                                            border: "none",
                                            backgroundColor: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#7C9CBF",
                                          }}
                                        >
                                          {"Prescription and Reports"}
                                        </button>
                                        <button
                                          style={{
                                            width: "81px",
                                            height: "38px",
                                            color: "#7C9CBF",
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            border: "none",
                                            boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                            backgroundColor: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color:
                                            e?.id === selectedchatwindow
                                              ? "#F0588B"
                                              : "#7C9CBF",
                                          boxShadow:
                                            e?.id === selectedchatwindow
                                              ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                              : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                          }}
                                         
                                          onClick={()=>renderchat(e.consultation_number,e)}
                                        >
                                          {"Join Consultation"}
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="position-relative"
                                      style={{ opacity: "0.4" }}
                                    >
                                      <div style={statusbtn}>
                                        <div
                                          style={{
                                            color: " #F92A2A",
                                            fontSize: isTabScreen ? "12px" : "",
                                          }}
                                        >
                                          {e.is_completed
                                            ? "COMPLETED"
                                            : e.scheduled_date
                                            ? "SCHEDULED"
                                            : "UNSCHEDULED"}
                                        </div>
                                        <button
                                          // onClick={() => schedulingconsult(e)}
                                          style={{
                                            width: "81px",
                                            height: "38px",
                                            fontSize: isTabScreen
                                              ? "8px"
                                              : "10px",
                                            fontWeight: "400",
                                            border: "none",
                                            backgroundColor: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color:
                                              e?.id === selectedschedule &&
                                              e.scheduled_date !== undefined
                                                ? "#F0588B"
                                                : "#7C9CBF",
                                            boxShadow:
                                              e?.id === selectedschedule
                                                ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                                : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                          }}
                                        >
                                          {"Schedule Consultation"}
                                        </button>
                                      </div>
                                      <div
                                        className="d-flex justify-content-center"
                                        style={{
                                          marginTop: "15px",
                                          opacity: "0.4",
                                        }}
                                      >
                                        <div style={lock}>
                                          <img src="/DoctorConsultation/lock.png"></img>
                                        </div>
                                        <button
                                          style={{
                                            width: "81px",
                                            height: "38px",
                                            fontSize: isTabScreen
                                              ? "8px"
                                              : "10px",
                                            fontWeight: "400",
                                            border: "none",
                                            backgroundColor: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#7C9CBF",
                                          }}
                                        >
                                          {"Prescription and Reports"}
                                        </button>
                                        <button
                                          style={{
                                            width: "81px",
                                            height: "38px",
                                            color: "#7C9CBF",
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            border: "none",
                                            boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                            backgroundColor: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color:
                                            e?.id === selectedchatwindow
                                              ? "#F0588B"
                                              : "#7C9CBF",
                                          boxShadow:
                                            e?.id === selectedchatwindow
                                              ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                                              : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                                          }}
                                          onClick={()=>renderchat(e.consultation_number,e)} >
                                          {"Join Consultation1"}
                                        </button>
                                      </div>
                                      <div style={lock}>
                                        <img src="/DoctorConsultation/lock.png"></img>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              // </div>
                            );
                          })}
                        </div>
                      </div>
                    </StyleRoot>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: "white", width: "30%" }}>
              {prescription === true ? (
                <Rightsidereport consultationdata={consultationdata} />
              ) : follow === true ? (
                <Followup followupdata={followupdata} 
                setVideo={setVideo} handleChangeConsultationView={handleChangeConsultationView} />
              ) : schedule === true ? (
                <>
                  <Calendarpart
                    scheduleddata={sdata}
                    state={location.state}
                    dates={dates}
                    timings={timings}
                    info={info}
                    timeData={timeData}
                    objectData={objectData}
                    doctorInfo={doctorInfo}
                    falseshowtime={falseshowtime}
                  />
                </>
              ) : chatwindow===true ? (
                <Followup followupdata={followupdata} />
              ):(
                <Rightsidedrconsultation
                  consultations={consultations}
                  Pres={myprescription}
                  doctorInfo={doctorInfo}
                  consultationsData={consultationsData}
                  patientdata={patientdata}
                />
              )}
            </div>
          </>
        ) : !token ? (
          <div
            style={{
              width: "100%",
              backgroundColor: "rgb(246, 231, 236)",
              height: "100vh",
              overflowY: "hidden",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#f6e7ec",
                  paddingTop: "48px",
                  paddingLeft: isTabScreen ? "20px" : "59px",
                  color: configData.THEME_COLORS.PRIMARY,
                  fontFamily: "Poppins",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "24px" : "32px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  Doctor Consultation
                </div>
                <div
                  style={{
                    paddingRight: "69px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NotificationsIcon
                    sx={{
                      width: "22.48px",
                      height: "23.09px",
                      color: "#004AAD",
                    }}
                  />
                  {/* <AccountCircleIcon
                    sx={{
                      marginLeft: "16px",
                      width: "33px",
                      height: "33px",
                      color: "#004AAD",
                    }}
                  /> */}
                  <Dropdown
                    submenu={submenu}
                    onClick={() => showsubmenu()}
                    page="Doctor_consultation"
                  />
                </div>
              </div>
              <Row
                style={{
                  height: window.innerHeight / 1.27,
                  // height: window.innerHeight / 1.19,
                  overflowY: "scroll",
                  width: "100%",
                  marginTop: "32px",
                  paddingBottom: "50px",
                }}
              >
                {doctors?.map((e, index) => {
                  if(e?.clinic_address?.length>0)
                    {
                      firstAddress = e?.clinic_address?.[0].clinic_address;
                    }
                  return (
                    <ConsultationCard
                      title={
                        e?.doctorDetails?.fname + " " + e?.doctorDetails?.lname
                      }
                      post={e?.doctorDetails?.medicalExpertise}
                      imgdr={ e?.doctorDetails?.profile_pic ? "https://admin.myprega.com/"+
                      e?.doctorDetails?.profile_pic :"/profile/Group-1151.png"
                       
                      }
                      address={typeof firstAddress === 'string' ? firstAddress : ''} 
                      per={e?.completed_percentage}
                      imgclinic={"/DoctorConsultation/Clinic.png"}
                      date={e.date}
                      consultationcompleted={e.consultationcompleted}
                      tip={e.tip}
                      index={index}
                      onClick={() => handlecard(e)}
                      clickindex={clickindex}
                      info={e}
                      percentage={percentage}
                    />
                  );
                })}
              </Row>
            </div>
          </div>
        ) : (
          token && (
            <MeetingNew
              roomId={token}
              handleMeetingLeave={handleMeetingLeave}
              consultaionId={consultId}
              participant={patientdata?.fname + " " + patientdata?.lname}
            />
          )
        )}
        <AlertShow
          alertShowHide={showAlert}
          alertClose={handleCloseAlert}
          setalertMsg={alertMsg}
          severity={severity}
        />
      </div>
    </>
  );
}
