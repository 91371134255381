import React, { useEffect, useState, createContext, useContext } from "react";
import {  useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import MiniDrawer from "../Components/MiniDrawer";
import RightBox from "../Components/RightBox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { fadeIn, tada } from "react-animations";
import Radium, { StyleRoot } from "radium";
import configData from "../../config.json";
import MeasurementsBox from "../Components/MeasurementsBox";
import {
  PieChart,
  Pie,
  Cell,
} from "recharts";
import Dropdown from "../Components/Categories/Dropdown";
import { useNavigate } from "react-router-dom/dist";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  tada: {
    animation: "x 1s",
    animationName: Radium.keyframes(tada, "tada"),
  },
};

export function PathTable2({ consults }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });
  return (
    <table className="spacing-table2">
      <thead>
        <tr
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            fontFamily: "Poppins",
            fontSize: isTabScreen ? "9px" : "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
            backgroundColor: "rgba(243, 246, 249, 1)",
          }}
        >
          <div
            className="theading"
            style={{
              width: "100%",
              height: isTabScreen ? "22px" : "44px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F3F6F9",
              alignSelf: "center",
              borderRadius: "6px",
            }}
          >
            <th
              style={{
                textAlign: "center",
                fontSize: isTabScreen ? "8px" : "12px",
                width: isTabScreen ? 100 : 160,
                overflowX: "auto",
                width: "33%",
              }}
            >
              DOCTOR
            </th>
            <th
              style={{
                textAlign: "center",
                fontSize: isTabScreen ? "8px" : "12px",
                width: "33%",
              }}
            >
              COMPLETED CONSULTATION
            </th>
            <th
              style={{
                textAlign: "center",
                fontSize: isTabScreen ? "8px" : "12px",
                width: "33%",
              }}
            >
              TOTAL CONSULTATION
            </th>
          </div>
        </tr>
      </thead>
      <tbody>
        {consults?.map((item, index) => (
          <tr
            key={index}
            style={{
              height: isTabScreen ? "30px" : "70px",
              width: "100%",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <td style={{ width: "33%" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={"/Dashboard/test1.png"}
                  alt="user avatar"
                  style={{
                    marginTop: "5px",
                    marginLeft: isTabScreen ? "10px" : "27px",
                    width: isTabScreen ? "20px" : "50px",
                    height: isTabScreen ? "20px" : "50px",
                    objectFit: "contain",
                  }}
                />
                <div style={{ marginLeft: "15px", textAlign: "left" }}>
                  <div
                    style={{
                      fontSize: isTabScreen ? "11px" : "14px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    {item?.fname + " " + item?.lname}
                  </div>
                  <div
                    style={{
                      fontSize: isTabScreen ? "9px" : "13px",
                      fontWeight: "500",
                      color: "#B5B5C3",
                      textAlign: "left",
                    }}
                  >
                    {item?.medical_expertise}
                  </div>
                </div>
              </div>
            </td>
            <td style={{ width: "33%" }}>
              <div style={{ marginLeft: 15 }}>{item?.completed}</div>
            </td>
            <td style={{ width: "33%" }}>
              <div>{item?.total}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
const UserContext = createContext();
function DoctorDashboard() {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });
  // const history = useHistory();
  const navigate = useNavigate();

  const location = useLocation();

  const [date, setDate] = useState("");
  const [dateClicked, setDateClicked] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [consults, setConsults] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [consultByDate, setConsultByDate] = useState([]);
  const [patientdata, setPatientdata] = useState([]);
  // const { data, loading } = useAxiosGet('https://api.publicapis.org/entries')
  // const { data, loading } = useAxiosGet('https://catfact.ninja/fact')
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);

  const [showImage, setShowImage] = useState(true);
  const [healthVitals, setHealthVitals] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [currentMonthBloodPressure, setCurrentMonthBloodPressure] = useState(
    new Date().getMonth() + 1
  );
  const [currentMonthsugarLevel, setCurrentMonthsugarLevel] = useState(
    new Date().getMonth() + 1
  );
  const [currentMonthtemperature, setCurrentMonthtemperature] = useState(
    new Date().getMonth() + 1
  );
  const [currentMonthWeight, setCurrentMonthWeight] = useState(
    new Date().getMonth() + 1
  );

  const [bloodPressure, setBloodPressure] = useState([]);
  const [sugarLevel, setSugarLevel] = useState([]);
  const [temperature, setTemperature] = useState([]);
  const [weight, setWeight] = useState([]);
  const [percentage, setPercentage] = useState();
  // const { data, loading } = useAxiosGet('https://catfact.ninja/fact')
  const toggleImage = () => {
    setShowImage((prevShowImage) => !prevShowImage); // Toggle between true and false
  };
  useEffect(() => {
    getsinglepatientdetail();
  }, []);

  useEffect(() => {
    const initialToggle = setTimeout(() => {
      toggleImage();
    }, 300);

    const interval = setInterval(toggleImage, 1000); // Toggle every second (adjust as needed)

    // Clear the interval and initial toggle when the component unmounts
    return () => {
      clearTimeout(initialToggle);
      clearInterval(interval);
    };
  }, []);

  // useEffect(() => {
  //   if (!loading) console.log('API Response1', data)
  // }, [loading])
  useEffect(() => {
    // http://192.168.1.31:9595/api/v1/patient/pregnancy_care_percentage
    showpercentage();
  });
  const showpercentage = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/pregnancy_care_percentage`,
        {
          patient_id: localStorage.getItem("userId"),
        }
      );

      if (response.data.success) {
        console.log(
          "@@preg care",
          response.data.Pregnancy_Care[0].completion_percentage
        );
        const per = response.data.Pregnancy_Care[0].completion_percentage;
        setPercentage(per);
      } else {
        console.error("Error in pregnancy care %:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const gaugedata = [
    { label: "Completed Consultations", value: percentage },
    { label: "Incomplete Consultations", value: 100 - percentage },
  ];
  useEffect(() => {
    getConsultCount();
    getUpcomingConsult();
    getHealthVitals();
    getHealthVitalsForGraph();
  }, []);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleReschedule = () => {
    navigate("/Doctor_consultation");
  };
useEffect(()=>{
  getConsultByDate(moment().format("YYYY-MM-DD")); 
},[]);

  const onClickDay = (item) => {
    setDateClicked(true);
    setDate(moment(item).format("Do MMMM YYYY"));
    getConsultByDate(moment(item).format("YYYY-MM-DD"));
  };
  const getsinglepatientdetail = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: localStorage.getItem("userId"),
      });

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);
        setPatientdata(response.data.user);
        console.log("<><><><>", patientdata);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const getConsultCount = async () => {
    try {
      console.log("cscsscsc", localStorage.getItem("userId").toString());
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_consultation_count_dashboard`,
        {
          patient_id: localStorage.getItem("userId").toString(),
        }
      );

      if (response.data?.success) {
        console.log("getCount-", response.data?.consultation_data);
        setConsults(response.data?.consultation_data);
      }
    } catch (error) {
      console.error("Error get consultCount:", error);
    }
  };

  const getUpcomingConsult = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_upcoming_consultation`,
        {
          patient_id: localStorage.getItem("userId").toString(),
        }
      );

      if (response.data?.success) {
        console.log("getUpcomingConsult-", response.data?.doctor_consultations);
        setUpcoming(response.data?.doctor_consultations);
      }
    } catch (error) {
      console.error("Error getUpcomingConsult:", error);
    }
  };
  const gotocasepaper = () => {
    navigate("/patient/Casepaper");
  };
  const getConsultByDate = async (date) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_consultation_by_date`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          scheduled_date: date,
        }
      );

      const onClickDay = (item) => {
        setDateClicked(true);
        setDate(moment(item).format("Do MMMM YYYY"));
      };

      if (response.data?.success) {
        console.log("get ConsultbyDate-", response.data?.doctor_consultations);
        setConsultByDate(response.data?.doctor_consultations);
      }
    } catch (error) {
      console.error("Error ConsultbyDate:", error);
    }
  };

  const getHealthVitals = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details_dashboard`,
        {
          patient_id: localStorage.getItem("userId").toString(),
        }
      );

      if (response.data) {
        console.log(
          "get_health_details_dashboard--",
          response.data?.health_Details
        );
        setHealthVitals(response.data?.health_Details);
      }
    } catch (error) {
      console.error("Error healthvitals--", error);
    }
  };

  const getHealthVitalsForGraph = async (healthType = null, month = null) => {
    try {
      const cMonth = month !== null ? month : new Date().getMonth() + 1;
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details_monthwise_dashboard`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          month: cMonth,
          type: healthType,
        }
      );
      console.log("get_health_details_monthwise_dashboard", response);
      if (response.data) {
        const data = response.data?.health_Details;
        if (healthType === "sugar_level") {
          setSugarLevel(data?.sugar_level);
          setCurrentMonthsugarLevel(month);
        } else if (healthType === "blood_pressure") {
          setBloodPressure(data?.blood_pressure);
          setCurrentMonthBloodPressure(month);
        } else if (healthType === "temperature") {
          setTemperature(data?.temperature);
          setCurrentMonthtemperature(month);
        } else if (healthType === "weight") {
          setWeight(data?.weight);
          setCurrentMonthWeight(month);
        } else {
          setBloodPressure(data?.blood_pressure);
          setSugarLevel(data?.sugar_level);
          setTemperature(data?.temperature);
          setWeight(data?.weight);
        }
        setGraphData(response.data?.health_Details);
      }
    } catch (error) {}
  };

  // useEffect(()=>{},[setSugarLevel])

  const getMonthWsiehealthVitalsGraph = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details_monthwise`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          month: "1",
          type: "blood_pressure",
        }
      );

      if (response.data) {
        const data = response.data?.health_Details[0];
        setBloodPressure(data.blood_pressure);
        setSugarLevel(data.sugar_level);
        setTemperature(data.temperature);
        setWeight(data.weight);
        console.log("Graphhealthvitals-", response.data?.health_Details);
        setGraphData(response.data?.health_Details);
      }
    } catch (error) {
      console.log("Error Graph :", error);
    }
  };

  const changeNextMonth = (type, month) => {
    getHealthVitalsForGraph(type, month);
  };

  const changePreMonth = (type, month) => {
    getHealthVitalsForGraph(type, month);
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
        width: "100%",
        minHeight: isTabScreen ? "180vh" : "160vh",
      }}
    >
      <MiniDrawer />
      <StyleRoot
        style={{
          width: "70%",
        }}
      >
        <div className="test" style={styles.fadeIn}>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "50px",
              }}
            >
              <Typography
                className="mb-0"
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                  marginLeft: isTabScreen ? "20px" : "40px",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "24px" : "36px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  {"Dashboard"}
                </div>
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "-1%",
                }}
              >
                {/* <div style={{ marginRight: "10px" }}>
                  {showImage ? (
                    <img src={"/Dashboard/Form-Incomplete.png"}></img>
                  ) : null}
                </div> */}
                <div
                  style={{
                    height: "36px",
                    width: "36px",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <img
                    onClick={gotocasepaper}
                    style={{ height: "80%", width: "80%" }}
                    src={"/Dashboard/casepaper.png"}
                  ></img>
                  <img
                    style={{ position: "absolute", top: "-5px", right: "-3px" }}
                    src={"/Dashboard/circle.png"}
                  ></img>
                </div>
                <NotificationsIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    color: "#004AAD",
                  }}
                />
                {/* <AccountCircleIcon
                  sx={{
                    marginLeft: "16px",
                    width: "33px",
                    height: "33px",
                    color: "#004AAD",
                  }}
                /> */}
                <Dropdown
                  submenu={submenu}
                  onClick={() => showsubmenu()}
                  page="dashboard"
                />
              </div>
            </div>

            <Typography
              className="mb-0 pt-2"
              paragraph
              sx={{
                color: configData.THEME_COLORS.SECONDARY,
                marginLeft: isTabScreen ? "20px" : "40px",
              }}
            >
              <div
                style={{
                  fontSize: isTabScreen ? "16px" : "32px",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
              >
                Hello {patientdata.fname}
              </div>

              <div
                className="d-flex justify-content-between align-items-center"
                style={{ marginTop: "-8.5%" }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "16px" : "29px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  Your current pregnancy day is
                  <span
                    style={{
                      marginLeft: "8px",
                      paddingTop: "12px",
                      fontSize: isTabScreen ? "24px" : "30px",
                      fontWeight: "700",
                      fontFamily: "Poppins",
                      color: configData.THEME_COLORS.PRIMARY,
                    }}
                  >
                    {patientdata.pregnancy_days}
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    top: 10,
                    right: isTabScreen ? -5 : 0,
                  }}
                >
                  <PieChart
                    height={isTabScreen ? 100 : 130}
                    width={isTabScreen ? 140 : 240}
                  >
                    <Pie
                      startAngle={180}
                      endAngle={0}
                      innerRadius="55%"
                      data={gaugedata}
                      dataKey="value"
                      labelLine={false}
                      blendStroke
                      isAnimationActive={true}
                      cy={"70%"}
                    >
                      <Cell fill="#58CF99" />
                      <Cell fill="white" />
                    </Pie>
                  </PieChart>
                  <div
                    style={{
                      color: "#58CF99",
                      top: isTabScreen ? -50 : -70,
                      position: "relative",
                      left: 2,
                      textAlign: "center",
                      fontSize: isTabScreen ? "20px" : "26px",
                      fontWeight: "700",
                    }}
                  >
                    {Math.floor(percentage)}
                  </div>
                  <div
                    style={{
                      color: "#707070",
                      top: isTabScreen ? -50 : -75,
                      position: "relative",
                      textAlign: "center",
                      fontSize: isTabScreen ? "8px" : "12px",
                      fontWeight: "500",
                    }}
                  >
                    Pregnancy Care
                    <br /> Completed
                  </div>
                </div>
                {/* <div>
                  <img
                    src={'/Dashboard/gauge.png'}
                    style={{
                      resizeMode: 'contain',
                      height: isTabScreen ? '48px' : '80px',
                      width: isTabScreen ? '48px' : '80px',
                      marginRight: '30px',
                      marginBottom: '10px'
                    }}
                  />
                </div> */}
              </div>
            </Typography>
            <div
              className="d-flex"
              style={{
                marginLeft: isTabScreen ? "20px" : "40px",
                marginRight: isTabScreen ? "20px" : "50px",
                marginTop: "-20px",
              }}
            >
              <div
                style={{
                  width: "25%",
                  height: isTabScreen ? "230px" : "420px",
                }}
              >
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{}}
                >
                  <div
                    style={{
                      borderRadius: "15px",
                      width: "100%",
                      backgroundColor: "#8FC8F2",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "45%",
                      justifyContent: "center",
                      cursor: "pointer",
                      height: isTabScreen ? "100px" : "179px",
                    }}
                    onClick={() =>
                      navigate({
                        pathname: "/patient/Garbha_Sanskar",
                        state: 1,
                      })
                    }
                  >
                    <img
                      src={"/Dashboard/activity.png"}
                      style={{
                        resizeMode: "contain",
                        height: isTabScreen ? "22px" : "42.36px",
                      }}
                    />

                    <div
                      style={{
                        marginTop: "12px",
                        fontWeight: "500",
                        fontSize: isTabScreen ? "12px" : "16px",
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        width: 100,
                        textAlign: "center",
                      }}
                    >
                      Activity of the Day
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "15px",
                      width: "100%",
                      backgroundColor: "#8FC8F2",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginTop: "20px",
                      height: isTabScreen ? "100px" : "179px",
                    }}
                    onClick={() =>
                      navigate({
                        pathname: "/patient/Garbha_Sanskar",
                        state: 2,
                      })
                    }
                  >
                    <img
                      src={"/Dashboard/diet.png"}
                      style={{
                        resizeMode: "contain",
                        height: isTabScreen ? "22px" : "42.36px",
                      }}
                    />
                    <div
                      style={{
                        marginTop: "12px",
                        fontWeight: "500",
                        fontSize: isTabScreen ? "12px" : "16px",
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                      }}
                    >
                      Diet Tips
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "75%" }}>
                <div
                  onMouseEnter={() => handleMouseEnter()}
                  onMouseLeave={() => handleMouseLeave()}
                  style={{
                    height: isTabScreen ? "235px" : "380px",
                    width: "96%",
                    marginLeft: "auto",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E8E7E7",
                    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.08)",
                    borderRadius: "12px",
                    overflowY: isHover === true ? "scroll" : "hidden",
                  }}
                >
                  <PathTable2 consults={consults} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                overflowX: "hidden",
                marginTop: "6px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              ></div>
            </div>
            <div
              className="container p-0 my-3"
              style={{
                marginLeft: "2%",
              }}
            >
              <div
                className="row"
                style={{
                  marginLeft: isTabScreen ? "20px" : "30px",
                  marginRight: isTabScreen ? "20px" : "30px",
                  // marginBottom: '4%'
                }}
              >
                <div className="col-3 px-0">
                  <MeasurementsBox
                    title={"Sugar Level"}
                    img={"/Dashboard/sugarLevel.png"}
                    color={"#f9a63c"}
                    value={Math.round(sugarLevel[0]?.avg_sugar_level_value)}
                    unit={"mg / Dl"}
                    mode={"Dashboard"}
                    // data={graphData && graphData[0]?.sugar_level}
                    data={sugarLevel}
                    x={"ideal_sugar"}
                    y={"avg_sugar_level"}
                    changeNextMonth={changeNextMonth}
                    changePreMonth={changePreMonth}
                    type="sugar_level"
                    month={currentMonthsugarLevel}
                  />
                </div>
                <div className="col-3 px-0">
                  <MeasurementsBox
                    title={"Blood Pressure"}
                    img={"/Dashboard/bloodpressure.png"}
                    color={"#00e9ff"}
                    value={Math.round(
                      bloodPressure[0]?.avg_blood_pressure_value
                    )}
                    unit={"mmhg"}
                    mode={"Dashboard"}
                    // data={graphData && graphData[0]?.blood_pressure}
                    data={bloodPressure}
                    x={"ideal_blood_pressure"}
                    y={"avg_blood_pressure"}
                    changeNextMonth={changeNextMonth}
                    changePreMonth={changePreMonth}
                    type="blood_pressure"
                    month={currentMonthBloodPressure}
                  />
                </div>
                <div className="col-3 px-0">
                  <MeasurementsBox
                    title={"Temperature"}
                    img={"/Dashboard/temperature.png"}
                    color={"#ff88a8"}
                    // value={
                    //   Math.round(healthVitals &&
                    //   healthVitals[healthVitals?.length - 1]?.avg_temperature)
                    // }
                    value={Math.round(temperature[0]?.avg_temperature_value)}
                    unit={"° Celsius"}
                    mode={"Dashboard"}
                    // data={graphData && graphData[0]?.temperature}
                    data={temperature}
                    x={"ideal_temperature"}
                    y={"avg_temperature"}
                    changeNextMonth={changeNextMonth}
                    changePreMonth={changePreMonth}
                    type="temperature"
                    month={currentMonthtemperature}
                  />
                </div>
                <div className="col-3 px-0">
                  <MeasurementsBox
                    title={"Weight"}
                    img={"/Dashboard/weight.png"}
                    color={"#8fa9b5"}
                    value={Math.round(weight[0]?.avg_weight_value)}
                    // value={weight[0]?.avg_weight}
                    unit={"Kg"}
                    mode={"Dashboard"}
                    // data={graphData && graphData[0]?.weight}
                    data={weight}
                    x={"ideal_weight"}
                    y={"avg_temperature"}
                    changeNextMonth={changeNextMonth}
                    changePreMonth={changePreMonth}
                    type="weight"
                    month={currentMonthWeight}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>
      <StyleRoot style={{ backgroundColor: "white" }}>
        <div className="test" style={styles.fadeIn}>
          <RightBox
            title={"Calendar"}
            onClick={handleReschedule}
            onClickDay={onClickDay}
            date={date}
            dateClicked={dateClicked}
            upcoming={upcoming}
            consultByDate={consultByDate}
            consults ={consults}
          />
        </div>
      </StyleRoot>
    </div>
  );
}

export default DoctorDashboard;
