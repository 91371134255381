import React, { useState, useEffect, useRef } from "react";
import configData from "../config.json";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery } from "react-responsive";
import AlertShow from "../Components/AlertShow";
import PrescriptionComponent from "../Components/PrescriptionComponent";
import CircularProgress from "@mui/material/CircularProgress";
function AddPrescriptionBox({
  titleendconsult,
  setEndoption,
  title1,
  drid,
  title,
  onClick,
  patientid,
  chatData,
  onBackClick,
  startVideoConsultFromChat,
  onClickDay,
  date1,
  dateClicked,
  editTimeSlot,
  handleAdd,
  drnm,
  consultationnumber,
  consultaionId,
  redirectToChat,
  profileData,
}) {
  console.log(
    "!@first",
    drid,chatData,
    title,
    onClick,
    onClickDay,
    date1,
    dateClicked,
    editTimeSlot,
    handleAdd,
    redirectToChat
  );

  const [showAlert, setShowAlert] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [showdiv, setshowdiv] = useState(false);
  const [value, onChange] = useState();
  const [submit, setSubmit] = useState(false);
  const [searchMedicine, setSearchMedicine] = useState(false);
  const [medicine, setMedicine] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [done, setDone] = useState(false);
  const [searchnm, setSearchnm] = useState();
  const [mlist, setMlist] = useState(false);
  const [chatagain, setChatagain] = useState(false);
  const [addtime, setAddtime] = useState(null);
  const [addtimeafternoon, setAddtimeafternoon] = useState(null);
  const [addtimenight, setAddtimenight] = useState(null);
  const [description, setDescription] = useState(null);
  const [medicinenm, setMedicinenm] = useState();
  const [medicinenmarray, setMedicinenmarray] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState({
    morning: false,
    afternoon: false,
    night: false,
  });
  const [medilist, setMedilist] = useState();
  const [check, setCheck] = useState(false);
  const [test, setTest] = useState(false);

  const [selectedOption, setSelectedOption] = useState({
    id: 1,
    packagingType: "In Packets",
  });
  const [selectedOptionday, setSelectedOptionday] = useState({
    id: 1,
    day: "Everyday",
  });
  const chatContainerRef = useRef(null);
  const [checkmealtime, setCheckmeal] = useState(null);
  const [checkmealtimeafternoon, setCheckmealafternoon] = useState(null);
  const [checkmealtimenight, setCheckmealnight] = useState(null);
  const [selectedMedicine, setSelectedMedicine] = useState({});
  const [incomingmsg, setIncomingmsg] = useState("");
  const [medicinearray, setMedicinearray] = useState([]);
  const [addPrescription, setAddPrescription] = useState(false);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const inputbox = {
    height: "40px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "7px",
  };
  const innerinput = {
    border: "none",
    height: "40px",
  };
  const msg = {
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
  };
  const consultationno = {
    color: "#004AAD",
    fontSize: isTabScreen ? "13px" : "16px",
    fontWeight: "400",
    paddingTop: "25px",
    paddingBottom: "20px",
    textAlign: "center",
    paddingLeft: "151px",
  };
  const msgbox = {
    height: "400px",
    width: "90%",
    margin: "0px auto",
    backgroundColor: "#FFF0F5",
  };
  const menu = {
    width: "120px",
    height: "35px",
    backgroundColor: "gray",
    position: "absolute",
    top: "67%",
    right: "5%",
  };
  const submenu = {
    margin: "5px",
    backgroundColor: "#141124",
    fontSize: "13px",
    textAlign: "center",
    color: "white",
    cursor: "pointer",
  };
  const nmimg = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  };
  useEffect(() => {
    console.log("seee", date1);
    onChange(date1);
  }, [date1]);

  useEffect(() => {
    console.log("RR-----", submit, medilist);
  }, [medilist]);

  useEffect(() => {
    if (selectedTimes.morning) {
      setCheckmeal((prevCheckmeal) => {
        if (!prevCheckmeal || prevCheckmeal.mealtiming !== "before") {
          return { id: 2, mealtiming: "after" };
        }
        return prevCheckmeal;
      });
    } else {
      setCheckmeal(null);
    }

    if (selectedTimes.afternoon) {
      setCheckmealafternoon((prevCheckmealafternoon) => {
        if (
          !prevCheckmealafternoon ||
          prevCheckmealafternoon.mealtiming !== "before"
        ) {
          return { id: 4, mealtiming: "after" };
        }
        return prevCheckmealafternoon;
      });
    } else {
      setCheckmealafternoon(null);
    }

    if (selectedTimes.night) {
      setCheckmealnight((prevCheckmealnight) => {
        if (!prevCheckmealnight || prevCheckmealnight.mealtiming !== "before") {
          return { id: 6, mealtiming: "after" };
        }
        return prevCheckmealnight;
      });
    } else {
      setCheckmealnight(null);
    }
  }, [selectedTimes]);
  const showoption = () => {
    setshowdiv(!showdiv);
  };
  const getmedicine = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/consultation/get_medicines`
      );

      if (response.data.success) {
        console.log("Medicines", response.data.medicines);
        setMedicine(response.data.medicines);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    getmedicine();
  }, []);
  useEffect(() => {
    getmsg();
  }, [chatData]);
  
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    });
  });
  const handleDivClick = (option) => {
    setSelectedOption(option);
  };
  const handleDivClickday = (option) => {
    setSelectedOptionday(option);
  };
  const checkmeal = (time, option) => {
    if (time == "morning") {
      setCheckmeal(option);
    }
    if (time == "afternoon") {
      setCheckmealafternoon(option);
    }
    if (time == "night") {
      setCheckmealnight(option);
    }
  };
  const handleChange = (event, newValue) => {
    if (newValue) {
      setSelectedMedicine(newValue);
      setMedicinenm(newValue["Medicine Name"]);
    }
    setSearchInput(event.target.value);
  };
  const getmsg = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/inbox/get_message`, {
        sender_id: chatData.id,
        reciever_id: drid,
      });
      if (response.status === 200) {
        console.log("@@##", response.data);
        const messages = response.data.messages.map((msg) => {
          const utcTimestamp = msg.created_at;
          const dateObject = new Date(utcTimestamp);
          const istTimestamp = dateObject.toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          });

          return {
            ...msg,
            created_at_ist: istTimestamp,
          };
        });
        setIncomingmsg(messages.reverse());
      } else {
        alert("error");
      }
    } catch {
      console.log("error in sending msg");
    }
  };
  const scrollToBottom = () => {
    console.log("inside scrollto bottom");
    if (chatContainerRef.current) {
      console.log("Scrolled");
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // if (
  //   medicineName &&
  //   searchInputLower &&
  //   medicineName.toLowerCase().includes(searchInputLower)
  // ) {

  // }
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const filteredMedicine = medicine?.filter((m) => {
        const medicineName = m["Medicine Name"]?.toString();
        const searchInputLower = searchInput?.toString()?.toLowerCase();
        return medicineName.includes(searchInputLower);
      });
      setSearchnm(filteredMedicine);
      console.log("Filtereddata", filteredMedicine, searchInput);
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchInput, medicine]);

  const handleCheckboxChange = (time) => {
    setSelectedTimes((prevSelectedTimes) => ({
      ...prevSelectedTimes,
      [time]: !prevSelectedTimes[time],
    }));
  };
  const getSelectedDosageTiming = (selectedTimes) => {
    const selectedKeys = Object.keys(selectedTimes).filter(
      (time) => selectedTimes[time]
    );
    return selectedKeys.join(",");
  };
  const handletime = (time, e) => {
    if (time == "morning") setAddtime(e.target.value);
    if (time == "afternoon") setAddtimeafternoon(e.target.value);
    if (time == "night") setAddtimenight(e.target.value);
  };
  const handleClose = () => {
    setShowAlert(false);
  };
  const addprescriptiondata = async () => {
    console.log("{{LL",medicinearray);
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/add_prescription`,
        medicinearray
      );
      if (response.data.success) {
        console.log("success", response.data);
        var msg = "Prescription saved successfully";
        const error_color = "success";
        // handleAlertMsg(error_color, msg);
        setAddPrescription(true);
        setTest(true);
        fetchprescription();
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  // const consultcomplete = async () => {
  //   try {
  //     console.log("ZZZZfirst", chatData.id, consultationnumber);
  //     const response = await axios.post(
  //       `${configData.SERVER_API_URL}/consultation/update_consultation_status`,
  //       {
  //         patient_id: chatData.id,
  //         consultation_id: consultaionId,
  //         is_completed: 1,
  //       }
  //     );
  //     if (response.data.success) {
  //     } else {
  //       console.log("Error in completing consultation");
  //     }
  //   } catch (error) {
  //     console.log("error",error);
  //   }
  // };
  const handledescriptionchange = (e) => {
    setDescription(e.target.value);
  };
  const handleApiRequest = async () => {
    console.log("@medicinearray", medicinearray);
    const apiRequestData = { ...medicinearray };
    delete apiRequestData.medicine_nm;
    addprescriptiondata();

    resetallfield();
    // consultcomplete();
  };
  const fetchprescription = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_prescription_data`,
        { consultation_id:  consultaionId }
      );
      if (response.data.success) {
        console.log("@prescription", response.data.Prescrption);
        setMedilist(response.data.Prescrption);
        // setTest(true);
        console.log("@prescription22", response.data.Prescrption);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    console.log("XX", check, medilist);
  }, [check, medilist]);
  const checkstate = () => {
    setCheck(true);
    handleApiRequest();
    setEndoption(true);
    setSubmit(true);
   
  };
  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };
  const handleAddMedicine = () => {
    resetallfield();
  };
  const savebtn = () => {
    setSearchMedicine(true);
    if (!selectedMedicine && !selectedMedicine?.id) {
      // Display an error message or handle the case where required values are not filled
      console.error("Please fill in all required fields.");
      return;
    } else {
      const newMedicine = {
        medicine_nm: selectedMedicine?.["Medicine Name"],
        consultation_id: consultaionId,
        medicine_id: selectedMedicine?.id,
        description: description,
        packaging_type: selectedOption.packagingType,
        dosage_frequency: selectedOptionday.day,
        dosage_timing: getSelectedDosageTiming(selectedTimes),
        meal_timing: [
          checkmealtime?.mealtiming,
          checkmealtimeafternoon?.mealtiming,
          checkmealtimenight?.mealtiming,
        ]
          .filter((value) => value === "before" || value === "after")
          .join(","),
        add_time: [
          addtime ? addtime + "AM" : "00",
          addtimeafternoon ? addtimeafternoon + "PM" : "00",
          addtimenight ? addtimenight + "PM" : "00",
        ].join(","),
      };
      setMedicinearray([...medicinearray, newMedicine]);
      setMedicinenmarray([...medicinenmarray, medicinenm]);
      console.log("{{{{{{first}}}}}}", medicinenmarray);
    }
  };
  const resetallfield = () => {
    setSearchMedicine(false);
    setSelectedMedicine(null);
    setMedicinenm(null);
    setDescription("");
    setSelectedOption({
      id: 1,
      packagingType: "In Packets",
    });

    setSelectedOptionday({
      id: 1,
      day: "Everyday",
    });
    setSelectedTimes({
      morning: false,
      afternoon: false,
      night: false,
    });
    setCheckmeal(null);
    setCheckmealafternoon(null);
    setCheckmealnight(null);
    setAddtime("");
    setAddtimeafternoon("");
    setAddtimenight("");
  };
  const handleRemoveMedicine = (index) => {
    const updatedarray = [...medicinearray];
    updatedarray.splice(index, 1);
    setMedicinearray(updatedarray);
  };
  return (
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "30vw",
        overflowY:"scroll",
        backgroundColor: "white",
        position: "relative",
        zIndex: "999",
      }}
    >
      {/* {submit ? (
      
      ) : (
       
      )} */}
    
      {!submit && !searchMedicine && (
        <>
          <div
            style={{
              paddingTop: 50,
              fontSize: editTimeSlot ? "22px" : isTabScreen ? "18px" : "30px",
              fontWeight: "600",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position:"sticky",
              backgroundColor:"white",
              top:"0px",
              zIndex:"9999",
              color: configData.THEME_COLORS.SECONDARY,
              fontFamily: "Poppins",
            }}>
              
              <div>{title}</div>
              <hr style={{ marginTop: "6%",width:"100%",color:"black" }}></hr>
          </div>
         
          <div
            style={{
              marginTop: "5%",
              alignSelf: "center",
              width: "90%",
              border: "0.746766px solid #CBC6C6",
              borderRadius: "17px",
              marginBottom: "7%",
              paddingLeft: "5%",
              paddingRight: "5%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginLeft: "5px",
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                ADD MEDICINE
              </div>
              <div
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => setSearchMedicine(false)}
              >
                {/* <CloseIcon /> */}
              </div>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#333333",
                marginTop: "8px",
                marginLeft: "8px",
              }}
            >
              MEDICINE NAME
            </div>

            <Box
              sx={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "center",
                width: "92%",
                marginTop: "4%",
              }}
            >
              {searchnm !== null && searchnm !== undefined && (
                <Autocomplete
                  className="w-100"
                  options={searchnm}
                  value={selectedMedicine}
           
                  getOptionLabel={(option) => option?.["Medicine Name"] || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Type"
                      variant="outlined"
                      sx={{
                        backgroundColor: "#F2F2F2",
                        fontSize: isTabScreen ? "10px" : "16px",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                      }}
                      // onChange={call}
                    />
                  )}
                  onChange={handleChange}
                />
              )}
            </Box>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#333333",
                marginTop: "8px",
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              PACKAGING
            </div>
            <div className="d-flex" style={{ marginLeft: "8px" }}>
              <div
                className={`my-2 py-2 ${
                  selectedOption && selectedOption.id === 1
                    ? "borderboxselected"
                    : "borderbox"
                }`}
                onClick={() =>
                  handleDivClick({ id: 1, packagingType: "In Packets" })
                }
              >
                {/* <img src="./packet.png" className="pktimg"></img> */}
                <div className="bottlehead  text-center px-2 py-1">
                  IN PACKETS
                </div>
              </div>

              <div
                className={`my-2 py-2 mx-2 ${
                  selectedOption && selectedOption.id === 2
                    ? "borderboxselected"
                    : "borderbox"
                }`}
                onClick={() =>
                  handleDivClick({ id: 2, packagingType: "In Bottle" })
                }
              >
                {/* <img src="./bottle.png" className="bottleimg"></img> */}
                <div className="bottlehead py-1 text-center px-2">
                  IN BOTTLE
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#333333",
                marginTop: "8px",
                marginLeft: "8px",
              }}
            >
              {"WHAT DAY(S)?"}
            </div>
            <div className="d-flex" style={{ marginLeft: "8px" }}>
              <div
                onClick={() => handleDivClickday({ id: 1, day: "Everyday" })}
                className={`my-2 py-2 ${
                  selectedOptionday && selectedOptionday.id === 1
                    ? "borderboxselectedday"
                    : "borderboxday"
                }`}
              >
                <div className="bottlehead text-uppercase text-center px-2 py-1">
                  Everyday
                </div>
              </div>

              <div
                className={`my-2 py-2 mx-2 ${
                  selectedOptionday && selectedOptionday.id === 2
                    ? "borderboxselectedday"
                    : "borderboxday"
                }`}
                onClick={() => handleDivClickday({ id: 2, day: "Alternate" })}
              >
                <div className="bottlehead py-1 text-center px-2 text-uppercase">
                  Alternate
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#333333",
                marginTop: "8px",
                marginLeft: "8px",
              }}
            >
              {"WHAT TIME(S)?"}
            </div>
            <div>
              <Box style={{ marginLeft: "8px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedTimes.morning}
                              onChange={() => handleCheckboxChange("morning")}
                            />
                          }
                          label=" Morning"
                        />
                        {selectedTimes.morning && (
                          <div className="my-3 w-100 d-flex align-items-center">
                            <div
                              className={`mealbtn ${
                                checkmealtime && checkmealtime.id === 1
                                  ? "borderboxselectedday"
                                  : "borderboxday"
                              }`}
                              onClick={() =>
                                checkmeal("morning", {
                                  id: 1,
                                  mealtiming: "before",
                                })
                              }
                            >
                              Before Meal
                            </div>
                            <div
                              className={`mealbtn mx-2 ${
                                checkmealtime && checkmealtime.id === 2
                                  ? "borderboxselectedday"
                                  : "borderboxday"
                              }`}
                              onClick={() =>
                                checkmeal("morning", {
                                  id: 2,
                                  mealtiming: "after",
                                })
                              }
                            >
                              After Meal
                            </div>
                            <div style={{ width: "160px" }}>
                              <input
                                onChange={(e) => handletime("morning", e)}
                                className="my-1"
                                type="text"
                                style={{ height: "42px", width: "70%" }}
                                placeholder=" time"
                                value={addtime && addtime}
                              ></input>
                              <span>AM</span>
                            </div>
                          </div>
                        )}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedTimes.afternoon}
                              onChange={() => handleCheckboxChange("afternoon")}
                            />
                          }
                          label="Afternoon"
                        />
                        {selectedTimes.afternoon && (
                          <div className="my-3 w-100 d-flex align-items-center">
                            <div
                              className={`mealbtn ${
                                checkmealtimeafternoon &&
                                checkmealtimeafternoon.id === 3
                                  ? "borderboxselectedday"
                                  : "borderboxday"
                              }`}
                              onClick={() =>
                                checkmeal("afternoon", {
                                  id: 3,
                                  mealtiming: "before",
                                })
                              }
                            >
                              Before Meal
                            </div>
                            <div
                              className={`mealbtn mx-2 ${
                                checkmealtimeafternoon &&
                                checkmealtimeafternoon.id === 4
                                  ? "borderboxselectedday"
                                  : "borderboxday"
                              }`}
                              onClick={() =>
                                checkmeal("afternoon", {
                                  id: 4,
                                  mealtiming: "after",
                                })
                              }
                            >
                              After Meal
                            </div>
                            <div style={{ width: "160px" }}>
                              <input
                                onChange={(e) => handletime("afternoon", e)}
                                className="my-1"
                                type="text"
                                style={{ height: "42px", width: "70%" }}
                                placeholder=" time"
                              ></input>
                              <span>PM</span>
                            </div>
                          </div>
                        )}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedTimes.night}
                              onChange={() => handleCheckboxChange("night")}
                            />
                          }
                          label="Night"
                        />
                        {selectedTimes.night && (
                          <div className="my-3 w-100 d-flex align-items-center">
                            <div
                              className={`mealbtn ${
                                checkmealtimenight &&
                                checkmealtimenight.id === 5
                                  ? "borderboxselectedday"
                                  : "borderboxday"
                              }`}
                              onClick={() =>
                                checkmeal("night", {
                                  id: 5,
                                  mealtiming: "before",
                                })
                              }
                            >
                              Before Meal
                            </div>
                            <div
                              className={`mealbtn mx-2 ${
                                checkmealtimenight &&
                                checkmealtimenight.id === 6
                                  ? "borderboxselectedday"
                                  : "borderboxday"
                              }`}
                              onClick={() =>
                                checkmeal("night", {
                                  id: 6,
                                  mealtiming: "after",
                                })
                              }
                            >
                              After Meal
                            </div>
                            <div style={{ width: "160px" }}>
                              <input
                                onChange={(e) => handletime("night", e)}
                                className="my-1"
                                type="text"
                                style={{ height: "42px", width: "70%" }}
                                placeholder="time"
                              ></input>
                              <span>PM</span>
                            </div>
                          </div>
                        )}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                {/* You can access the selected times using `selectedTimes` state */}
                {/* <pre>{JSON.stringify(selectedTimes, null, 2)}</pre> */}
              </Box>
            </div>
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "18px",
                fontWeight: "500",
                marginTop: "10px",
                marginLeft: "5px",
              }}
            >
              Comment
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                // value={input}
                multiline
                size="small"
                sx={{ mt: 1, width: "90%" }}
                inputProps={{
                  style: {
                    fontSize: isTabScreen ? "10px" : "18px",
                    fontWeight: "600",
                  },
                }}
                id="outlined-basic"
                label=""
                variant="outlined"
                rows={4}
                onChange={handledescriptionchange}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={savebtn}
                style={{
                  backgroundColor: configData.THEME_COLORS.PRIMARY,
                  border: "1px solid rgba(0, 74, 173, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                  width: "8vw",
                  borderRadius: "8px",
                  color: "white",
                  marginTop: "7%",
                  cursor: "pointer",
                  marginBottom: "5%",
                }}
              >
                Next
              </div>
            </div>
          </div>
        </>
      )}
      {!submit && searchMedicine && (
        <>
          <div
            style={{
              marginTop: 50,
              fontSize: editTimeSlot ? "22px" : isTabScreen ? "18px" : "30px",
              fontWeight: "600",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: configData.THEME_COLORS.SECONDARY,
              fontFamily: "Poppins",
            }}
          >
            {title}
          </div>
          <hr style={{ marginTop: "6%",width:"100%" }}></hr>
          <div
            style={{
              marginTop: "5%",
              alignSelf: "center",
              height: "100%",
              width: "90%",
              border: "0.746766px solid #CBC6C6",
              borderRadius: "17px",
              marginBottom: "7%",
            }}
          >
            <div
              className="mt-3 backsearch"
              onClick={() => setSearchMedicine(false)}
            >
              Back to search
            </div>
            <div className="addmedicine mx-2 mt-2" onClick={handleAddMedicine}>
              Add new medicine
            </div>
            {medicinearray?.some((medicine) => medicine?.medicine_nm != null) &&
              medicinearray?.map((medicine, index) => (
                <div className="medicinenm my-2 px-2" key={index}>
                  {medicine?.medicine_nm}{" "}
                  <span
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    onClick={() => handleRemoveMedicine(index)}
                  >
                    &#x2715; {/* Cross icon */}
                  </span>
                </div>
              ))}
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "18px",
                fontWeight: "500",
                marginLeft: "5px",
                marginTop: "30px",
              }}
            >
              Doctor Name
            </div>
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "18px",
                fontWeight: "400",
                color: "#333333",
                marginTop: "8px",
                marginLeft: "8px",
              }}
            >
              {drnm}
            </div>
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "18px",
                fontWeight: "500",
                marginTop: "10px",
                marginLeft: "5px",
              }}
            >
              Consultation Number
            </div>
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "18px",
                fontWeight: "400",
                color: "#333333",
                marginTop: "8px",
                marginLeft: "8px",
              }}
            >
              {consultationnumber}
            </div>
            {/* <div
            style={{
              fontSize: isTabScreen ? "10px" : "18px",
              fontWeight: "500",
              marginTop: "10px",
              marginLeft: "5px",
            }}
          >
            Patient Condition
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TextField
              // value={input}
              size="small"
              sx={{ mt: 1, width: "90%" }}
              inputProps={{
                style: { fontSize: "18px", fontWeight: "600" },
              }}
              id="outlined-basic"
              label=""
              variant="outlined"
              // onChange={(e) => setInput(e.target.value)}
            />
          </div> */}
            <div
              style={{
                fontSize: isTabScreen ? "10px" : "18px",
                fontWeight: "500",
                marginTop: "10px",
                marginLeft: "5px",
              }}
            >
              Medicine
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  marginTop: 1,
                  width: "90%",
                  height: "42px",
                  fontSize: isTabScreen ? "10px" : "18px",
                  border: "1px solid #cccccc",
                  fontWeight: 600,
                  borderRadius: "4px",
                }}
              >
                {medicinenm}
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={checkstate}
                // onClick={() => {
                //   setSubmit(true);
                //   handleAdd();
                // }}

                style={{
                  backgroundColor: configData.THEME_COLORS.PRIMARY,
                  border: "1px solid rgba(0, 74, 173, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: isTabScreen ? "10px" : "18px",
                  fontWeight: "500",
                  width: "8vw",
                  borderRadius: "8px",
                  color: "white",
                  marginTop: "7%",
                  cursor: "pointer",
                  marginBottom: "5%",
                }}
              >
                Submit
              </div>
            </div>
            {/* <div>CHECK</div> */}
          </div>
        </>
      )}
      {submit===true && <div className="pt-5">
          <div className="text-center"><CircularProgress color="primary" 
          style={{ height: '100px', width:"100px", color: '#F0588B' }}/></div>
          <h1 className="fw-bolder text-center" style={{ marginLeft: "10px",color:"rgb(240, 88, 139)" }}>
            Wait, your prescription is being generated...
            </h1>
        </div>}
      {/* {medilist && ( */}
     
      {/* )} */}
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleClose}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  <div className="mt-5 position-absolute right-0 w-50 bg-danger" 
  style={{ left: "100px" }}
  >
          <PrescriptionComponent
            medicationDetails={medilist}
            profileData={profileData}
            chatData={chatData}
            redirectToChat={redirectToChat}
            submit={submit}
          />
        </div>
    </>
  );
}

export default AddPrescriptionBox;
