import moment from "moment";
import React, { useMemo } from "react";

const ConsultationCardDetails = ({consultationsData}) => {
    const memoizedValue = useMemo(() => {
        console.log("consultationsData",consultationsData)
        return consultationsData ? consultationsData.length : 0;
    },[consultationsData]);

    return(
        <div style={{ border: "0.5px solid #E8E7E7" , width: "80%" , margin: "20px auto" , borderRadius: "12px" ,padding:"4px" }}>
        <div style={{ paddingTop: "10px" , fontSize: "20px" , fontWeight: "500" , color: "#004AAD" , textAlign: "center" , }}>
        {moment(consultationsData.date).format("Do MMMM YYYY")}
        </div>
        <div className="d-flex justify-content-between px-1" style={{ paddingTop: "17px" , paddingBottom: "10px" }}>
            <div className="text-center pt-3">
            <img src={"/Pathology/scheduleimg.png"} style={{ width: "37px" , height: "56px" }}></img>
            <div style={{ paddingTop: "10px" , fontSize: "16px" , fontWeight: "600" , color: "#464E5F" , }}>
                Dr.{consultationsData.doctorName}
            </div>
            {/* <div style={{ paddingTop: "5px" , fontSize: "13px" , fontWeight: "500" , color: "#B5B5C3" , }}>
                {moment(consultationsData.date).format("Do MMMM YYYY")}
            </div> */}
            </div>
            <div>
            <div>
                <div style={{ fontSize: "16px" , fontWeight: "500" }}>
                Consultation ModeP
                </div>
                <div style={{ fontSize: "16px" , fontWeight: "400" , color: "#868686" , }}>
                {consultationsData.mode}
                </div>
            </div>
            <div style={{ fontSize: "16px" , fontWeight: "500" , paddingTop: "36px" , }}>
                {" "}
                Timing AM
            </div>
            <div style={{ fontSize: "16px" , fontWeight: "400" , color: "#868686" , }}>
                {" "}
                {consultationsData.timing}
            </div>
            </div>
        </div>
        </div>
    )
} 

export default ConsultationCardDetails;