import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Calendar from "react-calendar";
// import configData from "../config.json";
import configData from "../../config.json"
import moment from "moment";
import Time1 from "./Time1";
const headcalendar = {
  marginTop: "46px",
  fontSize: "30px",
  fontWeight: "600",
  textAlign: "center",
  alignItems: "center",
  color: configData.THEME_COLORS.SECONDARY,
  fontFamily: "Poppins",
};
let dateclicked;
let r;
let flag = 0;
function Calendarpart({
  onClick,
  scheduleddata,
  state,
  dates,
  timings,
  info,
  objectData,
  doctorInfo,
  falseshowtime,
}) {

  const [value, setValue] = useState([new Date()]);
  const [showTime, setShowTime] = useState(false);
  const [date, setdate] = useState("02 MAY 2023");
  const [formattedDate, setFormattedDate] = useState();
  const [timingsData, setTimingsData] = useState(timings);
  const [enabledDates, setEnabledDates] = useState(dates);
  const [dateclick, setDateclick] = useState(false);
  const [showTimes, setShowTimes] = useState(false);

  const handlechange = (e) => {
    dateclicked = e;
    setShowTime(true);
    setFormattedDate(moment(dateclicked).format("YYYY-MM-DD"));
    
    // if (state?.id == 2) {
    //   setInfo(false);
    // }
    setDateclick(true);
    r = moment(dateclicked).format("Do MMMM YYYY");
    const findDate = moment(dateclicked).format("YYYY-MM-DD");
    const timings_ = objectData.find((i) => i.date === findDate);
    const timingsData_ = timings_?.timings && timings_?.timings.split(",");
    setTimingsData(timingsData_);
    setdate(r);
  };

  console.log("timingsData", timingsData);
  const location = useLocation();
  useEffect(() => {
    if (state?.id == 2) {
      //  eventDate={moment(item?.scheduled_date).format("MMM DD, YYYY")}
      setdate(state.eventDate);
      setShowTime(true);
    }
  }, [timingsData]);

  useEffect(() => {
    if (enabledDates?.length > 0) {
      const firstEnabledDate = new Date(enabledDates[0]);
      // Set the initial month to the month of the first enabled date
      setValue(firstEnabledDate);
    }
  }, [enabledDates]);

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const currentDate = date;
      let isEnabled = false;
      setEnabledDates(dates);
      console.log("ENABLED DATES", enabledDates);
      enabledDates?.forEach((enabledDate) => {
        const [year, month, day] = enabledDate?.split("-").map(Number);
        const enabledDateObj = new Date(year, month - 1, day); // month is 0-indexed in JavaScript dates

        if (isSameDay(currentDate, enabledDateObj)) {
          isEnabled = true;
        }
      });

      return !isEnabled;
    }

    return false;
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={headcalendar}>Schedule Consultation</div>
      <p className="text-center">Consultation {scheduleddata?.consultation_number}</p>
      <hr className="w-100"></hr>
      <div className="px-3" style={{ marginTop: 20 }}>
        {showTimes===false &&
        <Calendar
          onChange={handlechange}
          value={value}
          tileDisabled={tileDisabled}
          tileClassName={() => {
            return "red_item";
          }}
          onClickDay={() => setShowTime(true)}
          navigation={"off"}
        />
        }
        <Time1
          showTime={showTime}
          value={value}
          onClick={onClick}
          scheduleddata={scheduleddata}
          date={date}
          formattedDate={formattedDate}
          state={state}
          timings={timingsData}
          info={info}
          doctorInfo={doctorInfo}
          dateclick={dateclick}
          setShowTimes={setShowTimes}
        />
      </div>
    </div>
  );
}

export default Calendarpart;
