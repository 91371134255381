import React, { useState, useEffect } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

var selected2;
function ReportBox({
  onClick,
  onClick2,
  item,
  isVisible,
  clicked,
  isVisibleInspect,
  patientId,
  isPathology,
  isSonography,
}) {
  const [clicked1, setClicked] = useState(false);
  const [clicked2, setClicked2] = useState(false);

  const [selected, setSelected] = useState();

  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  return (
    <div className={clicked ? "col-12" : "col-4"} style={{ marginTop: "20px" }}>
      <div
        style={
          isVisible && selected === item.id
            ? {
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "97.5%",
                borderRadius: "12px",
                backgroundColor: "#FFFFFF",
                border: "0.5px solid #CBC6C6",
                boxSizing: "border-box",
                border: "0.5px solid #CBC6C6",
                boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1608)",
                borderRadius: "12px",
              }
            : {
                alignSelf: "center",
                display: "flex",
                width: "97.5%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "12px",
                backgroundColor: "#FFFFFF",
                border: "0.5px solid #CBC6C6",
                boxSizing: "border-box",
                border: "0.5px solid #CBC6C6",
                borderRadius: "12px",
              }
        }
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            paddingTop: "20px",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div>
            <img alt="nt found"
              src={
                item?.profile_pic
                  ? configData.API_URL + item?.profile_pic
                  : "/profile/Group-1151.png"
              }
              style={{
                resizeMode: "contain",
                height: isTabScreen ? "40px" : "60px",
                width: isTabScreen ? "40px" : "60px",
                marginRight: "10px",
                marginLeft: "15%",
                borderRadius: "50%",
              }}
            />
          </div>
          <div style={{ marginRight: "10%" }}>
            <div
              style={{
                fontSize: isTabScreen ? "15px" : "18px",
                fontWeight: "600",
                color: "#333333",
                marginTop: "13px",
                marginLeft: "6px",
              }}
            >
              {"Admission Date"}
            </div>

            <div
              style={{
                fontSize: isTabScreen ? "14px" : "17.3px",
                fontWeight: "500",
                color: configData.THEME_COLORS.PRIMARY,
                marginLeft: "6px",
              }}
            >
              {/* {"01 OCT 2022"} */}
              {moment(item?.created_date).format("Do MMM YYYY")}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontSize: isTabScreen ? "15px" : "17px",
                  color: "#333333",
                  width: "80px",
                }}
              >
                Name :
              </div>
              <div
                className="d-flex"
                style={{
                  fontSize: isTabScreen ? "15px" : "17px",
                  fontWeight: "500",
                  color: "#333333",
                  marginLeft: "5%",
                  width: "70%",
                }}
              >
                <div>{item.fname}</div>
                <div className="ms-1">{item.lname}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "2%",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: isTabScreen ? "12px" : "14px",
                  color: configData.THEME_COLORS.PRIMARY,
                }}
              >
                Patient ID:
              </div>
              <div
                style={{
                  marginLeft: "2%",
                  width: isTabScreen ? "50%" : "150px",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "14px" : "16px",
                    fontWeight: "500",
                    color: configData.THEME_COLORS.PRIMARY,
                    // wordBreak: "break-word",
                  }}
                >
                  {item.id}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "80%",
          }}
        >
          {!isPathology && !isSonography && (
            <div
              style={{
                marginTop: "40px",
                fontWeight: "400",
                fontSize: isTabScreen ? "14px" : "20px",
                color: "#7C9CBF",
                display: "flex",
                cursor: "pointer",
                height: "44.41px",
                width: isTabScreen ? "70px" : "110px",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "35px",
                boxShadow:
                  isVisible && patientId === item?.id
                    ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                    : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                color:
                  isVisible && patientId === item?.id ? "#F0588B" : "#7C9CBF",
                borderRadius: "10%",
              }}
              onClick={() => {
                setSelected(item.id);
                setClicked(true);
                onClick(item);
              }}
            >
              Chat
            </div>
          )}

          <div
            style={{
              marginTop: "40px",
              marginLeft: isTabScreen ? "5%" : "10%",
              fontWeight: "400",
              fontSize: isTabScreen ? "14px" : "20px",
              color: "#7C9CBF",
              display: "flex",
              cursor: "pointer",
              height: "44.41px",
              width: isTabScreen ? "70px" : "110px",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "35px",
              boxShadow:
                isVisibleInspect && selected2 === item.id
                  ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                  : "0px 4px 8px rgba(44, 39, 56, 0.078)",
              color:
                isVisibleInspect && selected2 === item.id
                  ? "#F0588B"
                  : "#7C9CBF",
              borderRadius: "10%",
            }}
            onClick={() => {
              selected2 = item.id;
              setClicked2(!clicked2);
              // console.log('selct?', item.id)
              onClick2();
            }}
          >
            Inspect
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportBox;
