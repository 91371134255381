import React, { useEffect, useState, useRef,createContext, useContext} from "react";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import moment, { RFC_2822, relativeTimeRounding } from "moment";
import MiniDrawer from "../Components/MiniDrawer";
import RightBox from "../Components/RightBox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Dropdown from "../Components/Dropdown";
import { fadeIn } from "react-animations";
import html2canvas from "html2canvas";
import Radium, { StyleRoot } from "radium";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircleIcon from "@mui/icons-material/Circle";
import Modal from "@mui/material/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Badge from "@mui/material/Badge";
import configData from "../config.json";
import AlertShow from "../Components/AlertShow";
import OTPInput from "../Components/OTPInput";
const medicalexpertcontext=createContext();
let flag = 0;
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

const dashboardData = [
  {
    name: "Jane Doe",
    type: "Gynaecologist",
    completed: 2,
    total: 15,
    time: "11:00",
    v: "am",
    consultationno: 2,
    week: 15,
    status: "Completed",
    index: "1",
  },
  {
    name: "Jane Doe",
    type: "Doctor of Medicine",
    completed: 1,
    total: 13,
    time: "11:00",
    v: "am",
    consultationno: 1,
    week: 13,
    status: "Completed",
    index: "2",
  },
  {
    name: "Jane Doe",
    type: "Pediatrician",
    completed: 0,
    total: 2,
    time: "11:00",
    v: "am",
    consultationno: 0,
    week: 2,
    status: "In Progress",
    index: "3",
  },
  {
    name: "Jane Doe",
    type: "Physiotherapist",
    completed: 1,
    total: 3,
    time: "11:00",
    v: "am",
    consultationno: 1,
    week: 3,
    status: "Pending",
    index: "4",
  },
  {
    name: "Jane Doe",
    type: "Pediatrician",
    completed: 2,
    total: 10,
    time: "11:00",
    v: "am",
    consultationno: 2,
    week: 15,
    status: "In Progress",
    index: "5",
  },
  {
    name: "Jane Doe",
    type: "Gynaecologist",
    completed: 2,
    total: 15,
    time: "11:00",
    v: "am",
    consultationno: 1,
    week: 15,
    status: "Completed",
    index: "6",
  },
  {
    name: "Jane Doe",
    type: "Doctor of Medicine",
    completed: 1,
    total: 13,
    v: "am",
    time: "11:00",
    consultationno: 0,
    week: 15,
    status: "No Show",
    index: "7",
  },
  {
    name: "Jane Doe",
    type: "Gynaecologist",
    completed: 2,
    total: 15,
    time: "11:00",
    v: "am",
    consultationno: 1,
    week: 15,
    status: "No Show",
    index: "8",
  },
];

const Initialdashboarddata = [];
for (let i = 1; i <= 27; i++) {
  Initialdashboarddata.push({
    index: i.toString(),
    week: "",
    value: "false",
    consentvalue: "false",
  });
}

const data = [];
for (let i = 1; i <= 40; i++) {
  data.push({ no: i.toString() });
}

const btn = [];

for (let i = 1; i <= 27; i++) {
  btn.push({
    id: i.toString(),
    online: "false",
    InClinic: "false",
  });
}
let clickedid;

let selectindex;
let indexfetched;
let onlineindex;
let inpersonindex;
let index1;
let selectedindex;
let selectedindex1;
let t;
let firstname;
let lastname;
const todayDate = moment().format("YYYY-MM-DD");
let patientid;
let signupFormphone = "9545242173";
let myflag = 0;
let testname;
function Dashboard() {
  const location = useLocation();
  const prescriptionContainerRef = useRef();
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const inputbox = {
    height: "40px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "7px",
    margin: "0px auto",
  };
  const innerinput = {
    border: "none",
    height: "40px",
  };
  const initiatebtn = {
    backgroundColor: "#0CB800",
    width: "150px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderRadius: "12px 12px 0px 0px",
  };

  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [give, setGive] = useState();
  const [dateClicked, setDateClicked] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isHovericon, setIsHovericon] = useState(false);
  const [click, setClick] = useState(false);
  const [clickimg, setClickimg] = useState(null);
  const [changeimgtab, setChangeimg] = useState(false);
  const [myindex, selectindex] = useState(false);
  const [clicktd, setClicktd] = useState();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [add, setAdd] = useState(false);
  const [onlinetype, setOnlinetype] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(null);
  const [timer, setTimer] = useState(90); // Initial timer value in seconds
  const [verifiedno, setVerified] = useState();
  const [verifiedtext, setVerifiedtext] = useState(false);
  const [otp, setOtp] = useState("");
  const [redphone, setRedPhone] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(0);
  // const [type, setType] = useState(null);
  const [inpersontype, setInpersontype] = useState(false);
  const [assignbox, setAssignbox] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const [showotp, setShowotp] = useState();
  const [sentconsent, setSentconsent] = useState();

  const handleOpen = (item, week) => {
    console.log("ModelOpen", week);
    if (week !== undefined) {
      selectindex(parseInt(week));
    } else {
      selectindex(false);
    }
    setItemId(item);
    setOpen(true);
    checkAllTrue();
  };

  // useEffect(() => {
  //   console.log("%%%", Initialdashboarddata);
  // }, [dashboardData3]);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  const [bg, setBg] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [consultationDataToBeSent, setConsultationDataToBeSent] = useState([]);
  const [consultationTypes, setConsultationTypes] = useState([]); //fetched object from API that is consultation type
  const [dashboardData1, setDashboardData1] = useState(Initialdashboarddata);
  const [dashboardData2, setDashboardData2] = useState(Initialdashboarddata);
  const [dashboardData3, setDashboardData3] = useState(Initialdashboarddata);
  const [changeArray, setChangeArray] = useState();

  const [isDisabled, setIsDisabled] = useState({});
  const [showbtn, setShowbtn] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [buttonStates, setButtonStates] = useState(btn);

  const [consultation_today, setconsultation_today] = useState();
  const [consultation_remaining_today, setconsultation_remaining_today] =
    useState();
  const [consultations, setConsultations] = useState();
  const [userData, setUserData] = useState();
  const [upcoming, setUpcoming] = useState();
  const [submenu, setSubmenu] = useState(false);
  const [profilemenu, setProfilemenu] = useState(false);
  const [logoutmenu, setLogoutmenu] = useState(false);
  const [count, setCount] = useState(0);
  const [profileData, setProfileData] = useState([]);
  const [itemId, setItemId] = useState(null);
  let filteredData;
  const [isPathology, setIsPathology] = useState(false);
  const [isSonography, setIsSonography] = useState(false);
  const [isYogaExperts, setIsYogaExperts] = useState(false);
  const [medicalExpertName, setMedicalExpertName] = useState();
  const [isAllTrue, setIsAllTrue] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const [consultg, setConsultg] = useState(false);
  const [consultp, setConsultp] = useState(false);
  const [consults, setConsults] = useState(false);
  const [indexotp, setIndexotp] = useState(0);
  const [sentconsentform, setSentconsentform] = useState();
  const [consentform, setConsentform] = useState();
  // const [consentform, setSentconsentform] = useState();
  useEffect(() => {
    setConsultg(true);
    setConsults(false);
    setConsultp(false);
  }, []);
  useEffect(() => {
    // console.log("@#W",changeArray,dashboardData1,dashboardData2,dashboardData3,isAllTrue);
    checkAllTrue();
    console.log("changeArray>>",changeArray)
  }, [changeArray]);

  const names = [
    "1st trimester test",
    "2nd trimester test",
    "3rd trimester test",
    "4th trimester test",
    "5th trimester test",
  ];
  const names1 = [
    "Obstetric sonography (First time)",
    "Routine Sonography",
    "Nuchal Translucency Test",
    "Anomaly Scan",
    "Fetal Echocardiography",
    "Fetal well-being scan",
    "Colour Doppler Ultrasound",
  ];
  useEffect(() => {
    console.log("Medical Expert Name:", userData?.medical_expert_id);
    switch (userData?.medical_expert_name) {
      case "Gynecologist":
        setDashboardData1(Initialdashboarddata.slice(0, 15));
        setDashboardData2(
          Initialdashboarddata.slice(15, 20).map((data, index) => ({
            ...data,
            Name: names[index],
          }))
        );
        setDashboardData3(
          Initialdashboarddata.slice(20, 27).map((data, index) => ({
            ...data,
            Name: names1[index],
          }))
        );

        break;
      case "General Physician":
        setDashboardData1(Initialdashboarddata.slice(0, 12));
        break;
      case "Pediatrician":
        setDashboardData1(Initialdashboarddata.slice(0, 2));
        break;
      case "Yoga Expert":
        setIsYogaExperts(true);
        setDashboardData1(Initialdashboarddata.slice(0, 5));
        break;
      case "Dietitian":
        setDashboardData1(Initialdashboarddata.slice(0, 5));
        break;
      case "Psychiatrist":
        setDashboardData1(Initialdashboarddata.slice(0, 9));
        break;
      case "Breastfeeding Consultant":
        setDashboardData1(Initialdashboarddata.slice(0, 1));
        break;
      case "Pathology Lab": {
        setIsPathology(true);
        setDashboardData1(Initialdashboarddata.slice(15, 20));
        break;
      }
      case "Sonography Centre": {
        setIsSonography(true);
        setDashboardData1(Initialdashboarddata.slice(21, 27));
        break;
      }
      default:
        setDashboardData1(Initialdashboarddata);
    }
  }, [userData]);

  // console.log("changeArray", changeArray);

  useEffect(() => {
    let interval;
    if (isTimerActive != null && timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      // setIsTimerActive(false);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTimerActive, timer]);

  const sentconsentformtodr = async (item,i) => {
    const prescriptionContainer = prescriptionContainerRef.current;
    try {
      const canvas = await html2canvas(prescriptionContainer);
      const image = canvas.toDataURL("image/png");
      console.log("==", selectedindex1, image,item,i);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/addConsentForm`,
        {
          patientId: selectedindex1,
          Files: image,
        }
      );
      if (response.status === 200) {
        setSentconsentform(i);
        var msg = "Sent successfully";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
           const data3 = dashboardData3?.map((item) => {
            console.log("pppp",item.index);
            console.log("pppp",i);
          if (item.index == i) {
            console.log("ppp",item.index,i.toString)
            return { ...item, consentvalue: "true" };
          
          }
          return item;
        });
        setDashboardData3(data3);
        setChangeArray(data3);
        const consentItem = data3.find((item) => item.index === i.toString());
        if (consentItem && consentItem.consentvalue === "true") {
             console.log("pp",consentItem)
        }
      } else {
        var msg = "Error";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }
    } catch (error) {
      console.error("Error in sending message:", error);
      var msg = "Error in sending message";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
    }
  };

  // useEffect(()=>{

  // },[ isTimerActive])
  const isMobileNumber = (value) => {
    return /^\d{10}$/.test(value);
  };
  const generateOTP = (i) => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }

    setOtp(OTP);
    console.log("MYYYYY oootttppp", i);
    setIsTimerActive(i);
    setTimer(90); // Reset timer to initial value when OTP is generated
    return OTP;
  };
  const sentform = (item, i) => {
    console.log("@#", i, item);
    setOpen2(true);
    setConsentform(i);
  };
  const checkAllTrue = () => {
    let isTrue;
    let isTrue1;
    let isTrue2;
    if (userData?.medical_expert_id === 1) {
      isTrue = dashboardData1.every((item) => item?.value === "true");
      isTrue1 = dashboardData2.every((item) => item?.value === "true");
      isTrue2 = dashboardData3.every((item) => item?.value === "true");
      console.log("@#W1", isTrue, isTrue1, isTrue2);
      if (isTrue && isTrue1 && isTrue2) {
        setIsAllTrue(true);
        console.log("@#W12", isTrue, isTrue1, isTrue2, isAllTrue);
      }
    } else {
      isTrue = dashboardData1.every((item) => item?.value === "true");
      setIsAllTrue(isTrue);
      console.log("@#W13", isTrue, isTrue1, isTrue2, isAllTrue);
    }
  };

  const handlePathologyConsulation = () => {
    setMedicalExpertName("Pathology Lab");
    setChangeArray(dashboardData2);
    setConsultp(true);
    setConsults(false);
    setConsultg(false);
    // selectindex(false)
  };

  const handleSonograpyConsultaion = () => {
    setMedicalExpertName("Sonography Centre");
    setChangeArray(dashboardData3);
    setConsults(true);
    setConsultp(false);
    setConsultg(false);
    // selectindex(false)
  };

  const handleGynecologistConsultation = () => {
    setMedicalExpertName("Gynecologist");
    setChangeArray(dashboardData1);
    setConsultg(true);
    setConsults(false);
    setConsultp(false);

    // selectindex(false)
  };
  const handleOTPComplete = (otp1, i) => {
    console.log("OTP Entered:", otp, otp1);
    // Add your logic to handle the completed OTP here
    checkpw(otp1, i);
  };
  const checkpw = (otpentered, i) => {
    console.log("{}{}{}{}otp check", otpentered,i);
    console.log("{}{}{}{}otp check", otp);
    if (otpentered === otp) {
      myflag = 1;
      setTimeout(() => {
        setVerified(i);
        setVerifiedtext(true);
      }, 1500);
      setInvalidOTP(false);
    } else {
      myflag = 0;
      setVerified(false);
      setVerifiedtext(false);
      setInvalidOTP(i);
      resetOtpFields();
      setTimeout(() => {
        // alert("Mobile number/OTP invalid");
        var msg = "Mobile number/OTP invalid";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }, 1500);
    }
  };
  const resetOtpFields = () => {
    setOtp(["", "", "", "", "", ""]); // Reset the OTP fields
    setResetKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    getapi1();
  }, []);
  const sendSMS = async (item, i) => {
    // e.preventDefault();
    console.log("mydata", i);
    console.log("##", i, item);
    testname = item?.Name;
    setIndexotp(i);
    setIsTimerActive(i);
    const otp = generateOTP(i);
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/sendOtp`, {
        phone: signupFormphone,
        otp: otp,
      });

      if (response && response.data) {
        console.log("++",response)
        if (response.data.success) {
          var msg = "OTP sent successfully";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          // alert("OTP sent successfully");
        } else if (response.data.error) {
          console.error("Error in sending message:", response.data.error);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error fetching details:", error.message);
    }
  };
  const getapi1 = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          console.log("@@@@fetched$$$$$$$$$$$$$$$$$$$$$", response.data);
          setProfileData(response.data);
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {}, [clicktd]);

  useEffect(() => {
    if (profileData) {
      getpatientlist();
    }
  }, [profileData]);
  useEffect(() => {
    if (profileData) {
      getpatientlist1();
    }
  }, [profileData]);

  function PathTable2(props) {
    const isTabScreen = useMediaQuery({
      query: "(min-width: 768px)" && "(max-width: 1243px)",
    });
    const [status, setStatus] = useState(false);
    const [filterdiv, setFilterdiv] = useState(false);
    const [filtereddata, setfiltereddata] = useState(dashboardData);

    const navigate = useNavigate();
    const settingstate = (id) => {
      setStatus(true);
      clickedid = id;
    };

    const noshowfun = () => {
      setFilterdiv(true);
      setcompleted(false);
      setnoshow(false);
    };
    const reschedule = () => {
      navigate("/doctor/Communication", { state: 1 });
    };

    const [completed, setcompleted] = useState(false);
    const [show, setnoshow] = useState(false);

    return (
      <>
        {props.click === true ? (
          <div
            style={{
              height: filterdiv === true ? "30px" : "20px",
              width: "100%",
              backgroundColor:
                filterdiv === true ? "rgba(225, 123, 158, 0.4)" : "white",
              border: "0.7px solid rgba(225, 123, 158, 0.5)",
              borderRadius:
                filterdiv === true
                  ? "16.35px 16.35px 0px 0px"
                  : "16.35px 16.3px 8px 8px",
              display: "flex",
              justifyContent: filterdiv === true ? "space-between" : "end",
              alignItems: "center",
              marginRight: "7px",
              boxShadow:
                filterdiv === true
                  ? "inset 0px -4px 4px rgba(51, 51, 51, 0.078)"
                  : "none",
            }}
          >
            {filterdiv === true ? (
              <>
                <div className="d-flex ms-4">
                  <div
                    style={{
                      background:
                        completed === true
                          ? "white"
                          : "rgba(255, 255, 255, 0.6)",
                      border: "1px solid #FFF0F5",
                      borderRadius: "10px 0px 0px 10px",
                      width: "100px",
                      height: "26px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color:
                        completed === true
                          ? "#58CF99"
                          : "rgba(70, 78, 95, 0.8)",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setcompleted(true);
                      setnoshow(false);
                      const filtereddata = dashboardData.filter(
                        (value) => value.status === "Completed"
                      );
                      console.log("@@@@@@@######", filtereddata);

                      setfiltereddata(filtereddata);
                    }}
                  >
                    Completed
                  </div>
                  <div
                    style={{
                      background:
                        show === true ? "white" : "rgba(255, 255, 255, 0.6)",
                      border: "1px solid #FFF0F5",
                      borderRadius: "0px 10px 10px 0px",
                      width: "100px",
                      height: "26px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color:
                        show === true ? "#EE2E41" : "rgba(70, 78, 95, 0.8)",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setnoshow(true);
                      setcompleted(false);
                      const filterdatanoshow = dashboardData.filter(
                        (val) => val.status == "No Show"
                      );
                      setfiltereddata(filterdatanoshow);
                    }}
                  >
                    No Show
                  </div>
                </div>
                <img
                  src="./Communication/downchevron.png"
                  className="pe-2"
                  onClick={() => {
                    setFilterdiv(false);
                    setfiltereddata(dashboardData);
                  }}
                  style={{ cursor: "pointer" }}
                ></img>
              </>
            ) : (
              <img
                src="./Communication/upchevron.png"
                className="pe-2"
                onClick={() => noshowfun()}
                style={{ cursor: "pointer" }}
              ></img>
            )}
          </div>
        ) : (
          <div></div>
        )}
        <table class="spacing-table2">
          <thead>
            <tr
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                fontFamily: "Poppins",
                fontSize: isTabScreen ? "6px" : "12px",
                fontWeight: "600",
                color: configData.THEME_COLORS.SECONDARY,
                backgroundColor: "rgba(243, 246, 249, 1)",
              }}
            >
              <div
                class="theading"
                style={{
                  width: "100%",
                  height: isTabScreen ? "22px" : "44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F3F6F9",
                  alignSelf: "center",
                  borderRadius: "6px",
                }}
              >
                <th
                  style={{
                    textAlign: "center",
                    fontSize: isTabScreen ? "6px" : "12px",
                    width: "10%",
                    padding: "0px",
                  }}
                >
                  TIME
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: isTabScreen ? "6px" : "12px",
                    width: "30%",
                    padding: "0px",
                  }}
                >
                  PATIENT NAME
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: isTabScreen ? "6px" : "12px",
                    width:
                      (completed === true && filterdiv === true) ||
                      (show === true && filterdiv === true)
                        ? "14%"
                        : "20%",
                    padding: "0px",
                  }}
                >
                  {/* {isYogaExperts ? "BATCH" : "CONSULTATION"} NUMBER */}
      {userData?.medical_expert_id==8 || userData?.medical_expert_id==9 ?
      "EVENT NUMBER":"CONSULTATION NUMBER"}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: isTabScreen ? "6px" : "12px",
                    width:
                      (completed === true && filterdiv === true) ||
                      (show === true && filterdiv === true)
                        ? "14%"
                        : "20%",
                    padding: "0px",
                  }}
                >
                  {isYogaExperts ? "SESSIONS" : "PREGNANCY WEEK"}
                </th>
                {((show === true &&
                  filterdiv === true &&
                  props.click === true) ||
                  (completed === true &&
                    filterdiv === true &&
                    props.click === true)) && (
                  <th
                    style={{
                      textAlign: "center",
                      // fontSize: isTabScreen
                      //   ? show === true || completed === true
                      //     ? "5px"
                      //     : "6px"
                      //   : show === true || completed === true
                      //   ? "10px"
                      //   : "12px",
                      fontSize: isTabScreen ? "6px" : "12px",
                      width: "16%",
                      padding: "0px",
                    }}
                  >
                    DATE
                  </th>
                )}
                <th
                  style={{
                    textAlign: "center",
                    fontSize: isTabScreen ? "6px" : "12px",
                    width:
                      (completed === true && filterdiv === true) ||
                      (show === true && filterdiv === true)
                        ? "16%"
                        : "20%",
                    padding: "0px",
                  }}
                >
                  STATUS
                </th>
              </div>
            </tr>
          </thead>
          <tbody>
            {consultations?.map((item) => (
              <tr style={{ height: isTabScreen ? "50px" : "70px" }}>
                <td
                  style={{
                    fontSize: isTabScreen ? "8px" : "13px",
                    fontWeight: "500",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  <div style={{}}>{item?.timing}</div>
                  {/* <div>{item?.v}</div> */}
                </td>
                <td
                  style={{
                    fontSize: isTabScreen ? "8px" : "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    width: "30%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "15px",
                    }}
                  >
                    <img
                      src={"/test1.png"}
                      style={{
                        marginTop: "5px",
                        // marginLeft: isTabScreen ? "10px" : "27px",
                        resizeMode: "contain",
                        height: isTabScreen ? "25px" : "50px",
                        width: isTabScreen ? "25px" : "50px",
                      }} alt="not found"
                    />

                    <div
                      style={{
                        marginLeft: "5px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginTop: "5px",
                        // marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: isTabScreen ? "8px" : "14px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        {item?.first_name + " " + item?.last_name}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    fontSize: isTabScreen ? "10px" : "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    width:
                      (completed === true && filterdiv === true) ||
                      (show === true && filterdiv === true)
                        ? "14%"
                        : "20%",
                  }}
                >
                  <div>
                    {isYogaExperts ? item?.batch_id : item?.consultation_number}
                  </div>
                </td>
                <td
                  style={{
                    fontSize: isTabScreen ? "10px" : "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    width:
                      (completed === true && filterdiv === true) ||
                      (show === true && filterdiv === true)
                        ? "14%"
                        : "20%",
                  }}
                >
                  <div>{item?.week}</div>
                </td>
                {((show === true &&
                  filterdiv === true &&
                  props.click === true) ||
                  (completed === true &&
                    filterdiv === true &&
                    props.click === true)) && (
                  <td
                    style={{
                      fontSize: isTabScreen ? "10px" : "20px",
                      fontWeight: "500",
                      textAlign: "center",
                      width: "16%",
                    }}
                  >
                    <div style={{ fontSize: isTabScreen ? "6px" : "12px" }}>
                      20 Aug 23
                    </div>
                  </td>
                )}
                <td
                  style={{
                    fontSize: isTabScreen ? "6px" : "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    width:
                      (completed === true && filterdiv === true) ||
                      (show === true && filterdiv === true)
                        ? "16%"
                        : "20%",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <div
                      style={{
                        color: item?.is_completed
                          ? "#58CF99"
                          : item.status === "Pending"
                          ? "#04AFF1"
                          : item.status === "No Show"
                          ? "#F03141"
                          : "#FEA928",
                      }}
                    >
                      {item?.is_completed ? "Completed" : "Pending"}
                    </div>
                    <div className="ps-2">
                      {item?.is_completed ? (
                        <img
                          src={"/tickmark.png"}
                          style={{
                            width: isTabScreen ? "6px" : "12px",
                            height: isTabScreen ? "6px" : "12px",
                          }}
                        ></img>
                      ) : // : clickedid === item.index ? (
                      //   <img
                      //     src={"/tickmark.png"}
                      //     style={{
                      //       width: isTabScreen ? "6px" : "12px",
                      //       height: isTabScreen ? "6px" : "12px",
                      //     }}
                      //   ></img>
                      // )
                      item.status === "Pending" ? (
                        <img
                          src={"/pending.png"}
                          style={{ width: "10px", height: "10px" }}
                        ></img>
                      ) : item.status === "No Show" ? (
                        <img
                          src={"./Communication/noshow.png"}
                          style={{ width: "10px", height: "10px" }}
                        alt ="nt found"></img>
                      ) : (
                        <img
                          src={"/progress.png"}
                          style={{ width: "10px", height: "10px" }}
                        alt ="nt found"></img>
                      )}
                    </div>
                  </div>
                  {item.status === "In Progress" ? (
                    clickedid != item.index ? (
                      <div
                        className="d-flex align-items-center"
                        style={{
                          borderRadius: "5px",
                          border: "0.5px solid gray",
                          cursor: "pointer",
                          width: "70%",
                          marginLeft: "auto",
                        }}
                        onClick={() => settingstate(item.index)}
                      >
                        <img
                          src={"/smalltick.png"}
                          style={{ width: "9px", height: "9px" }}
                        alt ="nt found"></img>
                        <div
                          className="ps-1 ss"
                          style={{
                            fontSize: isTabScreen ? "4px" : "5px",
                            color: "#7FB3D5",
                          }}
                        >
                          MARK AS COMPLETED
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div></div>
                  )}
                  {item.status === "No Show" ? (
                    clickedid != item.index ? (
                      <div>
                        <button
                          style={{
                            border: "1px solid rgba(0, 74, 173, 0.8)",
                            borderRadius: "4px",
                            color: "#004AAD",
                            backgroundColor: "white",
                            fontSize: isTabScreen ? "5px" : "8px",
                          }}
                          onClick={() => reschedule()}
                        >
                          Re-schedule
                        </button>
                      </div>
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div></div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [message, setMessage] = useState("");
  const [incomingmsg, setIncomingmsg] = useState("");
  const [patients, setPatients] = useState();
  const [notification, setNotification] = useState([]);
  const [shownotification, setShownotification] = useState(false);
  const [chat, setChat] = useState(false);
  let fname, lname;
  const encodedKey = "NzU3NzRmMzk2MzU4NzczMjM2MzE0YzZhNzA2MTRhNzg=";
  const apiKey = atob(encodedKey);
  // const [apiKey, setApiKey] = useState("YOUR_API_KEY");
  // console.log(apiKey);
  const addConsultationData = (
    cosultationType,
    consultationId,
    week,
    patientId,
    doctorId
  ) => {
    console.log(
      "addConsultationData",
      cosultationType,
      consultationId,
      week,
      patientId,
      doctorId
    );

    const newConsultation = {
      consultation_type: parseInt(cosultationType),
      consultation_id: parseInt(consultationId),
      week: week,
      patient_id: patientId,
      doctor_id: doctorId,
    };

    setConsultationDataToBeSent((prevData) => [...prevData, newConsultation]);
  };
  const settypeofconsultation = (index) => {
    console.log("myIndex", index, consultg, consultp);
    console.log("myIndex", itemId);
    if (index !== itemId) {
      index = itemId;
    }
    setAdd(true);
    handleClose();
    setShowbtn(index);
    get(index, selectedWeek);
  };
  const pathosono = (inperson, index) => {
    console.log("myIndex@", index, inperson, consultg, consultp);
    console.log("myIndex@", itemId);
    if (index !== itemId) {
      index = itemId;
    }
    setAdd(true);
    handleClose();
    setShowbtn(index);
    get3(inperson, index, selectedWeek);

    // fetchselecteddateinperson(inperson,index);
  };
  const fetchselecteddate = (indexmodal_, index, name, event) => {
    const indexmodal = event.currentTarget.getAttribute("data-id");
    console.log("G", index, indexmodal, event);
    let type = index;
    t = index;
    onlineindex = indexmodal;
    let selectedWeek_;
    let upadteDoctorId;
    if (medicalExpertName === "Gynecologist") {
      selectedWeek_ = dashboardData1.find((item) => item.index === indexmodal);
      upadteDoctorId = profileData.user.id;
      console.log("PP##", indexmodal, selectedWeek_);
    } else if (medicalExpertName === "Pathology Lab") {
      selectedWeek_ = dashboardData2.find((item) => item.index === indexmodal);
      upadteDoctorId = profileData.user?.pathology?.id;
      console.log("PP##patho", indexmodal, selectedWeek_);
    } else if (medicalExpertName === "Sonography Centre") {
      selectedWeek_ = dashboardData3.find((item) => item.index === indexmodal);
      upadteDoctorId = profileData.user?.sonography?.id;
    } else {
      selectedWeek_ = dashboardData1.find((item) => item.index === indexmodal);
      upadteDoctorId = profileData.user.id;
    }
    console.log("@@", selectedWeek_);
    if (upadteDoctorId !== undefined) {
      console.log("indexmodal", event.currentTarget.getAttribute("data-id"));
      console.log("selectedWeek", selectedWeek);
      console.log("");
      const updatedButtonStates = [...buttonStates];

      const buttonIndex = updatedButtonStates.findIndex(
        (button) => button.id == indexmodal
      );
      console.log("+++++++++", buttonIndex, index, indexmodal);
      if (buttonIndex !== -1) {
        if (index == 1) {
          updatedButtonStates[buttonIndex].online = true;
          if (updatedButtonStates[buttonIndex].online == true) {
            updatedButtonStates[buttonIndex].InClinic = false;
          } else {
            updatedButtonStates[buttonIndex].InClinic = true;
          }
        }
        if (index == 2) {
          updatedButtonStates[buttonIndex].InClinic = true;
          if (updatedButtonStates[buttonIndex].InClinic == true) {
            updatedButtonStates[buttonIndex].online = false;
          } else {
            updatedButtonStates[buttonIndex].online = true;
          }
        }
        setButtonStates(updatedButtonStates);
        console.log("object>>>>>>", buttonStates);
      }
      setOnlinetype(true);
      index1 = indexmodal;
      get(indexmodal, selectedWeek);

      console.log("Pathology Labuser", profileData.user?.sonography?.id);

      if (indexfetched !== null) {
        const existingConsultationIndex = consultationDataToBeSent.findIndex(
          (item) => item.consultation_id == indexmodal
        );
        console.log("FINDINDEX", existingConsultationIndex, indexmodal);
        if (existingConsultationIndex !== -1) {
          // Update existing consultation data
          consultationDataToBeSent[existingConsultationIndex] = {
            consultation_type: type,
            consultation_id: parseInt(indexmodal),
            week: selectedWeek_?.week,
            patient_id: selectedindex,
            doctor_id: upadteDoctorId,
          };
          setConsultationDataToBeSent([...consultationDataToBeSent]);
        } else {
          addConsultationData(
            type,
            indexmodal,
            selectedWeek_?.week,
            selectedindex,
            upadteDoctorId
          );
        }
        checkAllTrue();
      } else {
        // Show a message to the user indicating they need to select a week
      }
    } else {
      var msg = `Please add in the area ${medicalExpertName} User`;
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert(`Please add in the area ${medicalExpertName} User`);
      // Show a message to the user indicating they need to select a week
    }
  };
  const fetchselecteddateinperson = (inperson, index, dataArray) => {
    const indexmodal = index;
    console.log("G1", inperson, indexmodal);
    let type = inperson;
    t = inperson;
    onlineindex = indexmodal;
    let selectedWeek_;
    let upadteDoctorId;
    console.log("dashboardData1", dataArray);
    if (medicalExpertName === "Gynecologist") {
      selectedWeek_ = dataArray.find((item) => item.index === indexmodal);

      upadteDoctorId = profileData.user.id;
      console.log("PP##new", indexmodal, selectedWeek_);
    } else if (medicalExpertName === "Pathology Lab") {
      selectedWeek_ = dataArray.find((item) => item.index === indexmodal);
      upadteDoctorId = profileData.user?.pathology?.id;
      console.log("PP##newpatho", indexmodal, selectedWeek_);
    } else if (medicalExpertName === "Sonography Centre") {
      selectedWeek_ = dataArray.find((item) => item.index === indexmodal);
      upadteDoctorId = profileData.user?.sonography?.id;
    } else {
      selectedWeek_ = dataArray.find((item) => item.index === indexmodal);
      upadteDoctorId = profileData.user.id;
    }
    console.log("@@", selectedWeek_);
    if (upadteDoctorId !== undefined) {
      console.log("selectedWeek", selectedWeek);
      console.log("");
      const updatedButtonStates = [...buttonStates];

      const buttonIndex = updatedButtonStates.findIndex(
        (button) => button.id == indexmodal
      );
      console.log("+++++++++", buttonIndex, index, indexmodal);
      if (buttonIndex !== -1) {
        if (inperson === 2) {
          updatedButtonStates[buttonIndex].InClinic = true;
          if (updatedButtonStates[buttonIndex].InClinic == true) {
            updatedButtonStates[buttonIndex].online = false;
          } else {
            updatedButtonStates[buttonIndex].online = true;
          }
        }
        setButtonStates(updatedButtonStates);
        console.log("object>>>>>>", buttonStates);
      }

      // let type = index;
      // t = index;
      // onlineindex = indexmodal;
      setOnlinetype(true);
      index1 = indexmodal;
      get(indexmodal, selectedWeek);

      console.log("Pathology Labuser", profileData.user?.sonography?.id);

      if (indexfetched !== null) {
        const existingConsultationIndex = consultationDataToBeSent.findIndex(
          (item) => item.consultation_id == indexmodal
        );
        console.log("FINDINDEX", existingConsultationIndex, indexmodal);
        if (existingConsultationIndex !== -1) {
          // Update existing consultation data
          consultationDataToBeSent[existingConsultationIndex] = {
            consultation_type: type,
            consultation_id: parseInt(indexmodal),
            week: selectedWeek_?.week,
            patient_id: selectedindex,
            doctor_id: upadteDoctorId,
          };
          setConsultationDataToBeSent([...consultationDataToBeSent]);
        } else {
          addConsultationData(
            type,
            indexmodal,
            selectedWeek_?.week,
            selectedindex,
            upadteDoctorId
          );
        }
        checkAllTrue();
      } else {
        // Show a message to the user indicating they need to select a week
      }
    } else {
      var msg = `Please add in the area ${medicalExpertName} User`;
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert(`Please add in the area ${medicalExpertName} User`);
      // Show a message to the user indicating they need to select a week
    }
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    });
  });
  const scrollToBottom = () => {
    console.log("inside scrollto bottom");
    if (chatBoxRef.current) {
      console.log("Scrolled");
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };
  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const chatBoxRef = useRef();
  const sendMessage = async () => {
    console.log("@@@@####", userData.id, patientid, message);
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/inbox/send_message`, {
        sender_id: userData.id,
        reciever_id: patientid,
        message: message,
      });
      if (response.status === 200) {
        var msg = "msg sent successfully";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("msg sent successfully");
      } else {
        var msg = "Error";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("error");
      }
    } catch {
      console.log("error in sending msg");
    }

    getmsg();
    setMessage("");
  };
  const get = (index, selectedWeek) => {
    console.log("@#WEEK", index, selectedWeek);
    if (userData.medical_expert_id === 1) {
      if (medicalExpertName === "Pathology Lab") {
        const data2 = dashboardData2?.map((item) => {
          if (item.index == index && selectedWeek?.index == index) {
            console.log(
              "item.index",
              medicalExpertName,
              selectedWeek?.week,
              index
            );
            console.log("item.index", medicalExpertName);
            return { ...item, week: String(selectedWeek.week), value: "true" };
          }
          return item;
        });
        setDashboardData2(data2);
        setChangeArray(data2);
      }
      if (medicalExpertName === "Sonography Centre") {
        const data3 = dashboardData3?.map((item) => {
          if (item.index == index && selectedWeek?.index == index) {
            return { ...item, week: String(selectedWeek.week), value: "true" };
          }
          return item;
        });
        setDashboardData3(data3);
        setChangeArray(data3);
      }

      if (medicalExpertName === "Gynecologist") {
        console.log("Gynecologist", index);
        console.log("Gynecologist", selectedWeek);
        const data = dashboardData1?.map((item) => {
          if (item.index == index && selectedWeek?.index == index) {
            console.log("Gynecologist----", selectedWeek);

            return { ...item, week: String(selectedWeek.week), value: "true" };
          }
          return item;
        });

        setDashboardData1(data);
        setChangeArray(data);
        console.log("GynecologistGynecologistGynecologist");
      }
    } else {
      const updatedDashboardData = dashboardData1?.map((item) => {
        if (item.index === index && selectedWeek?.index === index) {
          return { ...item, week: String(selectedWeek.week), value: "true" };
        }
        return item;
      });
      setDashboardData1(updatedDashboardData);
      setChangeArray(updatedDashboardData);
      console.log("Gynecologist");
    }
    // fetchselecteddateinperson(inperson,index);
  };
  const get3 = (inperson, index, selectedWeek) => {
    console.log("@#WEEK", index, selectedWeek);
    if (userData.medical_expert_id === 1) {
      if (medicalExpertName === "Pathology Lab") {
        const data2 = dashboardData2?.map((item) => {
          if (item.index == index && selectedWeek?.index == index) {
            console.log(
              "item.index1",
              medicalExpertName,
              selectedWeek?.week,
              index
            );
            console.log("item.index GET3", medicalExpertName, item);
            return { ...item, week: String(selectedWeek.week), value: "true" };
          }
          return item;
        });
        console.log("999", data2);
        setDashboardData2(data2);
        setChangeArray(data2);
        fetchselecteddateinperson(inperson, index, data2);
      }
      if (medicalExpertName === "Sonography Centre") {
        const data3 = dashboardData3?.map((item) => {
          if (item.index == index && selectedWeek?.index == index) {
            return { ...item, week: String(selectedWeek.week), value: "true" };
          }
          return item;
        });
        setDashboardData3(data3);
        setChangeArray(data3);
        fetchselecteddateinperson(inperson, index, data3);
      }

      if (medicalExpertName === "Gynecologist") {
        console.log("Gynecologist", index);
        console.log("Gynecologist", selectedWeek);
        const data = dashboardData1?.map((item) => {
          if (item.index == index && selectedWeek?.index == index) {
            console.log("Gynecologist----", selectedWeek);

            return { ...item, week: String(selectedWeek.week), value: "true" };
          }
          return item;
        });

        setDashboardData1(data);
        setChangeArray(data);
        fetchselecteddateinperson(inperson, index, data);
        console.log("GynecologistGynecologistGynecologist");
      }
    } else {
      const updatedDashboardData = dashboardData1?.map((item) => {
        if (item.index === index && selectedWeek?.index === index) {
          return { ...item, week: String(selectedWeek.week), value: "true" };
        }
        return item;
      });
      setDashboardData1(updatedDashboardData);
      setChangeArray(updatedDashboardData);
      fetchselecteddateinperson(inperson, index, updatedDashboardData);
      console.log("Gynecologist");
    }
    checkAllTrue();
  };

  useEffect(() => {}, [consultationDataToBeSent]);
  const readnotification = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/read_notification`,
        { receiver_id: userData.id }
      );
      if (response.status === 200) {
        console.log("successfully read ");
      } else {
        console.log("Error in reading notification");
      }
    } catch (error) {
      console.log("Error", error.message);
    }
    fetchData();
  };
  useEffect(() => {
    fetchData();
  }, [userData]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/get_notification`,
        {
          receiver_id: userData.id,
        }
      );

      if (response.status === 200) {
        const l = response.data.notifications.length;
        console.log(
          "GetNotification Response",
          response.data.notifications,
          response.data.notifications[l - 1]
        );

        setNotification(response.data.notifications);
        let cnt = response.data.notifications[l - 1].notification_count;
        setCount(cnt);
      } else {
        console.log("Error in getting notification");
      }
    } catch (error) {
      console.log("Error in getting catch block", error.message);
    }
  };
  useEffect(() => {
    console.log("{count}{}{}", count);
  }, [count]);
  useEffect(() => {
    
  }, [dashboardData1, dashboardData2, dashboardData3]);

  useEffect(() => {
    if (patients?.length > 0) {
      let p = patients[0].id;
      patientid = p;
      getmsg();
    }
  }, [patients]);

  useEffect(() => {
    setTimeout(() => {
      getmsg();
    }, 1000);
  }, []);
  // useEffect(() => {
  //   if (patients?.length > 0) {
  //     let p = patients[0].id;
  //     patientid = p;
  //     getmsg();
  //   }
  // }, [patients]);

  const getmsg = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/inbox/get_message`, {
        sender_id: patientid,
        reciever_id: userData?.id,
      });
      if (response.status === 200) {
        const messages = response.data.messages.map((msg) => {
          const utcTimestamp = msg.created_at;
          const dateObject = new Date(utcTimestamp);
          const istTimestamp = dateObject.toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          });

          return {
            ...msg,
            created_at_ist: istTimestamp,
          };
        });
        console.log("@MSG@#", response.data.messages);
        setIncomingmsg(messages.reverse());
      } else {
        var msg = "Error";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("error");
      }
    } catch {
      console.log("error in sending msg");
    }
  };
  const formatTime = (timestamp) => {
    const dateObject = new Date(timestamp);
    return dateObject.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: isTabScreen ? 200 : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "1px solid #D4CDCD",
    boxShadow: "0px 0px 90px rgba(90, 90, 90, 0.1)",
    borderRadius: "40px 12px 12px 40px",
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleMouseEntericon = (index, fname, lname) => {
    setClickimg(false);
    flag = 1;
    firstname = fname;
    lastname = lname;
    setBg(true);
    selectedindex1 = index;
    fname = fname;
    lname = lname;
  };
  const handleMouseLeaveicon = () => {
    setBg(false);
  };

  const handleMouseEnterbg = (index) => {
    flag = 1;
    setIsHovericon(true);
    selectedindex = index;
  };
  const startchat = (id) => {
    patientid = id;
    getmsg();
  };
  const handleMouseLeavebg = () => {
    setIsHovericon(false);
  };

  const handleReschedule = () => {
    navigate("/doctor/Doctor_consultation");
  };
  const changerightbox = () => {
    setChangeimg(false);
    setScheduled(false);
    ClickOnPluseBtn();

    // setSelectedWeek(null);

    // const updatedButtonStates = buttonStates.map((button) => ({
    //   ...button,
    //   online: false,
    //   InClinic: false,
    // }));
    // const updateIntiadashboarddata = dashboardData1.map((data) => ({
    //   ...data,
    //   week: "",
    //   value: "false",
    // }));
    // console.log(dashboardData1,"dashboardData1");
    // setButtonStates(updatedButtonStates);
    // setDashboardData1(updateIntiadashboarddata);
    // setChangeArray(updateIntiadashboarddata);
    // setClicktd(false);
    // setIsAllTrue(false);
    // selectindex(false);
  };
  const changeimg = (id) => {
    ClickOnPluseBtn();
    setClickimg(true);
    setIsHovericon(false);
  };
  // Initialdashboarddata
  const ClickOnPluseBtn = () => {
    const updateIntiadashboarddata = dashboardData1.map((data) => ({
      ...data,
      week: "",
      value: "false",
    }));
    if (userData.medical_expert_id === 1) {
      const updateIntiadashboarddata2 = dashboardData2.map((data) => ({
        ...data,
        week: "",
        value: "false",
      }));
      const updateIntiadashboarddata3 = dashboardData3.map((data) => ({
        ...data,
        week: "",
        value: "false",
      }));
      setDashboardData2(updateIntiadashboarddata2);
      setDashboardData3(updateIntiadashboarddata3);
    }

    setChangeArray(updateIntiadashboarddata);
    setDashboardData1(updateIntiadashboarddata);
    const updatedButtonStates = buttonStates.map((button) => ({
      ...button,
      online: false,
      InClinic: false,
    }));

    setButtonStates(updatedButtonStates);
    setChangeimg(false);
    setClicktd(false);
    setSelectedWeek(null);
    setIsAllTrue(false);
    selectindex(false);
    setShowotp();
    setVerified();
    setIsTimerActive(null);
  };

  const onClickDay = (item) => {
    setDateClicked(true);
    console.log("date..", moment(item).format("YYYY-MM-DD"));
    setDate(moment(item).format("Do MMMM YYYY"));
    setSelectedDate(moment(item).format("YYYY-MM-DD"));
  };
  const setwidth = () => {
    setClick(!click);
  };
  const changetodark = (id) => {
    setClicktd(id);
    setChangeimg(true);
  };
  const selectweek = (index) => {
    selectindex(index + 1);
    indexfetched = index + 1;
    setSelectedWeek({
      index: itemId,
      week: indexfetched,
    });
  };

  const setonline = (index) => {
    onlineindex = index;
    setOnlinetype(true);
    setInpersontype(false);
  };

  const setinperson = (index) => {
    inpersonindex = index;
    setInpersontype(false);
    setOnlinetype(0);
  };

  const shownotificationofdr = () => {
    setShownotification(!shownotification);
    readnotification();
  };
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getmsg();
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    // Fetch consultation types when the component mounts
    getDetail();
    fetchConsultationTypes();
    getTodaysPatientConsultations();
    getUpcomingEvents();
  }, [profileData, selectedDate]);
  useEffect(() => {
    getTodaysPatientConsultations();
  }, [selectedDate, isYogaExperts, profileData]);
  console.log("54", profileData);
  // const { doctor_signature, id } = profileData?.user;
  const sign = profileData?.user?.doctor_signature;
  const drid = profileData?.user?.id;
  const doctor_signature_url = "https://admin.myprega.com/doctor_signature/";
  const full_doctor_signature_url = `${doctor_signature_url}${drid}/${sign}`;
  // const configData.SERVER_API_URL = "http://192.168.1.31:9595/api/v1";
  // const handleSendMessage = () => {
  //   if (newMessage) {
  //     const newMessageObject = {
  //       text: newMessage,
  //       sender: "user",
  //     };
  //     setMessages([...messages, newMessageObject]);
  //     sendSMS(newMessage);
  //     setNewMessage("");
  //   }
  // };
  const gotoprofile = () => {
    navigate("/doctor/Profile", { state: { profileData: profileData } });
  };
  const fetchConsultationTypes = async () => {
    fetch(`${configData.SERVER_API_URL}/consultation/get_consultation_type`)
      .then((response) => response.json())
      .then((data) => {
        console.log("BBB", data.Consulation_Types);
        const consultationTypesData = data.Consulation_Types;
        setConsultationTypes(consultationTypesData);

        // setBloodGroups(data.bloodGroup); // Note the key "bloodGroup" from the API response
      })
      .catch((error) => {
        console.error("Error fetching TYPES:", error);
      });
  };
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };

  const getUpcomingEvents = async () => {
    try {
      const id = localStorage.getItem("userId");
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_upcoming_patient_consultation`,
        { doctor_id: id }
      );
      if (response.status === 200) {
        console.log("QQQ", response.data);
        setUpcoming(response.data?.patient_consultations);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getDetail = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          console.log("fetched User Data", response.data?.user);
          setUserData(response.data?.user);
          setMedicalExpertName(response.data?.user.medical_expert_name);
        } else {
          console.error("Error ----:", response.data.error);
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const updatedWeekFiled = () => {
    console.log("consultationDataToBeSent", consultationDataToBeSent);
    let count = dashboardData1.length;
    if (userData.medical_expert_id === 1) {
      count = 27;
    }
    const isAnyWeekFieldEmpty = consultationDataToBeSent.some(
      (item) => item.week === ""
    );
    if (consultationDataToBeSent.length === count) {
      if (isAnyWeekFieldEmpty) {
        const updatedConsultationData = consultationDataToBeSent.map(
          (prevData) => {
            const weekdata = dashboardData1.find(
              (itemdata) => itemdata.index == prevData.consultation_id
            );
            const updatedData = {
              ...prevData,
              week: weekdata ? weekdata?.week : "N/A",
            };

            return updatedData;
          }
        );

        return {
          isAnyWeekFieldEmpty: isAnyWeekFieldEmpty,
          updatedConsultationData: updatedConsultationData,
          validation: true,
        };
      } else {
        return { validation: true };
      }
    } else {
      return { validation: false };
    }
  };
  const getTodaysPatientConsultations = async () => {
    try {
      console.log("MedicalExpertName", medicalExpertName);
      console.log("fetched-todays-", selectedDate);
      console.log("isYogaExperts-", isYogaExperts);
      const currentDate = moment().format("YYYY-MM-DD");
      let param = "";
      if (isYogaExperts) {
        param = {
          selectedDate: currentDate,
          medical_expert_id: 4,
        };
      } else {
        param = {
          selectedDate: selectedDate ? selectedDate : currentDate,
          doctor_id: profileData?.user?.id,
        };
      }
      console.log("@@@param", param, profileData);
      const id = localStorage.getItem("userId");
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_todays_patient_consultation`,
        param
      );
      if (response.data.success) {
        console.log("fetched-todays-", response.data);
        setConsultations(response.data.patient_consultations);
        setconsultation_today(response.data.consultation_today);
        setconsultation_remaining_today(
          response.data.consultation_remaining_today
        );
        response.data.consultation_remaining_today >= 4
          ? setIsHover(true)
          : setIsHover(false);
      } else {
        console.error("Error :", response.data.error);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const Done = async () => {
    try {
      const consultationData = updatedWeekFiled();
      if (consultationData.validation) {
        const apiData = consultationData.isAnyWeekFieldEmpty
          ? consultationData.updatedConsultationData
          : consultationDataToBeSent;
        console.log("APIDATA", apiData);
        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/add_consultation`,
          apiData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          console.log(
            "DONE@@@,,,,Data sent successfully",
            userData.id,
            apiData[0].patient_id
          );
          const doctorid = apiData[0].patient_id;
          setScheduled(true);
          var msg = "Consultation data sent successfully";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          window.location.reload();
          console.log("first", userData.id, doctorid);
          try {
            const response = await axios.post(
              `${configData.SERVER_API_URL}/common/add_notification`,
              {
                message:
                  "Added all consultation with week and type of consultations",
                sender_id: userData.id,
                receiver_id: doctorid,
              }
            );
            if (response.status === 200) {
              console.log("AAadd notification");
            } else {
              console.log("error in adding notification");
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }

          // alert(scheduled);
        } else {
          // Handle error
          console.error("Error sending data");
        }
      } else {
        var msg = "Select consultation Type";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // alert("Select consultation Type");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const getpatientlist = async () => {
    console.log("MyPATIENT AREA ID", profileData.user?.area_id);
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_paitent_list_dashboard`,
        {
          area_id: profileData.user?.area_id,
          doctor_id: profileData?.user?.id,
          medical_expert_id: profileData?.user?.medical_expert_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        console.log("PATIENTTTTT DATA", response.data.patientList);
        setAssignbox(response.data.patientList);
      } else {
        // Handle error
        console.error("Error sending data");
      }
    } catch (error) {
      console.log("Error fetching user1");
    }
  };
  const getpatientlist1 = async () => {
    const area_id = profileData.user?.area_id;
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_paitent_list`,
        {
          area_id: area_id,
          doctor_id: profileData.user?.id,
          medical_expert_id: profileData?.user?.medical_expert_id,
        }
      );
      if (response.status === 200) {
        setPatients(response.data.patientList);
        console.log("3TTT DATA", response.data.patientList);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const openchat = async () => {
    setClick(false);
    setChat(true);
  };
  const convertToImage = async () => {
    alert("ll");

    // console.log("@!@!", item);
    const prescriptionContainer = prescriptionContainerRef.current;
    console.log("prescriptionContainer", prescriptionContainer);
    if (!prescriptionContainer) {
      console.log("Prescription container ref not yet assigned or is null.");
      return;
    }
    // prescriptionContainer.style.display = "block";
    await html2canvas(prescriptionContainer).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const imgElement = document.createElement("img");
      imgElement.className = "prescription-image";
      imgElement.src = image;
      console.log("@presimg", imgElement);
    });
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <StyleRoot
        style={{
          minHeight: "100vh",
          width: click === true ? "95%" : "70%",
          backgroundColor: configData.THEME_COLORS.BACKGROUND,
        }}
      >
        <div className="test" style={styles.fadeIn}>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginTop: 50,

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "50px",
              }}
            >
              <Typography
                className="mb-0"
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                  width: "182px",
                  height: "48px",
                  marginLeft: isTabScreen ? "15px" : "30px",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "24px" : "36px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  {"Dashboard"}
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Badge
                  badgeContent={count?.toString()}
                  color="primary"
                  overlap="circular"
                  style={{ left: -5, cursor: "pointer" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#FFFFFF",
                      backgroundColor: "#E42346",
                      zIndex: "auto",
                    },
                  }}
                >
                  <NotificationsIcon
                    onClick={shownotificationofdr}
                    sx={{
                      width: isTabScreen ? "16.48px" : "25px",
                      height: isTabScreen ? "16.09px" : "25px",
                      color: "#004AAD",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  />
                </Badge>
                <div
                  style={{
                    position: "absolute",
                    top: "90px",
                    zIndex: "999",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      boxShadow: "rgba(44, 39, 56, 0.08) 0px 4px 8px inset",
                    }}
                  >
                    {shownotification == true &&
                      notification &&
                      notification.slice(0, -1).map((item) => (
                        <div
                          style={{ width: "300px", backgroundColor: "white" }}
                        >
                          <div className="d-flex align-items-center w-100">
                            <div
                              className="text-center"
                              style={{ width: "20%" }}
                            >
                              <CircleIcon
                                style={{ height: "15px", width: "15px" }}
                              />
                              {/* <img className="" src={"/Union.png"}></img> */}
                            </div>
                            <div style={{ width: "75%" }}>
                              <div className="notification w-100">
                                {item.message}
                              </div>
                              <hr className="m-0"></hr>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <Dropdown
                  submenu={submenu}
                  profileData={profileData}
                  onClick={() => showsubmenu()}
                  page="dashboard"
                />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div
                style={{
                  fontSize: isTabScreen ? "20px" : "32px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  color: configData.THEME_COLORS.SECONDARY,
                  marginLeft: isTabScreen ? "15px" : "30px",
                }}
              >
                Hello{" "}
                {userData?.fname
                  ? "Dr. " + userData?.fname + " " + userData?.lname
                  : ""}
              </div>
            </div>
            {!isPathology && !isSonography && !isYogaExperts && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "10px" : "13.07px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: configData.THEME_COLORS.SECONDARY,
                    marginBottom: "8px",
                    marginTop: "5px",
                    marginLeft: isTabScreen ? "15px" : "30px",
                  }}
                >
                  New Patient has assented to you..
                </div>
              </div>
            )}
            {!isPathology && !isSonography && !isYogaExperts && (
              <div
                style={{
                  display: "flex",
                  marginLeft: isTabScreen ? "15px" : "30px",
                  marginBottom: "20px",
                  marginRight: "14px",
                }}
              >
                <div
                  style={{
                    width: isTabScreen ? "28%" : "22%",
                    height:
                      click === true
                        ? isTabScreen
                          ? "100px"
                          : "178px"
                        : isTabScreen
                        ? "90px"
                        : "121px",
                    backgroundColor: "white",
                    overflowY: "scroll",
                    border: "3px solid #8FC8F2",
                    boxShadow: "0px 2px 2px rgba(51, 51, 51, 0.25)",
                    borderRadius: "5px",
                  }}
                >
                  {assignbox.length > 0 ? (
                    assignbox?.map((item) => {
                      return (
                        <div
                          style={{
                            padding: "6px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor:
                              selectedindex === item.id ? "#C3EDED" : "white",
                            border:
                              selectedindex === item.id && clickimg === true
                                ? "1px solid #0FB8B8"
                                : "none",
                            borderRadius:
                              selectedindex === item.id &&
                              clickimg === true &&
                              "0px 5px 0px 0px",
                          }}
                          onMouseEnter={() => handleMouseEnterbg(item.id)}
                          onMouseLeave={() => handleMouseLeavebg(item.id)}
                        >
                          <div style={{ width: "20%" }}>
                            <img
                              src={
                                item?.profile_pic
                                  ? "https://admin.myprega.com/" +
                                    item?.profile_pic
                                  : "/test1.png"
                              }
                              style={{ width: "80%", height: "100%" }}
                              alt="nt found"
                            ></img>
                          </div>
                          <div
                            style={{
                              fontSize: isTabScreen ? "9px" : "10px",
                              display: "flex",
                              flexDirection: "column",
                              width: "80%",
                              paddingLeft: "10px",
                              fontWeight: "600",
                            }}
                          >
                            <div
                              className="d-flex justify-content-between"
                              onMouseEnter={() =>
                                handleMouseEntericon(
                                  item.id,
                                  item.fname,
                                  item.lname
                                )
                              }
                              onMouseLeave={() => handleMouseLeaveicon(item.id)}
                            >
                              <div
                                className="d-flex"
                                style={{
                                  flexDirection:
                                    item.fname.length + item.lname.length > 14
                                      ? "column"
                                      : "row",
                                }}
                              >
                                <div>{item.fname}</div>
                                <div
                                  className={`ms-${
                                    item.fname.length + item.lname.length > 14
                                      ? "0"
                                      : "1"
                                  }`}
                                >
                                  {item.lname}
                                </div>
                              </div>
                              <div style={{}}>
                                {clickimg === true &&
                                selectedindex === item.id ? (
                                  <img
                                    src={"/Union.png"}
                                    style={{ height: "16px", weight: "10px" }}
                                    onClick={() => changerightbox()}
                                  ></img>
                                ) : (
                                  <img
                                    src={"/plusicon.png"}
                                    style={{
                                      height: "16px",
                                      weight: "10px",
                                      display:
                                        isHovericon === true &&
                                        selectedindex1 === item.id
                                          ? "block"
                                          : "none",
                                    }} alt="nt found"
                                    onClick={() => changeimg(item.id)}
                                  ></img>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            ></div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="px-4 pt-2"
                      style={{ color: "#f0588b", fontSize: "13px" }}
                    >
                      No Patient allocated yet
                    </div>
                  )}
                </div>

                <div
                  style={{
                    fontSize: isTabScreen ? "18px" : "24px",
                    color: configData.THEME_COLORS.SECONDARY,
                    marginTop: "20px",
                    textAlign: "center",
                    width: "76%",
                  }}
                >
                  {selectedDate && selectedDate !== todayDate
                    ? moment(selectedDate, "YYYY-MM-DD").format("Do MMMM")
                    : "Today's"}{" "}
                  Schedule
                </div>
              </div>
            )}

            <div
              className="d-flex"
              style={{
                marginLeft: isTabScreen ? "15px" : "30px",
                marginRight: isTabScreen ? "15px" : "30px",
                marginTop:
                  isPathology || isSonography || isYogaExperts ? "80px" : "0px",
              }}
            >
              <div
                style={{
                  width: isTabScreen ? "28%" : "22%",
                }}
              >
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{}}
                >
                  <div
                    style={{
                      borderRadius: "15px",
                      margin: "0px auto",
                      width: click === true ? "90%" : "96%",
                      backgroundColor: "#8FC8F2",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "default",
                      // height: isTabScreen
                      //   ? click === true
                      //     ? "132px"
                      //     : "100px"
                      //   : "179px",
                    }}
                  >
                    <div
                      className="px-2 py-1"
                      style={{
                        marginTop: "12px",
                        fontWeight: "500",
                        fontSize: isTabScreen ? "11px" : "16px",
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      {isYogaExperts ? "Session" : userData?.medical_expert_id === 8 || userData?.medical_expert_id === 9 ? "Event ": "Consultations"} <br></br>
                      Today
                    </div>
                    <div
                      style={{
                        fontSize: isTabScreen ? "30px" : "50px",
                        color: "white",
                      }}
                    >
                      {consultation_today}
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "15px",
                      margin: "10px auto",
                      width: click === true ? "90%" : "96%",
                      backgroundColor: "#8FC8F2",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // height: "45%",
                      justifyContent: "center",
                      cursor: "default",
                    }}
                  >
                    <div
                      className="px-2 py-1"
                      style={{
                        marginTop: "12px",
                        fontWeight: "500",
                        fontSize: isTabScreen ? "11px" : "16px",
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      {isYogaExperts ? "Session " : 
                      userData?.medical_expert_id === 8 || userData?.medical_expert_id === 9 ? "Event ":"Consultations "}
                      Remaining Today
                    </div>
                    <div
                      style={{
                        fontSize: isTabScreen ? "30px" : "50px",
                        color: "white",
                      }}
                    >
                      {consultation_remaining_today}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: isTabScreen ? "72%" : "78%",
                  position: "relative",
                  top: "-67px",
                }}
              >
                {isPathology && (
                  <div
                    style={{
                      fontSize: isTabScreen ? "18px" : "24px",
                      color: configData.THEME_COLORS.SECONDARY,
                      marginBottom: "20px",
                      textAlign: "center",
                      // width: "300px",
                    }}
                  >
                    {selectedDate && selectedDate !== todayDate
                      ? moment(selectedDate, "YYYY-MM-DD").format("Do MMMM")
                      : "Today's"}{" "}
                    Schedule
                  </div>
                )}
                <div
                  // onMouseEnter={() => handleMouseEnter()}
                  // onMouseLeave={() => handleMouseLeave()}
                  style={{
                    // height: isTabScreen
                    //   ? click === true
                    //     ? "395px"
                    //     : "334px"
                    //   : click === false
                    //   ? "450px"
                    //   : "420px",
                    height: isTabScreen ? "296px" : "390px",
                    width: "96%",
                    marginLeft: "auto",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E8E7E7",
                    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.08)",
                    borderRadius: "12px",
                    overflowY: isHover === true ? "scroll" : "hidden",
                    overflowX: "hidden",
                  }}
                >
                  {/* {consultation_today >= '4' ? handleMouseEnter() : handleMouseLeave() } */}
                  <PathTable2 click={click} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isPathology && !isSonography && (
          <>
            <div
              style={{
                marginLeft: "52.7px",
                marginTop: "28px",
                fontSize: "24px",
                fontWeight: "600",
                color: configData.THEME_COLORS.SECONDARY,
              }}
            >
              Critical Patient Alert
            </div>
            <div
              className="pt-1"
              style={{
                width: "92%",
                backgroundColor: "white",
                margin: "0px auto",
                border: "1px solid #F92A2A",
                boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.08)",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              <table
                class="spacing-table2"
                style={{ width: "97%", msOverflowX: "hidden" }}
              >
                <thead>
                  <tr
                    style={{
                      fontFamily: "Poppins",
                      fontSize: isTabScreen ? "6px" : "12px",
                      fontWeight: "600",
                      color: configData.THEME_COLORS.SECONDARY,
                      backgroundColor: "rgba(243, 246, 249, 1)",
                    }}
                  >
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: isTabScreen ? "6px" : "12px",
                        width: "20%",
                      }}
                    >
                      PATIENT NAME
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: isTabScreen ? "6px" : "12px",
                        width: "80%",
                      }}
                    >
                      MESSAGE
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="d-flex">
                <div
                  style={{
                    width: "30%",
                    height: "280px",
                    overflowY: "auto",
                  }}
                >
                  {patients?.length > 0 ? (
                    patients?.map((item) => {
                      return (
                        <div
                          onClick={() => startchat(item.id)}
                          className="my-2 ms-2 d-flex"
                          style={{
                            width: "90%",
                            height: "60px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            backgroundColor:
                              patientid === item.id ? "#dae5f3" : "white",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/test1.png"
                              className="ps-1"
                              style={{ height: "80%" }}
                              alt="test1"
                            ></img>
                            <div className="ps-2" style={{ fontSize: "12px" }}>
                              {item.fname + " " + item.lname}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">No patient onboard</div>
                  )}
                </div>
                <div
                  style={{
                    width: "70%",
                    height: "280px",
                  }}
                >
                  <div style={{ height: "220px", overflowY: "auto" }}>
                    {incomingmsg.length > 0 ? (
                      incomingmsg?.map((item) => (
                        <>
                          {item.is_critical == 1 && (
                            <>
                              {" "}
                              <div className="d-flex my-2" ref={chatBoxRef}>
                                <div className="d-flex flex-column align-items-center">
                                  <img
                                    src="/test1.png"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "50%",
                                    }}
                                    alt="test1"
                                  ></img>
                                  <div
                                    style={{
                                      color: "#c4c4c4",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {formatTime(item.created_at_ist)}
                                  </div>
                                </div>
                                <div
                                  className="w-75 mx-auto defaultmsg p-2 py-2 my-2"
                                  key={item.id}
                                >
                                  {item.message}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))
                    ) : (
                      <div>No messages to show</div>
                    )}
                  </div>
                  {!isYogaExperts && (
                    <div className="mx-2">
                      <div
                        className="d-flex align-items-center"
                        style={initiatebtn}
                      >
                        <img src={"/plusinitiate.png"} alt="not found"></img>
                        <button className="ps-2" onClick={() => openchat()}>
                          REPLY NOW
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 w-50 bg-white" style={{ left: "-9999px",position:"absolute" }}>
              <div className="">
                <div ref={prescriptionContainerRef}>
                  <div
                    className="d-flex text-white justify-content-between py-3"
                    style={{ color: "#F0588B",backgroundColor:"white" }}
                  >
                    <div className=" pt-2 d-flex justify-content-between align-items-center ps-2 w-100">
                      <div className="fs-3 mx-2" style={{color:"#F0588B"}}>MyPrega</div>
                      <div className="me-3">
                        <img
                          src={"/logo-removebg.png"}
                          style={{
                            resizeMode: "contain",
                            height: "120px",
                            width: "120px",
                            margin: "auto",
                          }} alt="nt found"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex text-white justify-content-between"
                    style={{ color: "#F0588B",backgroundColor:"white" }}
                  >
                    <div></div>
                    <div className="pe-4 text-end py-4">
                      <div className="fw-bolder text-capitalize"  style={{ color: "#F0588B"}}>
                        Dr.{" "}
                        {`${profileData?.user?.fname} ${profileData?.user?.lname}`}
                      </div>
                      <p className="drpost" style={{color:"#F0588B"}}>
                        {profileData?.user?.medical_expert_name}
                      </p>
                      <p className="areanm" style={{color:"#F0588B"}}>
                        {profileData?.user?.area_name}
                      </p>
                      {/* <h6>Medical Registration Number: {profileData?.medical_reg[0]?.reg_num}</h6> */}
                    </div>
                  </div>
                  <hr className="w-100 my-2" style={{color:"gray"}}></hr>
                  <div className="bg-white">
                    <div className="d-flex py-2 ms-2 align-items-center justify-content-between ">
                      <div className="ps-2">
                        <div className="text-capitalize pe-2">
                          {firstname} {lastname}
                        </div>
                        <div className="text-secondary">33 years, Female</div>
                      </div>
                      <div className="pe-2">
                        {moment().format("DD MMM, YYYY")}
                      </div>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="mx-4 py-5 px-5 d-inline my-5">
                      Dear Doctor, I, Dr.{" "}
                      {`${profileData?.user?.fname} ${profileData?.user?.lname}`}
                      {profileData &&
                        profileData.medical_reg &&
                        profileData.medical_reg.length > 0 && (
                          <div className="d-inline">
                            {" "}
                            Medical registration number{" "}
                            {profileData?.medical_reg[0]?.reg_num}, am referring
                            herewith to my patient,
                          </div>
                        )}{" "}
                      Mrs. {firstname ? firstname : ""}{" "}
                      {lastname ? lastname : ""}, of age 24 and female, for the
                      following {testname ? testname : ""}
                    </div>
                  </div>
                  <div className="py-3 my-2 text-end bg-white">
                    <img
                      style={{ height: "100px", width: "100px" }}
                      src={`${full_doctor_signature_url}`}
                      alt="Doctor's Signature"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </StyleRoot>
      {clickimg === true ? (
        <div
          className="bg-white"
          style={{
            width: click === true ? "5%" : "45%",
            position: click != true ? "absolute" : "",
            right: click != true ? "0px" : "",
          }}
        >
          <div className="d-flex align-items-center py-4">
            {click === true ? (
              <img
                className="ms-3"
                src={"/righticon.png"}
                onClick={() => setwidth()}
                style={{
                  height: isTabScreen ? "16px" : "22px",
                  width: isTabScreen ? "16px" : "22px",
                }}
                alt="righticon"
              ></img>
            ) : (
              <img
                className="ms-3"
                src={"/lefticon.png"}
                onClick={() => setwidth()}
                style={{
                  height: "30px",
                  width: "23px",
                  paddingLeft: "5px",
                  color: "configData.THEME_COLORS.SECONDARY",
                }}
                alt="lefticon"
              ></img>
            )}
            {click === false && (
              <div
                className="ms-3"
                style={{
                  fontSize: isTabScreen ? "20px" : "26px",
                  color: "#F0588B",
                  fontWeight: "bold",
                }}
              >
                {firstname} {lastname}
              </div>
            )}
          </div>
          <hr className="w-100"></hr>
          {click === false && (
            <>
              <h6
                className="ps-2 py-2"
                style={{
                  color: "#004AAD",
                  fontSize: isTabScreen ? "18px" : "28px",
                  fontWeight: "bold",
                }}
              >
                Schedule Care
              </h6>

              <hr className="w-100"></hr>
              <div
                className="px-4 pt-2 "
                style={{
                  // color: "#004AAD",
                  color: "white",
                  fontSize: isTabScreen ? "15px" : "20px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {userData?.medical_expert_id === 1 && (
                  // <div style={{ cursor: "pointer", }}>
                  <>
                    {/* // &nbsp;&nbsp;&nbsp; */}
                    <button
                      style={{
                        cursor: "pointer",
                        width: "160px",
                        height: "40px",
                        backgroundColor: consultg ? "#F0588B" : "#8FC8F2",
                        borderRadius: "7px",
                      }}
                      onClick={handleGynecologistConsultation}
                    >
                      Gynecologist
                    </button>
                    {/* // &nbsp;&nbsp;&nbsp; */}
                    <button
                      style={{
                        cursor: "pointer",
                        width: "160px",
                        height: "40px",
                        backgroundColor: consultp ? "#F0588B" : "#8FC8F2",
                        borderRadius: "7px",
                      }}
                      onClick={handlePathologyConsulation}
                    >
                      Pathology
                    </button>
                    {/* // &nbsp;&nbsp;&nbsp; */}
                    <button
                      style={{
                        cursor: "pointer",
                        width: "160px",
                        height: "40px",
                        backgroundColor: consults ? "#F0588B" : "#8FC8F2",
                        borderRadius: "7px",
                      }}
                      onClick={handleSonograpyConsultaion}
                    >
                      Ultrasound
                    </button>
                    {/* // </div> */}
                  </>
                )}
              </div>
              {scheduled == true ? (
                <div
                  className="px-2"
                  style={{
                    textAlign: "center",
                    fontSize: "26px",
                    color: "#26931F",
                    fontWeight: "500",
                    paddingTop: "300px",
                    fontFamily: "poppins",
                  }}
                >
                  Schedule care Weeks<br></br>Updated Successfully!!
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      width: "96%",
                      margin: "0px auto",
                      border: "0.5px solid #004AAD",
                      borderRadius: "11px",
                    }}
                  >
                    <table class="spacing-table2">
                      <thead>
                        <tr
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                            fontFamily: "Poppins",
                            fontSize: isTabScreen ? "6px" : "12px",
                            fontWeight: "600",
                            color: configData.THEME_COLORS.SECONDARY,
                            backgroundColor: "rgba(243, 246, 249, 1)",
                          }}
                        >
                          <div
                            class="theading"
                            style={{
                              width: "100%",
                              height: isTabScreen ? "22px" : "44px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#F3F6F9",
                              alignSelf: "center",
                              borderRadius: "6px",
                            }}
                          >
                            <th
                              style={{
                                textAlign: "center",
                                fontSize: isTabScreen ? "6px" : "12px",
                                width: "30%",
                              }}
                            >
                              {consultp===true || consults===true  ? (
                                <div>EVENT NUMBER</div>
                              ):( <div>CONSULTATION NUMBER</div>)}
                             
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                fontSize: isTabScreen ? "6px" : "12px",
                                width: "25%",
                              }}
                            >
                              WEEK
                            </th>
                            {consultg === true && (
                                 <th
                                 style={{
                                   textAlign: "center",
                                   fontSize: isTabScreen ? "6px" : "12px",
                                   width: "45%",
                                 }}
                               >
                                 CONSULTATION TYPE
                               </th>
                              )}
                              {consults === true && (
                                 <th
                              style={{
                                textAlign: "center",
                                fontSize: isTabScreen ? "6px" : "12px",
                                width: "45%",
                              }}
                            >
                              EVENT TYPE
                            </th>
                              )}
                              {consultp === true && (
                                 <th
                              style={{
                                textAlign: "center",
                                fontSize: isTabScreen ? "6px" : "12px",
                                width: "45%",
                              }}
                            >
                              EVENT TYPE
                            </th>
                              )}
                           
                          </div>
                        </tr>
                      </thead>
                      <tbody>
                        {changeArray?.map((item, i) => (
                          <>
                            <tr
                              style={{
                                height: isTabScreen ? "30px" : "45px",
                                border:
                                  clicktd === item.index
                                    ? "7px solid red"
                                    : "none",
                                borderRadius: "7px",
                              }}
                              key={item.index}
                            >
                              <td
                                style={{
                                  fontSize: isTabScreen ? "8px" : "13px",
                                  fontWeight: "500",
                                  textAlign: "center",
                                  width: "30%",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight:
                                      changeimgtab === true &&
                                      clicktd === item.index
                                        ? "600"
                                        : "100",
                                  }}
                                >
                                  {consultg === true && i + 1}
                                  {consultp === true && item.Name}
                                  {consults === true && item.Name}
                                </div>
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  width: "25%",
                                }}
                              >
                                {changeimgtab === true &&
                                clicktd === item.index ? (
                                  add == true &&
                                  item.value == "true" &&
                                  item.week !== null ? (
                                    <div>
                                      <button
                                        type="button"
                                        style={{
                                          width: isTabScreen ? "30px" : "50px",
                                          height: "25px",
                                          background: "#F2F2F2",
                                          border:
                                            "1px solid rgba(127, 179, 213, 0.5)",
                                          borderRadius: "7px",
                                          color: "#004AAD",
                                          fontSize: isTabScreen
                                            ? "12px"
                                            : "16px",
                                          margin: "0px auto",
                                        }}
                                        data-id={item.index}
                                        onClick={() => {
                                          handleOpen(item.index, item.week);
                                        }}
                                      >
                                        {item.week}{" "}
                                      </button>

                                      <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                      >
                                        <Box sx={style}>
                                          <div style={{}}>
                                            <div className="d-flex align-items-center">
                                              <img
                                                src="/lefticon.png"
                                                style={{
                                                  height: "30px",
                                                  width: "23px",
                                                  paddingLeft: "5px",
                                                  color:
                                                    "configData.THEME_COLORS.SECONDARY",
                                                }}
                                                alt="lefticon"
                                              ></img>
                                              <div
                                                style={{
                                                  fontSize: isTabScreen
                                                    ? "20px"
                                                    : "28px",
                                                  color: "#F0588B",
                                                  paddingLeft: "20px",
                                                }}
                                              >
                                                Select Week
                                              </div>
                                            </div>
                                            <hr></hr>
                                            {data?.map((item, index) => (
                                              <>
                                                <div
                                                  style={{
                                                    cursor: "pointer",
                                                    width: "45px",
                                                    height: "45px",
                                                    textAlign: "center",
                                                    display: "inline-block",
                                                    backgroundColor:
                                                      myindex === index + 1
                                                        ? "#F0588B"
                                                        : "white",
                                                    color:
                                                      myindex === index + 1
                                                        ? "white"
                                                        : "black",
                                                    borderRadius:
                                                      myindex === index + 1
                                                        ? "50%"
                                                        : "0%",
                                                  }}
                                                  className="px-2  my-2 pt-2"
                                                  onClick={() =>
                                                    selectweek(index)
                                                  }
                                                >
                                                  {item.no}
                                                </div>
                                                {(index + 1) % 7 === 0 && (
                                                  <br />
                                                )}
                                              </>
                                            ))}
                                            {myindex !== false && (
                                              <div className="text-center">
                                                <Button
                                                  style={{
                                                    background:
                                                      "rgba(0, 74, 173, 0.8)",
                                                    border:
                                                      "1px solid rgba(0, 74, 173, 0.5)",
                                                    color: "white",
                                                    width: "120px",
                                                    height: "35px",
                                                    borderRadius: "5px",
                                                  }}
                                                  onClick={() => {
                                                    consultg === false &&
                                                      pathosono(2, item.index);
                                                    consultg === true &&
                                                      settypeofconsultation(
                                                        item.index
                                                      );
                                                  }}
                                                >
                                                  Add1
                                                </Button>
                                              </div>
                                            )}
                                          </div>
                                        </Box>
                                      </Modal>
                                    </div>
                                  ) : (
                                    <div>
                                      {" "}
                                      <img
                                        src={"/icondowndark.png"}
                                        onClick={() => {
                                          handleOpen(item.index);
                                        }}
                                        style={{
                                          marginTop: "5px",
                                          resizeMode: "contain",
                                          margin: "0px auto",
                                          height: isTabScreen ? "10px" : "12px",
                                          width: isTabScreen ? "10px" : "12px",
                                        }}
                                        alt="icondowndark"
                                      />
                                      <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                      >
                                        <Box sx={style}>
                                          <div style={{}}>
                                            <div className="d-flex align-items-center">
                                              <img
                                                src="/lefticon.png"
                                                style={{
                                                  height: "30px",
                                                  width: "23px",
                                                  paddingLeft: "5px",
                                                  color:
                                                    "configData.THEME_COLORS.SECONDARY",
                                                }}
                                                alt="lefticon"
                                              ></img>
                                              <div
                                                style={{
                                                  fontSize: isTabScreen
                                                    ? "20px"
                                                    : "28px",
                                                  color: "#F0588B",
                                                  paddingLeft: "20px",
                                                }}
                                              >
                                                Select Week
                                              </div>
                                            </div>
                                            <hr></hr>
                                            {data?.map((item, index) => (
                                              <>
                                                <div
                                                  style={{
                                                    cursor: "pointer",
                                                    width: "45px",
                                                    height: "45px",
                                                    textAlign: "center",
                                                    display: "inline-block",
                                                    backgroundColor:
                                                      myindex === index + 1
                                                        ? "#F0588B"
                                                        : "white",
                                                    color:
                                                      myindex === index + 1
                                                        ? "white"
                                                        : "black",
                                                    borderRadius:
                                                      myindex === index + 1
                                                        ? "50%"
                                                        : "0%",
                                                  }}
                                                  className="px-2  my-2 pt-2"
                                                  onClick={() =>
                                                    selectweek(index)
                                                  }
                                                >
                                                  {item.no}
                                                </div>
                                                {(index + 1) % 7 === 0 && (
                                                  <br />
                                                )}
                                              </>
                                            ))}
                                            {myindex !== false && (
                                              <div className="text-center">
                                                <Button
                                                  style={{
                                                    background:
                                                      "rgba(0, 74, 173, 0.8)",
                                                    border:
                                                      "1px solid rgba(0, 74, 173, 0.5)",
                                                    color: "white",
                                                    width: "120px",
                                                    height: "35px",
                                                    borderRadius: "5px",
                                                  }}
                                                  onClick={() => {
                                                    consultg === false &&
                                                      pathosono(2, item.index);
                                                    consultg === true &&
                                                      settypeofconsultation(
                                                        item.index
                                                      );
                                                  }}
                                                >
                                                  Add
                                                </Button>
                                              </div>
                                            )}
                                          </div>
                                        </Box>
                                      </Modal>
                                    </div>
                                  )
                                ) : item.value === "true" &&
                                  item.week !== "null" ? (
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleOpen(item.index, item.week);
                                      }}
                                      style={{
                                        width: isTabScreen ? "30px" : "50px",
                                        height: "25px",
                                        background: "#F2F2F2",
                                        border:
                                          "1px solid rgba(127, 179, 213, 0.5)",
                                        borderRadius: "7px",
                                        color: "#004AAD",
                                        fontSize: isTabScreen ? "12px" : "16px",
                                        margin: "0px auto",
                                      }}
                                    >
                                      {item.week ? item.week : ""}
                                    </button>
                                    <Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box sx={style}>
                                        <div style={{}}>
                                          <div className="d-flex align-items-center">
                                            <img
                                              src="/lefticon.png"
                                              style={{
                                                height: "30px",
                                                width: "23px",
                                                paddingLeft: "5px",
                                                color:
                                                  "configData.THEME_COLORS.SECONDARY",
                                              }}
                                              alt="lefticon"
                                            ></img>
                                            <div
                                              style={{
                                                fontSize: isTabScreen
                                                  ? "20px"
                                                  : "28px",
                                                color: "#F0588B",
                                                paddingLeft: "20px",
                                              }}
                                            >
                                              Select Week
                                            </div>
                                          </div>
                                          <hr></hr>
                                          {data?.map((item, index) => (
                                            <>
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  width: "45px",
                                                  height: "45px",
                                                  textAlign: "center",
                                                  display: "inline-block",
                                                  backgroundColor:
                                                    myindex === index + 1
                                                      ? "#F0588B"
                                                      : "white",
                                                  color:
                                                    myindex === index + 1
                                                      ? "white"
                                                      : "black",
                                                  borderRadius:
                                                    myindex === index + 1
                                                      ? "50%"
                                                      : "0%",
                                                }}
                                                className="px-2  my-2 pt-2"
                                                onClick={() =>
                                                  selectweek(index)
                                                }
                                              >
                                                {item.no}
                                              </div>
                                              {(index + 1) % 7 === 0 && <br />}
                                            </>
                                          ))}
                                          {myindex !== false && (
                                            <div className="text-center">
                                              <Button
                                                style={{
                                                  background:
                                                    "rgba(0, 74, 173, 0.8)",
                                                  border:
                                                    "1px solid rgba(0, 74, 173, 0.5)",
                                                  color: "white",
                                                  width: "120px",
                                                  height: "35px",
                                                  borderRadius: "5px",
                                                }}
                                                onClick={() =>
                                                  settypeofconsultation(
                                                    item.index
                                                  )
                                                }
                                              >
                                                Add
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                      </Box>
                                    </Modal>
                                  </div>
                                ) : (
                                  <img
                                    className="mx-auto"
                                    src="../icondown.png"
                                    onClick={() => {
                                      changetodark(item.index);
                                      handleOpen(item.index);
                                    }}
                                    style={{
                                      resizeMode: "contain",
                                      height: "10px",
                                      width: "14px",
                                    }}
                                    alt="icondown"
                                  />
                                )}
                              </td>
                              <td
                                style={{
                                  fontSize: isTabScreen ? "10px" : "20px",
                                  fontWeight: "500",
                                  textAlign: "center",
                                  width: "45%",
                                }}
                              >
                                <div
                                  style={{
                                    display: consultg === true && "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {consultationTypes?.map((type) => (
                                    <>
                                      {consultg === true && (
                                        <div
                                          onClick={(event) => {
                                            fetchselecteddate(
                                              item.index,
                                              type.id,
                                              type.nm,
                                              event
                                            );
                                          }}
                                          data-id={item.index}
                                          className="px-1 py-1 text-uppercase"
                                          style={{
                                            border:
                                              "1px solid rgba(134, 134, 134, 0.4)",
                                            borderRadius: "9px 0px 0px 9px",
                                            fontSize:
                                              (buttonStates[item.index - 1]
                                                ?.online == true &&
                                                type.id == 1) ||
                                              (buttonStates[item.index - 1]
                                                ?.InClinic == true &&
                                                type.id == 2)
                                                ? isTabScreen
                                                  ? "8px"
                                                  : "12px"
                                                : isTabScreen
                                                ? "6px"
                                                : "8.6px",

                                            display: "flex",
                                            justifyContent: "center",
                                            width:
                                              (buttonStates[item.index - 1]
                                                ?.online == true &&
                                                type.id == 1) ||
                                              (buttonStates[item.index - 1]
                                                ?.InClinic == true &&
                                                type.id == 2)
                                                ? "260px"
                                                : "170px",

                                            height:
                                              (buttonStates[item.index - 1]
                                                ?.online == true &&
                                                type.id == 1) ||
                                              (buttonStates[item.index - 1]
                                                ?.InClinic == true &&
                                                type.id == 2)
                                                ? "35px"
                                                : "25px",

                                            alignItems: "center",
                                            marginRight: "1px",
                                            color:
                                              (buttonStates[item.index - 1]
                                                ?.online == true &&
                                                type.id == 1) ||
                                              (buttonStates[item.index - 1]
                                                ?.InClinic == true &&
                                                type.id == 2)
                                                ? "white"
                                                : "#464E5F",
                                            cursor: "pointer",
                                            backgroundColor:
                                              (buttonStates[item.index - 1]
                                                ?.online == true &&
                                                type.id == 1) ||
                                              (buttonStates[item.index - 1]
                                                ?.InClinic == true &&
                                                type.id == 2)
                                                ? "#F8A2BF"
                                                : "#F2F2F2",
                                          }}
                                        >
                                          {type.name}
                                        </div>
                                      )}

                                      {consultg === false && type.id === 2 && (
                                        <>
                                          <div
                                            // onClick={(e)=>fetchselecteddateinperson(2,item.index)}
                                            data-id={item.index}
                                            className="px-1 py-1 text-uppercase"
                                            style={{
                                              border:
                                                "1px solid rgba(134, 134, 134, 0.4)",
                                              borderRadius: "9px 0px 0px 9px",
                                              fontSize:
                                                (buttonStates[item.index - 1]
                                                  ?.online == true &&
                                                  type.id == 1) ||
                                                (buttonStates[item.index - 1]
                                                  ?.InClinic == true &&
                                                  type.id == 2)
                                                  ? isTabScreen
                                                    ? "8px"
                                                    : "10px"
                                                  : isTabScreen
                                                  ? "6px"
                                                  : "7px",

                                              display: "flex",
                                              justifyContent: "center",
                                              width: "150px",
                                              height: "30px",
                                              alignItems: "center",
                                              marginRight: "1px",
                                              color: "#FFFFFF",
                                              margin: "0px auto",
                                              cursor: "pointer",
                                              backgroundColor: "#F8A2BF",
                                            }}
                                          >
                                            Home Sample Collection
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ))}
                                </div>
                              </td>
                            </tr>

                            {consults === true && (
                              <>
                                <div>  
                                  {verifiedno === i ? (
                                    sentconsentform === item.index || item.consentvalue==="true" ? (
                                      <div className="text-success">consent form Sent successfully</div>
                                    ) : (
                                      <button
                                        className="text-white verifypreview"
                                        onClick={() => sentconsentformtodr(item,item.index)}
                                      >
                                        Sent Consent
                                      </button>
                                    )
                                  ) :item.week && item.consentvalue==="false" ? (<button
                                    className="bg-success text-white verifyconsult"
                                    onClick={() => setGive(i)}
                                  >
                                    Give consent
                                  </button>):item.consentvalue==="true"?
                                  (<div className="text-success">consent form Sent successfully</div>):
                                  (<div></div>)}

                                  {give === i && verifiedno !== i ? (
                                    <button
                                      className="text-white verifypreview"
                                      onClick={(e) => {
                                        sentform(item, i);
                                        sendSMS(item, i);
                                        setShowotp(i);
                                      }}
                                      onMouseOver={(e) => {
                                        e.target.style.color = "#ee5e8f"; // Change the color on hover
                                      }}
                                      onMouseOut={(e) => {
                                        e.target.style.color = "black"; // Reset the color when not hovering
                                      }}
                                    >
                                      Preview & verify
                                    </button>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                                {i === showotp && verifiedno !== i && (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "10px",
                                      width: "fit-content",
                                      margin: "0px auto",
                                    }}
                                  >
                                    <OTPInput
                                      onComplete={(otp) =>
                                        handleOTPComplete(otp, i)
                                      }
                                      reset={invalidOTP}
                                      resetKey={resetKey}
                                    />
                                    <p
                                      onClick={(e) => {
                                        sendSMS(item, i);
                                        setShowotp(i);
                                      }}
                                      style={{ cursor: "pointer" }}
                                      onMouseOver={(e) => {
                                        e.target.style.color = "#ee5e8f"; // Change the color on hover
                                      }}
                                      onMouseOut={(e) => {
                                        e.target.style.color = "black"; // Reset the color when not hovering
                                      }}
                                    >
                                      {timer === 0
                                        ? "Resend OTP"
                                        : invalidOTP === i &&
                                          invalidOTP != false
                                        ? "Resend OTPs"
                                        : ""}
                                    </p>
                                    <p
                                      className="mt-3"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {isTimerActive === i
                                        ? `OTP expires in ${timer} seconds`
                                        : isTimerActive === i
                                        ? timer === 0
                                          ? "OTP expired"
                                          : ""
                                        : ""}
                                    </p>
                                  </div>
                                )}
                                {verifiedno === i && (
                                  <>
                                    <p
                                      style={{ width: "400px" }}
                                      className="text-success"
                                    >
                                      Your mobile number is verified
                                    </p>
                                  </>
                                )}
                              </>
                            )}

                            <Modal
                              open={open2}
                              onClose={handleClose2}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              size="lg"
                            >
                              <Box className="modal-container">
                                <div
                                  className="modal-content text-end w-50"
                                  style={{ backgroundColor: "#FFF0F5" }}
                                >
                                  <span
                                    className="close"
                                    onClick={handleClose2}
                                    style={{ color: "black" }}
                                  >
                                    &times;
                                  </span>
                                  <div className="">
                                    <div>
                                      <div
                                        className="d-flex text-white justify-content-between py-3"
                                        style={{ backgroundColor: "configData.THEME_COLORS.SECONDARY" }}
                                      >
                                        <div className=" pt-2 d-flex justify-content-between align-items-center ps-2 w-100">
                                          <div className="text-white fs-3 mx-2">
                                            MyPrega
                                          </div>
                                          <div className="me-3">
                                            <img
                                              src={"/logo-removebg.png"}
                                              style={{
                                                resizeMode: "contain",
                                                height: "120px",
                                                width: "120px",
                                                margin: "auto",
                                              }} alt="nt found"
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="d-flex text-white justify-content-between"
                                        style={{ backgroundColor: "red" }}
                                      >
                                        <div></div>
                                        <div className="pe-4 text-end py-4">
                                          <div className="fw-bolder text-capitalize">
                                            Dr.{" "}
                                            {`${profileData?.user?.fname} ${profileData?.user?.lname}`}
                                          </div>
                                          <p className="drpost text-white">
                                            {
                                              profileData?.user
                                                ?.medical_expert_name
                                            }
                                          </p>
                                          <p className="areanm text-white">
                                            {profileData?.user?.area_name}
                                          </p>
                                          {profileData &&
                                            profileData.medical_reg &&
                                            profileData.medical_reg.length >
                                              0 && (
                                              <p className="text-white">
                                                {" "}
                                                Medical registration number{" "}
                                                {
                                                  profileData?.medical_reg[0]
                                                    ?.reg_num
                                                }
      
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                      <div className="bg-white">
                                        <div className="d-flex py-2 ms-2 align-items-center justify-content-between ">
                                          <div className="ps-2">
                                            <div className="text-capitalize">
                                              {firstname} {lastname}
                                            </div>
                                            <div className="text-secondary">
                                              33 years, Female
                                            </div>
                                          </div>
                                          <div className="pe-2">
                                            {moment().format("DD MMM, YYYY")}
                                          </div>
                                        </div>
                                        <hr className="w-100" />
                                        <div className="p-5 ">
                                          <div className="text-left py-3">
                                            Dear Doctor, I, Dr.{" "}
                                            {`${profileData?.user?.fname} ${profileData?.user?.lname}`}
                                            {profileData &&
                                              profileData.medical_reg &&
                                              profileData.medical_reg.length >
                                                0 && (
                                                <div className="d-inline">
                                                  {" "}
                                                  Medical registration number{" "}
                                                  {
                                                    profileData?.medical_reg[0]
                                                      ?.reg_num
                                                  }
                                                  , am referring herewith to my
                                                  patient,
                                                </div>
                                              )}{" "}
                                            Mrs. {firstname ? firstname : ""}{" "}
                                            {lastname ? lastname : ""}, of age
                                            24 and female, for the following{" "}
                                            {testname ? testname : ""}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="py-3 text-end">
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={`${full_doctor_signature_url}`}
                                          alt="Doctor's Signature"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-content-scrollable">
                                    <h1></h1>
                                  </div>
                                </div>
                              </Box>
                            </Modal>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-center">
                    {isAllTrue && (
                      <Button
                        style={{
                          background: "rgba(0, 74, 173, 0.8)",
                          border: "1px solid rgba(0, 74, 173, 0.5)",
                          color: "white",
                          margin: "20px auto",
                          width: isTabScreen ? "100px" : "120px",
                          fontSize: isTabScreen ? "14px" : "18px",
                          fontWeight: "bold",
                        }}
                        onClick={Done}
                      >
                        Done
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div style={{ width: click === true ? "5%" : "30%" }}>
          {click === true && chat === false ? (
            <>
              <img
                className="ms-3"
                src={"/righticon.png"}
                onClick={() => setwidth()}
                style={{
                  height: isTabScreen ? "16px" : "22px",
                  width: isTabScreen ? "16px" : "22px",
                }} alt="nt found"
              ></img>
            </>
          ) : (
            chat === false && (
              <>
                <img
                  className="ms-3 d-inline my-4"
                  src={"/lefticon.png"}
                  onClick={() => setwidth()}
                  style={{
                    height: "30px",
                    width: "23px",
                    paddingLeft: "5px",
                    color: "configData.THEME_COLORS.SECONDARY",
                  }}
                ></img>
              </>
            )
          )}

          {chat === true && click == false && (
            <>
              <div className="d-flex align-items-center my-4">
                <div>
                  {" "}
                  <img
                    className="ms-3 d-inline my-4"
                    src={"/lefticon.png"}
                    onClick={() => setwidth()}
                    style={{
                      height: "30px",
                      width: "23px",
                      paddingLeft: "5px",
                      color: "configData.THEME_COLORS.SECONDARY",
                    }}
                  ></img>
                </div>
                <div className="ps-3">
                  <img
                    src={
                      userData?.profile_pic
                        ? "https://admin.myprega.com/" + userData?.profile_pic
                        : "/test1.png"
                    }
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                    alt="nt found"
                  ></img>
                </div>
                <div>
                  <div
                    className="d-inline my-4 ps-3"
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      color: "rgb(240, 88, 139)",
                    }}
                  >
                    {userData?.fname
                      ? "Dr. " + userData?.fname + " " + userData?.lname
                      : ""}
                  </div>
                  <div className="ps-3">{userData?.medical_expert_name}</div>
                </div>
              </div>
              <hr className="w-100"></hr>
            </>
          )}
          {click === false && chat == false && (
            <medicalexpertcontext.Provider value={{medical_expert_id:userData?.medical_expert_id}}>
            <RightBox
              title={"Calendar"}
              onClick={handleReschedule}
              onClickDay={onClickDay}
              date={date}
              dateClicked={dateClicked}
              onClick1={setwidth}
              upcoming={upcoming}
              consultations={consultations}
              todayConsultaion={consultation_today}
              
            />
            </medicalexpertcontext.Provider>
          )}

          {chat == true && click == false && (
            <>
              <div
                style={{
                  height: "376px",
                  backgroundColor: "#FFF0F5",
                  width: "96%",
                  margin: "0px auto",
                }}
              >
                <div
                  className="mx-auto my-4"
                  ref={chatBoxRef}
                  style={{
                    height: "360px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    backgroundColor: "#FFF0F5",
                    width: "96%",
                  }}
                >
                  <div className="w-75 mx-auto defaultmsg p-2 py-2 my-2">
                    Messages are end-to-end encrypted. No one outside of this
                    chat, not even MyPrega, can read or listen to them.
                  </div>
                  {incomingmsg.length > 0 ? (
                    incomingmsg?.map((item, index) => (
                      <>
                        <div key={item.id}>
                          {index === 0 ||
                            (moment(item.created_at).format("DD-MM-YYYY") !==
                              moment(incomingmsg[index - 1].created_at).format(
                                "DD-MM-YYYY"
                              ) && (
                              <div
                                className="mt-1 mx-auto text-white d-flex justify-content-center align-items-center"
                                style={{
                                  width: "90px",
                                  height: "30px",
                                  fontSize: "11px",
                                  backgroundColor: "rgb(240, 88, 139)",
                                  borderRadius: "14px",
                                }}
                              >
                                {moment(item.created_at).format("DD MMM YYYY")}
                              </div>
                            ))}
                          <div className="d-flex my-2">
                            {item.is_system_generated === 1 ? (
                              <div className="w-75 mx-auto defaultmsg p-2 py-2 my-2">
                                {item.message}
                              </div>
                            ) : (
                              <>
                                {" "}
                                <div
                                  className={`p-2  ${
                                    item.user_id === userData.id
                                      ? "rightsideclass"
                                      : "leftsideclass"
                                  }  ${
                                    item.message.length > 28
                                      ? "setw"
                                      : "removew"
                                  }`}
                                  style={{ fontSize: "12px" }}
                                  key={item.id}
                                >
                                  {item.message}
                                  <div
                                    className="text-end"
                                    style={{
                                      color:
                                        item.user_id === userData.id
                                          ? "black"
                                          : "#c4c4c4",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {moment(item.created_at).format("hh:mm A")}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div>No messages to show</div>
                  )}
                </div>
              </div>
              {/* <div className="mx-2" style={{height:"40px",backgroundColor:"#FFF0F5"}}>
                    <input
                      className="w-75"
                      type="text"
                      value={message}
                      placeholder="Type your message..."
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                      className="mx-2"
                      onClick={sendMessage}
                      style={{
                        backgroundColor: "#f0588b",
                        color: "white",
                        borderRadius: "6px",
                      }}
                    >
                      Send
                    </button>
                  </div> */}
              <div
                className="mx-auto"
                style={{
                  width: "96%",
                  backgroundColor: "#fff0f5",
                  height: "55px",
                  paddingBottom: "15px",
                  marginBottom: "22px",
                }}
              >
                <div className="mx-2" style={inputbox}>
                  <img
                    src="/Communication/Attachbutton.png"
                    className="mx-2"
                    // onClick={}
                  ></img>
                  <input
                    className="w-75"
                    type="text"
                    style={innerinput}
                    value={message}
                    placeholder="Type your message..."
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <img
                    src="/Communication/sendbtn.png"
                    className="mx-2"
                    onClick={sendMessage}
                  ></img>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}



export { medicalexpertcontext };
export default Dashboard;

