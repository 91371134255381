import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./website/Pages/About";
import Home from "./website/Pages/Home";
import Contact from "./website/Pages/Contact";
import Elementalcare from "./website/Pages/Elementalcare";
import Cherishcare from "./website/Pages/Cherishcare";
import Pricing from "./website/Pages/Pricing";
import Termsofuse from "./website/Pages/Termsofuse";
import Refundandcancellationpolicy from "./website/Pages/Refundandcancellationpolicy";
import Disclaimerpolicy from "./website/Pages/Disclaimerpolicy";

// import MeetingNew from "./website/Pages/MeetingNew";
//patient
import Dashboard from "./patient/Pages/Dashboard";
// import Login from "./Pages/login";
import DoctorConsultation from "./patient/Pages/Doctor_consultation";
import Pathology from "./patient/Pages/Pathology";
import HealthTracker from "./patient/Pages/HealthTracker";
import Reports from "./patient/Pages/Reports";
import GarbhaSanskar from "./patient/Pages/GarbhaSanskar";
import Sonography from "./patient/Pages/Sonography";
import PregnancyCare from "./patient/Pages/PregnancyCare";
import ContactPatient from "./patient/Pages/Contact";
// import SplashScreen from "./patient/Components/SplashScreen";
import Loginform from "./patient/Pages/Loginform";
import Signup from "./patient/Pages/Signup";
import Casepaper from "./patient/Pages/Casepaper";
import Profile from "./patient/Pages/Profile";
import DoctorDashboard from "./doctor/Pages/Dashboard";
// import DoctorSplashScreen from "./doctor/Pages/SplashScreen";
import Communication from "./doctor/Pages/Communication";
import Patient from "./doctor/Pages/Patient";
import DoctorProfile from "./doctor/Pages/Profile";
import Payment from "./doctor/Pages/Payment";
import Login from "./doctor/Pages/Login";
import SignupDoctor from "./doctor/Pages/Signup";
// import MeetingNewPatient from "./doctor/Pages/MeetingNew";
import YogaPatient from "./doctor/Pages/YogaPatient";
import ProtectedRoute from "./website/components/ProtectedRoute";
function AppRoutes() {
  return (
    <div>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact/>}/>
           <Route path="/elementalcare" element={<Elementalcare />} />
           <Route path="/cherishcare" element={<Cherishcare/>}/>
           <Route path="/pricing" element={<Pricing/>}/>
           <Route path="/termsofuse" element={<Termsofuse/>}/>
           <Route path="/refund&cancellationpolicy" element={<Refundandcancellationpolicy/>}/>
           <Route path="/disclaimerpolicy" element={<Disclaimerpolicy/>}/>
           {/* <Route path="/meeting" element={<MeetingNew />} /> */}

          {/* patient Router */}
          {/* <Route exact path="/">
              <SplashScreen />
            </Route> */}
            <Route exact path="/patient/Signup"  element={<Signup />}  />
              
            <Route exact path="/patient/Casepaper" element={<Casepaper />}  />
              
            <Route exact path="/patient/loginform" element={<Loginform />}  />
            <Route exact path="/patient/Dashboard" element={<Dashboard />}  />
                  <Route
        path="/patient/Doctor_consultation"
        element={
          <ProtectedRoute
            element={DoctorConsultation}
            requiredStatus={true} // Only allow if the user is a paid user
            redirectTo="/patient/Garbha_Sanskar"
          />
        }
      />
      <Route
        path="/patient/Reports"
        element={
          <ProtectedRoute
            element={Reports}
            requiredStatus={true} // Only allow if the user is a paid user
            redirectTo="/patient/Garbha_Sanskar"
          />
        }
      />
        <Route
        path="/patient/Pathology"
        element={
          <ProtectedRoute
            element={Pathology}
            requiredStatus={true} // Only allow if the user is a paid user
            redirectTo="/patient/Garbha_Sanskar"
          />
        }
      />
        <Route
        path="/patient/Sonography"
        element={
          <ProtectedRoute
            element={Sonography}
            requiredStatus={true} // Only allow if the user is a paid user
            redirectTo="/patient/Garbha_Sanskar"
          />
        }
      />
     
      <Route
        path="/patient/PregnancyCare"
        element={
          <ProtectedRoute
            element={PregnancyCare}
            requiredStatus={true} // Only allow if the user is a paid user
            redirectTo="/patient/Garbha_Sanskar"
          />
        }
      />
            {/* <Route path="/patient/Pathology" element={<Pathology />}  /> */}
            <Route exact path="/patient/Health_Tracker" element={<HealthTracker />}  />
          
            <Route exact path="/patient/Garbha_Sanskar" element={ <GarbhaSanskar />}  />
             
            {/* <Route exact path="/patient/Sonography" element={ <Sonography />}  /> */}
            {/* <Route exact path="/patient/PregnancyCare" element={<PregnancyCare />}  /> */}
            <Route exact path="/patient/Contact" element={ <ContactPatient />}  />
            <Route exact path="/patient/profile" element={<Profile/>}  />
            {/* <Route exact path="/patient/meeting" element={<MeetingPatient />}  /> */}

            {/* Doctor router */}
            {/* <Route exact path="/" element={<SplashScreen />} /> */}
             
            <Route exact path="/doctor/Signup" element={<SignupDoctor />} />
            <Route exact path="/doctor/login" element={<Login />} />
            <Route exact path="/doctor/Dashboard" element={<DoctorDashboard />} />
            <Route exact path="/doctor/Communication" element={<Communication />} />
            <Route exact path="/doctor/Patient" element={<Patient />} />
            <Route exact path="/doctor/Payment" element={ <Payment />} />
            <Route exact path="/doctor/Profile" element={<DoctorProfile />} />
            {/* <Route exact path="/doctor/meeting" element={<MeetingNew />} /> */}
            <Route exact path="/doctor/yogapatient" element={<YogaPatient />} />
            
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default AppRoutes;
