import React, { useEffect, useState } from "react";
import MiniDrawer from "../Components/MiniDrawer";
import Typography from "@mui/material/Typography";
import Carousel from "nuka-carousel";
import MeasurementsBox from "../Components/MeasurementsBox";
import RecentHistoryRightBox from "../Components/RecentHistoryRightBox";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import axios from "axios";
import Dropdown from "../Components/Categories/Dropdown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import HealthVital1 from "../Components/HealthVital1";
import HealthVital4 from "../Components/HealthVital4";
import HealthVital3 from "../Components/HealthVital3";
import HealthVital2 from "../Components/HealthVital2";

const currentDate = new Date();
const formattedDate = currentDate.toISOString().split("T")[0];
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};
let flag=0;
let flag1=0;
let flag2=0;
function HealthTracker() {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const isTabScreen2 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });

  const isScreen3 = useMediaQuery({
    query: "(min-width: 1500px)",
  });

  const [healthVitals, setHealthVitals] = useState();
  const [avgsugar,setAvgsugar]=useState();
  const [avgsystolic,setAvgsystolic]=useState();
  const [avgdiastolic,setAvgdiastolic]=useState();
  const [avgtemp,setAvgtemp]=useState();
  const [avgweight,setAvgweight]=useState();
  const [graphData, setGraphData] = useState();
  const [vitals, setVitals] = useState({
    sugar: "",
    systolic_bp:"",
    diastolic_bp:"",
    // bloodPressure: "",
    temperature: "",
    weight: "",
  });
  const [vitalsDate, setVitalsDate] = useState(formattedDate);
  const [healthVital1Comp, setHealthVital1Comp] = useState(false);
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);

  useEffect(() => {
    getHealthVitals();
    getHealthVitalsForGraph();
    getHealthVitalsByDate();
    setHealthVital1Comp(true);
  }, []);

  const addHealthVitals = async (keyName, keyValue) => {
    try {
      let requestBody = {
        patient_id: localStorage.getItem("userId").toString(),
        value: keyValue,
        key: keyName,
        date: vitalsDate,
      };
      console.log("vitalsDate", vitalsDate, requestBody);
      if (keyName === "blood_pressure") {
        requestBody = {
          ...requestBody,
          systolic_bp: keyValue.systolic_bp,
          diastolic_bp: keyValue.diastolic_bp,
        };
      }
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/add_health_details`,
        requestBody
      );
      if (response.data?.success) {
        console.log("added healthvitals-", response.data);
        // alert("Health Vitals Added!");
        // setVitals({
        //   sugar: "20",
        //   bloodPressure: "10",
        //   temperature: "",
        //   weight: "",
        // });
        // getHealthVitalsByDate();
        getHealthVitals();
      }
    } catch (error) {
      console.error("Error get add Healthvital:", error);
    }
    getHealthVitalsByDatecheck(keyName,vitalsDate);
  };
  const onSubmit1 = (val) => {
    setVitals((prevVitals) => ({
      ...prevVitals,
      sugar: val,
    }));


    addHealthVitals("sugar_level", val);
    // getHealthVitalsByDatecheck("sugar_level",vitalsDate);
   

    
  };
  const onSubmit2 = (systolicBP, diastolicBP) => {
    setVitals((prevVitals) => ({
      ...prevVitals,
        systolic_bp: systolicBP,
        diastolic_bp: diastolicBP,
    }));
    addHealthVitals("blood_pressure", {
      systolic_bp: systolicBP,
      diastolic_bp: diastolicBP,
    });
  
  };
  const onSubmit3 = (val) => {
    setVitals((prevVitals) => ({
      ...prevVitals,
      temperature: val,
    }));
    addHealthVitals("temperature", val);
  };
  const onSubmit4 = (val) => {
    setVitals((prevVitals) => ({
      ...prevVitals,
      weight: val,
    }));
    addHealthVitals("weight", val);
  };

  // const editMode = (val) => {
  //   setIsEdit(val);
  // };

  const getHealthVitals = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details`,
        {
          patient_id: localStorage.getItem("userId").toString(),
        }
      );

      if (response.data?.success) {
        console.log("healthvitals-", response.data?.health_Details);
        setHealthVitals(response.data?.health_Details);
      }
    } catch (error) {
      console.error("Error ge Healthvital:", error);
    }
  };

  const getHealthVitalsByDate = async (date = null) => {
    try {
      const cDate = date === null ? formattedDate : date;
      console.log("cDate", cDate);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details_dateWise`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          date: cDate,
        }
      );
      if (response.data?.success) {
        const data = response.data?.health_Details;
        console.log("cDate", response.data);
        if (data.length !== 0) {
          setVitals({
            sugar: data[0].sugar_level,
            systolic_bp:data[0].systolic_bp,
            diastolic_bp:data[0].diastolic_bp,
            // bloodPressure: data[0].blood_pressure,
            temperature: data[0].temperature,
            weight: data[0].weight,
          });
         
        } else {
          setVitals({
            sugar: "",
            systolic_bp:"",
            diastolic_bp:"",
            // bloodPressure: "",
            temperature: "",
            weight: "",
          });
        }
        getHealthVitals();
      }
    } catch (error) {
      console.error("Error ge Healthvital:", error);
    }
  };

  const handleChangeDate = async (e) => {
    try {
      const date = e.target.value;
      setVitalsDate(date);
      getHealthVitalsByDate(date);
      console.log("handelChangeDate", e.target.value);
    } catch (error) {
      console.error("Error vitals");
    }
  };

  const getHealthVitalsForGraph = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details_monthwise`,
        {
          patient_id: localStorage.getItem("userId").toString(),
        }
      );

      if (response.data) {
        console.log("Graphhealthvitals-", response.data?.health_Details);
        setGraphData(response.data?.health_Details);
        // setAvgsugar(response.data?.health_Details[0]?.sugar_level.avg_sugar_level)
        const m=moment().format('MMM');
        for(let i=0;i<=11;i++)
        {
            if(m==response.data?.health_Details[0]?.sugar_level[i].month_name) 
            {
                  setAvgsugar(response.data?.health_Details[0]?.sugar_level[i].avg_sugar_level);
                  setAvgdiastolic(response.data?.health_Details[0]?.blood_pressure[i].avg_diastolic_blood_pressure);
                  setAvgsystolic(response.data?.health_Details[0]?.blood_pressure[i].avg_systolic_blood_pressure);
                  setAvgtemp(response.data?.health_Details[0]?.temperature[i].avg_temperature);
                  setAvgweight(response.data?.health_Details[0]?.weight[i].avg_weight);
            }
        }
        console.log(
          "Graphhealthvitals@@",
          response.data?.health_Details[0]?.sugar_level,m
        );
      }
    } catch (error) {
      console.error("Error Graphhealthvitals:", error);
    }
  };
  const getHealthVitalsByDatecheck = async (key,date = null) => {
    try {
      const cDate = date === null ? formattedDate : date;
      console.log("cDate", cDate);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details_dateWise`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          date: cDate,
        }
      );
      if (response.data?.success) {
        const data = response.data?.health_Details;
        console.log("cDate", response.data);
        if (data.length !== 0) {
          
           let sugar=data[0]?.sugar_level;
           let systolic_bp=data[0]?.systolic_bp;
           let diastolic_bp=data[0]?.diastolic_bp;
           let temperature=data[0]?.temperature;
           let weight=data[0]?.weight;
       
          // console.log("BLOOD PRESSURE",sugar,key,data[0].systolic_bp,data[0].diastolic_bp);
          if(key=="sugar_level")
          {
                    if(sugar < 70)
          {
           alert("Your sugar level has lowered. We will update your medical experts regarding this condition. They will connect with you shortly, if needed. Don’t panic; we are here to take care of you!")
                      }
                    else if(sugar>=70 && sugar<=140)
          {
            alert("Your sugar level is normal. Continue your sugar monitoring and your current care")
                       }
                     else
          {
            alert("Your sugar level has increased We will update your medical experts regarding. this condition. They will connect with you shortly if needed Dont panic we are here to take care of you"); 
                       }
        }
        if(key=="blood_pressure" && flag==1)
        {
                    if(systolic_bp>=140 || diastolic_bp>=90)
                      {   
                     alert("Your blood pressure is high. We will update your medical experts regarding this condition. They will connect with you shortly, if needed. In the meantime, please be calm and focus on deep breathing and meditation. Don’t get panic, we are here to take care of you!");
                    //  flag=0
                    }
                    if((systolic_bp>=110 && systolic_bp<=134) || (diastolic_bp>=70 && diastolic_bp<=84))
                      {
                       alert("Your blood pressure is normal. Continue blood pressure monitoring and your current care");
                        }
                     if((systolic_bp>=135 && systolic_bp<=139) || (diastolic_bp>=85 && diastolic_bp<=89))
                        {
                          alert("Your blood pressure is high normal. Please be calm and focus on deep breathing and meditation; reduce your stress levels and take a warm bath or shower. This will help you normalize your blood pressure.");
                          // flag=0
                        }
                        if(systolic_bp<=109 && diastolic_bp<=69)
                        {
                          alert("Your blood pressure is low. We will update your medical experts regarding this condition. They will connect with you shortly, if needed. In the meantime, drink more water continuously. Don’t panic; we are here to take care of you");
                          // flag=0;
                        }
        }
        if(key=="blood_pressure" && flag==0)
        {
                        if(systolic_bp>=150 || diastolic_bp>=100)
                        {
                          alert("Your blood pressure is high. Sit quietly for 5 minutes, then measure it again and send in the reading.");
                           flag=1;
                        }
                        if((systolic_bp>=110 && systolic_bp<=134) && (diastolic_bp>=70 && diastolic_bp<=84))
                        {
                          alert("Your blood pressure is normal. Continue blood pressure monitoring and your current care");
                        }
                        if((systolic_bp>=135 && systolic_bp<=139) || (diastolic_bp>=85 && diastolic_bp<=89))
                        {
                          alert("Your blood pressure is normal. Continue blood pressure monitoring and your current care");
                          flag=1;
                        }
                        if(systolic_bp<=109 && diastolic_bp<=69)
                        {
                          alert("Your blood pressure is low. Repeat once more in 5 minutes and send in the reading");
                          flag=1;
                        }

        }
        if(key=="temperature")
        {
          if(temperature>39)
          {
            alert("Your temperature has increased significantly. This might be a condition of fever. We will update your medical experts regarding this condition. They will connect with you shortly, if needed. Don’t panic; we are here to take care of you!")
          }
          else if(temperature>37.5 && temperature<=39)
          {
            alert("Your temperature has increased. We will update your medical experts regarding this condition. They will connect with you shortly, if needed. Don’t panic; we are here to take care of you! ")
          }
          else if(temperature>36.5 && temperature<=37.5)
          {
            alert("Your temperature is normal. Continue your temperature monitoring and your current care.")
          }
          else if(temperature<36.5)
          {
            alert("Your temperature has lowered. We will update your medical experts regarding this condition. They will connect with you shortly, if needed. Don’t panic; we are here to take care of you!")
          }
          else{
                alert("Enter valid input");
          }
        }
        } else {
          // setVitals({
          //   sugar: "",
          //   systolic_bp:"",
          //   diastolic_bp:"",
          //   // bloodPressure: "",
          //   temperature: "",
          //   weight: "",
          // });
        }
        // getHealthVitals();
      }
    } catch (error) {
      console.error("Error ge Healthvital:", error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <StyleRoot style={{ width: "70%", backgroundColor: "#FFF0F5" }}>
        <div className="test" style={styles.fadeIn}>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: configData.THEME_COLORS.BACKGROUND,
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "80px",
              }}
            >
              <Typography
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                  height: "48px",
                  marginLeft: isTabScreen ? "20px" : "59px",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "24px" : "32px",
                    fontWeight: "600",
                  }}
                >
                  {"Health Tracker"}
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "60px",
                }}
              >
                <NotificationsIcon
                  sx={{
                    width: isScreen3 ? "40px" : "22.48px",
                    height: isScreen3 ? "41px" : "23.09px",
                    color: "#004AAD",
                  }}
                />
                <Dropdown
                  submenu={submenu}
                  onClick={() => showsubmenu()}
                  page="Health Tracker"
                />
              </div>
            </div>

            <div
              style={{
                marginTop: "2px",
                marginLeft: isTabScreen ? "20px" : "59px",
              }}
            >
              <Typography
                paragraph
                sx={{ color: configData.THEME_COLORS.SECONDARY }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "18px" : "26px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  Health Vitals
                </div>
              </Typography>
            </div>

            <div
              style={{
                marginTop: "2px",
                marginLeft: isTabScreen ? "20px" : "59px",
              }}
            >
              <Typography
                paragraph
                sx={{ color: configData.THEME_COLORS.SECONDARY }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "12px" : "16px",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    color: "#333333",
                    width: "90%",
                  }}
                >
                  Health Vitals are necessary to monitor your health during
                  pregnancy. Use the Health Kit provided to you to check your
                  health vitals and enter the values below after every 2 days.{" "}
                </div>
                <br />
                <div>
                  Date : &nbsp;
                  <input
                    type="date"
                    className="h-t-date"
                    value={vitalsDate}
                    onChange={handleChangeDate}
                  />
                </div>
              </Typography>
            </div>
            <div className="container p-0 m-0">
              <div
                className="row"
                style={{
                  marginLeft: isTabScreen ? "10px" : "59px",
                  marginRight: isTabScreen ? "10px" : "59px",
                  marginTop: "20px",
                }}
              >
                <div className="col-3 p-0">
                  {healthVital1Comp && vitals.sugar !== undefined && (
                    <HealthVital1
                      title={"Sugar Level"}
                      img={"/Dashboard/sugarLevel.png"}
                      color={"#F8DEBD"}
                      value={80}
                      unit={"mg / dl"}
                      mode={"Consultation"}
                      onSubmit1={onSubmit1}
                      vital={vitals.sugar}
                    />
                  )}
                </div>
                <div className="col-3 p-0">
                  <HealthVital2
                    title={"Blood Pressure"}
                    img={"/Dashboard/bloodpressure.png"}
                    color={"#D0FBFF"}
                    value={"102 / 72"}
                    unit={"mmhg"}
                    mode={"Consultation"}
                    onSubmit2={onSubmit2}
                    vital={vitals.systolic_bp}
                    vital1={vitals.diastolic_bp}
                  />
                </div>
                <div className="col-3 p-0">
                  <HealthVital3
                    title={"Temperature"}
                    img={"/Dashboard/temperature.png"}
                    color={"#FBF0F3"}
                    value={98.6}
                    unit={"°Celsius"}
                    mode={"Consultation"}
                    onSubmit3={onSubmit3}
                    vital={vitals.temperature}
                  />
                </div>
                <div className="col-3 p-0">
                  <HealthVital4
                    title={"Weight"}
                    img={"/Dashboard/weight.png"}
                    color={"#8fa9b570"}
                    value={60}
                    unit={"Kg"}
                    mode={"Consultation"}
                    onSubmit4={onSubmit4}
                    vital={vitals.weight}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "90px",
                marginLeft: isTabScreen ? "20px" : "59px",
              }}
            >
              <Typography
                paragraph
                sx={{ color: configData.THEME_COLORS.SECONDARY }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "20px" : "24px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  Graphs
                </div>
              </Typography>
            </div>
            <div
              style={{
                marginLeft: isTabScreen ? "20px" : "59px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-end",
                  // marginBottom: "5%",
                }}
              ></div>

              <div
                style={{
                  height: isTabScreen ? "45vh" : "46vh",
                  width: isTabScreen ? "42vw" : "42vw",
                  boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.1608)",
                  marginLeft: "12%",
                  marginBottom: "5%",
                }}
              >
                <Carousel
                  slidesToShow={1}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <img
                      onClick={previousSlide}
                      src={"/HealthTracker/circle-left.png"}
                      style={{
                        resizeMode: "contain",
                        height: isTabScreen ? "25px" : "34.88px",
                        width: isTabScreen ? "25px" : "34.88px",
                        cursor: "pointer",
                        position: "absolute",
                        left: "-10px",
                      }}
                    />
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <img
                      onClick={nextSlide}
                      src={"/HealthTracker/circle-right.png"}
                      style={{
                        resizeMode: "contain",
                        height: isTabScreen ? "25px" : "34.88px",
                        width: isTabScreen ? "25px" : "34.88px",
                        cursor: "pointer",
                        position: "absolute",
                        right: "-50px",
                      }}
                    />
                  )}
                  defaultControlsConfig={{
                    pagingDotsStyle: {
                      display: "none",
                    },
                  }}
                >
                  {/* {[0, 1, 2, 3]?.map((e) => {
                    return ( */}
                  <div
                    className="bg-white"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3">
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Sugar Level
                      </div>
                      <div
                        style={{
                          padding: 5,
                          marginLeft: "15px",
                          height: "25px",
                          backgroundColor: "#58CF99",
                          display: "flex",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                      >
                        {avgsugar} mg/dl
                      </div>
                    </div>
                    <ResponsiveContainer
                      width={window.innerWidth / 2.6}
                      height={window.innerHeight / 2}
                    >
                      <LineChart
                        // width={isTabScreen ? 390 : isScreen3 ? 580 : 550}
                        // height={isTabScreen ? 300 : isScreen3 ? 450 : 250}
                        data={graphData && graphData[0]?.sugar_level}
                        margin={{
                          top: 5,
                          // // right: 30,
                          left: isTabScreen ? 0 : 20,
                          bottom: 20,
                          // bottom: 0
                        }}
                      >
                        <XAxis
                          dataKey="month_name"
                          domain={["auto", "auto"]}
                          axisLine={true}
                          fontSize={"12px"}
                        />
                        <YAxis datakey="vl" axisLine={true} fontSize={"12px"} />
                        <Tooltip />
                        <Legend
                          iconType="circle"
                          iconSize={10}
                          height={20}
                          wrapperStyle={{
                            fontSize: isTabScreen ? "10px" : "14px",
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="avg_sugar_level"
                          stroke="#4791FF"
                          strokeWidth={2}
                          dot={{
                            fill: "#4791FF",
                            r: 3,
                          }}
                          activeDot={{ r: 8 }}
                        />
                        {/* <Line
                          type="monotone"
                          dataKey="ideal_sugar"
                          stroke="#02BC77"
                          strokeWidth={2}
                          dot={{
                            fill: "#02BC77",
                            r: 3,
                          }}
                        /> */}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>

                  <div
                    className="bg-white"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3">
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Blood Pressure
                      </div>
                      <div
                        style={{
                          padding: 5,
                          marginLeft: "15px",
                          height: "25px",
                          backgroundColor: "#58CF99",
                          display: "flex",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                      >
                        {avgsystolic}/{avgdiastolic} mmhg 
                      </div>
                    </div>
                    <ResponsiveContainer
                      width={window.innerWidth / 2.6}
                      height={window.innerHeight / 2}
                    >
                      <LineChart
                        // width={isTabScreen ? 390 : isScreen3 ? 580 : 550}
                        // height={isTabScreen ? 300 : isScreen3 ? 450 : 250}
                        data={graphData && graphData[0]?.blood_pressure}
                        margin={{
                          top: 5,
                          // // right: 30,
                          left: isTabScreen ? 0 : 20,
                          bottom: 20,
                          // bottom: 0
                        }}
                      >
                        <XAxis
                          dataKey="month_name"
                          axisLine={true}
                          domain={["auto", "auto"]}
                          fontSize={"12px"}
                        />
                        <YAxis datakey="vl" axisLine={true} fontSize={"12px"} />
                        <Tooltip />
                        <Legend
                          iconType="circle"
                          iconSize={10}
                          height={20}
                          wrapperStyle={{
                            fontSize: isTabScreen ? "10px" : "14px",
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="avg_diastolic_blood_pressure"
                          stroke="#4791FF"
                          strokeWidth={2}
                          dot={{
                            fill: "#4791FF",
                            r: 3,
                          }}
                          activeDot={{ r: 8 }}
                        />
                          <Line
                          type="monotone"
                          dataKey="avg_systolic_blood_pressure"
                          stroke="#4791FF"
                          strokeWidth={2}
                          dot={{
                            fill: "purple",
                            r: 3,
                          }}
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="ideal_diastolic_pressure"
                          stroke="#02BC77"
                          strokeWidth={2}
                          dot={{
                            fill: "#02BC77",
                            r: 3,
                          }}
                        />
                         <Line
                          type="monotone"
                          dataKey="ideal_systolic_pressure"
                          stroke="#02BC77"
                          strokeWidth={2}
                          dot={{
                            fill: "#02BC77",
                            r: 3,
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  <div
                    className="bg-white"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3">
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Temperature Level
                      </div>
                      <div
                        style={{
                          padding: 5,
                          marginLeft: "15px",
                          height: "25px",
                          backgroundColor: "#58CF99",
                          display: "flex",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                      >
                        {avgtemp} °Celsius
                      </div>
                    </div>
                    <ResponsiveContainer
                      width={window.innerWidth / 2.6}
                      height={window.innerHeight / 2}
                    >
                      <LineChart
                        // width={isTabScreen ? 390 : isScreen3 ? 580 : 550}
                        // height={isTabScreen ? 300 : isScreen3 ? 450 : 250}
                        data={graphData && graphData[0]?.temperature}
                        margin={{
                          top: 5,
                          // // right: 30,
                          left: isTabScreen ? 0 : 20,
                          bottom: 20,
                          // bottom: 0
                        }}
                      >
                        <XAxis
                          dataKey="month_name"
                          axisLine={true}
                          fontSize={"12px"}
                          domain={["auto", "auto"]}
                        />
                        <YAxis datakey="vl" axisLine={true} fontSize={"12px"} />
                        <Tooltip />
                        <Legend
                          iconType="circle"
                          iconSize={10}
                          height={20}
                          wrapperStyle={{
                            fontSize: isTabScreen ? "10px" : "14px",
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="avg_temperature"
                          stroke="#4791FF"
                          strokeWidth={2}
                          dot={{
                            fill: "#4791FF",
                            r: 3,
                          }}
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="ideal_max_temperature"
                          stroke="#02BC77"
                          strokeWidth={2}
                          dot={{
                            fill: "#02BC77",
                            r: 3,
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>

                  <div
                    className="bg-white"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-3">
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Weight
                      </div>
                      <div
                        style={{
                          padding: 5,
                          marginLeft: "15px",
                          height: "25px",
                          backgroundColor: "#58CF99",
                          display: "flex",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                      >
                        {avgweight} Kg
                      </div>
                    </div>
                    <ResponsiveContainer
                      width={window.innerWidth / 2.6}
                      height={window.innerHeight / 2}
                    >
                      <LineChart
                        // width={isTabScreen ? 390 : isScreen3 ? 580 : 550}
                        // height={isTabScreen ? 300 : isScreen3 ? 450 : 250}
                        data={graphData && graphData[0]?.weight}
                        margin={{
                          top: 5,
                          // // right: 30,
                          left: isTabScreen ? 0 : 20,
                          bottom: 20,
                          // bottom: 0
                        }}
                      >
                        <XAxis
                          dataKey="month_name"
                          axisLine={true}
                          fontSize={"12px"}
                          domain={["auto", "auto"]}
                        />
                        <YAxis datakey="vl" axisLine={true} fontSize={"12px"} />
                        <Tooltip />
                        <Legend
                          iconType="circle"
                          iconSize={10}
                          height={20}
                          wrapperStyle={{
                            fontSize: isTabScreen ? "10px" : "14px",
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="avg_weight"
                          stroke="#4791FF"
                          strokeWidth={2}
                          dot={{
                            fill: "#4791FF",
                            r: 3,
                          }}
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="ideal_weight"
                          stroke="#02BC77"
                          strokeWidth={2}
                          dot={{
                            fill: "#02BC77",
                            r: 3,
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  {/* );
                  })} */}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>

      <StyleRoot style={{ width: "30%" }}>
        <div className="test" style={styles.fadeIn}>
          <RecentHistoryRightBox title={""} healthVitals={healthVitals} />
        </div>
      </StyleRoot>
    </div>
  );
}

export default HealthTracker;
