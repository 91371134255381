import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar } from "react-circular-progressbar";

const CircularProgressBar = ({ percentage }) => {
  const circleRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
        }
      });
    }, options);

    if (circleRef.current) {
      observer.observe(circleRef.current);
    }

    return () => {
      if (circleRef.current) {
        observer.unobserve(circleRef.current);
      }
    };
  }, []);

  return (
    <div className="circular-progress">
      <div
        ref={circleRef}
        className={`${
          isIntersecting ? "animate" : ""
        } circular-progress-animation`}
      >
        <CircularProgressbar
          value={isIntersecting ? percentage : 0}
          text={isIntersecting ? `${percentage}%` : "0%"}
          styles={{
            path: { stroke: isIntersecting ? `#fe5659` : "transparent" },
            trail: { stroke: "#f5f5f5" },
            text: { fill: "#fe5659", fontSize: "18px" },
          }}
        />
      </div>
    </div>
  );
};

export default CircularProgressBar;
