const Pathlist = [
  {
    index: "1",
    title: "1st Trimester Test",
    date: "AUG 14",
    enddate: "AUG 14",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    drname: "Dr. Jane Doe",
    drid: "Phlebologist ID",
    mobno: "+91 9876543210",
    drstatus: "Yet to be assigned",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "WEEK 1",
    drpost: "Phlebologist",
  },

  {
    index: "2",
    title: "2nd Trimester Test",
    date: "AUG 30",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drstatus: "Yet to be assigned",
    drpost: "Phlebologist",
  },

  {
    index: "3",
    title: "Quadruple Screen Test",
    date: "SEP 13",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drstatus: "Yet to be assigned",
    drpost: "Phlebologist",
  },
  {
    index: "4",
    title: "3rd Trimester Test",
    date: "SEP 28-OCT 04",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drstatus: "Yet to be assigned",
    drpost: "Phlebologist",
  },
  {
    index: "5",
    title: "Group B Streptococcus(GBS) Test",
    date: "SEP 28-OCT 04",
    teststatus: "UNSCHEDULED",
    drimg: "/Pathology/jondoe2.png",
    drname: "Dr. Jane Doe",
    drid: "",
    mobno: "",
    checkreportbtn: "Check Report",
    testincludedbtn: "Tests Included",
    scheduletestbtn: "Schedule Test",
    week: "",
    drstatus: "Yet to be assigned",
    drpost: "Phlebologist",
  },
];
export default Pathlist;
