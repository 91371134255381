import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Radium, { StyleRoot } from "radium";
import { fadeIn } from "react-animations";
import MiniDrawer from "../Components/MiniDrawer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import configData from "../../config.json";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Dropdown from "../Components/Categories/Dropdown";
import AlertShow from "../Components/AlertShow";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};


function Profile() {

  const userId = localStorage.getItem("userId");
  
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);

  useEffect(() => {
    fetch(`${configData.SERVER_API_URL}/common/get_languages`)
      .then((response) => response.json())
      .then((data) => {
        console.log("get_languages",data);
        setLanguages(data.languages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);


  const handleLanguageCheckboxChange = (languageId, isChecked) => {
    console.log("Checkbox changed:", languageId, isChecked);
    setSelectedLanguages((prevSelectedLanguages) => {
      let updatedSelectedLanguages = [...prevSelectedLanguages];

      if (isChecked) {
        // If the checkbox is checked and the languageId is not already in the array, add it
        if (!updatedSelectedLanguages.includes(languageId)) {
          updatedSelectedLanguages.push(parseInt(languageId));
        }
      } else {
        // If the checkbox is unchecked, remove the languageId from the array if it's present
        updatedSelectedLanguages = updatedSelectedLanguages.filter(
          (id) => id !== languageId
        );
      }

      // Convert the array of language IDs to a comma-separated string
      const updatedLanguagesString = updatedSelectedLanguages.join(",");
      console.log("Updated Languages:", updatedLanguagesString);

      // Update the form data with the unique language IDs
      setFormData((prevFormData) => ({
        ...prevFormData,
        languages: updatedLanguagesString,
      }));

      return updatedSelectedLanguages; // Return the updated state
    });
    // console.log("ppp", updatedLanguagesString);
  };

  const [selectedId, setSelectedId] = useState(1);
  const [profileDataMain, setProfileDataMain] = useState({});
 

  const [selectedImage, setSelectedImage] = useState();
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    // Trigger a click event on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloseUploadPopup = () => {
    setIsUploadPopupOpen(false);
  };

  const handleImageChange = (event) => {
    console.log("event.target.files?", event.target.files[0]);
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState();

  const [currentStep, setCurrentStep] = useState(1);
  const [profileComplete, setProfileComplete] = useState(false);
  const [profileCompletefinal, setProfileCompletefinal] = useState(false);
  const [hover, setHover] = useState(false);
  const handleClose = () => {
    setProfileComplete(false);
  };
  const [showAlert , setShowAlert ] = useState(false);
  const [alertMsg , setAlertMsg ] = useState('');
  const [severity , setSeverity ] = useState('');

  useEffect(() => {
    if (selectedImage) uploadImage();
  }, [selectedImage]);

  useEffect(() => {
    console.log("SEEEEE", formData);
  }, [formData]);

  useEffect(() => {
    if (profileDataMain.user?.languages) {
      const languageIdsArray = profileDataMain.user?.languages
        .split(",")
        .map((id) => parseInt(id));
      setSelectedLanguages(languageIdsArray);
    }
    
    getdetail();
  }, []);

  const getapi = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          const profileData = response.data ;
          setProfileDataMain({
            user : {
              profile_pic: profileData.user?.profile_pic,
              languages_name: profileData.user?.languages_name,
              languages :  profileData.user?.languages,
              gender: profileData.user?.gender,
            }
          });
          if (profileData.user?.languages) {
            const languageIdsArray = profileData.user?.languages
              .split(",")
              .map((id) => parseInt(id));
            setSelectedLanguages(languageIdsArray);
          }
          setFormData ({
            id : profileData.user?.id,
            fname : profileData.user?.fname,
            lname : profileData.user?.lname,
            phoneNumber: profileData.user?.phone,
            emailAddress: profileData.user?.email,
            gender: profileData.user?.gender,
            dob: profileData.user?.dob,
            bg: profileData.user?.blood_group_name,
            Timezone: profileData.user?.timezone,
            AlternateNo: profileData.user?.phone2,
            languages: profileData.user?.languages_name,
            // city: profileData.user?.city_id,
            // State: profileData.user?.state_id,
        
            mrn: profileData.medical_reg[0]?.reg_num,
            specialisation:
              profileDataMain.specialisations?.length > 0
                ? profileDataMain.specialisations
                : "",
            Regcouncil: profileData.medical_reg[0]?.reg_council,
            Regyear: profileData.medical_reg[0]?.reg_year,
        
            name: profileData.educations[0]?.name,
            college: profileData.educations[0]?.college,
            completion_year: profileData.educations[0]?.completion_year,
            experience: profileData.educations[0]?.experience,
        
            cityest: profileData.clinics[0]?.city_id,
            stateest: profileData.clinics[0]?.state_id,
            hospitalnm: profileData.clinics[0]?.name,
            localityest: "pune",
            clinicphno: profileData.clinics[0]?.phone,
            clinicmail: profileData.clinics[0]?.email,
            clinicadd: profileData.clinics[0]?.address_line_1,
        
            yourstory: profileData.user?.story,
          });


          // Now you have the profile data, you can render the profile page
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
      getapi();
  }, []);

  const Edit = () => {
    setIsEditing(true);
  };
  const Editprofile = () => {
    setIsEditing(true);
    setProfileCompletefinal(false);
    setCurrentStep(1);
  };

  const handleAlertMsg =(error_type,msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(()=>{setShowAlert(false);},2500);
  }
  
  const handleCloseAlert = () => {
    setShowAlert(false);
  }

  const save = async () => {
    setIsEditing(false);
    try {
      const response = await fetch(`${configData.SERVER_API_URL}/users/update_user_personal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: formData.id, // User ID
          fname: formData.fname, // Assuming you have firstName property in formData
          lname: formData.lname, // Assuming you have lastName property in formData
          email: formData?.emailAddress, // Assuming you have emailAddress property in formData
          phone: parseInt(formData?.phoneNumber), // Assuming you have phoneNumber property in formData
          address_line_1: formData?.addressLine1, // Assuming you have addressLine1 property in formData
          address_line_2: formData.addressLine2,
          city_id: null,
          state_id: null,
          specialization: null,
          qualifications: null,
          about_me: null,
          gender: formData.gender,
          dob: formData.dob ? moment(formData.dob).format("YYYY/MM/DD") : "",
          blood_group: parseInt(selectedId), // Assuming you're storing blood group ID
          phone2: parseInt(formData.AlternateNo),
          languages: selectedLanguages?.join(","), // Assuming languages is an array of IDs
        }),
      });
      if (response.ok) {
        var msg = "Basic Details Updated Successfully!";
      const error_color = "success";
      handleAlertMsg(error_color, msg);
        // console.log("User data saved successfully");
        // alert("Basic Details Updated Successfully!");
        // You can perform further actions if n
      } else {
        console.log("Failed to save user data");
        // Handle the error scenario if needed
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    getdetail();
  };

  const uploadImage = async () => {
    console.log("detail", selectedImage,userId);

    const form = new FormData();
    form.append("userId", userId);
    form.append("profile_pic", selectedImage);

    console.log("FORM-", form);
    let isFormDataEmpty = true;

    // Iterate through the FormData entries
    form.forEach((value, key) => {
      isFormDataEmpty = false;
      // You can log the key and value if needed
      console.log(`Key: ${key}, Value: ${value}`);
    });

    if (isFormDataEmpty) {
      console.log("FormData is empty");
    } else {
      console.log("FormData is not empty");
    }

    try {
      const response = await axios.post(
        profileDataMain?.user?.profile_pic
          ? `${configData.SERVER_API_URL}/users/update_user_profile_pic`
          : `${configData.SERVER_API_URL}/users/add_user_profile_pic`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("Profile Image Uploaded successfully");
        getdetail();
        const msg = profileDataMain?.user?.profile_pic
        ? "Profile Image Updated successfully"
        : "Profile Image Added successfully";
      
          const error_color = "success";
          handleAlertMsg(error_color, msg);
      } else {
        var msg = "Profile Image Upload Failed";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // alert("Profile Image Upload Failed");
        // console.error("Profile Image Upload", response.data.error);
      }
    } catch (error) {
      console.error("Profile Image Upload", error);
    }
  };


  const getdetail = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: userId,
      });

      if (response.data.success) {
        console.log("get single user successfully", response.data);
        setProfileDataMain(response.data);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
    // }, 60000);
  };


  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const isScreen3 = useMediaQuery({
    query: "(min-width: 2000px)",
  });

  const handleChange4 = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
    setSelectedId(value);
    console.log("???", formData);
  };
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  return (
    <div
      className="mm"
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <StyleRoot
        style={{
          height: "100%",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          backgroundColor: configData.THEME_COLORS.BACKGROUND,
        }}
      >
        <div className="test" style={styles.fadeIn}>
          <div
            style={{
              marginLeft: "4%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen
                      ? "16px"
                      : isScreen3
                      ? "42px"
                      : "32px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    marginLeft: isTabScreen ? "19px" : "20px",
                  }}
                >
                  {"Profile"}
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "60px",
                }}
              >
                <NotificationsIcon
                  sx={{
                    width: isScreen3 ? "40px" : "22.48px",
                    height: isScreen3 ? "41px" : "23.09px",
                    color: "#004AAD",
                  }}
                />
                <Dropdown
                  submenu={submenu}
                  profileData={formData}
                  onClick={() => showsubmenu()}
                  page="profile"
                />
              </div>
              {/* <Dropdown submenu={submenu} /> */}
            </div>

            <div
              style={{
                width: "95%",
                backgroundColor: "white",
                boxShadow: "0px 0px 111.295px rgba(90, 90, 90, 0.1)",
                borderRadius: "14.8393px 14.8393px 0px 0px",
                alignSelf: "center",
                marginBottom: "50px",
                display: "flex",
                flexDirection: "column",
                // display: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "70%",
                  alignItems: "center",
                  marginTop: "3%",
                  marginBottom: "2%",
                  alignSelf: "center",
                  display: profileCompletefinal === true ? "none" : "flex",
                }}
              >
              </div>
              <div
                style={{
                  marginTop: "1%",
                  height: "25vh",
                  width: "94%",
                  display: "flex",
                  alignSelf: "center",
                  // background:
                  //   'linear-gradient(93.37deg, rgba(0, 74, 173, 0.05) 3.33%, rgba(240, 88, 139, 0.025) 52.78%)',
                  border:
                    profileCompletefinal === false
                      ? "0.92346px solid rgba(203, 198, 198, 0.2)"
                      : "none",
                  borderRadius: "100px 0px 0px 0px",
                  backgroundColor: "white",
                  // profileCompletefinal === false ? "white" : "white",
                  // backgroundColor: '#CBC6C633'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5%",
                    marginTop: "14%",
                    width: "50%",
                  }}
                >
                  <div
                    className={hover ? "transparent-image" : ""}
                    style={{
                      position: "absolute",
                      borderRadius: "50%",
                      zIndex: 999,
                    }}
                    onClick={handleImageClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <img
                     src={
                      profileDataMain?.user?.profile_pic
                        ? "https://admin.myprega.com/" +
                          profileDataMain?.user?.profile_pic
                        : "/Profile/Group 1151.png"
                    } alt="nt found"
                      style={{
                        marginTop: "5px",
                        resizeMode: "contain",
                        backgroundColor: "lightblue",
                        // backgroundColor: '#CBC6C633',
                        borderRadius: "50%",
                        height: isTabScreen
                          ? "100px"
                          : isScreen3
                          ? "14vh"
                          : "160px",
                        width: "160px",
                        cursor: "pointer",
                      }}
                      onClick={handleImageClick}
                    />

                    {hover && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                          borderRadius: "50%",
                          height: "20%",
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon
                          fontSize="large"
                          sx={{
                            color: profileDataMain?.user?.profile_pic
                              ? "white"
                              : "#004AAD",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginLeft: "36%", position: "relative", top: 60 }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: isTabScreen
                          ? "20px"
                          : isScreen3
                          ? "48px"
                          : "32px",
                        color: "#333333",
                      }}
                    >
                     {formData?.fname} {formData?.lname}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {profileCompletefinal === false ? (
                  <div>
                    {currentStep === 1 && (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "10%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                ? "28px"
                                : "22px",
                              fontWeight: "600",
                            }}
                          >
                            Basic Details
                          </div>
                          <div>
                            <button
                              className="me-2"
                              onClick={Edit}
                              style={{
                                fontWeight: "400",
                                fontSize: isTabScreen ? "15px" : "20px",
                                color: "#7C9CBF",
                                cursor: "pointer",
                                border: "1px solid gray",
                                position: "relative",
                                zIndex: "99",
                                marginLeft: "auto",
                                backgroundColor: "white",
                                display: isEditing === true ? "none" : "block",
                              }}
                            >
                              <EditIcon />
                            </button>
                          </div>
                        </div>

                        <div
                          style={{
                            marginTop: "2%",
                            width: "95%",
                            paddingBottom: "1%",
                            borderRadius: "15px",
                          }}
                        >
<div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "22px"
                                    : "18px",
                                  // width: "35%",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                First Name
                              </div>

                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  marginTop: "10px",
                                  // width: "65%",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    type="text"
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                   
                                    value={formData.fname}
                                    onChange={(e) =>
                                      handleChange4(
                                        "fname",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      height: "45px",
                                      width: "300px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {formData?.fname}
                                  </div>
                                )}
                              </div>
                            </div>
                            
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginRight: "100px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "22px"
                                    : "18px",
                                  // width: "35%",
                                  position: "relative",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                               Last Name
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  // width: "65%",
                                  marginTop: "10px",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    type="text"
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                    value={formData?.lname}
                                    onChange={(e) =>
                                      handleChange4(
                                        "lname",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                      height: "45px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {formData?.lname}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>



                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "22px"
                                    : "18px",
                                  // width: "35%",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Phone Number
                              </div>

                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  marginTop: "10px",
                                  // width: "65%",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    type="number"
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                    maxLength={10}
                                    value={formData.phoneNumber}
                                    onChange={(e) =>
                                      handleChange4(
                                        "phoneNumber",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      height: "45px",
                                      width: "300px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {formData?.phoneNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                            
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginRight: "100px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "22px"
                                    : "18px",
                                  // width: "35%",
                                  position: "relative",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Email Address
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  // width: "65%",
                                  marginTop: "10px",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    type="text"
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                    value={formData?.emailAddress}
                                    onChange={(e) =>
                                      handleChange4(
                                        "emailAddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                      height: "45px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {formData?.emailAddress}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                // marginTop: "10px",
                                flexDirection: "column",
                                // marginLeft: "100px",
                                marginLeft: "5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "22px"
                                    : "18px",
                                  // width: "35%",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Date of Birth
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  // width: "65%",
                                  marginTop: "10px",

                                  borderRadius: "12px",
                                  border: "1px solid gray",
                                }}
                              >
                                {isEditing ? (
                                  <div
                                    style={{
                                      borderRadius: "15px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      overflow: "hidden",
                                      border: "1px solid gray", // Remove the border from the container div
                                    }}
                                  >
                                    <DatePicker
                                      className="custom-datepicker" // Add a custom CSS class to the DatePicker component
                                      selected={
                                        formData?.dob
                                          ? moment(formData?.dob).toDate()
                                          : null
                                      }
                                      onChange={(date) =>
                                        handleChange4(
                                          "dob",
                                          moment(date).format("YYYY-MM-DD")
                                        )
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      placeholderText="yyyy-mm-dd"
                                      showTimeSelect={false} // Disable time selection
                                    />
                                  </div>
                                ) : formData?.dob ? (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {moment(formData?.dob).format("YYYY-MM-DD")}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      padding: 6,
                                      height: "45px",
                                      width: "300px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {""}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                // marginTop: "10px",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginRight: "100px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "21px"
                                    : "17px",
                                  // width: "35%",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Alternate Contact Number
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  // width: "65%",
                                  marginTop: "10px",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                    type="number"
                                    value={formData?.AlternateNo}
                                    onChange={(e) =>
                                      handleChange4(
                                        "AlternateNo",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {formData?.AlternateNo}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              marginTop: "20px",
                              alignSelf: "center",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                // display: "flex",
                                marginLeft: "5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "21px"
                                    : "17px",

                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Languages you can speak/understand
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                }}
                              >
                                {isEditing ? (
                                  <div>
                                    {languages.map((language) => {
                                      return (
                                        <div key={language.id}>
                                          <input
                                            type="checkbox"
                                            id={language.id}
                                            value={language.id}
                                            checked={selectedLanguages.includes(
                                              language.id
                                            )}
                                            onChange={(e) =>
                                              handleLanguageCheckboxChange(
                                                language.id,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={language.id}
                                            style={{ marginLeft: "12px" }}
                                          >
                                            {language.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {profileDataMain.user?.languages_name}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                // alignItems: "center",
                                marginRight: isEditing ? "130px" : "100px",
                                marginBottom: "12px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  // width: "35%",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Gender
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                    ? "24px"
                                    : "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {isEditing ? (
                                  <>
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        width: "250px",
                                        padding: 10,
                                        borderRadius: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          style={{
                                            display: "none", // Hide the actual radio input
                                          }}
                                          type="radio"
                                          value="Male"
                                          checked={
                                            formData?.gender === "Male" ||
                                            profileDataMain.user?.gender == null
                                          }
                                          onChange={(e) =>
                                            setFormData({
                                              ...formData,
                                              gender: e.target.value,
                                            })
                                          }
                                        />
                                        <div
                                          style={{
                                            // marginLeft: "10px",
                                            textAlign: "center",
                                            width: "90px",
                                            padding: "8px",
                                            background:
                                              (formData?.gender === "Male" ||
                                                profileDataMain.user?.gender ==
                                                  null) &&
                                              formData?.gender != "Female"
                                                ? "#F0588B"
                                                : "white", // Change background color when checked
                                            borderRadius: "25px",
                                            cursor: "pointer", // Add pointer cursor to indicate interactivity
                                            color:
                                              (formData?.gender === "Male") &&
                                              formData?.gender != "Female"
                                                ? "#fff"
                                                : "#000", // Change text color when checked
                                          }}
                                        >
                                          Male
                                        </div>
                                      </label>
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          style={{
                                            display: "none", // Hide the actual radio input
                                          }}
                                          type="radio"
                                          value="Female"
                                          checked={formData?.gender === "Female"}
                                          onChange={(e) =>
                                            setFormData({
                                              ...formData,
                                              gender: e.target.value,
                                            })
                                          }
                                        />
                                        <div
                                          style={{
                                            width: "90px",
                                            padding: "8px",
                                            background:
                                              formData?.gender === "Female"
                                                ? "#F0588B"
                                                : "white", // Change background color when checked
                                            borderRadius: "25px",
                                            cursor: "pointer", // Add pointer cursor to indicate interactivity
                                            color:
                                              formData?.gender === "Female"
                                                ? "#fff"
                                                : "#000", // Change text color when checked
                                          }}
                                        >
                                          Female
                                        </div>
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid gray",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {formData?.gender}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pb-2 d-flex justify-content-end">
                         {isEditing &&  <div
                            style={{
                              fontWeight: "400",
                              // fontSize: isTabScreen ? "15px" : "20px",
                              color: "black",
                              cursor: "pointer",
                              display: isEditing === false ? "none" : "flex",
                              float: "right",

                              marginRight: "30px",
                              height: "40px",
                              width: "90px",
                              backgroundColor: "#004AAD",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "500",
                              borderRadius: "12px",
                            }}
                            onClick={save}
                          >
                            Save
                          </div> }
                          
                        </div>
                      </>
                    )}
                    
                  </div>
                ) : (
                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center mx-5 ps-5"
                      style={{ marginTop: "10%" }}
                    >
                      <div
                        style={{
                          fontSize: isTabScreen
                            ? "17px"
                            : isScreen3
                            ? "28px"
                            : "22px",
                          fontWeight: "600",
                        }}
                      >
                        Basic Details
                      </div>
                      <div>
                        <button
                          className="me-2"
                          onClick={Editprofile}
                          style={{
                            fontWeight: "400",
                            fontSize: isTabScreen ? "15px" : "20px",
                            color: "#7C9CBF",
                            cursor: "pointer",
                            border: "1px solid gray",
                            position: "relative",
                            zIndex: "99",
                            marginLeft: "auto",
                            backgroundColor: "white",
                          }}
                        >
                          <EditIcon />
                        </button>
                      </div>
                    </div>

                    <div
                      className="mx-5 px-5"
                      style={{ color: "#333333", fontSize: "17px" }}
                    >
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">First Name</div>
                        <div className="w-50">{formData?.fname}</div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Last Name</div>
                        <div className="w-50">{formData?.lname}</div>
                      </div>

                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Phone Number</div>
                        <div className="w-50">{formData?.phoneNumber}</div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Email Address</div>
                        <div className="w-50">{formData?.emailAddress}</div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Date of Birth</div>
                        <div className="w-50">
                          {" "}
                          {formData?.dob ? (
                            moment(formData?.dob).format("YYYY-MM-DD")
                          ) : (
                            <div className="">-</div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">
                          Alternate Contact Number
                        </div>
                        <div className=" w-50">
                          {" "}
                          {formData?.AlternateNo ? (
                            formData?.AlternateNo
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">
                          Languages you can speak/understand
                        </div>
                        <div className="w-50">
                          {
                          profileDataMain.user?.languages_name ? (
                            profileDataMain.user?.languages_name
                          ) : (
                            <div>-</div>
                          )}
                        </div>{" "}
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Gender</div>
                        <div className="w-50">
                          {formData?.gender ? formData?.gender : <div>-</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>

      {isUploadPopupOpen && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Upload popup content */}
          <div>
            {/* <h2>Upload Popup</h2> */}
            {/* Skip the "Upload" button */}
            {/* Include a close button to close the popup */}
            <button onClick={handleCloseUploadPopup}>Close</button>
          </div>
        </div>
      )}

      {/* Hidden file input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      {/* <Dialog
        open={profileComplete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity="success">{"Profile Saved Successfully"}</Alert>
        </DialogContent>
      </Dialog> */}
      <AlertShow alertShowHide={showAlert} alertClose={handleCloseAlert} setalertMsg={alertMsg} severity={severity} />

    </div>
  );
}

export default Profile;