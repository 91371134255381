import React, { useState, useEffect, useRef } from "react";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import AlertShow from "./AlertShow";
import moment from "moment";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CallIcon from "@mui/icons-material/Call";
function Followup({ followupdata,handleChangeConsultationView}) {
  const chatContainerRef = useRef(null);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [showAlert , setShowAlert ] = useState(false);
  const [alertMsg , setAlertMsg ] = useState('');
  const [severity , setSeverity ] = useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const heading = {
    paddingTop: "48px",
    fontSize: isTabScreen ? "24px" : "30px",
    color: configData.THEME_COLORS.PRIMARY,
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#F0588B",
    textAlign: "center",
  };
  const innerdiv = {
    display: "flex",
    paddingTop: "25px",
    alignItems: "center",
    justifyContent:"center",
  };
  const nm = {
    color: "#004AAD",
    fontSize: isTabScreen ? "16px" : "20px",
    fontWeight: "600",
    paddingLeft: "13px",
  };
  const post = {
    color: "#333333",
    paddingLeft: "13px",
    fontSize: isTabScreen ? "12px" : "16px",
    fontWeight: "400",
  };
  const consultationno = {
    color: "#004AAD",
    fontSize: isTabScreen ? "13px" : "16px",
    fontWeight: "400",
    paddingTop: "25px",
    paddingBottom: "20px",
    textAlign: "center",
    paddingLeft: "151px",
  };
  const msgbox = {
    height: "400px",
    width: "90%",
    margin: "0px auto",
    backgroundColor: "#FFF0F5",
  };
  const msg = {
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
  };
  const nmimg = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  };
  const menu = {
    width: "80px",
    height: "60px",
    backgroundColor: "gray",
    position: "absolute",
    top: "67%",
    right: "5%",
  };
  const submenu = {
    margin: "5px",
    backgroundColor: "#141124",
    fontSize: "13px",
    textAlign: "center",
    color: "white",
  };
  const inputbox = {
    height: "40px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "7px",
  };
  const innerinput = {
    border: "none",
    height: "40px",
  };
  const [showdiv, setshowdiv] = useState(false);
  const [message, setMessage] = useState("");
  const [incomingmsg, setIncomingmsg] = useState("");
  useEffect(() => {
    console.log("scrollto bot");
  }, []);
  useEffect(() => {
    setInterval(() => {
      getmsg();
    }, 1000);
    setTimeout(() => {
      scrollToBottom();
    }, 1500);
  }, []);

  const getmsg = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/inbox/get_message`, {
        sender_id: followupdata.doctor_id,
        reciever_id: followupdata.patient_id,
      });
      if (response.status === 200) {
        const messages = response.data.messages.map((msg) => {
          const utcTimestamp = msg.created_at;
          const dateObject = new Date(utcTimestamp);
          const istTimestamp = dateObject.toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          });

          return {
            ...msg,
            created_at_ist: istTimestamp,
          };
        });
        setIncomingmsg(messages.reverse());
      } else {
        var msg = "error";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // alert("error");
      }
    } catch {
      console.log("error in sending msg");
    }
  };

  const scrollToBottom = () => {
    console.log("inside scrollto bottom");
    if (chatContainerRef.current) {
      console.log("Scrolled");
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const sendMessage = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/inbox/send_message`, {
        sender_id: followupdata.patient_id,
        reciever_id: followupdata.doctor_id,
        message: message,
      });
      if (response.status === 200) {
        var msg = "msg sent successfully";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("msg sent successfully");
      } else {
        var msg = "error";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // alert("error");
      }
    } catch {
      console.log("error in sending msg");
    }

    getmsg();
    setMessage("");
  };

  const formatTime = (timestamp) => {
    const dateObject = new Date(timestamp);
    return dateObject.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const showoption = () => {
    setshowdiv(!showdiv);
  };

  const handleAlertMsg =(error_type,msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(()=>{setShowAlert(false);},2500);
  }
  
  const handleCloseAlert = () => {
    setShowAlert(false);
  }
  const modopen = (imageUrl) => {
    setOpen(true);
    setSelectedImage(imageUrl);
  };
 
  return (
    <div style={{ width: "100%" }}>
      <hr className="w-100"></hr>
      <div style={innerdiv} className="py-4">
        <div>
          <img style={{width:"75px",height:"75px"}} src={
                followupdata?.profile_pic
                  ? "https://admin.myprega.com/" +  followupdata?.profile_pic
                  : "/profile/Group-1151.png"
              } alt="not found" />
        </div>
        <div>
          <div style={nm}>
            Dr {followupdata?.doctor_firstName} {followupdata?.doctor_lastName}
          </div>
          <div style={post}>{followupdata?.drpost}</div>
        </div>
      </div>
      <hr className="w-100"></hr>
      <div style={nmimg}>
        <div style={consultationno}>
          Consultation {" " + followupdata?.consultation_number}
        </div>
        <img alt="not found"
          className="pe-3"
          src={"/DoctorConsultation/Shape.png"}
          onClick={() => showoption()}
        />
        {showdiv && (
          <div style={menu}>
            <div style={submenu}>Clear chat</div>
            <div style={submenu}>Delete</div>
          </div>
        )}
      </div>
    
      <div style={msgbox}>
      <div className="text-end my-3">
        <CallIcon
                    style={{
                      color: "#eb6391",
                      fontSize: 30,
                      cursor: "pointer",
                      marginRight: "16px",
                    }}
                  />

                  <VideoCallIcon
                    style={{
                      color: "#eb6391",
                      fontSize: 35,
                      cursor: "pointer",
                      marginRight: "16px",
                    }}  
                    onClick={handleChangeConsultationView}
                  />
                </div>
        <div style={msg} ref={chatContainerRef}>
        
        <div className="w-75 mx-auto defaultmsg p-2 py-2 my-2">
              Messages are end-to-end encrypted. No one outside of this chat, not even MyPrega, can read or listen to them.
              </div>
          {incomingmsg && incomingmsg.length > 0 ? (
                 incomingmsg.map((item, index) => {
                        const currentDate = moment(item.created_at).format('DD-MM-YYYY');
                 const prevDate = index > 0 ? moment(incomingmsg[index - 1].created_at).format('DD-MM-YYYY') : null;
                const shouldDisplayDate = currentDate !== prevDate;

    return (
      <div key={item.id}>
        {shouldDisplayDate && (
          <div className="mt-1 mx-auto text-white d-flex justify-content-center align-items-center" 
               style={{width:"90px",height:"30px",fontSize:"11px",backgroundColor:"rgb(240, 88, 139)",borderRadius:"14px"}}>
            {moment(item.created_at).format('DD MMM YYYY')}
          </div>
        )}
        
        {/* Render the message */}
        <div className="mx-2 my-2">
          {item.is_image ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => modopen(item.message)}
              className={`p-2 ${
                followupdata.patient_id === item.user_id
                  ? "rightsideclass1"
                  : "leftsideclassimg"
              }`}
            >
              <img
                src={item.message}
                className="imgmsg"
                alt="not found"
              />
              <div className="text-end"
                style={{
                  color:
                  followupdata.patient_id === item.user_id
                      ? "black"
                      : "#c4c4c4",
                  fontSize: "8px",
                }}
              >
                {moment(item.created_at).format('hh:mm A')}
              </div>
            </div>
          ) : 
          item.is_flag===1?(<div className="w-75 mx-auto defaultmsg p-2 py-2 my-2">{item.message}</div>):(<div
            className={`p-2 ${
              followupdata.patient_id === item.user_id
                ? "leftsideclass1"
                : "rightsideclass1"
            } ${
              item.message?.length > 18 ? "setwidth" : "removewidth"
            }`}
          >
            {item.message}
            <div className="text-end"
              style={{
                color:
                followupdata.patient_id === item.user_id
                    ? "black"
                    : "#c4c4c4",
                fontSize: "8px",
              }}
            >
              {moment(item.created_at).format('hh:mm A')}
            </div>
          </div>)}
        </div>
      </div>
    );
  })
                ) : (
                   <p className="text-center pt-2">No messages available.</p>
                  )}
        </div>
        <div className="mx-2" style={inputbox}>
          <img alt="not found"
            src="/DoctorConsultation/Attachbutton.png"
            className="mx-2"
            // onClick={}
          />
          <input
            className="w-75"
            type="text"
            style={innerinput}
            value={message}
            placeholder="Type your message..."
            onChange={(e) => setMessage(e.target.value)}
          />
          <img alt="not found"
            src="/DoctorConsultation/sendbtn.png"
            className="mx-2"
            onClick={sendMessage}
          />
        </div>
      </div>
    <AlertShow alertShowHide={showAlert} alertClose={handleCloseAlert} setalertMsg={alertMsg} severity={severity} />

    </div>
  );
}

export default Followup;
