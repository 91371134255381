import React from "react";
import { useState, useEffect } from "react";
import configData from "../../config.json"
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import AlertShow from "./AlertShow";


function Times(props) {
  console.log("SSSSS",props)
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [event, setEvent] = useState(null);
  const [info, setInfo] = useState(false);
  const [schedulingtest, setschedulingtest] = useState(false);
  const [isHover, setIsHover] = useState(1000);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const [timings, setTimings] = useState();
  const [timingId, setTimingId] = useState();

  useEffect(() => {
    getTimings();
    if (props.consultData?.scheduled_date) {
      setInfo(true);
      setschedulingtest(true);
    }
  }, []);

  const handleMouseEnter = (e) => {
    setIsHover(e);
  };
  const handleMouseLeave = () => {
    setIsHover(1000);
  };

  function displayInfo(e, data) {
    console.log("@@@@@@@@@@", data,e.target.innerText);
    setInfo(true);
    setTimingId(data?.id);
    setEvent(e.target.innerText);
  }
  const handleclick = (props) => {
    console.log("click schedule");
    setschedulingtest(!schedulingtest);
    scheduleConsultation();
  };
  const setBack = (isAlreadyScheduled) => {
    if (isAlreadyScheduled) setschedulingtest(false);
    setInfo(false);
  };
  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const getTimings = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/consultation/get_doctor_timings`,
        {}
      );

      if (response.data?.success) {
        console.log("Dr timings-", response.data?.doctor_timing);
        setTimings(response.data?.doctor_timing);
      }
    } catch (error) {
      console.error("Error get timings:", error);
    }
  };

  const scheduleConsultation = async () => {
    var msg = "Consultation Scheduled!";

    let data = props.consultData;
    console.log(
      "CCSCSC-",
      timingId,
      props.formattedDate,
      data?.patient_id,
      data?.id,
      data?.pregnancy_week
    );

    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/update_consultation`,
        {
          consultation_type: data.consultation_type === "Online" ? 1 : 2,
          doctor_timing_id: timingId,
          paitent_id: data?.patient_id,
          week: data?.pregnancy_week,
          consultation_id: data?.id,
          scheduled_date: props.formattedDate,
        }
      );

      if (response.data?.success) {
        console.log("Updated Consultation-", response.data);
        var msg = "Consultation Scheduled!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        props.setRenderconsult(true);
        // alert("Consultation Scheduled");
      }
    } catch (error) {
      console.error("Error in update Consult:", error);
    }
  };

  return (
    <>
      {info ? (
        schedulingtest === true ? (
          <>
          <div className="d-flex align-items-center justify-content-center"  style={{
                paddingTop: "55px"}}>
          {/* <div><img
                src={"/Pathology/Arrow.png"}
                style={{ width: "30px", height: "20px", cursor: "pointer" }}
                onClick={() => setBack()}
              ></img><span style={{fontSize:"10px"}}>Reschedule</span>
              </div> */}
            <div
              style={{
                textAlign: "center",
                fontSize: isTabScreen ? "18px" : "24px",
                fontWeight: "400px",
                color: "#F0588B",
               
              }}
            >
              {props.trimesternm}
            </div>
            </div>
           
            <div
              style={{
                border: "0.5px solid #E8E7E7",
                height: isTabScreen ? "200px" : "280px",
                width: "95%",
                margin: isTabScreen ? "10px auto" : "20px auto",
                borderRadius: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: isTabScreen ? "10px" : "20px",
                }}
              >
                {/* <img
                  src={"/Pathology/Arrow.png"}
                  style={{
                    width: "30px",
                    height: "20px",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => setBack(1)}
                ></img> */}
                <div
                  style={{
                    flex: "1",
                    fontSize: isTabScreen ? "14px" : "20px",
                    fontWeight: "500",
                    color: "#004AAD",
                    textAlign: "center",
                  }}
                >
                  {props.date}
                </div>
              </div>

              <div
                className="d-flex justify-content-between px-1"
                style={{ paddingTop: "17px" }}
              >
                <div className="text-center pt-3 px-md-1 px-lg-2">
                  <img
                    src={"/Pathology/scheduleimg.png"}
                    style={{ width: "37px", height: "56px" }}
                  ></img>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: isTabScreen ? "12px" : "16px",
                      fontWeight: "600",
                    }}
                  >
                    {props.drpost}
                  </div>
                  <div
                    style={{
                      paddingTop: "5px",
                      fontSize: isTabScreen ? "8px" : "10px",
                      fontWeight: "500",
                      color: "#B5B5C3",
                    }}
                  >
                    {props.drstatus}
                  </div>
                </div>
                <div className="px-2 px-lg-3">
                  <div>
                    <div
                      style={{
                        fontSize: isTabScreen ? "12px" : "16px",
                        fontWeight: "500",
                      }}
                    >
                      Tests Included
                    </div>
                    <div
                      style={{
                        fontSize: isTabScreen ? "12px" : "16px",
                        fontWeight: "400",
                        color: "#868686",
                      }}
                    >
                      HB, GTT, CBC, Urine Test
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: isTabScreen ? "12px" : "16px",
                      fontWeight: "500",
                      paddingTop: "60px",
                    }}
                  >
                    {" "}
                    Timing
                  </div>
                  <div
                    style={{
                      fontSize: isTabScreen ? "12px" : "16px",
                      fontWeight: "400",
                      color: "#868686",
                    }}
                  >
                    {" "}
                    {event ? event : props?.consultData?.timing}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex align-items-center px-3"
              style={{ paddingTop: "55px" }}
            >
              <img
                src={"/Pathology/Arrow.png"}
                style={{ width: "30px", height: "20px", cursor: "pointer" }}
                onClick={() => setBack()}
              ></img>
              <div
                style={{
                  fontSize: isTabScreen ? "18px" : "24px",
                  fontWeight: "400",
                  color: "#F0588B",
                  paddingLeft: isTabScreen ? "24px" : "60px",
                }}
              >
                Book Timing
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                border: "0.5px solid #E8E7E7",
                height: isTabScreen ? "140px" : "180px",
                width: "95%",
                margin: "10px auto",
              }}
            >
              <div
                style={{
                  paddingTop: "20px",
                  fontSize: isTabScreen ? "10px" : "12px",
                  fontWeight: "500",
                  color: "#004AAD",
                  textAlign: "center",
                }}
              >
                {props.date}
              </div>
              <div
                style={{
                  fontSize: isTabScreen ? "16px" : "20px",
                  fontWeight: "500",
                  paddingTop: isTabScreen ? "10px" : "20px",
                  paddingLeft: "32px",
                }}
              >
                {props.trimesternm}
              </div>

              <div className="d-flex px-md-2 px-lg-5 pt-md-4 align-items-center justify-content-between">
                <div
                  style={{
                    fontSize: isTabScreen ? "18px" : "24px",
                    fontWeight: "600",
                    color: "#58CF99",
                  }}
                >
                  {event ? event : props?.consultData?.timing}
                </div>
                <div>
                  <button
                    style={{
                      width: isTabScreen ? "70px" : "100px",
                      height: "32px",
                      backgroundColor: "#004AAD",
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
                      borderRadius: "6px",
                      border: "none",
                      fontSize: isTabScreen ? "9px" : "12px",
                      fontWeight: "600",
                      color: "white",
                    }}
                    onClick={(props) => handleclick(props)}
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <>
          <div
            className="mb-2"
            style={{
              paddingTop: "20px",
              fontSize: isTabScreen ? "16px" : "20px",
              fontWeight: "500",
              color: "#004AAD",
              textAlign: "center",
              paddingBottom: "25px",
            }}
          >
            Timings on {props.date}
          </div>
          <div className="mt-1" style={{ width: "96%", margin: "0px auto" }}>
            <div className="container">
              <div className="row">
                {timings?.map((times, index) => {
                  return (
                    <div className="times col-3 text-center px-0 px-md-1 px-lg-2">
                      <button
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                        style={{
                          width: isTabScreen ? "40px" : "65px",
                          height: isTabScreen ? "34px" : "54px",
                          // backgroundColor: "#EEEEEE",
                          border: "none",
                          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
                          marginBottom: isTabScreen ? "12px" : "25px",
                          borderRadius: "4px",
                          backgroundColor:
                            isHover === index ? "green" : "#EEEEEE",
                          color: isHover === index ? "white" : "black",
                          fontSize: isTabScreen ? "11px" : "14px",
                        }}
                        onClick={(e) => displayInfo(e, times)}
                      >
                        {times?.start_time}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </>
  );
}

export default Times;
