const ragalist = [
  {
    testname: "Pregnancy Raga 1",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/raga1.mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 2",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Ahir Bhairav - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 3",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Bageshwari - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 4",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Bairagi Bhairav - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 5",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Brindabani Sarang - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 6",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Hindol - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 7",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Jaunpuri - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 8",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Kalavati - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 9",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Komal Rishabh Asavari (Gat) - Pt . Ramnarain - (Raag.Fm)",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 10",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Madhmad Sarang - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 8",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Malkauns - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 11",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga Yamuna Kalyani - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 12",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Raga_Todi - (Raag.Fm).mp3",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    testname: "Pregnancy Raga 13",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/Vasudevayani Raga Kalyani G.N. Balasubramaniam",
    infohead: "Lorem ipsum lorem dolor",
    info: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
];
export default ragalist;
