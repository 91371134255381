import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AlertShow from "../AlertShow";
import configData from "../../../config.json";
const doctorTypes = [
  "Gynaecologist",
  "Doctor Of Medicine",
  "Sonography Lab",
  "Pathalogy Lab",
];

let noteshead;
let notesinfo;
let subj;
let flag;
function Notes({ setExpand, setSelected }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
    overflowY: "scroll",
  };
  const back = {
    fontWeight: "600",
    fontSize: "12px",
    color: "#004AAD",
    cursor: "pointer",
  };
  const notes = {
    paddingTop: "10px",
    paddingBottom: "20px",
    color: "#F0588B",
    fontWeight: "600",
    fontSize: isTabScreen ? "20px" : "24px",
  };
  const square = {
    // width: isTabScreen ? "180px" : "220px",
    // height: isTabScreen ? "180px" : "220px",
    // background: "red",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    flexDirection: "column",
  };
  const innersquare = {
    display: "flex",
    background: "#CCCCCC",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "270px",
    height: "200px",
  };
  const txt = {
    fontWeight: "500",
    fontSize: "14px",
    color: "#333333",
    paddingTop: "20px",
  };
  const textinp = {
    background: "rgba(238, 238, 238, 0.94)",
    borderRadius: "10px",
    width: "519px",
    height: "57px",
  };
  const textinp2 = {
    background: "rgba(238, 238, 238, 0.94)",
    borderRadius: "10px",
    width: "90%",
  };
  const save = {
    width: "124px",
    height: "37.34px",
    background: "#004AAD",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    color: "white",
    border: "none",
  };
  const imgtxt = {
    width: "200px",
    height: "300px",
  };
  const notesheading = {
    paddingBottom: "30px",
    fontWeight: "400",
    fontSize: "20px",
  };
  const notesinformation = {
    fontWeight: "400",
    fontSize: "20px",
    paddingRight: "40px",
    height: "300px",
    overflowY: "auto",
  };
  const doctype = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const [plusclick, setplusclick] = useState(false);
  const [focused, setFocused] = useState(false);
  const [saveclick, setsaveclick] = useState(false);
  const [mynotes, setmynotes] = useState(false);
  const [drType, setDrtype] = React.useState("");
  const [editnote, setEditnote] = useState([]);
  const [patientnotes, setPatientnotes] = useState();
  const [notessub, setNotessub] = useState();
  const [subj, setSubj] = useState("");
  const [content, setContent] = useState("");
  const [dr, setDr] = useState("");
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const handleChange1 = (event) => {
    setDrtype(event.target.value);
    setDr(event.target.value);
  };
  const onFocus = () => {
    setFocused(true);
  };
  const fun = () => {
    setplusclick(true);
    flag = 0;
  };

  useEffect(() => {
    getpatientnotes();
  }, [patientnotes]);
  const getpatientnotes = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_patient_notes`,
        {
          patient_id: localStorage.getItem("userId"),
        }
      );
      if (response.data) {
        setPatientnotes(response.data.Notes);
      }
    } catch {
      console.log("Error......");
    }
  };
  const editnotes = (item) => {
    console.log("++++", item);
    flag = 1;
    setEditnote(item);
    setSubj(item.title);
    setContent(item.content);
    setDr(item.doctor_type);
    setId(item.id);
  };
  const setdata = async () => {
    // setsaveclick(true);
    setplusclick(false);
    let apiUrl;
    let requestBody;
    try {
      if (flag == 0) {
        apiUrl = `${configData.SERVER_API_URL}/patient/add_patient_notes`;
        requestBody = {
          title: subj,
          doctor_type: drType,
          content: notesinfo,
          patient_id: localStorage.getItem("userId"),
        };
      }
      if (flag == 1) {
        apiUrl = `${configData.SERVER_API_URL}/patient/update_patient_notes`;
        requestBody = {
          title: subj,
          doctor_type: drType,
          content: notesinfo,
          id: id,
          patient_id: localStorage.getItem("userId"),
        };
      }

      const response = await axios.post(apiUrl, requestBody);
      if (response.data) {
        var msg = "Notes saved successfully";
        const error_color = "success";
        handleAlertMsg(error_color, msg);

        // alert("notes saved successfully");
        // console.log("NNNNooootteess", response.data);
      }
    } catch {
      console.log("Error......");
    }
  };
  const Settingstates = () => {
    setsaveclick(true);
    // setmynotes(false);
  };
  const shownotes = (item) => {
    setData(item);
    setmynotes(true);
  };
  const gettxt = (e) => {
    noteshead = e.target.value;
    setSubj(noteshead);
    // setNotessub(noteshead);
  };
  const gettxtnotes = (e) => {
    notesinfo = e.target.value;
    setContent(notesinfo);
    // console.log("@#@#@#@#@#@#", notesinfo);
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return plusclick === true ? (
    <div style={wrapper}>
      {saveclick === true ? (
        mynotes === true ? (
          <>
            <div style={back} onClick={() => Settingstates()}>
              Back
            </div>
            <div style={doctype}>
              <div style={notes}>Notesv</div>
              <div className="d-flex" style={{ marginRight: "50px" }}>
                <div>Doctor Type:</div>
                <div className="ms-2">{data.doctor_type}</div>
              </div>
            </div>
            <div style={notesheading}>{data.title}</div>

            <div style={notesinformation}>{data.content}</div>
          </>
        ) : (
          // <>
          //   <div
          //     style={back}
          //     onClick={() => {
          //       setSelected(false);
          //       setExpand(true);
          //     }}
          //   >
          //     Back
          //   </div>
          //   <div style={notes}>Notesdisplayall</div>
          //   <div className="container p-0 m-0">
          //     <div className="row">
          //       {patientnotes?.map((item) => (
          //         <div className="col-3">
          //           <div style={imgtxt}>
          //             <img
          //               src="/GarbhaSanskar/notes.png"
          //               onClick={() => shownotes(item)}
          //             ></img>
          //             <div className="text-center">{item.title}</div>
          //           </div>
          //         </div>
          //       ))}
          //     </div>
          //   </div>
          // </>
          <div></div>
        )
      ) : (
        <>
          <div
            style={back}
            onClick={() => {
              setSelected(false);
              setExpand(true);
            }}
          >
            Back
          </div>
          <div style={notes}>Notes</div>
          <form>
            <TextField
              id="outlined-basic"
              placeholder="Subject"
              variant="outlined"
              style={textinp}
              onFocus={onFocus}
              value={subj}
              onChange={(e) => gettxt(e)}
              className="my-4"
            />
            <br></br>
            <Box
              style={{
                borderRadius: "10px",
                backgroundColor: "rgba(238, 238, 238, 0.94)",
                width: "519px",
                // height: "57px",
              }}
            >
              <FormControl fullWidth size={"small"}>
                <Select
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  style={{
                    backgroundColor: "#F2F2F2",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dr}
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value="">Doctor Type</MenuItem>
                  {doctorTypes?.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <br></br>
            <TextField
              id="outlined-basic"
              placeholder="Type your notes here.."
              variant="outlined"
              multiline
              rows={6}
              value={content}
              style={textinp2}
              onFocus={onFocus}
              onChange={(e) => gettxtnotes(e)}
              className="my-4"
            />
          </form>
        </>
      )}

      {focused === true && saveclick === false ? (
        <div className="text-center">
          <button style={save} onClick={() => setdata()}>
            SAVE
          </button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  ) : (
    <div style={wrapper}>
      <div
        style={back}
        onClick={() => {
          setSelected(false);
          setExpand(true);
        }}
      >
        Back
      </div>
      <div style={notes}>Notes123</div>
      <div className="container p-0 m-0">
        <div style={innersquare}>
          <img
            src="/GarbhaSanskar/plus.png"
            style={{
              height: isTabScreen ? "40px" : "60px",
              width: isTabScreen ? "40px" : "60px",
              cursor: "pointer",
            }}
            onClick={() => fun()}
            alt="PlusImage"
          ></img>
          <div style={txt}>Create New Note</div>
        </div>
      </div>
      <div className="container p-0 m-0">
        <div className="row">
          {patientnotes?.map((item) => {
            return (
              <>
                <div style={square} className="col-3 my-2">
                  <div style={innersquare} onClick={() => editnotes(item, 1)}>
                    <img
                      // className="pt-2"
                      src="/GarbhaSanskar/notes.png"
                      style={{
                        height: isTabScreen ? "40px" : "60px",
                        width: isTabScreen ? "40px" : "60px",
                        cursor: "pointer",
                      }}
                      onClick={() => fun()}
                    ></img>
                    <div>{item?.title}</div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}

export default Notes;
