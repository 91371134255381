import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../App.css";
import "../../Slider.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CounterUpComponent from "../components/CounterUpComponent";
import CircularProgressBar from "../components/CircularProgressBar";
import { webUrl, webUrlGarbhsanskar } from "../Utility/config";
import Radium, { StyleRoot } from "radium";
import { fadeInLeft } from "react-animations";
import { slideOutRight } from "react-animations";
import Button from "react-bootstrap/Button";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useMediaQuery } from "react-responsive";
import SearchIcon from '@mui/icons-material/Search';
import configData from "../../config.json";
function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const searchParams = new URLSearchParams(location.search);
  const getUserid = localStorage.getItem("userId");
  // const userIdValue;
  const user=localStorage.getItem('userId');

  const [count, setCount] = useState(0);
  const [patientdata, setPatientdata] = useState([]);
 const [babywt,setBabywt]=useState([]);
 const [ht,setHeight]=useState();
  const [currentPara, setCurrentPara] = useState(1);
  const [weekwisedata,setWeekwisedata]=useState([]);
  const [daywisedata,setDaywisedata]=useState([]);
  const [length,setLength]=useState()
  const [weight,setWeight]=useState()
  const [kg,setKg]=useState();
  const [cm,setCm]=useState();
  
  const firstParagraphRef = useRef(null);
  const secondParagraphRef = useRef(null);
  const thirdParagraphRef = useRef(null);
  const fourthParagraphRef = useRef(null);
useEffect(()=>{
  console.log("WWWWW",weight)
},[weight]);
  useEffect(() => {
    const innerImageElement = document.getElementById("inner-image");

    if (
      innerImageElement &&
      (currentPara === 1 ||
        currentPara === 2 ||
        currentPara === 3 ||
        currentPara === 4)
    ) {
      innerImageElement.classList.add("fade-out");

      // Set a timeout to remove the show class after the animation duration
      setTimeout(() => {
        innerImageElement.classList.remove("fade-out");
      }, 400); // 1 second (adjust this to match your animation duration)
    }
  }, [currentPara]);

  // const styles = {
  //   fadeIn: {
  //     animation: "x 1s",
  //     animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
  //   },
  // };

  const styles = {
    slideInRight: {
      animation: "x 1s",
      animationName: Radium.keyframes(slideOutRight, "slideOutRight"),
    },
  };

  const containerStyle = {
    marginLeft: 60,
    marginTop: 60,
    position: "sticky",
    top: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh", // Set an appropriate height for the container
  };

  const imageStyle = {
    width: "80%",
    maxWidth: "100%", // Ensure the image does not exceed its natural size
    opacity: currentPara === 1 ? 1 : 0, // Apply fade-in effect for currentPara === 1
    transition: "opacity 0.5s ease-in-out",
  };

  const [userid, setUserid] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const firstParagraph = firstParagraphRef.current;
      const secondParagraph = secondParagraphRef.current;
      const thirdParagraph = thirdParagraphRef.current;
      const fourthParagraph = fourthParagraphRef.current;

      const firstParagraphIsInViewport = isElementInViewport(firstParagraph);
      const secondParagraphIsInViewport = isElementInViewport(secondParagraph);
      const thirdParagraphIsInViewport = isElementInViewport(thirdParagraph);
      const fourthParagraphIsInViewport = isElementInViewport(fourthParagraph);

      if (firstParagraphIsInViewport) {
        setCurrentPara(1);
      } else if (secondParagraphIsInViewport) {
        setCurrentPara(2);
      } else if (thirdParagraphIsInViewport) {
        setCurrentPara(3);
      } else if (fourthParagraphIsInViewport) {
        setCurrentPara(4);

        // console.log("Fourth paragraph is in the viewport.");
        // console.log("First paragraph is out of the viewport.");
      } else {
        // console.log("Both paragraphs are out of the viewport.");
      }
    };

    const isElementInViewport = (element) => {
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return rect.top < window.innerHeight && rect.bottom > 0;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //   const horizontalScene = new ScrollMagic.Scene({
  //     triggerElement: ".horizontal-trigger",
  //     triggerHook: 0.8,
  //     reverse: false,
  //   })
  //     .setTween(horizontalTween)
  //     .addIndicators() // Enable indicators for debugging
  //     .addTo(controller);

  //   // Clean up when the component unmounts
  //   return () => {
  //     controller.destroy(true);
  //   };
  // }, []);
  useEffect(() => {
    const targetCount = 162;
    let currentCount = 0;
    const increment = Math.ceil(targetCount / 60); // Adjust the speed as needed

    const timer = setInterval(() => {
      if (currentCount < targetCount) {
        currentCount += increment;
        setCount(currentCount);
      } else {
        clearInterval(timer);
        setCount(targetCount);
      }
    }, 1000 / 60); // 60 FPS

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // const userIdValue = userIdParam ? userIdParam : 0;
   
    // setUserid(getUserid);

    // console.log("getUserid", userIdParam);
    // console.log("getUserid", userIdValue);
    console.log("getUserid", getUserid);
    // if (userIdValue !== "0") {
    //   localStorage.setItem("userId", userIdValue);
    // } else if (userIdValue === "0") {
    //   localStorage.removeItem("userId");
    //   localStorage.clear();
    // }
    
    getsinglepatientdetail(getUserid);
    gettinggarbhsanskardata();
  }, []);
  // useEffect(() => {
  //   getsinglepatientdetail(userid);
  //   gettinggarbhsanskardata();
  // }, []);
  // fetchUserData(userIdValue)
  //   .then((data) => {
  //     setUserData(data);
  //   })
  //   .catch((error) => {
  //     // Handle the error
  //   });

  // };
  const getsinglepatientdetail = async (id) => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: id,
      });

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);
        setPatientdata(response.data.user);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const sendmail = () => {
    var link =
      "mailto:info@myprega.com" +
      "?cc=" +
      "&subject= About subscription" +
      "&body=" +
      encodeURIComponent("\n");
    window.location.href = link;
  };

  const [userData, setUserData] = useState(null);
  // Now you can use 'userId' in your component

  const [counterValue, setCounterValue] = useState(0);

  // const text = ["Mom", "Mom Buddy", "Medical Expert", "Mom to Be"];
  const [counter, setCounter] = useState(0);
  // useEffect(()=>{
  //   gettinggarbhsanskardata();
  // },[]);

  const gettinggarbhsanskardata=async()=>{
    console.log("inside api calling",localStorage.getItem("userId"));
    try {
      console.log("calling api......")
      const response = await axios.post(`${configData.SERVER_API_URL}/patient/get_garbhsanskar_data`, {
        patientId: localStorage.getItem("userId"),
      });
      console.log("response....",response);
      if (response.data.success) {
        console.log("garbhsanskar data", response.data);
        setWeekwisedata(response.data?.weekwiseData[0]);
        setDaywisedata(response.data?.weekwiseData[0]?.dayWiseData[0])
        const height=response.data?.weekwiseData[0]?.length;
        setHeight(height);
        if(height)
        {
        const [length,cm]=height?.split(" ");
        setLength(length);
        setCm(cm);
        }
        const weightbaby=response.data?.weekwiseData[0]?.weight;
        setBabywt(weightbaby);
        console.log("weight",weightbaby)
        if(weightbaby)
          {
            const str=weightbaby;
            const parts=str?.split(" ");
            if(parts.length>2)
              {
                const weight = parts[0]+parts[1]+parts[2]; 
                const kg = parts[3]; 
                setWeight(weight);
                setKg(kg);
              }
              else{
                const weight = parts[0]; 
                const kg = parts[1];
                setWeight(weight);
                setKg(kg);
              }
           
            console.log("weight1",parts);
            console.log("weight12",kg);
           
          }
        
      } else {
        console.error(
          "Error:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error.message);
      gettinggarbhsanskardata();
    }

  }
  const [inputValue, setInputValue] = useState('');
  const [isBlinking, setIsBlinking] = useState(true);
  const lines = [
    'Every day in 2020, almost 800 women died from preventable causes related to pregnancy and childbirth',
    ' Women die as a result of complications during and following pregnancy. Most of these complications develop during pregnancy, and most are preventable or treatable.',
    ' Indias infant mortality rate is 3.5%! (Developed countries have <0.2%), and its due to poor healthcare, both during the pregnancy and during the birth.',
    'The leading causes of maternal death were obstetric haemorrhage (47%), pregnancy-related infection (12%), and hypertensive disorders of pregnancy (7%).',
  ];
  const typingSpeed = 50; // Adjust typing speed
  const deletingSpeed = 50; // Adjust deleting speed
  const pauseDuration = 1500; // Pause before deleting

  // Using useRef to persist values across renders
  const lineIndexRef = useRef(0);
  const charIndexRef = useRef(0);
  const isDeletingRef = useRef(false);

  useEffect(() => {
    const type = () => {
      const currentLineIndex = lineIndexRef.current;
      const currentCharIndex = charIndexRef.current;
      const isDeleting = isDeletingRef.current;

      const currentLine = lines[currentLineIndex];
      const textLength = currentLine.length;

      if (!isDeleting && currentCharIndex < textLength) {
        setInputValue((prev) => prev + currentLine.charAt(currentCharIndex));
        charIndexRef.current += 1;
      } else if (isDeleting && currentCharIndex > 0) {
        setInputValue((prev) => prev.slice(0, -1));
        charIndexRef.current -= 1;
      }

      if (!isDeleting && currentCharIndex === textLength) {
        setTimeout(() => {
          isDeletingRef.current = true;
        }, pauseDuration);
      }

      if (isDeleting && currentCharIndex === 0) {
        isDeletingRef.current = false;
        lineIndexRef.current = (currentLineIndex + 1) % lines.length; // Move to the next line
        setTimeout(() => {
          charIndexRef.current = 0; // Start typing new line from the beginning
          setInputValue(''); // Clear textarea before typing new line
        }, 0); // No delay before typing new line
      }

      const timeout = setTimeout(type, isDeleting ? deletingSpeed : typingSpeed);

      return () => clearTimeout(timeout);
    };

    const initialTimeout = setTimeout(type, 500);

    return () => clearTimeout(initialTimeout);
  }, []);

  useEffect(() => {
    const blink = setInterval(() => {
      setIsBlinking((prev) => !prev);
    }, 500);

    return () => clearInterval(blink);
  }, []);

  // const change = () => {
  //   setCounter((prevCounter) => (prevCounter + 1) % text.length);
  // };
  
  const openapp = () => {
    console.log("@!@!", patientdata);
    localStorage.setItem('isPaidUser',patientdata?.payment_status===true ? true:false)
    const currentDate=new Date();
    const createdDate=new Date(patientdata?.created_date);
    const differenceInTime = currentDate - createdDate;
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const isWithinSevenDays = differenceInDays <= 7;
    if (patientdata && typeof patientdata.payment_status !== "undefined") {
      if(isWithinSevenDays)
        {
            if (patientdata.payment_status === true) {
            window.location.href = `${webUrl}`;
           } else {
            window.location.href = `${webUrlGarbhsanskar}`;
          }
        }
        else
        {
          navigate("/patient/dashboard");
        }
    } else {
        console.log("payment_status not available in patientdata");
        getsinglepatientdetail(userid);
       openapp();
    }
};



  return (
    <>
      <Header userid={user} />
      {/* <div className="containertt p">
        <section className="herosection pt-3">
          <img src="/heroimg.png" className="img-fluid" alt="nt found" />
        </section>
      </div> */}

    <div className="container-fluid">
      <div className="row">
      <div className="col-12 col-md-5 order-md-2">
            <video autoPlay muted loop className="pt-5">
              <source src="/heroimgvideo.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
        </div>
      <div className="col-12 col-md-7 order-md-1 pt-5 mt-5">
          <h1 className="text-danger pt-5 fw-bolder ps-5 headlinehero" >
            We make your pregnancy care <br></br>better, because we know mom’s <br></br>like no one else! 
          </h1>
          <h1 className="ps-5 pt-5 fw-bolder headlinesubhero">We know your pregnancy is unique, and we have the specialized care you need.</h1>
          <Link
                  style={{ cursor: "pointer", color: "white" }}
                  to="/cherishcare"
                  className="mt-3 ms-5 buyhome"
                >
                  Buy Now
                </Link>
          <div className="pt-5 ps-5 ">
            <div  className="inputboxw position-relative">
            <textarea
      rows={2}
      className="textareastyle  ps-4 d-flex justify-content-center align-items-center fw-bolder"
      style={{ width: '100%', paddingRight: '80px', fontSize: '15px', height: '70px', border: '3px solid blue', borderRadius: '40px' }}
      value={inputValue}
      readOnly
    />
           <div className="searchhero">
            <SearchIcon className="position-absolute right-0" style={{top:"26%",right:"20px",fontSize:"40px",color:"white"}}/></div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <br></br>
      <div className="container-fluid mt-3">
        <div className="containertt">
          <div className="drawborder mt-4">
            <div className="p-3">
              <div
                className="row align-items-center mx-2"
                style={{
                  backgroundColor: "#EDFFFE",
                }}
              >
                <div className="col-12 col-md-5">
                  <div className="innerdiv1">
                    <div className="one position-relative mb-4 px-3">
                      <h6 className="fw-bolder mb-0">
                        Weight of your baby today is:
                      </h6>
                      {user !== '0' && user !== null && user !== 'null' ? (<p></p>):
                      (<div
                        className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center"
                      >
                        <i className="bi bi-lock-fill ll"></i>
                      </div>)}
                      {user !== '0' && user !== null &&  userid !== 'null' && 
                        (babywt===null ? <span className="ps-4">  <HorizontalRuleIcon /></span> :<div className="ps-4">{weight}{kg}</div>)
                      }
                      
                    </div>
                    <div className="two position-relative my-4 px-3">
                      <h6 className="fw-bolder mb-0">
                        Height of your baby today is:
                      </h6> {user === '0' || user=== null || user=== 'null' ? (<span></span>):(ht===null ? <span className="ps-4">  <HorizontalRuleIcon /></span> :<div className="ps-4">{length}{cm}</div>)}
                       {user !== '0' && user != null &&  user !== 'null' ? (<p></p>):
                      (<div
                        className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center"
                      >
                        <i className="bi bi-lock-fill ll"></i>
                      </div>)}
                    </div>
                    <div className="three position-relative mt-4 px-3">
                      <h6 className="fw-bolder mb-0">Harmone info:</h6>
                      {user !== '0' && user !== null &&  user !== 'null' ? (<p></p>):
                      (<div
                        className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center"
                      >
                        <i className="bi bi-lock-fill ll"></i>
                      </div>)}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 text-center">
                  <div className="innerdiv2 position-relative">
                  {user !== '0' && user !== null &&  user !== 'null' ? (<p></p>):
                      (<><div  style={{
                        borderRadius: "50%",
                      }}
                        className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center"
                      >
                        <i className="bi bi-lock-fill ll"></i>
                      </div><div
                        style={{
                          fontSize: isTabScreen ? "12px" : "22px",
                          fontWeight: "500",
                          marginTop: isTabScreen ? "6px" : "80px",
                          marginLeft: "8%",
                        }}
                      >
                           </div></>)}
                      {user !== '0' && user !== null &&  user !== 'null' ?
                        (weekwisedata?.shape_image===null?
                        <p> <HorizontalRuleIcon /></p>: 
                        <>
                        {weekwisedata?.shape_image==undefined ?  <p><HorizontalRuleIcon /></p>:<img
                          className="mb-2 img-fluid mx-auto"
                          src={"https://admin.myprega.com/baby-shape/"+weekwisedata?.shape_image}
                          style={{
                            // height:  "160px",
                            height:"100%",width:"100%",borderRadius:"50%"
                          }}
                          alt="straw"
                        />}
                       <div
                        style={{
                          fontSize: isTabScreen ? "12px" : "22px",
                          fontWeight: "500",
                          marginTop: isTabScreen ? "6px" : "30px",
                          marginLeft: "8%",
                        }}
                      >

                      </div></>):(<h6 className="fw-bolder mb-0">shape</h6>)
                      }
                   
                  </div>
                  <div
                          style={{
                            fontSize: isTabScreen ? "12px" : "22px",
                            fontWeight: "500",
                            marginTop: isTabScreen ? "6px" : "30px",
                           
                          }}
                        >
                          Baby Shape
                        </div>
                </div>
                <div className="col-12 col-md-4 pt-5 pb-4  pt-lg-0">
                  <div className="innerdiv3 mt-lg-0 p-4 position-relative">

                    {user !== '0' && user !== null &&  user !== 'null' ?
                        (<span> {daywisedata?.description}</span>):(<h6 className="fw-bolder pl-3">Fact of the day</h6>)
                      }
                  {user !== '0' && user !== null &&  user !== 'null' ? (<p></p>):
                      (<div
                        className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center"
                      >
                        <i className="bi bi-lock-fill ll"></i>
                      </div>)}
                      </div>
                </div>
                {/* <h3 className="text-center mx-auto pb-4">
                  your baby shape is like
                </h3> */}
              </div>
              {user === '0' || user=== null || user=== 'null' ? (
                <>
                  <div className="row py-3 mx-auto">
                    <div className="text-center">
                      <button
                        className="buttongrid"
                        onClick={() =>
                          navigate(
                            "/patient/loginform"
                          )
                        }
                      >
                        Log In
                      </button>
                      <button
                        className="buttongrid ml-4"
                        onClick={() =>
                          navigate(
                            "/patient/Signup"
                          )
                        }
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center my-4">
                  <div>
                    <img
                      src="/dashboardimage.png"
                      className="imgdashboard" alt="not found"
                    ></img>
                    <br></br>
                    <Button
                      onClick={openapp}
                      className="mt-2"
                      style={{
                        backgroundColor: "#0000ff",
                        fontWeight: "bolder",
                      }}
                    >
                      Go to Dashboard
                    </Button>
                  </div>
                </div>
              )}

              <h6 className="fw-bolder text-center">
                For making your pregnancy personalised and awesome!
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="containertt my-5">
        <h1 className="text-left fw-bolder mt-5 mb-3 mb-lg-5 increasefont">
          Why do you need<span className="blue"> myprega.com?</span>
        </h1>
        <h4 className="mt-3 mb-5 fw-bolder underlineone text-secondary">
          We asked more then thousand of moms and mom buddies
        </h4>
        {/* <br className="d-none d-lg-block">
        <br> */}
        <h3 className="mb-3 mb-lg-5 mt-5 fw-bolder">
          What problems have you faced during your pregnancy care?
        </h3>
        <br />
        <h3 className="mb-5 mt-3 fw-bolder text-center" id="animation-section">
          This is what we found
        </h3>
        <div className="row my-5">
          <div className="col-12 col-md-4 text-center my-1">
            <div className="d-flex flex-column">
              <div
                style={{
                  margin: "0px auto",
                  height: "200px",
                  width: "200px",
                }}
              >
                <CircularProgressBar percentage={96} />
              </div>
              <p className="mt-2">
                Current Pregnancy care leaves you disconnected with your doctor{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center my-1">
            <div className="d-flex flex-column">
              <div
                style={{
                  margin: "0px auto",
                  height: "200px",
                  width: "200px",
                }}
              >
                <CircularProgressBar percentage={82} />
              </div>
              <p className="mt-2">
                Lack of Transparency and Visibility in care act as silent killer
                during journey{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center my-1">
            <div className="d-flex flex-column">
              <div
                style={{
                  margin: "0px auto",
                  height: "200px",
                  width: "200px",
                }}
              >
                <CircularProgressBar percentage={71} />
              </div>
              <p className="mt-2">
                Pregnancy care seems unorganised and no easy way exists to
                organise it{" "}
              </p>
            </div>
          </div>

          <div className="col-2"></div>
          <div className="col-12 col-md-4 text-center my-1">
            <div className="d-flex flex-column">
              <div
                style={{
                  margin: "0px auto",
                  height: "200px",
                  width: "200px",
                }}
              >
                <CircularProgressBar percentage={42} />
              </div>
              <p className="mt-2 imgdiv1">
                Lack of medical education on Pregnancy{" "}
              </p>
            </div>
          </div>

          <div className="col-12 col-md-4 text-center my-1">
            <div className="d-flex flex-column">
              <div
                style={{
                  margin: "0px auto",
                  height: "200px",
                  width: "200px",
                }}
              >
                <CircularProgressBar percentage={38} />
              </div>
              <p className="mt-2 imgdiv1">
                Lack of medical education on Pregnancy{" "}
              </p>
            </div>
          </div>
          <div className="col-2"></div>
        </div>

        <div className="containerinside mb-3 d-none d-md-block">
          <div className="row align-items-center">
            <div className="col-6 col-md-7 ">
              <h4 className="mb-5 fw-bolder">
                <span className="underlineone text-secondary">Then We Asked</span>
                <br />
              </h4>
              <h3 className="fw-bolder mb-4">
                'Are you{" "}
                <span className="blue">satisfied with the solution,</span>
              </h3>
              <h3 className="fw-bolder mb-4">
                that you have in age-old pregnancy care?'
              </h3>
              <span className="per mb-4">93% Said </span>
              <span className="per text-danger">NO!</span>
            </div>
            <div className="col-6 col-md-5 text-center">
              <img src="/sadlady.jpg" className="sadlady" alt="nt found"/>
            </div>
          </div>
        </div>
        <div className="containerinside mb-3 d-md-none">
          <div className="row align-items-center">
            <div className="col-6 col-md-7 ">
              <h4 className="mb-3 fw-bolder">
                <span className="underlineone text-secondary">Then We Asked</span>
              </h4>
              <h3 className="fw-bolder mb-4">
                'Are you{" "}
                <span className="blue">satisfied with the solution,</span>
              </h3>
              <h3 className="fw-bolder mb-4">
                that you have in age-old pregnancy care?'
              </h3>
            </div>
            <div className="col-6 col-md-5 text-center">
              <img src="/sadlady.jpg" className="sadladymobile" alt="nt found" />
            </div>
            <span className="belowline pl-3">93% Said </span>
            <span className="belowline text-danger pl-2"> NO!</span>
          </div>
        </div>
      </div>

      <div className="rectangle">
        <h4 className="fw-bolder incsize mb-0 text-white text-center">
          So, together with hundreds of medical professional across, we have
          developed myprega.com
        </h4>
      </div>
      <div className="slider d-none d-lg-block">
        <div className="container">
          <div className="row">
            <div className="vertical-trigger col-6">
              <div className="vertical-element">
                {/* Your vertical slider content goes here */}
                <div>
                  {" "}
                  <h2
                    className="mt-5 pt-5 fw-bolder increasefont fontpro"
                    id="consult"
                  >
                    Consult The Team Of Health Wizards{" "}
                  </h2>
                  <p
                    ref={firstParagraphRef}
                    className="pe-3 text-left featurepara fontpro"
                  >
                    Finding the right health expert at such a crucial time is a
                    reason for worry for you? Every mom-to-be needs special care
                    during this beautifully significant journey of her life.
                    This journey not only changes your body but a lot of other
                    things inside your body. To get you a perfect pregnancy care
                    approach, Myprega is the one-stop solution to your worries.
                    We unfold the insights of the pertinent information about
                    foetus development, other essential changes in the body, and
                    adequate nutrition required and emotional sentiments handled
                    during the entire journey.
                  </p>
                </div>
                <div
                  data-target="about"
                  style={{
                    width: "100%",
                    height: "693px",
                    paddingTop: "200px",
                  }}
                  className="ptmore"
                >
                  <h2 className="fw-bolder increasefont fontpro">
                    Feel Your Baby’s Kick
                  </h2>
                  <p
                    ref={secondParagraphRef}
                    className="pe-3 text-left featurepara fontpro"
                  >
                    Are you willing to maintain a diary to keep your sonography
                    pictures in place? This whole thing is surely very
                    overwhelming for you and you’d not miss a chance to initiate
                    this memorable gesture. To amp up your emotions in tons and
                    feel that kick through the screen, we’ve planned an adequate
                    number of ultrasounds for you so that you can fill these
                    emotional moments with extravaganzas every time you go for
                    sonography.
                  </p>
                </div>
                <div
                  data-target="examples"
                  style={{ width: "100%", height: "100%" }}
                  className="pt-5"
                >
                  <h2
                    className="fw-bolder increasefont fontpro"
                    style={{ paddingTop: "40px" }}
                  >
                    Reach Your Next Milestone
                  </h2>
                  <p
                    ref={thirdParagraphRef}
                    className="pe-3 text-left featurepara fontpro"
                  >
                    Since pregnancy is the most precious phase of your life and
                    during this crucial time, you need to get the essential
                    checkups on time to make sure that your baby is growing well
                    inside. But with all the schedule you have, does it sound
                    like a big task to you? No worries! Our fully automated
                    system supports a health tracker that records oxygen pulse
                    meter reading, keeps track of the growth of your baby
                    through your weight and checks your temperature consistently
                  </p>
                </div>
                <div
                  data-target="contact"
                  style={{ width: "100%", height: "693px" }}
                >
                  <h2 className="fw-bolder pp increasefont fontpro">
                    We Care For You 24x7
                  </h2>
                  <p
                    ref={fourthParagraphRef}
                    className="pe-3 text-left featurepara fontpro"
                  >
                    {" "}
                    Feeling those bad contractions and it’s already midnight,
                    will your doctor see you at this time? This is another phase
                    that you’re likely to face in case you’re expecting. Well,
                    for that you’re just a click away. Select the emergency care
                    option and it will alert the health expert about the
                    emergence. Don’t worry, your doctor will see you even if
                    it’s midnight. When we say we care for you, we do it in
                    every aspect. So, that’s why we provide you with a pregnancy
                    care kit that includes an oxy pulse meter, thermometer and
                    baby born kit. As you’re special to us, we let you know that
                    every month by sending you exclusive giveaway baskets that
                    include fruits, nuts and aromatic flowers. It’s our gesture
                    to let you know we’re always there!
                  </p>
                </div>
              </div>
            </div>

            <div className="horizontal-trigger col-6">
              {/* <div className="horizontal-element">
               
                <div id="pinMaster">
                  <img
                    src="assets/tab.png"
                    style={{
                      width: "100%",
                      height: "105%",
                      overflow: "hidden",
                      zIndex: "12000",
                      paddingTop: "70px",
                    }}
                  />
                  <div
                    id="pinContainer"
                    style={{
                      position: "absolute",
                      marginTop: "-72%",
                      zIndex: "-1",
                    }}
                  >
                    <section
                      className="panel b"
                      style={{ zIndex: "-1", width: "100%", height: "90%" }}
                    >
                      <img
                        src="assets/d1.png"
                        id="dashboard1"
                        style={{ width: "100%" }}
                        className="img-fluid i1"
                      />
                    </section>
                    <section
                      className="panel turqoise"
                      style={{ zIndex: "-1", width: "90%" }}
                    >
                      <img
                        src="assets/d2.png"
                        id="dashboard2"
                        className="img-fluid"
                        style={{ width: "100%" }}
                      />
                    </section>
                    <section
                      className="panel bordeaux"
                      style={{ zIndex: "-1", width: "90%" }}
                    >
                      <img
                        src="assets/d3.png"
                        id="dashboard3"
                        className="img-fluid"
                        style={{ width: "100%" }}
                      />
                    </section>
                    <section
                      className="panel brown"
                      style={{ zIndex: "-1", width: "90%" }}
                    >
                      <img
                        src="assets/d4.png"
                        id="dashboard4"
                        className="img-fluid"
                        style={{ width: "100%" }}
                      />
                    </section>
                  </div>
                </div>
              </div> */}

              <div
                style={{
                  marginLeft: 60,
                  marginTop: 60,
                  position: "sticky",
                  top: 230,
                }}
              >
                <img
                  src="/tabScreen.png"
                  className="img-fluid"
                  alt="Outer Image"
                />
                {currentPara === 1 ? (
                  <img
                    id="inner-image"
                    src={
                      "/screen1.png"
                      // currentPara === 1
                      //   ? "/screen1.png"
                      //   : currentPara === 2
                      //   ? "/screen2.png"
                      //   : currentPara === 3
                      //   ? "/screen3.png"
                      //   : "/screen4.png"
                    }
                    // className="img-fluid"
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      // bottom: 0,
                      right: 10,
                      top: 35,
                      left: 35,
                      height: "80%",
                      width: "80%",
                      height: "80%",
                      width: "80%",
                    }}
                  />
                ) : currentPara === 2 ? (
                  <img
                    id="inner-image"
                    src={
                      "/screen2.png"
                      // currentPara === 1
                      //   ? "/screen1.png"
                      //   : currentPara === 2
                      //   ? "/screen2.png"
                      //   : currentPara === 3
                      //   ? "/screen3.png"
                      //   : "/screen4.png"
                    }
                    // className="img-fluid"
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      // bottom: 0,
                      right: 10,
                      top: 35,
                      left: 35,
                      height: "80%",
                      width: "80%",
                      height: "80%",
                      width: "80%",
                    }}
                  />
                ) : currentPara === 3 ? (
                  <img
                    id="inner-image"
                    src={
                      "/screen3.png"
                    }
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      // bottom: 0,
                      right: 10,
                      top: 35,
                      left: 35,
                      height: "80%",
                      width: "80%",
                      height: "80%",
                      width: "80%",
                    }}
                  />
                ) : (
                  <img
                    id="inner-image"
                    src={
                      "/screen4.png"
                    }
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      // bottom: 0,
                      right: 10,
                      top: 35,
                      left: 35,
                      height: "80%",
                      width: "80%",
                      height: "80%",
                      width: "80%",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-lg-none container-fluid" id="feature">
        <div className="container">
          <h1 className="text-center fw-bolder py-3 pt-5">OUR FEATURES</h1>
          <h3 className="text-capitalize fw-bolder py-3">
            consult the team
            <br className="d-none" /> of health hazards
          </h3>
          <div
            className="tab mb-3"
            style={{ height: "200px", width: "300px", margin: "0px auto" }}
          >
            <img src="/d1.png" className="img-fluid py-2 " id="dash1"></img>
          </div>

          <p className="pb-5">
            Finding the right health expert at such a crucial time is a reason
            for worry for you? Every mom-to-be needs special care during this
            beautifully significant journey of her life. This journey not only
            changes your body but a lot of other things inside your body. To get
            you a perfect pregnancy care approach, Remedinart is the one-stop
            solution to your worries. We unfold the insights of the pertinent
            information about foetus development, other essential changes in the
            body, and adequate nutrition required and emotional sentiments
            handled during the entire journey
          </p>
          <h3 className="text-capitalize fw-bolder py-3">
            feel your
            <br className="d-none" /> baby's kick
          </h3>
          <div
            className="tab mb-3"
            style={{ height: "200px", width: "300px", margin: "0px auto" }}
          >
            <img src="/d2.png" className="img-fluid py-2" id="dash2" />
          </div>
          <p className="pb-5">
            Are you willing to maintain a diary to keep your sonography pictures
            in place? This whole thing is surely very overwhelming for you and
            you’d not miss a chance to initiate this memorable gesture. To amp
            up your emotions in tons and feel that kick through the screen,
            we’ve planned an adequate number of ultrasounds for you so that you
            can fill these emotional moments with extravaganzas every time you
            go for sonography
          </p>
          <h3 className="text-capitalize fw-bolder py-3">
            reach your
            <br className="d-none" /> next milestone
          </h3>
          <div
            className="tab mb-3"
            style={{ height: "200px", width: "300px", margin: "0px auto" }}
          >
            <img src="/d3.png" className="img-fluid py-2" id="dash3" />
          </div>
          <p className="pb-5">
            Since pregnancy is the most precious phase of your life and during
            this crucial time, you need to get the essential checkups on time to
            make sure that your baby is growing well inside. But with all the
            schedule you have, does it sound like a big task to you? No worries!
            Our fully automated system supports a health tracker that records
            oxygen pulse meter reading, keeps track of the growth of your baby
            through your weight and checks your temperature consistently
          </p>
          <h3 className="text-capitalize fw-bolder py-3">
            we care for
            <br className="d-none" /> you 24X7
          </h3>
          <div
            className="tab mb-3"
            style={{ height: "200px", width: "300px", margin: "0px auto" }}
          >
            <img src="/d4.png" className="img-fluid py-2" id="dash4" />
          </div>
          <p className="pb-5">
            Feeling those bad contractions and it's already midnight, will your
            doctor see you at this time? This is another phase that you're
            likely to face in case you're expecting. Well, for that you're just
            a click away. Select the emergency care option and it will alert the
            health expert about the emergence. Don't worry, your doctor will see
            you even if it's midnight. When we say we care for you, we do it in
            every aspect. So, that's why we provide you with a pregnancy care
            kit that includes an oxy pulse meter, thermometer and baby born kit.
            As you're special to us, we let you know that every month by sending
            you exclusive giveaway baskets that include fruits, nuts and
            aromatic flowers. It's our gesture to let you know we're always
            there
          </p>
        </div>
      </div>
      <div className="container-fluid pb-5 ">
        <div className="containertt">
          <h1 className="text-capitalize text-left mb-5 mt-5 fw-bolder increasefont">
            our packages
          </h1>
          <div className="row">
            <div className="col-lg-1 d-none d-lg-block"></div>
            <div className="col-6 col-lg-4 ml-auto">
              <div className="c">
                <img src="/E1final.png" className="img-fluid" id="E" />
                <h3 className="fw-bolder d-none d-md-block text-center mt-2 mb-4">
                  Elemental Care
                </h3>
                <h3 className="fw-bolder d-md-none text-center mt-2">
                  Elemental
                </h3>
                <h3 className="fw-bolder d-md-none text-center mb-2">Care</h3>
                <h4
                  className="text-center my-2 d-none d-md-block normal"
                  style={{ color: "#a4113a" }}
                >
                  Starting from<i className="bi bi-currency-rupee"></i>0
                </h4>
                <h6
                  className="text-center my-2 d-md-none start normal"
                  style={{ color: "#a4113a" }}
                >
                  Starting from<i className="bi bi-currency-rupee"></i>
                  <span className="d-block">0</span>
                </h6>
                <Link
                  style={{ cursor: "pointer", color: "white" }}
                  to="/elementalcare"
                  className="my-2 mx-auto buy"
                >
                  EXPLORE
                </Link>
                <div className="text-center">
                  <Link
                    className="d-block mt-3 mb-2 mx-auto text-primary"
                    // onClick={() => navigate("/elementalcare")}
                    to="/elementalcare"
                  >
                    Explore More
                  </Link>
                </div>
                <hr />
                <div className="card-body">
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Completely free for 7 days
                    </p>
                    <img src="/si1babyholdwidhand.png" className="icon" alt="nt found"/>
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Foetus Education (Garbh sanskar)
                      <br /> care for complete 7 days
                    </p>
                    <img src="/i6babycap.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <i className="bi bi-dash-lg"></i>
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Complete dashboard view
                    </p>
                    <img src="/i11.jpg" className="icon1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-none d-lg-block"></div>
            <div className="col-6 col-lg-4 me-auto">
              <div className="c">
                <img src="cherishfinal.png" className="img-fluid" id="t" />
                <h3 className="fw-bolder text-center mt-2 mb-4 d-none d-md-block">
                  Cherish Care
                </h3>
                <h3 className="fw-bolder text-center mt-2 d-md-none">
                  Cherish
                </h3>
                <h3 className="fw-bolder text-center mb-2 d-md-none">Care</h3>
                <h4
                  className="text-center my-2 d-none d-md-block normal"
                  style={{ color: "#a4113a" }}
                >
                  Starting from<i className="bi bi-currency-rupee"></i>39,999
                </h4>
                <h6
                  className="text-center my-2 start d-md-none normal"
                  style={{ color: "#a4113a" }}
                >
                  Starting from<i className="bi bi-currency-rupee"></i>
                  <span className="d-block">39,999</span>
                </h6>
                <Link
                  style={{ cursor: "pointer", color: "white" }}
                  to="/pricing"
                  className="my-2 mx-auto buy"
                >
                  BUY NOW
                </Link>
                <div className="text-center">
                  <Link
                    to="/cherishcare"
                    // onClick={() => navigate("/cherishcare")}
                    className="mt-3 d-block mb-2 mx-auto text-primary"
                  >
                    Explore More
                  </Link>
                </div>
                <hr />
                <div className="card-body">
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      10 Expert Consultation sessions{" "}
                    </p>
                    <img src="/i1.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      48 Consultation sessions with Doctor
                      <br /> Including both in clinic & online{" "}
                    </p>
                    <img src="/i2.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      12 types of Pathology Blood <br />
                      and Genetic Tests
                    </p>
                    <img src="/i3.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Bacterial Parental Screening Test
                    </p>
                    <img src="/i4circle.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      9 sessions of ultrasound scan{" "}
                    </p>
                    <img src="/i5babyind.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-center text-capitalize fw-bolder pt-2">
                      Weekly foetus education sessions
                      <br /> are known as garbha sanskar
                    </p>
                    <img src="/i6babycap.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Alternative days body checkup
                      <br /> via Health tracker
                    </p>
                    <img src="/i7watch.jpg" className="icon" />
                  </div>

                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      24x7 Emergency Care{" "}
                    </p>
                    <img src="/i824x7.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Complete pregnancy care kit
                    </p>
                    <img src="/i9pregnantlady.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Monthly premium exclusive
                      <br />
                      giveaway basket{" "}
                    </p>
                    <img src="/i10gift.jpg" className="icon" />
                  </div>
                  <div className="wrap my-1">
                    <p className="card-text text-center text-center text-capitalize fw-bolder pt-2">
                      Complete dashboard view
                    </p>
                    <img src="/i11.jpg" className="icon1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1 d-none d-lg-block"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-3">
        <div className="containertt">
          <h1 className="mb-5 fw-bolder increasefont">
            Our success stories:
            <span className="blue"> From the voice of mom and mom buddies</span>
          </h1>
          <h4 className="pb-3 mt-4 fw-bolder">
            <span className="underlineone text-secondary">
              Don't just take our word for it, Our moms love our care just as
              much as we do
            </span>
            <br />
            <br />
          </h4>
          <div className="text-center  d-lg-none">
            <img src="customerreview.jpg" className="img-fluid" id="cust" />
          </div>
          <span className="mb-3 pl-3">
            <br />
          </span>
          <div className="container bg-secondary"></div>
          <div className="container d-none d-lg-block">
            <div className="row pt-3">
              <div className="col-12 col-md-6 col-lg-4">
                <Carousel
                  showArrows={false}
                  infiniteLoop={true}
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={true}
                  interval={4000} // Set the autoplay interval in milliseconds
                  showIndicators={false}
                >
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-2 pl-2 marginadj">
                        I like the easy access to my <br />
                        daily pregnancy records, alerts on when consultation and
                        labs are due, and the opportunity to ask questions
                        anytime. myprega simplified my pregnancy journey.
                      </p>
                      <div className="star pt-lg-1 pl-lg-2">
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square">
                      <img src="/customer1.jpg" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-5 pl-2 text-left">
                        At myprega, nothing is out of reach when it comes to
                        getting connected to your doctor. Incredible, how
                        everything in my journey is too organised.
                      </p>
                      <div className="star pt-lg-1">
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square">
                      <img src="/customer2.jpg" className="img-fluid" />
                    </div>
                  </div>
                </Carousel>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Carousel
                  showArrows={false}
                  infiniteLoop={true}
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={true}
                  interval={4000} // Set the autoplay interval in milliseconds
                  showIndicators={false}
                >
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-2 pl-2 mara text-left">
                        The responsibility of caring for pregnant mothers lies
                        on the shoulders of family, public health systems and on
                        volunteers, who often struggle to navigate unorganised
                        care and myprega is the only solution for this problem.
                      </p>
                      <div className="star pt-lg-1">
                        <i className="bi bi-star-fill as pl-4"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square">
                      <img src="/customer3.jpg" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-5 pl-2 text-left">
                        myprega is changing the traditional pregnancy care with
                        their technology driven solution and I'm proud to be
                        pioneers for this vision.
                      </p>
                      <div className="star pt-lg-1 sp">
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square">
                      <img src="/customer4.jpg" className="img-fluid" />
                    </div>
                  </div>
                </Carousel>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-5 d-none d-md-block">
                <img
                  src="/customerreview.jpg"
                  className="img-fluid"
                  id="cust"
                />
              </div>
            </div>
          </div>

          <div className="container d-lg-none">
            <div className="row pt-3">
              <div className="col-12  mx-auto">
                <Carousel
                  showArrows={false}
                  infiniteLoop={true}
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={true}
                  interval={4000} // Set the autoplay interval in milliseconds
                  showIndicators={false}
                >
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-2 pl-2 mb-5 text-left">
                        I like the easy access to my <br />
                        daily pregnancy records, alerts on when consultation and
                        labs are due, and the opportunity to ask questions
                        anytime. myprega simplified my pregnancy journey.
                      </p>
                      <div className="star pt-lg-1">
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square mb-2">
                      <img src="/customer1.jpg" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-5 pl-2 text-left">
                        At myprega, nothing is out of reach when it comes to
                        getting connected to your doctor. Incredible, how
                        everything in my journey is too organised.
                      </p>
                      <div className="star pt-lg-1">
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square">
                      <img src="/customer2.jpg" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-2 pl-2 text-left">
                        The responsibility of caring for pregnant<br></br>{" "}
                        mothers lies on the shoulders of family, public health
                        systems and on volunteers, who often struggle to
                        navigate unorganised care and myprega is the only
                        solution for this problem.
                      </p>
                      <div className="star pt-lg-1">
                        <i className="bi bi-star-fill as pl-4"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square">
                      <img src="/customer3.jpg" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-5 item abc">
                    <div className="square bg-white">
                      <p className="pt-5 pl-2 text-left">
                        myprega is changing the traditional pregnancy care with
                        their technology driven solution and I'm proud to be
                        pioneers for this vision.
                      </p>
                      <div className="star pt-lg-1">
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                        <i className="bi bi-star-fill as"></i>
                      </div>
                      <p className="nm"></p>
                    </div>

                    <div className="small-square mb-2">
                      <img src="/customer4.jpg" className="img-fluid" />
                    </div>
                  </div>
                </Carousel>
              </div>

              <div className="col-12 col-md-4 mt-5 d-none">
                <img src="customerreview.jpg" className="img-fluid" id="cust" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-5">
        <div className="containertt my-4 ctr">
          <div className="row">
            <h1 className="my-5 w-100 fw-bolder">
              Every
              {/* <span className="fw-bolder blue" id="changingtext">
                {" "}
                {text[counter]}{" "}
              </span> */}
              has a tab open to <span>myprega.com</span>
            </h1>
            <div className="col-12 col-lg-4 text-center my-3">
              <div className="element">
                <div className="top-border"></div>
                <div className="d-flex flex-column pt-4 content">
                  <div className="outerwrapper d-flex justify-content-center align-items-center">
                    <div className="sq">
                      <CounterUpComponent targetCount={162} duration={3000} />
                      <i className="bi bi-plus-lg"></i>{" "}
                    </div>
                  </div>
                  <h5 className="fw-bolder text-capitalize">Medical experts</h5>
                </div>
                <div className="bottom-border"></div>
              </div>
            </div>
            <div className="col-12 col-lg-4 text-center my-3">
              <div className="element">
                <div className="top-border"></div>
                <div className="d-flex flex-column pt-4 content">
                  <div className="outerwrapper d-flex justify-content-center align-items-center">
                    <div className="sq">
                      <CounterUpComponent targetCount={606} duration={3000} />
                      <i className="bi bi-plus-lg "></i>{" "}
                    </div>
                  </div>
                  <h5 className="fw-bolder text-capitalize">mom</h5>
                </div>
                <div className="bottom-border"></div>
              </div>
            </div>
            <div className="col-12 mx-auto col-lg-4 text-center my-3">
              <div className="element">
                <div className="top-border"></div>
                <div className="d-flex flex-column pt-4 content">
                  <div className="outerwrapper d-flex justify-content-center align-items-center">
                    <div className="sq">
                      <CounterUpComponent targetCount={2030} duration={3000} />
                      <i className="bi bi-plus-lg "></i>{" "}
                    </div>
                  </div>
                  <h5 className="fw-bolder text-capitalize">mom buddies</h5>
                </div>
                <div className="bottom-border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="containertt mt-5">
          <div className="py-5">
            <div className="row align-items-center my-5">
              <div className="col-12 col-md-5">
                <h1 className="fw-bolder pb-5 increasefont">
                  Are you someone, not a mom-to-be?
                </h1>
                <h1
                  className="pb-5 increasefont"
                  style={{ fontWeight: "normal", color: "#a4113a" }}
                >
                  Be mom buddy! Join Our Community!
                </h1>

                <div className="col-12 col-md-7 d-md-none">
                  <img src="/joinus.png" className="img-fluid" />
                </div>

                <Link
                  className="mLEFT buy mt-2 mt-md-0"
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={sendmail}
                >
                  SUBSCRIBE
                </Link>
              </div>
              <div className="col-12 col-md-7 d-none d-md-block">
                <img src="/joinus.png" className="img-fluid pt-5 pt-lg-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    
      {/* <Myslider/> */}
    </>
  );
}

export default Home;
