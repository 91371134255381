// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* OTPInput.css */

/* Container styling */
.otp-input-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* OTP input field styling */
.otp-input {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #333;
  border-radius: 4px;
  outline: none;
}

/* Highlight the input field on focus */
.otp-input:focus {
  border-color: #007bff; /* Change to your desired focus color */
}
`, "",{"version":3,"sources":["webpack://./src/doctor/Components/OTPInput.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB,sBAAsB;AACtB;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA,4BAA4B;AAC5B;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf;;AAEA,uCAAuC;AACvC;EACE,qBAAqB,EAAE,uCAAuC;AAChE","sourcesContent":["/* OTPInput.css */\r\n\r\n/* Container styling */\r\n.otp-input-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 10px;\r\n}\r\n\r\n/* OTP input field styling */\r\n.otp-input {\r\n  width: 40px;\r\n  height: 40px;\r\n  font-size: 20px;\r\n  text-align: center;\r\n  border: 2px solid #333;\r\n  border-radius: 4px;\r\n  outline: none;\r\n}\r\n\r\n/* Highlight the input field on focus */\r\n.otp-input:focus {\r\n  border-color: #007bff; /* Change to your desired focus color */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
