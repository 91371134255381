import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import configData from "../../config.json";
import Pathlist from "./Pathlist";
import { useState } from "react";
import { fontSize, width } from "@mui/system";
import { useMediaQuery } from "react-responsive";
import moment from "moment/moment";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Gynaecologist", 2, 15),
//   createData("Doctor of Medicine", 1, 13),
//   createData("Pediatrician", 0, 2),
//   createData("Physiotherapist", 1, 3),
// ];
let drid;
export function PathTable1(props) {
  console.log("9999",props);
  if(props?.data) 
  drid=props?.data[0]?.doctor_id;
  
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
 
  let i = 1;

  const showavailability=()=>{

  }
  return (
    <table className="spacing-table">
      <thead>
        <tr
          style={{
            fontFamily: "Poppins",
            fontSize: isTabScreen ? "10px" : "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
            backgroundColor: "rgba(243, 246, 249, 1)",
          }}
        >
          <th>NUMBER</th>
          <th>DATE</th>
          <th>STATUS</th>
        </tr>
      </thead>
      {props?.data?.length>0?( props?.data?.map((row) => (
        <tbody>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {row?.consultation_number}
            </td>
            <td
              style={{
                fontSize: isTabScreen ? "8px" : "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {row?.scheduled_date
                ? moment(row?.scheduled_date).format("DD MMM YYYY")
                : "Not Scheduled"}
                <br></br>
                <p style={{fontSize:"11px"}}>{row?.timing}</p>
            </td>
            <td>
              {" "}
              {row?.is_completed ? (
                <img
                  src={"/Pathology/tickmark.png"}
                  style={{ width: "28px", height: "25px",margin:"0px auto" }}
                ></img>
              ) : (
                <div
                  style={{
                    fontSize: isTabScreen ? "8px" : "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    color: "#F92A2A",
                  }}
                >
                  {row?.is_completed ? "COMPLETED" : "NOT COMPLETED"}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      ))):(<tbody><p className="ps-3" style={{color:"rgb(0, 74, 173)"}}>No summary to show yet</p></tbody>)}
     
    </table>
  );
}


let sdata;
function PathologyTest(props) {
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMsg, setAlertMsg] = useState("");
  // const [severity, setSeverity] = useState("");
  const s = {
    fontWeight: "500",
    fontSize: "9px",
  };
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });
  const navigate = useNavigate();
  const findMinDate = (datesArray) => {
    let minDate = datesArray[0];
  
    for (let i = 1; i < datesArray?.length; i++) {
      if (moment(datesArray[i]).isBefore(minDate)) {
        minDate = datesArray[i];
      }
    }
    return minDate;
  };
  const handleAlertMsg = (error_type, msg) => {
    // setShowAlert(true);
    // setSeverity(error_type);
    // setAlertMsg(msg);
    // setTimeout(() => {
    //   setShowAlert(false);
    // }, 2000);
  };
  const is24HoursBefore = (scheduledDate) => {
    const startOf24HoursBefore = moment(scheduledDate).subtract(1, 'day').startOf('day');
    const startOfCurrentDay = moment().startOf('day');
    var msg = "You cant reschedule now";
    const error_color = "error";
    handleAlertMsg(error_color, msg);
    return moment(startOfCurrentDay).isAfter(startOf24HoursBefore);
  };
  const lock = {
    top: "-6px",
    left: "38%",
    position: "absolute",
    height: "40px",
    width: "40px",
    cursor:"not-allowed",
  };
//  const reschedule=(e)=>{
//   console.log("CLICKED ON SMALL CARD", e);
//   sdata = e;
//   props.setRendercalendar(true);
//   props.setClickedschedule(false);
//   props.setSdata(sdata);
  // console.log("+++",e);
  // navigate("/patient/Doctor_consultation", {
  //   state: {
  //     id: 3,
  //     drid: drid,
  //     cardid:e.drid,
  //   },
  // }) 
//  }
// alert(props.viewindex);
const showavailability=(e)=>{

  setschedule(true);
  console.log("CLICKED ON SMALL CARD", e);
  sdata = e;
}
  // Function to check if the current date is within a 7-day window before the start date
  const isWithinSchedulingWindow = (currentDate, startDate) => {
    const startOfSevenDaysBefore = moment(startDate).subtract(7, 'days').startOf('day');
    const startOfCurrentDay = moment(currentDate).startOf('day');
    
    return moment(startOfCurrentDay).isSameOrAfter(startOfSevenDaysBefore);
  };
  const isstatus = props.teststatus;
  const [clicked, setClicked] = useState(false);
  const [clickedschedule, setClickedschedule] = useState(false);
  const [clr, SetClr] = useState();
  const [selected, setselected] = useState();
  const [schedule, setschedule] = useState(false);


  return (
    <>
      <Col md={6} className="px-0" style={{ height: "fit-content" }}>
        <div
          style={{
            marginLeft:
              isTabScreen || isTabScreen1
                ? props.index % 2 === 0
                  ? "10px"
                  : "10px"
                : props.index % 2 === 0
                ? "20px"
                : "59px",

            marginRight:
              isTabScreen || isTabScreen1
                ? props.index % 2 === 0
                  ? "10px"
                  : "10px"
                : props.index % 2 === 0
                ? "59px"
                : "20px",
          }}
        >
          <div
            className="bg-white"
            style={{
              // marginRight: "20px",
              marginTop: "20px",
              border: "0.5px solid #CBC6C6",
              borderRadius: "12px",
              boxShadow:
                props.index === props.selectedreport ||
                props.index === props.selectedtest
                  ? "0px 1px 50px rgba(0, 0, 0, 0.1608)"
                  : "none",
            }}
          >
            <div
              className="text-center"
              style={{
                fontSize: isTabScreen ? "16px" : "22px",
                fontWeight: "500",
                paddingTop: "21px",
              }}
            >
              {props.title}
              <div className="d-flex justify-content-between px-md-2 px-lg-4 pt-2  align-items-center">
                <div>
                  <img
                    style={{
                      height: isTabScreen ? "20px" : "30px",
                      width: isTabScreen ? "20px" : "30px",
                    }} alt="not found"
                    src={"/Pathology/calendar.png"}
                  ></img>
                  <div
                    style={{
                      fontSize: isTabScreen ? "10px" : "12px",
                      color: "#333333",
                      fontWeight: "500",
                    }}
                  >
                    {props.date ? moment(props.date).format("MMM DD") : ""}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: isTabScreen ? "14px" : "20px",
                    fontWeight: "500",
                    color:
                      props.teststatus === "COMPLETED" ? "#58CF99" : "#F92A2A",
                  }}
                >
                  {props.teststatus}
                </div>
              </div>
              <div
                className="px-4 pt-4 pb-3"
                style={{
                  fontSize: isTabScreen ? "13px" : "18px",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                {props.drpost} Details
              </div>
              {isstatus === "COMPLETED" ? (
                <div>
                  <div
                    className="d-flex align-items-center px-4"
                    style={{ height: isTabScreen ? "50px" : "100px" }}
                  >
                    <div>
                      <img
                        src={props.drimg}
                        style={{
                          height: isTabScreen ? "30px" : "60px",
                          width: isTabScreen ? "30px" : "60px",
                        }}
                      />
                    </div>
                    <div className="ps-3" style={{ textAlign: "left" }}>
                      <div
                        style={{
                          fontSize: isTabScreen ? "11px" : "16px",
                          fontWeight: "600",
                        }}
                      >
                        {props.drstatus}
                      </div>
                      <div
                        style={{
                          fontSize: isTabScreen ? "10px" : "14px",
                          fontWeight: "400",
                        }}
                      >
                       Id: {props.phlebologistdrid}
                      </div>
                      <div
                        style={{
                          fontSize: isTabScreen ? "10px" : "14px",
                          fontWeight: "400",
                        }}
                      >
                         Mobile no: {props.phlebologistdrmobile}
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-right"
                    style={{ textAlign: "end", marginRight: "30px" }}
                  >
                    <button
                      style={{
                        width: "110px",
                        height: "35px",
                        fontSize: isTabScreen ? "10px" : "12px",
                        fontWeight: "400",
                        backgroundColor: "#ffffff",
                        color: "#7C9CBF",
                        marginTop: "8px",
                        marginBottom: "35px",
                        border: "none",
                        boxShadow:
                          props.clicked === true &&
                          props.selectedreport === props.id
                            ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                            : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                        color:
                          props.clicked === true &&
                          props.selectedreport === props.id
                            ? "#F0588B"
                            : "#7C9CBF",
                      }}
                      onClick={() => props.onClick3()}
                    >
                      {props.checkreportbtn}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className="d-flex align-items-center px-4"
                    style={{ height: isTabScreen ? "50px" : "100px" }}
                  >
                    <div>
                      <img
                        src={props.drimg}
                        style={{
                          height: isTabScreen ? "30px" : "60px",
                          width: isTabScreen ? "30px" : "60px",
                          filter: "blur(2px)"
                        }} alt="nt found"
                      />
                    </div>
                    <div className="ps-3" style={{ textAlign: "left" }}>
                      <div
                        style={{
                          fontSize: isTabScreen ? "11px" : "16px",
                          fontWeight: "600",
                        }}
                      >
                        {props.drstatus}
                      </div>
                      <div
                        style={{
                          fontSize: isTabScreen ? "10px" : "14px",
                          fontWeight: "400",
                        }}
                      >
                       Id: {props.phlebologistdrid}
                      </div>
                      <div
                        style={{
                          fontSize: isTabScreen ? "10px" : "14px",
                          fontWeight: "400",
                        }}
                      >
                       Mobile no: {props.phlebologistdrmobile}
                      </div>
                    </div>
                  </div>
                      
                  {props.date && (
                                  <>
                                        <button 
                                        style={s}
                                          className={`ml-auto me-2 ${
                                          props.viewindex ===  props.drid
                                              ? "patient-consultation-btn-active"
                                              : "patient-consultation-btn"
                                          }`}
                                          // style={{backgroundColor:props.viewindex ===  props.id ?"blue":"green"}}
                                          onClick={() => props.onClick5()}>
                                          View
                                        </button>
                                      </>
                     )} 
                  <div
                    className="d-flex justify-content-between mx-4"
                    style={{ marginTop: "8px", marginBottom: "35px" }}
                  >
                  
                    <button
                      onClick={() => props.onClick2()}
                      style={{
                        width: "110px",
                        height: "35px",
                        fontSize: isTabScreen ? "10px" : "12px",
                        fontWeight: "400",
                        backgroundColor: "#ffffff",
                        border: "none",
                        boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
                        color: "#7C9CBF",
                      }}
                    >
                      {props.testincludedbtn}
                    </button>
                    
                    {props.date!=null && props.is_confirmed!=1 ? 
                    (props.consulted_by_doctor=== false ? (
                    <button disabled={is24HoursBefore(props.date)}
                    onClick={() => {
                      props.onClickreschedule()}}
                    style={{
                        width: "110px",
                        height: "35px",
                        fontSize: isTabScreen ? "10px" : "12px",
                        fontWeight: "400",
                        backgroundColor: "#ffffff",
                        border: "none",
                        boxShadow:"0px 4px 8px rgba(44, 39, 56, 0.078)",
                        color:  "#7C9CBF", 
                      }}>Re-Schedule3 
                      {/* lock icon before  no availability added give option to select time and date again  */}
                      </button>)
                      :
                    <button disabled={is24HoursBefore(props.date)}
                    onClick={()=>props.onClick4()}   
                    style={{
                        width: "110px",
                        height: "35px",
                        fontSize: isTabScreen ? "10px" : "12px",
                        fontWeight: "400",
                        backgroundColor: "#ffffff",
                        border: "none",
                        boxShadow:
                        props?.availability === props?.id
                          ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                          : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                          color:  props?.availability === props?.id ? "#F0588B" :  "#7C9CBF", 
                      }}>Re-Schedule5
                      </button>
                      ): 
                       (props.is_confirmed===2 ?
                         <button disabled={is24HoursBefore(props.date)} onClick={()=>props.onClick4()}  
                       style={{
                           width: "110px",
                           height: "35px",
                           fontSize: isTabScreen ? "10px" : "12px",
                           fontWeight: "400",
                           backgroundColor: "#ffffff",
                           border: "none",
                           boxShadow:
                           props?.availability === props?.id
                             ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                             : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                             color:  props?.availability === props?.id ? "#F0588B" :  "#7C9CBF", 
                         }}>Re-Schedule4
                         </button> :<button  className={props.is_confirmed === 1 ? "disabled-btn" : ""}
                    disabled={props.is_confirmed==1 ||
                        !isWithinSchedulingWindow(
                         moment().format('YYYY-MM-DD'),
                        findMinDate(props.weekDates)
                       )
                     }
                      onClick={() => {
                              props.onClick();
                      }}
                      style={{
                        width: "110px",
                        height: "35px",
                        fontSize: isTabScreen ? "10px" : "12px",
                        fontWeight: "400",
                        backgroundColor: "#ffffff",
                        border: "none",
                        boxShadow:
                          props?.selectedtest === props?.id
                            ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                            : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                            color: props?.selectedtest === props?.id ? "#F0588B" : !isWithinSchedulingWindow(
                              moment().format('YYYY-MM-DD'),
                              findMinDate(props.weekDates)
                            ) ? "#D3D3D3" : "#7C9CBF", 
                      }}
                    >
                      {props.scheduletestbtn}
                    </button>)}
                  
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}
export default PathologyTest;
