import React, { useEffect } from "react";
import MiniDrawer from "../Components/MiniDrawer";
import configData from "../../config.json";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConsultationCard from "../Components/ConsultationCard";
import Users from "../Components/List";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import PathologyTest from "../Components/PathologyTest";
import Pathlist from "../Components/Pathlist";
import { useState } from "react";
import { PathTable1 } from "../Components/PathologyTest";
import PathologyReportDetail from "../Components/PathologyReportDetail";
import RightBoxofpathology from "../Components/RightBoxofpathology";
import IncludedTest from "../Components/IncludedTest";
import { useMediaQuery } from "react-responsive";
import Dropdown from "../Components/Categories/Dropdown";
import { useLocation } from 'react-router-dom';
import Calendarpart from "../Components/Calendarpart";
import ConsultationCardDetails from "../Components/Categories/ConsultationCardDetails";
let mydata;
let sdata;
let t;
function Pathology(props) {
  const location = useLocation();
  const state = location?.state;
  const { stateid, id } = state || {};
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1240px)",
  });
  const isTabScreen2 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });

  const [clicked, setClicked] = useState(false);
  const [clickedschedule, setClickedschedule] = useState(false);
  const [complete, incomplete] = useState(false);
  const [testinclude, setTestinclude] = useState(false);
  const [backbtn, setBackbtn] = useState(false);
  const [testinfo, settestinfo] = useState(false);
  const [selectedreport, setSelectedreport] = useState(false);
  const [selectedtest, setselectedtest] = useState(false);
  const [includedtest, setincludedtest] = useState(false);
  const [availability,setAvailability]=useState(false);
  const [showTime, setShowTime] = useState(false);
  const [submenu, setSubmenu] = useState(false);
  const [schedulingid,setSchedulingid]=useState()
  const [patientdata, setPatientdata] = useState();
  const [consultations, setConsultations] = useState();
  const [consultData, setConsultData] = useState();
  const [reportid,setReportid]=useState();
 const [renderconsult,setRenderconsult]=useState(false);
 const [rendercalendar,setRendercalendar]=useState(false);
 const [objectData, setObjectData] = useState();
 const [dates, setDates] = useState();
 const [timings, setTimings] = useState();
 const [timeData, setTimeData] = useState();
 const [doctorInfo, setDoctorInfo] = useState();
 const [info, setInfo] = useState(false);
 const [viewBtn,setViewBtn]=useState(false);
  const [consultno,setConsultno]=useState();
  const [consultId, setConsultId] = useState();
  const [consultationsData, setConsultationsData] = useState();
  const [viewindex,setIndex1]=useState();
  useEffect(() => {
    getPatientDetail();
  }, [setRenderconsult]);

useEffect(()=>{
        console.log("PP",sdata);
        getDoctorAvailability(sdata?.id);
        setDoctorInfo(patientdata?.pathology);
        setInfo(sdata)
},[sdata]);

const getDoctorAvailability = async (cId) => {
  if(patientdata?.pathology?.id)
  {
  try {
    console.log("check api", localStorage.getItem("userId"),patientdata?.pathology?.id, cId);
    const response = await axios.post(
      `${configData.SERVER_API_URL}/consultation/get_doctor_availability`,
      {
        patient_id: localStorage.getItem("userId").toString(),
        doctor_id: patientdata?.pathology?.id,
        consultation_id: cId,
      }
    );
    console.log("get_doctor_availability", response.data);
    if (response.data?.success) {
      const selectedDate = [];
      const dateTimeData = [];
      response.data.doctor_availability.map((item) => {
        console.log("item", item);
        selectedDate.push(item.dates);

        const formattedDateobj2 = {
          id: item?.id,
          date: item?.dates,
          timing_id: item?.timing_id,
          timings: item?.timings,
        };
        dateTimeData.push(formattedDateobj2);
      });
      setObjectData(dateTimeData);
      console.log("DoctorAvailability-", response.data);
      console.log("DoctorAvailability-", selectedDate);
      console.log("DoctorAvailability-", dateTimeData);
      setTimeData(response.data?.doctor_availability[0]);
      setDates(selectedDate);
      setTimings(response.data?.doctor_availability[0]?.timings?.split(","));
    }
  } catch (error) {
    console.error("Error get availdates:", error);
  }
}
else{

}
};
  useEffect(() => {
   
      const interval = setInterval(() => {
        getPatientDetail();
      }, 2000);
      return () => clearInterval(interval);
    
  },[]);

  const pathologylist=[{title:"1st trimester test"},
  {title:"2nd trimester test"},
  {title:"3rd trimester test"},{title:"4th trimester test"},{title:"5th trimester test"}]
  let matchconsultation;
  useEffect(()=>{
    if(stateid===2)
    {
      setClickedschedule(true);
         if(consultations)
          {
           
             matchconsultation=consultations?.find(consultation => consultation.id===id)
             console.log("PP@@",matchconsultation);
          }
        mydata = matchconsultation;
      setConsultData(matchconsultation);
      console.log("mydata>", matchconsultation);
      {
        matchconsultation?.teststatus === "COMPLETED" ? setcheckreport() : setschedulebtn(matchconsultation);
      }
      setSelectedreport(matchconsultation?.id);
      if (matchconsultation) {
        const index = consultations.indexOf(matchconsultation);
        setselectedtest(index);
    }
    // setselectedtest(matchconsultation?.id);
    // setSelectedreport(0);
    // setincludedtest(0);
    // setClickedschedule(true);
    // setClicked(false);
    // setShowTime(false);
    }
  },[consultations,stateid,id,selectedreport,selectedtest]);
  
  const handleClick = (e,id,title) => {
    mydata = e;
    setConsultData(e);
    t=title;
    console.log("mydata>", mydata,e,title);
    {
      mydata.teststatus === "COMPLETED" ? setcheckreport(e) : setschedulebtn(e);
    }
    setSelectedreport(e?.id);
    setselectedtest(id);
    setSelectedreport(0);
    setincludedtest(0);
    setAvailability(0);
    setIndex1(0);
    setSchedulingid(e?.id);
  };
  const handleClickreschedule = (e,id,title) => {
    mydata = e;
    // setConsultData(e);
    t=title;
    console.log("mydata>", mydata,e,title);
    {
      mydata.teststatus === "COMPLETED" ? setcheckreport(e) : setschedulebtn(e);
    }
    setSelectedreport(e?.id);
    setselectedtest(id);
    setSelectedreport(0);
    setincludedtest(0);
    setAvailability(0);
    setSchedulingid(e?.id);
    setShowTime(false);
  };
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };

  const handleClick1 = (e, id) => {
    setTestinclude(true);
    setincludedtest(id);
    setClickedschedule(false);
    setClicked(false);
    // setSelectedreport(0);
    // setAvailability(0);
    // setselectedtest(0);
    // setIndex1(0);
    setselectedtest(false);
    setShowTime(false);
  };

  const reschedule=(e,id)=>{
    console.log("CLICKED ON SMALL CARD", e);
    sdata = e;
    setRendercalendar(true);
    setAvailability(id);
    setClickedschedule(false);
    setselectedtest(0);
    setincludedtest(0);
    setIndex1(0);
  }
  const handlebackbtn = () => {
    setBackbtn(true);
    setTestinclude(false);
    setShowTime(false);
  };

  function setcheckreport(e,id) {
    setClicked(true);
    mydata=e;
    console.log("Q",e,id);
    setReportid(e?.id);
    setSelectedreport(id);
    setClickedschedule(false);
    setselectedtest(0);
    setincludedtest(0);
    setAvailability(0);
    setIndex1(0);
    setShowTime(false);
  }
  function setschedulebtn(e) {
    setselectedtest(e?.id);
    setSelectedreport(0);
    setincludedtest(0);
    setAvailability(0);
    setClickedschedule(true);
    setClicked(false);
    setShowTime(false);
  }
  function onClickDay(date) {
    console.log("selected date>>", date);
    setShowTime(true);
    // alert(showTime);
  }
  // const staticTitles = ["Title 1", "Title 2", "Title 3", ...];
  const getPatientDetail = async () => {
    console.log("PATIENTID-", localStorage.getItem("userId"));
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: localStorage.getItem("userId"),
      });

      if (response.data.success) {
        console.log("get loginuserdata successfully-", response.data.user);
        let area = response.data.user.area_id;
        setPatientdata(response.data?.user);
        // console.log("patientdata>", patientdata);
        getDoctorConsultations(response.data?.user?.pathology?.id);
      } else {
        console.error("Error loginuserdata:", response.data.error);
      }
    } catch (error) {
      console.error("Error loginuserdata:", error);
    }
  };
  const viewconsultation=(e)=>{
    console.log("@#@#",e);
    setIndex1(e.id);
    setSelectedreport(0);
    setincludedtest(0);
    setAvailability(0);
    setClickedschedule(false);
    setSchedulingid(0);
    handleChangeConsultationView1(e.id,e)

  }
  const handleChangeConsultationView1 = async (consultno,consultdata) => {
    try {
      console.log("consult_id",consultdata,consultno);
      setConsultId(consultdata?.id);
      // fetchToken(consultdata?.id);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_consultation_by_id`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          consultation_id: consultdata?.id,
        }
      );
      if (response.data?.success) {
        console.log("consultationsData", doctorInfo);
        console.log("consultationsData", response.data);
        const consultData = {
          doctorName: doctorInfo?.fname + " " + doctorInfo?.lname,
          date: response.data.consultation_data[0].scheduled_date,
          mode: response.data.consultation_data[0].consultation_type,
          timing: response.data.consultation_data[0].timing,
         
        };
        setConsultationsData(consultData);
        setConsultno(response.data.consultation_data[0]?.consultation_number);
        setRendercalendar(false);
        setClicked(false);
        // setschedule(false);
        // setprescription(false);
        // setfollowup(false);
        // setSelected(consultdata?.id);
        setViewBtn(true);
      }
    } catch (error) {
      console.error("Error get consults:", error);
    }
  };
  
  const getDoctorConsultations = async (DoctorID) => {
    try {
      console.log(
        "DoctorID--",
        DoctorID,
        localStorage.getItem("userId").toString()
      );
      const response = await axios.post(
      `${configData.SERVER_API_URL}/consultation/get_doctor_consultation`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          doctor_id: DoctorID,
        }
      );

      if (response.data?.success) {
        console.log("DoctorConsults-", response.data?.patient_consultations);
        setConsultations(response.data?.patient_consultations);
      }
    } catch (error) {
      console.error("Error get consults:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <div style={{ width: "70%", backgroundColor: "#FFF0F5" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#FFF0F5",
            paddingTop: "48px",
            paddingLeft: isTabScreen || isTabScreen2 ? "20px" : "59px",
            paddingBottom: "27px",
            color: configData.THEME_COLORS.PRIMARY,
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              fontSize: isTabScreen ? "24px" : "32px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Pathology  
          </div>
          <div
            style={{
              paddingRight: "69px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NotificationsIcon
              sx={{ width: "25px", height: "25px", color: "#004AAD" }}
            />
            {/* <AccountCircleIcon
              sx={{
                marginLeft: "16px",
                width: "33px",
                height: "33px",
                color: "#004AAD",
              }}
            /> */}
            <Dropdown
              submenu={submenu}
              onClick={() => showsubmenu()}
              page="pathology"
            />
          </div>
        </div>
        {testinclude === true ? (
          <IncludedTest
            onClick={() => handlebackbtn()}
            includedtest={includedtest}
          />
        ) : (
          <Container style={{ backgroundColor: "#FFF0F5" }}>
           {consultations?.length > 0 ?  
           (<Row
              className="mx-0"
              style={{
                height:
                  showTime === true
                    ? window.innerHeight / 0.9
                    : window.innerHeight / 1,
                overflowY: "scroll",
                width: "100%",
                width: isTabScreen ? "96%" : isTabScreen1 ? "96%" : "93%",
                paddingRight: isTabScreen ? "10px" : "10px",
              }}
            >
              {consultations?.length>0 && (consultations?.map((e, id) => {
                 const staticTitle = pathologylist[id]?.title;
                return (
                  <PathologyTest
                    title={staticTitle}
                    date={e.scheduled_date}
                    // consultations={consultations}
                    teststatus={e.scheduled_date ? e.is_completed===1 ? "COMPLETED":"SCHEDULED" : "UNSCHEDULED"}
                    drimg={"/Pathology/jondoe2.png"}
                    drname={e.doctor_firstName + " " + e.doctor_lastName}
                    drid={e.id}
                    enabledates={e.weekDates}
                    mobno={e.mobno}
                    consulted_by_doctor={e.consulted_by_doctor}
                    checkreportbtn={"Check Report"}
                    testincludedbtn={"Tests Included"}
                    scheduletestbtn={"Schedule Test"}
                    onClick3={()=>setcheckreport(e,id)}
                    onClick={() => handleClick(e,id,staticTitle)}
                    onClick2={() => handleClick1(e, id)}
                    onClick4={()=>reschedule(e,id)}
                    onClick5={()=>  viewconsultation(e)}
                    onClickreschedule={()=>handleClickreschedule(e,id,staticTitle)}
                    week={"pregnancy_week"}
                    drpost={"Phlebologist"}
                    drstatus={
                      e?.phlebologist_name
                        ? e?.phlebologist_name
                        : "Yet to be assigned"
                    }
                    phlebologistdrmobile={e.phlebologist_mobile}
                    phlebologistdrid={e.phlebologistId} 
                    id={id}
                    testinclude={testinclude}
                    weekDates={e.weekDates}
                    selectedreport={selectedreport}
                    selectedtest={selectedtest}
                    includedtest={includedtest}
                    availability={availability}
                    clicked={clicked}
                    schedulingid={schedulingid}
                    is_confirmed={e.is_confirmed}
                    is_completed={e.is_completed}
                    setRendercalendar={setRendercalendar}
                    viewindex={viewindex}
                 
                  />
                );  
              }))}
              
            </Row>):(<div className="ps-5">
            <h5 className="fw-bolder" style={{color:"rgb(0, 74, 173)"}}>Consultations from doctor Not Scheduled yet</h5>
              <p style={{color:"rgb(240, 88, 139)"}}>After Consultations scheduled by doctor it will be reflected here</p>
              </div>)} 
          
          </Container>
        )}
      </div>

      {clicked === true ? (
        <PathologyReportDetail
          date={mydata?.date}
          title={mydata?.title}
          week={mydata?.week}
          reportid={reportid}
        />
      ) : clickedschedule === true ? (
        <RightBoxofpathology
          title={"Schedule test"}
          trimesternm={t}
          drpost={mydata?.drpost}
          weekDates={mydata?.weekDates}
          drstatus={mydata?.drstatus}
          showTime={showTime}
          onClick={() => setBackbtn()}
          onClick1={onClickDay}
          consultData={consultData}
          setRenderconsult={setRenderconsult}
        />
      ) : ( rendercalendar=== true?( 
      <>
       <div style={{ backgroundColor: "white", width: "30%" }}>
        <Calendarpart
          scheduleddata={sdata}
          state={location?.state}
          dates={dates}
          timings={timings}
          info={info}
          timeData={timeData}
          objectData={objectData}
          doctorInfo={doctorInfo}
          // falseshowtime={falseshowtime}
        />
        </div>
      </>):
        (<div style={{ backgroundColor: "white" }}>
          <div style={{ width: "30vw" }}>
            <div
              style={{
                paddingTop: "48px",
                fontSize: isTabScreen ? "24px" : isTabScreen1 ? "24px" : "32px",
                color: configData.THEME_COLORS.PRIMARY,
                fontFamily: "Poppins",
                fontWeight: "500",
                color: "#F0588B",
                textAlign: "center",
              }}
            >
              Lab Details
            </div>
            <hr className="mb-0 w-100"></hr>
            <div>
              {/* div for inner details below lab details dhande */}
              <div
                className="px-4 d-flex align-items-center"
                style={{ marginTop: "25px" }}
              >
                <img alt="nt found"
                  src={"/Pathology/labimage.png"}
                  style={{
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                  }}
                ></img>
                <div
                  style={{
                    paddingLeft: "20px",
                    fontWeight: "600",
                    fontSize: isTabScreen ? "14px" : "20px",
                    color: "rgba(0, 74, 173, 1)",
                  }}
                >
                {patientdata?.pathology?.fname}{" "}{patientdata?.pathology?.lname}
                </div>
              </div>
              {/* inner details below lab details closed */}
              <div
                className="d-flex px-md-1 px-lg-4"
                style={{ paddingTop: "25px" }}
              >
                {" "}
                {/* div for lab address */}
                <div>
                  <img
                    src={"/Pathology/clinic.png"}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginTop: "5px",
                    }}
                  ></img>
                  <div
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      color: "rgba(112, 112, 112, 1)",
                    }}
                  >
                    Address
                  </div>
                </div>
                <div
                  style={{
                    fontSize: isTabScreen ? "14px" : "16px",
                    fontWeight: "400",
                    paddingLeft: "21px",
                    color: "rgba(51, 51, 51, 1)",
                  }}
                >
                    {patientdata?.sonography?.clinic_address?.address_line_1}
                </div>
              </div>

              <div
                className="d-flex px-4 align-items-center"
                style={{ paddingTop: "19px" }}
              >
                <img alt="nt found"
                  src={"/Pathology/map.png"}
                  style={{ height: "20px", width: "15px", marginTop: "5px" }}
                ></img>
                <div
                  style={{
                    color: "rgba(0, 82, 204, 1)",
                    fontSize: isTabScreen ? "12px" : "16px",
                    fontWeight: "400",
                    paddingLeft: "25px",
                  }}
                >
                  Check on map
                </div>
              </div>
              <hr className="mb-0 w-100 my-2"></hr>
              {viewBtn === true ? ( 
                  <>
                  <div className="d-flex mt-4">
          <div onClick={()=>setViewBtn(false)} className="ps-2"><img src={"/GarbhaSanskar/back.png"}
          style={{
            height: "21px",
            width: "30px",
            cursor: "pointer",
          }}
        /></div>
           <div className="ps-5">Consultation {consultno}</div>
        </div>
                <ConsultationCardDetails consultationsData = {consultationsData}  />
                </>
               ):(<>  <div
                className="text-center"
                style={{
                  marginTop: "25px",
                  color: "rgba(240, 88, 139, 1)",
                  fontSize: isTabScreen ? "20px" : "24px",
                  fontWeight: "500",
                }}
              >
                Test Summary
              </div>
              <div style={{ marginBottom: "100px" }}>
                <PathTable1 data={consultations} />
              </div></>)}
            
            </div>
          </div>
        </div>)
      )}
    </div>
  );
}

export default Pathology;
