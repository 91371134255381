import React, { useState, useEffect, useRef } from "react";
import "./OTPInput.css"; // Import your CSS file

const OTPInput = ({ length = 6, onComplete, reset, resetKey }) => {
  const [otp, setOTP] = useState(Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    console.log("valllllllllllllll", value);
    if (!isNaN(value)) {
      const updatedOTP = [...otp];
      updatedOTP[index] = value;
      setOTP(updatedOTP);

      if (index < length - 1 && value !== "") {
        inputRefs.current[index + 1].focus();
      }
      if (updatedOTP.every((digit) => digit !== "")) {
        onComplete(updatedOTP.join(""));
      }
    } else if (value === "") {
      const updatedOTP = [...otp];
      updatedOTP[index] = "";
      setOTP(updatedOTP);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "ArrowLeft" && index > 0) {
      e.preventDefault();
      inputRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < length - 1) {
      e.preventDefault();
      inputRefs.current[index + 1].focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  useEffect(() => {
    if (reset) setOTP(Array(length).fill(""));
    inputRefs.current[0].focus();
  }, [reset, length, resetKey]);

  return (
    <>
      <div className="text-center" style={{ fontSize: "13px" }}>
        Enter OTP code sent on Mobile
      </div>
      <br></br>
      <div className="otp-input-container">
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            className="otp-input"
            maxLength="1"
            value={digit}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)} // Added onKeyDown event
            ref={(inputRef) => (inputRefs.current[index] = inputRef)}
          />
        ))}
      </div>
    </>
  );
};

export default OTPInput;
