import React, { useState, useEffect } from "react";
import MiniDrawer from "../Components/MiniDrawer";
import configData from "../../config.json";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "react-responsive";
import Dropdown from "../Components/Categories/Dropdown";
import axios from "axios";
import moment from "moment";
let mydata1;
function PregnancyCare() {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 991px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 992px)" && "(max-width: 1023px)",
  });
  const isTabScreen2 = useMediaQuery({
    query: "(min-width:1024px)" && "(max-width: 1199px)",
  });
  //  isTabScreen1
  const [readclicked, setreadclicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);
  const [pregnancycare, setPregnancycare] = useState([]);
  useEffect(() => {
    getdetailpregnancy();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const getdetailpregnancy = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_consultation_summary`,
        {
          patient_id: localStorage.getItem("userId"),
        }
      );
      if (response.data.summary) {
        console.log("QQQPPP", response.data.summary);
        setPregnancycare(response.data.summary);
      }
    } catch (error) {}
  };
  useEffect(() => {
    console.log("{}{}{", pregnancycare);
  }, []);
  const handleOpen = (e) => {
    mydata1 = e;
    console.log("@@@@@@", mydata1);
    setOpen(true);
  };
  const handleclick = () => {
    setreadclicked(!readclicked);
  };
  const maindiv = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "48px",
    paddingLeft: "59px",
    paddingBottom: "27px",
    color: configData.THEME_COLORS.PRIMARY,
    fontFamily: "Poppins",
    width: "100%",
  };
  const outerwrapper = {
    backgroundColor: "#FFF0F5",
    width: "100%",
    height: "100vh",
    overflowY: "auto",
  };
  const heading = {
    fontSize: "32px",
    fontWeight: "600",
    fontFamily: "Poppins",
  };
  const containicons = {
    paddingRight: "69px",
    display: "flex",
    alignItems: "center",
  };
  const inner = {
    width: isTabScreen || isTabScreen1 ? "250px" : "300px",
    backgroundColor: "green",
    marginBottom: "20px",
    background: "#FFFFFF",
    border: "0.5px solid #CBC6C6",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.1608)",
    borderRadius: "12px",
    marginLeft: isTabScreen
      ? "10px"
      : isTabScreen1
      ? "50px"
      : isTabScreen2
      ? "30px"
      : "100px",
  };
  const inner1 = {
    width: isTabScreen || isTabScreen1 ? "250px" : "300px",
    backgroundColor: "green",
    marginBottom: "20px",
    background: "#FFFFFF",
    border: "0.5px solid #CBC6C6",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.1608)",
    borderRadius: "12px",
    height: "490px",
    marginTop: "20px",
    marginLeft: isTabScreen ? "-30px" : "-50px",
  };
  const calendar = {
    height: isTabScreen ? "12px" : "18px",
    width: isTabScreen ? "12px" : "18px",
  };
  const weekimg = {
    width: isTabScreen ? "18px" : "24px",
    height: isTabScreen ? "18px" : "24px",
  };
  const txt = {
    paddingLeft: "5px",
    color: "#004AAD",
    fontSize: isTabScreen ? "12px" : "14px",
  };
  const title = {
    paddingTop: isTabScreen ? "10px" : "15px",
    fontWeight: "500",
    fontSize: isTabScreen ? "20px" : "24px",
    textAlign: "center",
    color: "#F0588B",
  };
  const title1 = {
    paddingTop: "15px",
    fontWeight: "500",
    fontSize: isTabScreen ? "20px" : "24px",
    textAlign: "left",
    color: "#F0588B",
  };
  const drnm = {
    fontWeight: "600",
    fontSize: isTabScreen ? "16px" : isTabScreen1 ? "18px" : "20px",
    color: "#333333",
  };
  const drpost = {
    fontWeight: "400",
    fontSize: isTabScreen ? "13px" : "16px",
    color: "#333333",
    paddingTop: "4px",
  };
  const btn = {
    width: isTabScreen ? "100px" : "120px",
    height: isTabScreen ? "35px" : "45px",
    backgroundColor: "#FFFFFF",
    borderWidth: "1px 0.5px 1px 1px",
    borderStyle: "solid",
    borderColor: "#F3EFEF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.078)",
    borderRadius: "12px 0px 0px 12px",
    textAlign: "center",
  };
  const circle = {
    width: isTabScreen ? "60px" : "45px",
    height: "45px",
    backgroundColor: "#F0588B",
    borderRadius: "50%",
  };
  const para = {
    fontWeight: "400",
    fontSize: isTabScreen ? "12px" : "16px",
    marginTop: "10px",
    marginBottom: "30px",
    color: "#333333",
  };
  const rectangle = {
    width: "20px",
    height: "500px",
    backgroundColor: "#C4C4C4",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.160784)",
    borderRadius: "20px",
    marginLeft: "14px",
    marginTop: "-20px",
  };
  const progressbar = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };
  const btntxt = {
    fontWeight: "400",
    fontSize: "8px",
    color: "#333333",
  };
  const date = {
    marginLeft: "5px",
  };
  const marginadjust = {
    marginTop: isTabScreen ? "-150px" : "-200px",
  };
  const mac = {
    width: "90%",
    height: "170px",
    margin: "0px auto",
    background: "#D9D9D9",
    opacity: "0.6",
    borderRadius: "6px",
    marginTop: "20px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };
  const mac1 = {
    width: "340px",
    height: "350px",
    margin: "0px auto",
    background: "#D9D9D9",
    opacity: "0.6",
    borderRadius: "6px",
    marginTop: "20px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };
  const camera = {
    height: "50px",
    width: "50px",
  };

  const readmoremodal = {
    height: "16px",
    width: "16px",
    position: "absolute",
    bottom: "20px",
    right: "20px",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0.5px solid #CBC6C6",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.1608)",
    borderRadius: "12px 12px 0px 0px",
  };
  return (
    <>
      <div className="d-flex">
        <div>
          <MiniDrawer />
        </div>
        <div style={outerwrapper}>
          <div style={maindiv}>
            <div style={heading}>Pregnancy Care Summary</div>
            <div style={containicons}>
              <NotificationsIcon
                sx={{ width: "25px", height: "25px", color: "#004AAD" }}
              />
              <Dropdown
                submenu={submenu}
                onClick={() => showsubmenu()}
                page="pregnancyCare"
              />
            </div>
          </div>
        
          <div className="container">
            <div className="row">
              {pregnancycare.length > 0 ? (
                pregnancycare.map((e, index) => {
                  return (
                    <>
                      {index % 2 === 0 && (
                        <>
                          <div
                            className="col-5 wrapper"
                            style={{
                              marginTop: index != 0 ? "-200px" : "0px",
                            }}
                          >
                            <div
                              style={inner}
                              styles={{
                                height:
                                  readclicked === true ? "440px" : "420px",
                              }}
                            >
                              <div className="d-flex justify-content-between px-3 pt-4">
                                <div className="d-flex">
                                  <img
                                    src="/Pregnancycare/calendar.png"
                                    style={calendar}
                                  ></img>
                                  <span style={txt} className="text-uppercase">
                                    {moment(e.scheduled_date).format("MMM D")}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <img
                                    src="/Pregnancycare/weekimg.png"
                                    style={weekimg}
                                  ></img>
                                  <span style={txt}>
                                    WEEK {e?.week}
                                  </span>
                                </div>
                              </div>

                              <div className="text-uppercase" style={title}>
                                consultation {e?.consultation_number}
                              </div>
                              <div className="d-flex px-4 pt-3 justify-content-between">
                                <div>
                                  <img alt="not found"
                                    src={
                                      e?.file_path
                                        ? "http://139.59.33.170:9595/"+
                                          e?.file_path
                                        : "/Pregnancycare/weekimg.png"
                                    }
                                    style={{
                                      width: isTabScreen ? "60px" : "80px",
                                      height: isTabScreen ? "60px" : "80px",
                                    }}
                                  ></img>
                                </div>
                                <div>
                                  <div style={drnm} className="text-uppercase">
                                    {e.fname} {e.lname}
                                  </div>
                                  <div style={drpost}>{e.medical_expert}</div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center align-items-center mt-4">
                                <div style={btn}>
                                  <img src="/Pregnancycare/message.png" style={{margin:"6px auto"}}></img>
                                  <div style={btntxt}>Interpretation</div>
                                </div>
                                <div style={btn}>
                                  <img src="/Pregnancycare/report.png" style={{margin:"6px auto"}}></img>
                                  <div style={btntxt}>Report</div>
                                </div>
                              </div>
                              {readclicked === true ? (
                                <div style={para} className="px-4">
                                  Lorem ipsum dolor sit amet, consec adipiscing
                                  elit, sed do eiusmod oppenhei Lorem ipsum
                                  dolor sit amet, consec adipiscing elit, sed do
                                  eiusmod oppenhei
                                  <div className="text-end">
                                    <span onClick={() => handleclick()}>
                                      <img src="/Pregnancycare/readmore.png" style={{marginLeft:"auto"}}></img>
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div style={para} className="px-4">
                                    Lorem ipsum dolor sit amet, consec
                                    adipiscing elit, sed do eiusmod oppenhei...
                                    <div className="text-end">
                                      <span onClick={() => handleclick()}>
                                        <img src="/Pregnancycare/readmore.png" style={{marginLeft:"auto"}}></img>
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-2"
                            style={{
                              marginTop: index != 0 ? "-200px" : "0px",
                            }}
                          >
                            <div className="d-flex">
                              <div style={circle}></div>
                              <div style={date}>
                                {moment(e.scheduled_date).format("MMM D")}
                              </div>
                            </div>
                            <div style={rectangle}></div>
                          </div>
                          <div className="col-5"></div>
                        </>
                      )}

                      {index % 2 === 1 && (
                        <>
                          <div className="col-5"></div>
                          <div className="col-2" style={marginadjust}>
                            <div className="d-flex">
                              <div style={circle}></div>
                              <div style={date}>
                                {moment(e.scheduled_date).format("MMM D")}
                              </div>
                            </div>
                            <div style={rectangle}></div>
                          </div>
                          <div className="col-5 wrapper" style={marginadjust}>
                            <div style={inner1}>
                              <div className="d-flex justify-content-between px-3 pt-4">
                                <div className="d-flex">
                                  <img alt="not found"
                                    src="/Pregnancycare/calendar.png"
                                    style={calendar}
                                  ></img>
                                  <span style={txt} className="text-uppercase">
                                    {moment(e.scheduled_date).format("MMM D")}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <img alt="not found"
                                    src="/Pregnancycare/weekimg.png"
                                    style={weekimg}
                                  ></img>
                                  <span style={txt}>WEEK {e.week}</span>
                                </div>
                              </div>
                              <div className="text-uppercase" style={title}>Consultation {e?.consultation_number}</div>
                              <div className="d-flex px-4 pt-3 justify-content-between">
                                <div>
                                  <img alt="not found"
                                    src={
                                      "http://139.59.33.170:9595" +
                                      e?.doctorDetails?.profile_pic
                                    }
                                    style={{
                                      width: isTabScreen ? "60px" : "80px",
                                      height: isTabScreen ? "60px" : "80px",
                                      borderRadius:"50%",
                                    }}
                                  ></img>
                                </div>
                                <div>
                                  <div style={drnm}>
                                    {e.fname} {e.lname}
                                  </div>
                                  <div style={drpost}>{e.medical_expert}</div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center align-items-center mt-4">
                                <div style={btn}>
                                  <img src="/Pregnancycare/message.png" style={{margin:"6px auto"}}></img>
                                  <div style={btntxt}>Interpretation</div>
                                </div>
                                <div style={btn}>
                                  <img src="/Pregnancycare/report.png" style={{margin:"6px auto"}}></img>
                                  <div style={btntxt}>Report</div>
                                </div>
                              </div>
                              <div className="px-4" style={mac}>
                                <img
                                  src="/Pregnancycare/camera.png"
                                  style={camera}
                                ></img>
                                <img
                                  src="/Pregnancycare/readmore.png"
                                  style={readmoremodal}
                                  onClick={() => handleOpen(e)}
                                ></img>
                                <Modal
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box sx={style}>
                                    <div>
                                      <div className="d-flex px-3 pt-3 align-items-center">
                                        <div className="d-flex align-items-center">
                                          <img
                                            src="/Pregnancycare/calendar.png"
                                            style={calendar}
                                          ></img>
                                          <span style={txt} className="text-uppercase">
                                            {moment(e.scheduled_date).format(
                                              "MMM D"
                                            )}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            marginRight: "20px",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <img
                                            src="/Pregnancycare/vline.png"
                                            style={{
                                              width: "20px",
                                              height: "0px",
                                              border: "1px solid #818181",
                                              transform: "rotate(90deg)",
                                            }}
                                          ></img>
                                        </div>
                                        <div className="d-flex">
                                          <img
                                            src="/Pregnancycare/weekimg.png"
                                            style={weekimg}
                                          ></img>
                                          <span style={txt}>WEEK {e.week}</span>
                                        </div>
                                      </div>
                                      <div style={title1} className="px-3">
                                        {mydata1?.title}
                                      </div>
                                      <div className="px-4" style={mac1}>
                                        <img src="/Pregnancycare/camera.png"></img>
                                      </div>
                                    </div>
                                  </Box>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })
              ) : (
                <div
                  className="pt-5 pl-5"
                  style={{ fontSize: "32px", color: "#e67ea2" }}
                >
                  No pregnancy care summary to show
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PregnancyCare;
