import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import configData from "../../config.json";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import Radium, { StyleRoot } from "radium";

import HealthVitalHistory from "./HealthVitalHistory";
function RecentHistoryRightBox({ healthVitals }) {
  console.log("<><><><><>", healthVitals);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const isTabScreen2 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });
  const isScreen3 = useMediaQuery({
    query: "(min-width: 1500px)",
  });
 
  const [healthData, sethealthData] = useState([]);
  const [desc, setDesc] = useState(true);

  useEffect(() => {
    sethealthData(healthVitals);
  }, [healthVitals]);
  
  const handleSort = () => {
    const data = healthVitals;
    data?.sort(function (a, b) {
      var c = new Date(a.created_date);
      var d = new Date(b.created_date);
      return desc ? c - d : d - c;
    });
    sethealthData(data);
  };
  return (
    <div
      style={{
        // borderTopRightRadius: 55,
        // borderBottomRightRadius: 55,
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "column",
        height: isTabScreen
          ? "120vh"
          : isTabScreen2
          ? "125vh"
          : isScreen3
          ? "130vh"
          : "152vh",
        width: "30vw",
        backgroundColor: "white",
      }}
    >
      <Typography
        paragraph
        sx={{
          color: configData.THEME_COLORS.SECONDARY,
          marginTop: "59px",
          marginLeft: isTabScreen ? "20px" : "30px",
        }}
      >
        <div
          style={{
            fontSize: isTabScreen ? "18px" : "26px",
            fontWeight: "600",
            fontFamily: "Poppins",
          }}
        >
          {"Recent History"}
        </div>
      </Typography>

      <Typography
        paragraph
        sx={{
          color: configData.THEME_COLORS.PRIMARY,
          alignSelf: "flex-end",
          display: "flex",
          marginRight: "15px",
        }}
      >
        <div
          style={{
            fontSize: isTabScreen ? "14px" : "20px",
            fontWeight: "500",
            fontFamily: "Poppins",
            cursor: "pointer",
          }}
          onClick={() => {
            setDesc(!desc);
            handleSort();
          }}
        >
          {desc ? "New - Old" : "Old - New"}
        </div>

        <img
          src={"/HealthTracker/sort.png"}
          style={{
            marginTop: "8px",
            resizeMode: "contain",
            height: "18px",
            width: "18px",
          }}
        />
      </Typography>
      <div
        style={{
          overflowY: "scroll",
          height: "134vh",
        }}
      >
        {healthData && healthData.length == 0 ? (
          <div className="text-center">
            <div style={{ color: "#004aad", fontSize: "20px" }}>
              No History to show
            </div>
            <br></br>
            <p style={{ color: "#004aad", fontSize: "14px" }}>
              Please Enter your details of sugar level,blood
              pressure,temperature and weight
            </p>
          </div>
        ) : (
          <div>
            {healthData?.map((item, index) => {
              return (
                <div style={{ marginBottom: -10 }}>
                  <HealthVitalHistory
                    item={item}
                    date={moment(item?.date).format("Do MMMM YY")}
                    type1={item.type1}
                    type2={item.type2}
                    type3={item.type3}
                    type4={item.type4}
                    value1={item.sugar_level}
                    value2={item.systolic_bp}
                    value2_d={item.diastolic_bp}
                    value3={item.temperature}
                    value4={item.weight}
                    unit1={"mg/dL"}
                    unit2={"mmhg"}
                    unit3={"° Celsius"}
                    unit4={"kg"}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default RecentHistoryRightBox;
