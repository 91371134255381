import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom/dist";

const Dropdown = React.memo((props) => {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1243px)",
  });
  // const history = useHistory();
  const navigate = useNavigate();
  const [profilemenu, setProfilemenu] = useState(false);
  const [logoutmenu, setLogoutmenu] = useState(false);

  const gotoprofile = () => {
    navigate('/patient/profile');
  };
  const logout = () => {
    const userConfirmed = window.confirm("Are you sure you want to log out?");
    if (userConfirmed) {
      // var iframe = document.getElementById("newifr");
      // var iframeContentWindow = iframe.contentWindow;
      // iframeContentWindow.postMessage(
      //   {
      //     action: "save",
      //     key: "newuserId",
      //     value: 0,
      //   },
      //   "https://myprega.com"
      // );

      localStorage.removeItem("userId");
      navigate("/");
      localStorage.clear();
    }
  };

  return (
    <>
      <AccountCircleIcon
        onClick={props.onClick}
        sx={{
          marginLeft: "14px",
          width: isTabScreen ? "24px" : "33px",
          height: isTabScreen ? "24px" : "33px",
          color: "#004AAD",
          cursor: "pointer",
        }}
      />
      <div
        className={
          props.submenu === true
            ? props.page !== "dashboard"
              ? "displaybox1"
              : "displaybox"
            : "hidebox"
        }
      >
        {" "}
        <div>
          <p
            className="mb-0 py-2 px-1"
            onClick={gotoprofile}
            onMouseEnter={() => setProfilemenu(true)}
            onMouseLeave={() => setProfilemenu(false)}
            style={{
              cursor: "pointer",
              backgroundColor:
                profilemenu === true ? "rgb(143, 200, 242)" : "white",
              display: props.page == "profile" ? "none" : "block",
            }}
          >
            Profile
          </p>
          <p
            className="mb-0 py-2 px-1"
            onMouseEnter={() => setLogoutmenu(true)}
            onMouseLeave={() => setLogoutmenu(false)}
            style={{
              cursor: "pointer",
              backgroundColor:
                logoutmenu === true ? "rgb(143, 200, 242)" : "white",
            }}
            onClick={logout}
          >
            Logout
          </p>
        </div>
      </div>
    </>
  );
});

export default Dropdown;
