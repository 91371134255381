import { fabClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { PieChart, Pie, Cell } from "recharts";
import configData from "../../config.json";
function Patientlist(props) {
  console.log("Proppsss--",props);
  const [click, setClick] = useState(false);
  const [btnclick, setBtnclick] = useState(false);
  const [week, setWeek] = useState();
  const p = props.pregnancy_days;
  console.log("PREGA", p);
  const w = Math.ceil(p / 7);
  console.log("PREGAw ", w);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 991px)",
  });
  useEffect(() => {
    setWeek(w);
  }, [props.pregnancy_days]);
  const gaugedata = [
    {
      label: "Completed Consultations",
      value: parseInt(props.item?.completion_percentage),
    },
    {
      label: "Incomplete Consultations",
      value: 100 - props.item?.completion_percentage,
    },
  ];
  const nm = {
    fontWeight: "600",
    fontSize: isTabScreen
      ? props.schedule === true
        ? "10px"
        : "12px"
      : "17px",
  };
  const age = {
    fontWeight: "normal",
    fontSize: isTabScreen ? "10px" : "16px",
    lineHeight: "26px",
    color: "#333333",
  };
  const pregday = {
    fontWeight: "normal",
    fontSize: isTabScreen ? "10px" : "16px",
    lineHeight: "26px",
    color: "#333333",
  };
  const btn = {};

  // </style>
  return (
    <div className={props.schedule === true || isTabScreen ? "col-6" : "col-4"}>
      <div
        className="bg-white mx-auto mt-3"
        style={{
          width: "95%",
          border: "0.54px solid #CBC6C6",
          marginBottom: "15px",
          background: "#FFFFFF",
          borderRadius: "12.9744px",
          boxShadow:
            click === props.id
              ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
              : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
        }}
      >
        <div className="d-flex justify-content-between px-3 py-2">
          <div>
            <img alt="nt found"
              src={
                props.img1
                  ? configData.API_URL + props.img1
                  : "/profile/Group-1151.png"
              }
              style={{
                height: isTabScreen
                  ? "32px"
                  : props.schedule === true
                  ? "40px"
                  : "50px",
                width: isTabScreen
                  ? "32px"
                  : props.schedule === true
                  ? "40px"
                  : "50px",
                borderRadius: "50%",
              }}
            ></img>
          </div>
          <div className="text-end">
            <div
              style={{
                position: "relative",
                top: -40,
                right: isTabScreen ? -5 : -60,
              }}
            >
              <PieChart
                height={isTabScreen ? 100 : 130}
                width={isTabScreen ? 140 : 240}
              >
                <Pie
                  startAngle={180}
                  endAngle={0}
                  innerRadius="55%"
                  data={gaugedata}
                  dataKey="value"
                  labelLine={false}
                  blendStroke
                  isAnimationActive={true}
                  cy={"70%"}
                >
                  <Cell fill="#58CF99" />
                  <Cell fill="gray" />
                </Pie>
              </PieChart>
              <div
                style={{
                  color: "#58CF99",
                  top: isTabScreen ? -50 : -70,
                  position: "relative",
                  left: 2,
                  textAlign: "center",
                  fontSize: isTabScreen ? "20px" : "26px",
                  fontWeight: "700",
                }}
              >
                {props.item.completion_percentage
                  ? Math.floor(props.item.completion_percentage)
                  : 0}
              </div>
              <div
                style={{
                  color: "#707070",
                  top: isTabScreen ? -50 : -75,
                  position: "relative",
                  textAlign: "center",
                  fontSize: isTabScreen ? "8px" : "8px",
                  fontWeight: "500",
                }}
              >
                Pregnancy Care
                <br /> Completed
              </div>
            </div>
            {/* <div>{props.item.completion_percentage}</div> */}
          </div>
        </div>
        <div
          style={{
            paddingLeft: isTabScreen ? "12px" : "25px",
            paddingRight: isTabScreen ? "10px" : "20px",
            marginTop: "-30px",
          }}
        >
          <div style={nm}>Name: {props.name}</div>
          {/* <div>{week}</div> */}
          <div style={age}>Age: {props.age}</div>
          <div style={pregday}>Pregnancy Day: {props.pregnancy_days}</div>
          <div className="container mt-4 mb-2">
            <div className="row">
              <div className="col-4 px-0">
                <div
                  style={{
                    width: "94%",
                    height: isTabScreen ? "20px" : "30px",
                    backgroundColor: "white",
                    margin: "0px auto",
                    color: "#7C9CBF",
                    fontSize: isTabScreen
                      ? props.schedule === true
                        ? "5px"
                        : "7px"
                      : props.schedule === true
                      ? "10px"
                      : "10px",
                    border: "none",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    boxShadow:
                      props.id === props.myschedule
                        ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                        : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                  }}
                  onClick={() => {
                    props.onClick(props);
                    setClick(props.id);
                    // setBtnclick(1);
                  }}
                >
                  View Schedule
                </div>
              </div>
              {((props.isYogaExperts===false && props.isPathology===false) || 
              (props?.state?.id === 2 && props?.state?.patientid === props?.item?.id)) && (
               <div className="col-4 px-0">
                   <div
                    style={{
                      width: "94%",
                      height: isTabScreen ? "20px" : "30px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      margin: "0px auto",
                      color: "#7C9CBF",
                      fontSize: isTabScreen ? (props.schedule ? "5px" : "7px") : (props.schedule ? "10px" : "9px"),
                      border: "none",
                     cursor: "pointer",
                     display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: props.id === props.update ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset" : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                    }}
                   onClick={() => {
                      props.onClick1(props);
                   }}
                 >
                   Update Schedule
                  </div>
               </div>
              )
              // :(<div className="d-none"></div>)
              }

              <div className="col-4 px-0">
                <div
                  style={{
                    width: "94%",
                    borderRadius: "5px",
                    height: isTabScreen ? "20px" : "30px",
                    backgroundColor: "white",
                    margin: "0px auto",
                    color: "#7C9CBF",
                    fontSize: isTabScreen
                      ? props.schedule === true
                        ? "5px"
                        : "7px"
                      : props.schedule === true
                      ? "10px"
                      : "10px",
                    border: "none",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow:
                      props.id === props.profile
                        ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                        : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                  }}
                  onClick={() => {
                    props.onClick5(props);
                    // setBtnclick(props.id);
                  }}
                >
                  View Profile
                </div>
              </div>
              {props.isPathology && (
                <div className="col-4 px-0">
                  <div
                    style={{
                      width: "94%",
                      borderRadius: "5px",
                      height: isTabScreen ? "20px" : "30px",
                      backgroundColor: "white",
                      margin: "0px auto",
                      color: "#7C9CBF",
                      fontSize: isTabScreen
                        ? props.schedule === true
                          ? "5px"
                          : "7px"
                        : props.schedule === true
                        ? "10px"
                        : "10px",
                      border: "none",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow:
                        props.id === props.report
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                    }}
                    onClick={() => {
                      props.onClick2(props);
                      // setBtnclick(4);
                    }}
                  >
                    Reports
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="container mt-3 mb-4">
            <div className="row">
              {!props.isPathology && (
                <div className="col-4 px-0">
                  <div
                    style={{
                      width: "94%",
                      borderRadius: "5px",
                      height: isTabScreen ? "20px" : "30px",
                      backgroundColor: "white",
                      margin: "0px auto",
                      color: "#7C9CBF",
                      fontSize: isTabScreen
                        ? props.schedule === true
                          ? "5px"
                          : "7px"
                        : props.schedule === true
                        ? "10px"
                        : "10px",
                      border: "none",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow:
                        props.id === props.report
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                    }}
                    onClick={() => {
                      props.onClick2(props);
                      // setBtnclick(4);
                    }}
                  >
                    Reports
                  </div>
                </div>
              )}
              {(!props.isPathology && !props.isYogaExperts && props.isSonography) &&  (
                <div className="col-4 px-0">
                  <div
                    style={{
                      width: "94%",
                      borderRadius: "5px",
                      height: isTabScreen ? "20px" : "30px",
                      backgroundColor: "white",
                      margin: "0px auto",
                      color: "#7C9CBF",
                      fontSize: isTabScreen
                        ? props.schedule === true
                          ? "5px"
                          : "7px"
                        : props.schedule === true
                        ? "10px"
                        : "10px",
                      border: "none",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow:
                        props.id === props.mycasepaper
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                    }}
                    onClick={() => {
                      props.onClick3(props);
                      // setBtnclick(5);
                    }}
                  >
                    Case Paper
                  </div>
                </div>
              )}
              {(!props.isPathology && props.isSonography) && (
                <div className="col-4 px-0">
                  <div
                    style={{
                      width: "94%",
                      borderRadius: "5px",
                      height: isTabScreen ? "20px" : "30px",
                      backgroundColor: "white",
                      margin: "0px auto",
                      color: "#7C9CBF",
                      fontSize: isTabScreen
                        ? props.schedule === true
                          ? "5px"
                          : "7px"
                        : props.schedule === true
                        ? "10px"
                        : "10px",
                      border: "none",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow:
                        btnclick === 6
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                    }}
                    onClick={() => {
                      props.onClick4(props);
                      setBtnclick(6);
                    }}
                  >
                    Health Tracker
                  </div>
                </div>
              )}
              {props.isGynacology &&  <div className="col-4 px-0">
                  <div
                    style={{
                      width: "94%",
                      borderRadius: "5px",
                      height: isTabScreen ? "20px" : "30px",
                      backgroundColor: "white",
                      margin: "0px auto",
                      color: "#7C9CBF",
                      fontSize: isTabScreen
                        ? props.schedule === true
                          ? "5px"
                          : "7px"
                        : props.schedule === true
                        ? "10px"
                        : "10px",
                      border: "none",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow:
                        props.id === props.mycasepaper
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                    }}
                    onClick={() => {
                      props.onClick3(props);
                      // setBtnclick(5);
                    }}
                  >
                    Case Paper
                  </div>
                </div>}
                {(props.isGynacology) && (
                <div className="col-4 px-0">
                  <div
                    style={{
                      width: "94%",
                      borderRadius: "5px",
                      height: isTabScreen ? "20px" : "30px",
                      backgroundColor: "white",
                      margin: "0px auto",
                      color: "#7C9CBF",
                      fontSize: isTabScreen
                        ? props.schedule === true
                          ? "5px"
                          : "7px"
                        : props.schedule === true
                        ? "10px"
                        : "10px",
                      border: "none",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow:
                        btnclick === 6
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "rgba(44, 39, 56, 0.08) 0px 4px 8px",
                    }}
                    onClick={() => {
                      props.onClick4(props);
                      setBtnclick(6);
                    }}
                  >
                    Health Tracker
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patientlist;
