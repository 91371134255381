import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { webUrl, webUrlGarbhsanskar } from "../Utility/config";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import configData from "../../config.json";
function Elementalcare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [userid, setUserid] = useState();
  const [patientdata, setPatientdata] = useState([]);
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
  const navigate = useNavigate();
  useEffect(() => {
    getsinglepatientdetail(userid);
  });
  const getsinglepatientdetail = async (id) => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: id,
      });

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);

        setPatientdata(response.data.user);

        console.log("<><><><>", patientdata);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const openapp = () => {
    localStorage.setItem('isPaidUser',patientdata?.payment_status===true ? true:false)
    // const createdDate=new Date(patientdata?.created_date);
    const createdDate="10 2024 12:27:53 GMT+0530 (India Standard Time)";
    const currentDate=new Date();
    const differenceInTime = currentDate - createdDate;
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const isWithinSevenDays = differenceInDays <= 7;
  if(isWithinSevenDays)
  {
    if (patientdata?.payment_status === true) {
           window.location.href = `${webUrl}`;
        } else {
            window.location.href = `${webUrlGarbhsanskar}`;
        }
  }
   else
   {
    if (patientdata?.payment_status === true) 
      window.location.href = `${webUrl}`;
   else 
   {
    window.location.href = "/patient/dashboard";
   }
       
   }   
};
  const openalert = () => {
    alert("Please Sign up");
  };
  return (
    <>
      <Header userid={userid} />
      <div className="container-fluid position-relative" style={{width:"100%",overflowX:"hidden"}}>
      <div className="newdesign">
      </div>
      <div className="row position-absolute top-0">
      <div className="col-md-6 order-md-2 d-none d-md-block">
      <img src="/elementalcareimg3.png" alt="nt found"></img>
        </div>
      <div className="col-md-6 order-md-1 pt-5 mt-5">
          <h1 className="text-dark fw-bolder ps-5 headlineheronew  pt-5 mt-0 mt-md-5" >
          Elemental Care:Your 7-day's free exploration of new-age pregnancy care!
          </h1>
          <h1 className="ps-5 pt-5 fw-bolder headlinesubheronew">
          As the name says it all, our Elemental Package grants you the privilege to visualize how our digitally organized pregnancy care works at no cost.
            </h1>
          <Link
                  style={{ cursor: "pointer", color: "white",fontSize:"22px" }}
                  to="/pricing"
                  className="mt-5 mx-auto mx-md-0 ms-md-5 explorepkg"
                >
                  Buy Now
                </Link>
          
        </div>
      </div>
    </div>
      {/* <div className="container-fluid px-0">
        <div className="pt-5"></div>
        <h1 className="fs-1 text-center text-capitalize fw-bolder mt-3 pt-5 py-4 w-100">
          elemental care
        </h1>
        <div className="w-100 text-center mx-auto text-secondary px-4 px-lg-0">
          <p className="mb-2 fw-bold text-secondary underlineone">
            As the name says it all, our Starter Package grants you a privilege
            to visualize how online pregnancy care works at no cost
          </p>
          <br />
          <br />
        </div>
      </div> */}

      {/* <div className="containertt boxbuynow my-5">
        <div className="d-flex justify-content-between align-items-center h-100">
          <div className="">
            <h1 className="text-primary pl-2 pl-md-5 decsize mb-0">
              Elemental Care
            </h1>
          </div>
          <div className="">
            {userid === 0 || userid == null ? (
              <Link
                onClick={openalert}
                className="buy me-2 me-md-5 fw-bolder text-white"
              >
                EXPLORE
              </Link>
            ) : (
              <Link
                onClick={openapp}
                className="buy me-2 me-md-5 fw-bolder text-white"
              >
                EXPLORE
              </Link>
            )}
          </div>
        </div>
      </div> */}
      <div className="container-fluid package">
        <div className="containertt pt-2">
          <div className="row pt-5 sp">
            <div className="col-12 col-lg-6 text-left text-center d-none d-md-block">
              <img
                src="/NStarterPackage.png"
                className="img-fluid s1 mx-auto" alt="nt found"
              />
            </div>
            <div className="col-12 col-lg-6 text-left">
              <h2 className="text-left text-capitalize fw-bolder pb-4 pt-5">
                package on the house
              </h2>
              <div className="text-center mb-2">
                <img
                  src="/NStarterPackage.png"
                  className="mobiles1 d-md-none " alt="nt found"
                />
              </div>
              <p className="info1">
                We offer you the starter package at zero cost for a complete 7
                days. Subscribe to this one to access a few of the services we
                provide you within this package. Give it a shot and you'll jump
                onto buying one of the paid packages.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5 package">
        <div className="containertt pt-5">
          <div className="row pt-5 sp">
            <div className="col-12 col-lg-6 text-left text-center order-lg-2 d-none d-md-block">
              <img
                src="/starterPackage2ndfeaturenew11.png"
                id="secondfeature" className="mx-auto" alt="nt found"
              />
            </div>
            <div className="col-12 col-lg-6 text-left order-lg-1">
              <h2 className="text-left text-capitalize pb-4 pt-2 fw-bolder">
                24 X 7 availability
                <br />
                <span className="">at one click</span>
              </h2>
              <div className="text-center mb-3">
                <img
                  src="/starterPackage2ndfeaturenew11.png"
                  className="img-fluid d-md-none"
                  id="mobsecondfeature" alt="nt found"
                />
              </div>
              <p className="info1">
                Feeling nauseous and its 1 AM already? No worries, your doctor
                will see you even at this hour. With our 24x7 availability and
                emergency care option, all you need to do is click the emergency
                care button and see your health expert.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid package">
        <div className="containertt pt-2">
          <div className="row pt-5 sp">
            <div className="col-12 col-lg-6 text-left text-center d-none d-md-block">
              <div>
                <img src="/s3.png" className="s1" alt="nt found" />
              </div>
            </div>
            <div className="col-12 col-lg-6 text-left">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder">
                Sit Back
                <br />
                Every Activity Matters
              </h2>
              <div className="text-center mb-3">
                <img src="/s3.png" alt="nt found" className=" sitmobile d-md-none" />
              </div>
              <p className="info1">
                Feeling nauseous and it's 1 AM already? No worries, your doctor
                will see you even at this hour. With our 24x7 availability and
                emergency care option, all you need to do is click the emergency
                care button and see your health expert.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid package">
        <div className="containertt pt-2 pb-5">
          <div className="row pt-5 sp">
            <div className="col-12 col-lg-6 text-left text-center order-lg-2 d-none d-md-block">
              <img src="/s4.png" className="img-fluid" id="lastfeature" />
            </div>
            <div className="col-12 col-lg-6 text-left order-lg-1 pt-3">
              <h2 className="text-left text-capitalize pb-4 pt-2 fw-bolder">
                Fully-Remote
                <br />
                Cutting-Edge Tech System
              </h2>
              <div className="text-center mb-3">
                <img
                  src="/s4.png"
                  className="img-fluid d-md-none"
                  id="moblastfeature" alt="nt found"
                />
              </div>
              <p className="info1">
                Our system is fully remote and does everything you need almost
                every day during your journey. From remembering your entire data
                to keeping it all private, our fully automated dashboard is
                built with groundbreaking technology compliance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center my-5">
        {userid === 0 || userid == null ? (
          <Link
            onClick={openalert}
            className="buy mx-auto fw-bolder text-white"
          >
            EXPLORE
          </Link>
        ) : (
          <button>
          <Link
            onClick={openapp}
            className="buy mx-auto fw-bolder text-white"
          >
           EXPLORE
          </Link>
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Elementalcare;
