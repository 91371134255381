import React, { useState, useEffect} from "react";
import { useMediaQuery } from "react-responsive";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

import { curveCardinal } from "d3-shape";

function MeasurementsBox({ img, color, value, unit, title, mode, data, x, y, changeNextMonth , changePreMonth,type,month }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const [clicked, setClicked] = useState(false);
  const [localMonth, setLocalMonth] = useState(month);
  const [hover, setHover] = useState(false);

  const cardinal = curveCardinal.tension(0.2);
  const incrementMonth = () => {
    
    setLocalMonth((prevMonth) => {
     const newMonth = (prevMonth % 12) + 1 
     changeNextMonth(type,newMonth);
     return newMonth;
  });
  };
  useEffect(() => {
    // Update localMonth when the currentMonth prop changes
    setLocalMonth(month);
  }, [month]);

  const decrementMonth = () => {
    setLocalMonth((prevMonth) => 
    {
      const newMonth = prevMonth  - 1;
      const nMonth = parseInt(newMonth) === 0  ? 12 : newMonth;
      changePreMonth(type,nMonth);
      return nMonth;
   });

  };

  return (
    <div
      style={{
        borderRadius: "12px",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        width: "94%",
        backgroundColor: "#FFFFFF",
        border: "1px solid #E8E7E7",
        boxShadow: "0px 1px 30px rgba(0, 0, 0, 0.08)",
        height: "100%",
      }}
    >
      <div
        className="py-1 py-lg-2 px-1"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: isTabScreen ? "25%" : "25%",
            backgroundColor: color,
            borderRadius: "12px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginLeft:
              title !== "Temperature" && mode === "Consultation" ? "5%" : 8,
          }}
        >
          <img
           alt="nt found"
            src={img}
            style={{
              resizeMode: "contain",
              height: isTabScreen ? "20px" : "34px",
            }}
          />
        </div>
        <div
          style={{
            width: "50%",
            textAlign: "center",
            fontWeight: "600",
            fontSize: isTabScreen
              ? "9px"
              : title === "Blood Pressure"
              ? "12px"
              : "13px",
            marginLeft: "10px",
          }}
        >
          {title}
        </div>
      </div>
      {mode === "Dashboard" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: isTabScreen ? "10px" : "15px",
              marginBottom: "-10px"
            }}
          >
            <div
              style={{
                marginBottom: isTabScreen ? "4px" : "12px",
                fontSize: isTabScreen ? "10px" : "24px",
                fontWeight: "400",
                fontFamily: "Poppins",
                flexWrap: "wrap",
              }}
            >
              {!isNaN(value) ? value : 0}
            </div>
            <div
              style={{
                paddingTop : "10px",
                marginLeft: "8px",
                fontSize: isTabScreen ? "8px" : "12px",
                fontWeight: "600",
                fontFamily: "Poppins",
                color: "#747171"
              }}
            >
              {unit}
            </div>
          </div>
          <div className="row">
          <div className="col-md-6 col-sm-6 ">
          <div
            style={{
              borderRadius: "4px",
              backgroundColor: color,
              width: isTabScreen ? "40px" : "59.87px",
              height: isTabScreen ? "19px" : "24.78px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "4px 8px",
              marginLeft: "15px",
           
            }}
          >
              <div
              style={{
                fontSize: isTabScreen ? "9px" : "12px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              Normal  
            </div>
                </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="text-end">
                      {data[0]?.month_name}&nbsp;&nbsp;
                    </div>
                </div>
              </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
                <button className="btn btn-default p-0" onClick= {decrementMonth} type="button" data-type={type}><img src={"/HealthTracker/circle-left.png"}  alt="left-arrow" data-type={type} data-month={month} style={{width:"20px"}} /></button>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="text-end">
                <button className="btn btn-default  p-0"  onClick= {incrementMonth} value={type} ><img src={"/HealthTracker/circle-right.png"}  alt="Right-arrow" data-type={type}  data-month={localMonth} style={{width:"20px"}}/> </button>
                </div>
                </div>
            </div>
          
          <div
            style={{
              width: "100%",
              height: isTabScreen ? "30px" : "54px",
              alignSelf: "center",
              marginTop: "8px",
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={data}
                margin={{
                  left: 0,
                  bottom: 0,
                }}
              >

                <Area
                  type="monotone"
                  dataKey={x}
                  stroke={color}
                  stroke-border="16px"
                  fill={color}
                  fillOpacity={0.3}
                />
                <Area
                  type={cardinal}
                  dataKey={y}
                  stroke={color}
                  stroke-border="16px"
                  fill={color}
                  fillOpacity={0.3}
                />
              </AreaChart>
            </ResponsiveContainer>
            
          </div>
        </div>
        
      )}
      {mode === "Consultation" && clicked === false && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15%",
            cursor: "pointer",
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => {
            console.log("clicked", unit);
            setClicked(unit);
          }}
        >
          <img alt="nt found"
            src={"/HealthTracker/plus-circle.png"}
            style={{
              resizeMode: "contain",
              height: "8vh",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default MeasurementsBox;
