import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Alert } from "@mui/material";
import React from "react";
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const AlertShow = ({alertShowHide,alertClose ,setalertMsg ,severity}) => {
    return(
      <>
      <Dialog
        open={alertShowHide}
        onClose={alertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {/* <Alert severity="success">{"Profile Saved Successfully"}</Alert> */}
          <Alert severity={severity}>{setalertMsg}</Alert>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={alertClose}>Cancel</Button> */}
          {/* <Button onClick={alertClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      </>
    )
}

export default AlertShow;