import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
function HealthVitalHistory({
  date,
  type1,
  type2,
  type3,
  type4,
  value1,
  value2,
  value3,
  value4,
  unit1,
  unit2,
  unit3,
  unit4,
}) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  return (
    <div
      className="container"
      style={{
        marginTop: isTabScreen ? "14px" : "18px",
        borderRadius: "12px",
        width: isTabScreen ? "90%" : "85%",
        margin: "0px auto",
        backgroundColor: " rgba(240, 250, 248, 0.501961)",
        border: "1px solid #CBC6C6",
        marginBottom: "35px",
      }}
    >
      <div className="row">
        {/* <div style={{}}> */}
        <Typography
          style={{
            marginTop: isTabScreen ? "12px" : "16px",
            marginBottom: isTabScreen ? "12px" : "16px",
            textAlign: "center",
            fontSize: isTabScreen ? "12px" : "16px",
            fontWeight: "500",
            fontFamily: "Poppins",
          }}
        >
          {date}
        </Typography>

        <div className="col-6 mb-3">
          <div
            style={{
              margin: "0px auto",
              width: "96%",
              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#F8DEBD",
              }}
            >
              <Typography
                style={{
                  marginTop: "11px",
                  alignSelf: "center",
                  fontSize: isTabScreen ? "30px" : "40px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#E79B38",
                }}
              >
                {value1}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: "center",
                  fontSize: isTabScreen ? "12px" : "16px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  color: "#818181",
                  marginBottom: "10px",
                }}
              >
                {unit1}
              </Typography>
            </div>
            <div
              style={{
                marginTop: "4px",
                marginLeft: "8px",
                fontFamily: "Poppins",
                fontSize: isTabScreen ? "9px" : "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {type1}
            </div>
          </div>
        </div>
        <div className="col-6 mb-3">
          <div
            style={{
              margin: "0px auto",
              width: "96%",

              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#D0FBFF",
              }}
            >
              <Typography
                style={{
                  marginTop: "11px",
                  alignSelf: "center",
                  fontSize: isTabScreen ? "30px" : "40px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#478F96",
                }}
              >
                {value2}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: "center",
                  marginBottom: "10px",
                  fontSize: isTabScreen ? "12px" : "16px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  color: "#818181",
                }}
              >
                {unit2}
              </Typography>
            </div>
            <div
              style={{
                marginTop: "4px",
                marginLeft: "8px",
                fontFamily: "Poppins",
                fontSize: isTabScreen ? "9px" : "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {type1}
            </div>
          </div>
        </div>

        <div className="col-6 mb-3">
          <div
            style={{
              margin: "0px auto",
              width: "96%",

              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#FBF0F3",
              }}
            >
              <Typography
                style={{
                  marginTop: "11px",
                  alignSelf: "center",
                  fontSize: isTabScreen ? "30px" : "40px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#CA6B6E",
                }}
              >
                {value3}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: "center",
                  fontSize: isTabScreen ? "12px" : "16px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  color: "#818181",
                  marginBottom: "10px",
                }}
              >
                {unit3}
              </Typography>
            </div>
            <div
              style={{
                marginTop: "4px",
                marginLeft: "8px",
                fontFamily: "Poppins",
                fontSize: isTabScreen ? "9px" : "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {type3}
            </div>
          </div>
        </div>
        <div className="col-6 mb-3">
          <div
            style={{
              margin: "0px auto",
              width: "96%",

              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "rgba(123, 151, 165, 0.25098)",
              }}
            >
              <Typography
                style={{
                  marginTop: "11px",
                  alignSelf: "center",
                  fontSize: isTabScreen ? "30px" : "40px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#707070",
                }}
              >
                {value4}
              </Typography>
              <Typography
                style={{
                  marginTop: -10,
                  alignSelf: "center",
                  marginBottom: "10px",
                  fontSize: isTabScreen ? "12px" : "16px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  color: "#818181",
                }}
              >
                {unit4}
              </Typography>
            </div>
            <div
              style={{
                marginTop: "4px",
                marginLeft: "8px",
                fontFamily: "Poppins",
                fontSize: isTabScreen ? "9px" : "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {type4}
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </div>
  );
}

export default HealthVitalHistory;
