import React, { useEffect, useState } from "react";
import configData from "../config.json";
import { useHistory, useLocation } from "react-router-dom";
import Radium, { StyleRoot } from "radium";
import { fadeIn } from "react-animations";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MiniDrawer from "../Components/MiniDrawer";
import Dropdown from "../Components/Dropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Badge from "@mui/material/Badge";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

const PaymentsData = [
  {
    Date: "29 March 2022",
    Name: "Jane Doe",
    Amount: "40,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
    id: "1",
  },
  {
    Date: "26 March 2022",
    Name: "Max Jarry",
    Amount: "25,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
    id: "2",
  },
  {
    Date: "23 March 2022",
    Name: "Anne Doe",
    Amount: "45,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
    id: "3",
  },
  {
    Date: "28 March 2022",
    Name: "John Wick",
    Amount: "50,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
    id: "4",
  },
  {
    Date: "29 March 2022",
    Name: "Jane Doe",
    Amount: "40,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
    id: "5",
  },
  {
    Date: "26 March 2022",
    Name: "Max Jarry",
    Amount: "25,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
  },
  {
    Date: "28 March 2022",
    Name: "John Wick",
    Amount: "50,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
  },
  {
    Date: "29 March 2022",
    Name: "Jane Doe",
    Amount: "40,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
  },
  {
    Date: "26 March 2022",
    Name: "Max Jarry",
    Amount: "25,000",
    PaymentLink:
      "3cxvdvX4r8PgBwkRXUevDgCoS-OLaHWrjmrcr0Uras/edit#gid=1433316305",
  },
];

function PaymentsTable() {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const location = useLocation();

  return (
    <div style={{ marginBottom: "5%" }}>
      <table class="payment-table2">
        <thead>
          <tr
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "600",
              color: configData.THEME_COLORS.SECONDARY,
              backgroundColor: "rgba(243, 246, 249, 1)",
            }}
          >
            <div
              class="theading"
              style={{
                width: isTabScreen ? "75vw" : "80vw",
                height: "10vh",
                display: "flex",
                alignItems: "center",
                backgroundColor: "lightblue",
                borderRadius: "6px",
                marginLeft: "3%",
              }}
            >
              <th
                style={{
                  marginLeft: "6%",
                  textAlign: "center",
                  fontSize: isTabScreen ? "10px" : "16px",
                }}
              >
                DATE
              </th>
              <th
                style={{
                  textAlign: "center",
                  fontSize: isTabScreen ? "10px" : "16px",
                  marginLeft: isTabScreen ? "18%" : "18%",
                  width: 50,
                }}
              >
                PATIENT NAME
              </th>
              <th
                style={{
                  textAlign: "center",
                  fontSize: isTabScreen ? "10px" : "16px",
                  marginLeft: isTabScreen ? "10%" : "12%",
                }}
              >
                AMOUNT
              </th>
              <th
                style={{
                  textAlign: "left",
                  fontSize: isTabScreen ? "10px" : "16px",
                  marginLeft: isTabScreen ? "20%" : "23%",
                  minWidth: isTabScreen ? "15%" : "16%",
                }}
              >
                RECEIPT LINK
              </th>
            </div>
          </tr>
        </thead>
        <tbody>
          <div>
            {PaymentsData.map((item, index) => (
              <tr>
                <td
                  style={{
                    fontSize: isTabScreen ? "13px" : "17px",
                    fontWeight: "400",
                    textAlign: "center",
                    color: "#464E5F",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 20,
                      width: "15vw",

                      //   backgroundColor: 'cyan'
                    }}
                  >
                    {item.Date}
                  </div>
                </td>
                <td
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    marginTop: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "15%",
                        fontSize: isTabScreen ? "13px" : "17px",
                        fontWeight: "600",
                        width: "15vw",
                        textAlign: "center",
                        color: "#464E5F",
                        // backgroundColor: 'lightblue'
                      }}
                    >
                      {item.Name}
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    fontSize: isTabScreen ? "14px" : "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    color: "#464E5F",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "15%",
                      width: "15vw",
                      //   backgroundColor: 'yellow'
                    }}
                  >
                    {item.Amount}
                  </div>
                </td>
                <td
                  style={{
                    fontWeight: "500",
                    textAlign: "center",
                    color: "rgba(0, 74, 173, 0.8)",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "31%",
                      marginRight: "20px",
                      fontSize: isTabScreen ? "7px" : "17px",
                      width: "20vw",
                      // marginBottom: '20px'
                      //   backgroundColor: 'red'
                    }}
                  >
                    {item.PaymentLink}
                  </div>
                </td>
              </tr>
            ))}
          </div>
        </tbody>
      </table>
    </div>
  );
}

function Payment() {
  const [viewAll, setView] = useState(false);
  const [submenu, setSubmenu] = useState(false);
  const [profileData, setProfileData] = useState([]);

  const [notification, setNotification] = useState([]);
  const [shownotification, setShownotification] = useState(false);
  const [count, setCount] = useState(0);

  const location = useLocation();
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const isScreen3 = useMediaQuery({
    query: "(min-width: 2000px)",
  });

  const getapi1 = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          console.log("fetched$$$$$$$$$$$$$$$$$$$$$", response.data);
          setProfileData(response.data);
          // getpatientlist();
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    getapi1();
    fetchNotifications();
  }, []);
  const fetchNotifications = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/get_notification`,
        {
          receiver_id: profileData.id,
        }
      );

      if (response.status === 200) {
        const l = response.data.notifications.length;
        console.log(
          "GetNotification Response",
          response.data.notifications,
          response.data.notifications[l - 1]
        );

        setNotification(response.data.notifications);
        let cnt = response.data.notifications[l - 1].notification_count;
        // console.log("cnt>", cnt);
        setCount(cnt);
      } else {
        console.log("Error in getting notification");
      }
    } catch (error) {
      console.log("Error in getting catch block", error.message);
    }
  };
  const readnotification = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/read_notification`,
        { receiver_id: profileData.id }
      );
      if (response.status === 200) {
        console.log("successfully read ");
      } else {
        console.log("Error in reading notification");
      }
    } catch (error) {
      console.log("Error", error.message);
    }
    fetchNotifications();
  };
  const shownotificationofdr = () => {
    setShownotification(!shownotification);
    readnotification();
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <StyleRoot style={{ width: "100%", marginLeft: isTabScreen ? "-3%" : 0 }}>
        <div className="test" style={styles.fadeIn}>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              height: window.innerHeight,
              backgroundColor: configData.THEME_COLORS.BACKGROUND,
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "50px",
              }}
            >
              <Typography
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                  width: "182px",
                  height: "48px",
                  marginLeft: "55px",
                }}
              >
                <div
                  style={{
                    fontSize: isScreen3 ? "42px" : "32px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  {"Payment"}
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Badge
                  badgeContent={count?.toString()}
                  color="primary"
                  overlap="circular"
                  style={{ left: -5, cursor: "pointer" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#FFFFFF",
                      backgroundColor: "#E42346",
                    },
                  }}
                >
                  <NotificationsIcon
                    onClick={shownotificationofdr}
                    sx={{
                      width: isScreen3 ? "42px" : "25px",
                      height: isScreen3 ? "43px" : "25px",
                      color: "#004AAD",
                      cursor: "pointer",
                    }}
                  />
                </Badge>
                <Dropdown
                  submenu={submenu}
                  profileData={profileData}
                  onClick={() => showsubmenu()}
                  page="communication"
                />
              </div>
            </div>

            <div
              style={{
                width: isTabScreen ? "80vw" : "85vw",
                backgroundColor: "#FFFFFF",
                border: "1px solid #E8E7E7",
                boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.08)",
                borderRadius: "12px",
                marginLeft: "45px",
                marginRight: "45px",
                marginTop: "20px",
                height: viewAll ? "85%" : "72%",
                overflowY: viewAll ? "scroll" : "hidden",
              }}
            >
              {viewAll ? (
                <div
                  style={{
                    display: "flex",
                    marginLeft: isTabScreen ? "80%" : "82%",
                    marginTop: 10,
                    marginRight: "20px",
                    marginBottom: "2%",
                  }}
                >
                  <div
                    style={{
                      alignItems: "right",
                      marginRight: "10px",
                      fontSize: isTabScreen
                        ? "12px"
                        : isScreen3
                        ? "30px"
                        : "16px",
                      fontWeight: "600",
                      color: configData.THEME_COLORS.PRIMARY,
                    }}
                  >
                    New - Old
                  </div>
                  <img
                    src="/Communication/sort.png"
                    style={{
                      height: isTabScreen ? "16px" : "22px",
                      width: isTabScreen ? "16px" : "22px",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "5px",
                    }}
                    onClick={() => console.log("sort ")}
                  />
                  <div onClick={() => setView(!viewAll)}>
                    <img
                      src="/Communication/upArrow.png"
                      style={{
                        height: isScreen3 ? "23px" : "13px",
                        width: isScreen3
                          ? "31px"
                          : isTabScreen
                          ? "19px"
                          : "21px",
                        cursor: "pointer",
                        marginRight: "10px",
                        marginTop: isTabScreen ? "-4px" : "6px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: isScreen3 ? "30px" : "16px",
                    fontWeight: "600",
                    color: configData.THEME_COLORS.PRIMARY,
                    marginLeft: isTabScreen ? "85%" : "90%",
                    marginTop: "10px",
                    marginRight: "20px",
                    cursor: "pointer",
                    marginBottom: "2%",
                  }}
                  onClick={() => setView(!viewAll)}
                >
                  View All
                </div>
              )}
              <PaymentsTable />
            </div>
          </div>
        </div>
      </StyleRoot>
    </div>
  );
}

export default Payment;
