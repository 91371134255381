import React from 'react'
import configData from '../../config.json'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'

function Rightsidereport ({ consultationdata }) {
  const isTabScreen = useMediaQuery({
    query: '(min-width: 768px)' && '(max-width: 1023px)'
  })
  const d=moment().format('DD MMMM YYYY')
console.log("++",consultationdata);
  const headingconsultation = {
    paddingTop: '25px',
    fontSize: isTabScreen ? '20px' : '24px',
    color: '#004AAD',
    fontFamily: 'Poppins',
    fontWeight: '500',
    textAlign: 'center'
  }
  const date = {
    paddingTop: '20px',
    fontSize: isTabScreen ? '14px' : '18px',
    color: '#004AAD',
    fontWeight: '400'
  }
  const paddingleft = {
    paddingLeft: '20px',
    paddingRight: '20px'
  }
  const p = {
    fontSize: isTabScreen ? '14px' : '20px',
    fontWeight: '600',
    paddingTop: '2px',
    color: '#333333'
  }
  const p1 = {
    fontSize: isTabScreen ? '14px' : '18px',
    fontWeight: '400',
    paddingTop: '3px',
    color: '#333333'
  }
  const pres = {
    fontSize: isTabScreen ? '14px' : '20px',
    fontWeight: '600',
    paddingTop: '40px',
    color: '#333333',
    paddingBottom: '20px'
  }
  const imgreport = {
    width: isTabScreen ? '80px' : '110px',
    height: isTabScreen ? '90px' : '143px'
  }

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          paddingTop: '48px',
          fontSize: isTabScreen ? '24px' : '30px',
          color: configData.THEME_COLORS.PRIMARY,
          fontFamily: 'Poppins',
          fontWeight: '500',
          color: '#F0588B',
          textAlign: 'center'
        }}
      >
        Prescription & Reports
      </div>
      <hr className='w-100'></hr>
      <div style={paddingleft}>
        <div style={headingconsultation}>{consultationdata.title}</div>
        <div style={date}>{d} </div>
        <div style={p}>Consulted Dr.{consultationdata?.doctor_firstName}{" "}{consultationdata?.doctor_lastName} for gynaec test</div>
        <div style={p1}>Dr. {consultationdata?.doctor_firstname}{" "}{consultationdata?.doctor_lastname} shared 2 records for Mrs.{consultationdata.first_name}{" "}{consultationdata?.last_name}</div>
        <div style={pres}>Prescription</div>
        <div className='text-center'>
          <img src='/DoctorConsultation/Reports.png' style={imgreport}></img>
        </div>
        <div style={pres}>Report</div>
        <div className='text-center'>
          <img src='/DoctorConsultation/Reports.png' style={imgreport}></img>
        </div>
      </div>
    </div>
  )
}

export default Rightsidereport
