import * as React from "react";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import configData from "../config.json";
import { useLocation ,useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import AlertShow from "./AlertShow";

// import Doctor_consultation from '../Pages/Doctor_consultation'
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const drawerMenu = [
  "Dashboard",
  "Import Dataset",
  "Query Editor",
  "Big Data Flow",
  "My Flows",
];
export default function MiniDrawer({ title, userName, pregnancyDay }) {
  // useEffect(() => {
  //   getapi1();
  // });
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const history = useHistory();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");

  function handleOnClick(index) {
    // console.log('INNN', index)
    if (index === 0) {
      getapi1(0);
    }
    if (index === 1) {
      getapi1(1);
    }
    if (index == 2) {
      getapi1(2);
    }
    if (index == 3) {
      getapi1(3);
    }
    // history.push("/Payment");
    if (index == 4) {
      getapi(4);
      // history.push("/Profile");
    }
  }
  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const getapi1 = async (index) => {
    // const pathname =
    //   id == 0
    //     ? "/Dashboard"
    //     : id == 1
    //     ? "/Patient"
    //     : id == 2
    //     ? "/Communication"
    //     : id == 3
    //     ? "/Payment"
    //     : "";
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          console.log("@@@fetched$$$$$$$$$$$$$$$$$$$$$", response.data);
          setProfileData(response.data);

          const isDetail =
            response.data.user?.phone &&
            response.data.user?.phone2 &&
            response.data.user?.languages_name != null &&
            response.data.user?.email != null &&
            response.data.user?.gender != null &&
            response.data.user?.story != null &&
            response.data.clinics[0]?.state_name != null &&
            response.data.clinics[0]?.city_name != null &&
            response.data.clinics[0]?.name != null &&
            response.data.clinics[0]?.phone != null &&
            response.data.clinics[0]?.email != null &&
            response.data.clinics[0]?.address_line_1 &&
            response.data.educations[0]?.name != null &&
            response.data.educations[0]?.college != null &&
            response.data.educations[0]?.completion_year != null &&
            response.data.educations[0]?.experience != null &&
            response.data.medical_reg[0]?.reg_num != null &&
            response.data.medical_reg[0]?.reg_council != null &&
            response.data.medical_reg[0]?.reg_year != null &&
            response.data.user?.profile_pic;

          if (!isDetail) {
            var msg = "First you have to complete your profile";
            const error_color = "error";
            handleAlertMsg(error_color, msg);
          } else {
            if (index == 0) {
              navigate("/doctor/Dashboard");
            }
            if (index == 1) {
              navigate("/doctor/Patient");
            }
            if (index == 2) {
              navigate("/doctor/Communication");
            }
            if (index == 3) {
              navigate("/doctor/Payment");
            }
          }
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const getapi = async (index) => {
    const pathname = index == 4 && "/doctor/Profile";
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          console.log("fetched$$$$$$$$$$$$$$$$$$$$$", response.data);
          // history.push({
          //   pathname,
          //   state: { profileData: response.data }, // Pass the fetched profileData
          // });
          navigate(pathname,{state: { profileData: response.data }})
          // Now you have the profile data, you can render the profile page
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <div
        style={{
          backgroundColor: "#9ec7e8",
          minWidth: "100px",
          maxWidth: "100px",
          display: "flex",
          flex: 1,
        }}
      >
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              minWidth: "100px",
            },
          }}
        >
          <DrawerHeader>
            <img
              src={"/logo-removebg.png"}
              style={{
                resizeMode: "contain",
                height: "80px",
                width: "80px",
                margin: "auto",
              }}
            />
          </DrawerHeader>
          <List sx={{ mt: 2 }}>
            {drawerMenu.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleOnClick(index)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      marginBottom: 2,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index === 0 ? (
                      <Tooltip title="Dashboard" placement="right-start">
                        <img
                          src={
                            pathname == "/doctor/Dashboard"
                              ? "/dashboard2.png"
                              : "/dashboard.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "30px",
                            width: "30px",
                          }}
                          alt="Dashboard"
                        />
                      </Tooltip>
                    ) : index === 1 ? (
                      <Tooltip title="Patient" placement="right-start">
                        <img
                          src={
                            pathname == "/doctor/Patient"
                              ? "/PatientDark.png"
                              : "/Icon-map-doctor.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "30px",
                            width: "30px",
                          }}
                          alt="patientDark"
                        />
                      </Tooltip>
                    ) : index === 2 ? (
                      <Tooltip title="Communication" placement="right-start">
                        <img
                          src={
                            pathname == "/doctor/Communication"
                              ? "/communicationDark.png"
                              : "/communicationIcon.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "30px",
                            width: "30px",
                          }}
                        />
                      </Tooltip>
                    ) : index === 3 ? (
                      <Tooltip title="Payment" placement="right-start">
                        <img
                          src={
                            pathname == "/doctor/Payment"
                              ? "/paymentDark.png"
                              : "/payments.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "25px",
                            width: "30px",
                          }}
                        />
                      </Tooltip>
                    ) : index === 4 ? (
                      <Tooltip title="Profile" placement="right-start">
                        <img
                          src={
                            pathname == "/doctor/Profile"
                              ? "/profileDark.png"
                              : "/profile.png"
                          }
                          style={{
                            resizeMode: "contain",
                            height: "30px",
                            width: "30px",
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </Box>
  );
}
