import React from "react";
import { useEffect} from "react";
import { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
function Termsofuse() {
   const [userid, setUserid] = useState(0);
  const supportmyprega = () => {
    
    const mailtoLink = `mailto:info@myprega.com?subject=About&body=" "`;

window.location.href = mailtoLink;

  };
 
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div>
      <Header userid={userid} />
      <div className="px-5 pt-5">
        <h2 className="text-center pt-5 pb-3 fw-bolder py-4">TERMS OF USE</h2>
        <h3 className="text-center pb-3">
          Welcome to myprega.com which is registered brand of Remedinart Medico
          Services Private Limited’s Terms of Use...
        </h3>
        <p>
          This Platform and Application is created and operated by myprega.com
          which is registered brand of Remedinart Medico Services Private
          Limited, a company incorporated under the provisions of the Companies
          Act 2014 and having its registered office at “ Plot No. 26, Parag
          Park, Malhar Chowk, Station Road, Ahmednagar, Maharashtra- 414001”
          represented by its Directors, hereinafter referred to as the “Company”
          (where such expression shall, unless repugnant to the context thereof,
          be deemed to include its respective legal heirs, representatives,
          administrators, permitted successors and assigns) are operating this
          Platform and the Mobile Application on the Brand Name of “Myprega”.
        </p>
        <br></br>
        <p>
          This legal agreement is an electronic record in terms of the Indian
          Information Technology Act, 2000 and rules there under as applicable
          and the amended provisions about electronic records in various
          statutes as amended by the Indian Information Technology Act, 2000.
          This electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </p>
        <br></br>
        <p>
          This legal document is published in accordance with the provisions of
          Rule 3 (1) of the Indian Information Technology (Intermediaries
          guidelines) Rules, 2011 and Rule 4 of the Information Technology
          (Reasonable security practices and procedures and sensitive personal
          data or information) Rules, 2011 of Information Technology Act, 2000
          amended through Information Technology Amendment Act, 2008 that
          require publishing the Terms of Services and practices for access or
          usage of “Myprega” (“platform”)
        </p>
        <br></br>
        <p>
          The creator of these Terms of Service ensures steady commitment to
          Your privacy concerning the protection of your invaluable information.
          This document contains information about the Mobile Application
          “Myprega” (hereinafter referred to as the “Platform”).
        </p>
        <br></br>
        <p>
          For the purpose of these Terms of Use (“Terms”), wherever the context
          so requires, “We”, “Our”, and “Us” shall mean and refer to the
          Platform and/or the Company myprega.com which is registered brand of
          Remedinart Medico Services Private Limited, as the context so
          requires. “You”, “Your”, “Yourself”, “User” shall mean and refer to
          natural and legal individuals who shall be Users of this Platform
          either availing the services of the platform and listed on the
          platform as a service provider and the same shall be as the context so
          requires and who is competent to enter into binding contracts, as per
          Indian laws. “Third Parties” refer to any Application, Company or
          individual apart from the Users and the creator of this platform.
          “Platform” refers to the Mobile Application created by the Company
          which provides the User to avail services of or from the Company
          through the Mobile Application.
        </p>
        {/* General Terms */}
        <h3 className="text-center fw-bolder py-4">GENERAL TERMS</h3>
        <ul>
          <li>
            The Company provides services through the Website as a marketplace
            and facilitates the Users to avail Pregnancy Care Service, Online
            Doctor Consultancies, Sonography/Ultrasounds Scans, diagnostic test/
            packages facilities offered by Third Party Labs through the Website.
          </li>
          <li>
            The Company is not and shall not be responsible for any sample
            collected, tests conducted and reports generated by the Third Party
            Labs and do not deal with any of Third Party Labs’ client or patient
            managed by Third Party Labs through the Website and only provides
            facilitation Services to the Users through the Website.
          </li>
          {/* Include other list items as needed */}
          <li>
            Use of the Website may require the Third Party Labs to use software
            and the Third Party Labs have to ensure the procurement of such
            software from the concerned providers. Users and the Third Party
            Labs agree to use the Website and the materials provided therein
            only for purposes that are permitted by (a) these Terms of Use; and
            (b) any applicable law(s), regulation or generally accepted
            practices or guidelines in the relevant jurisdictions.
          </li>
          <li>
            The Company will ensure Users are provided confirmed appointments on
            the Book facility. However, it has no liability if such an
            appointment is later cancelled by the Practitioner, or the same
            Practitioner is not available for appointment. Provided, it does not
            fall under in which case the terms of this program shall apply.
          </li>
          <li>
            The headings of each section in these Terms or Privacy Policy are
            only for the purpose of organizing the various provisions under
            these Terms and the Privacy Policy in an orderly manner and shall be
            used by either Party to interpret the provisions contained herein in
            a manner as may apply to the User. Further, it is specifically
            agreed to by the Parties that the headings shall have legal or
            contractual value on the User availing the services from the
            Platform.
          </li>
          <li>
            The use of this Platform by the Users is solely governed by these
            Terms, Privacy Policy and as well as other policies that may be
            uploaded on the platform the purpose of the Users effective
            applicability, and any modifications or amendments made thereto by
            the Company, from time to time, at its sole discretion. If you as a
            User continue to access and use this Platform, you are agreeing to
            comply with and be bound by the following Terms and Conditions,
            Privacy Policy and such other policy as that may be available and
            applicable for the Users on the Platform.
          </li>
          <li>
            The User expressly agrees and acknowledges that these Terms and
            Policy are co-terminus in nature and that expiry/termination of
            either one will lead to the termination of the other.
          </li>
          <li>
            The User unequivocally agrees that these Terms and the
            aforementioned Policies constitute a legally binding agreement
            between the User and the Company, and that the User shall be subject
            to the rules, guidelines, policies, terms, and conditions applicable
            to any service that is provided by the Platform, and that the same
            shall be deemed to be incorporated into these Terms, and shall be
            treated as part and parcel of the same. The User acknowledges and
            agrees that no signature or express act is required to make these
            Terms and the Policy binding on the User and that the User’s act of
            visiting any part of the Platform constitutes the User’s full and
            final acceptance of these Terms and the aforementioned Policy.
          </li>
          <li>
            The Company reserves the sole and exclusive right to amend or modify
            these Terms without any prior permission or intimation to the User,
            and the User expressly agrees that any such amendments or
            modifications shall come into effect immediately. If the User does
            not adhere to the changes, they must stop using the Services at
            once. Their continuous use of the Services will signify your
            acceptance of the changed terms.
          </li>
        </ul>
        {/* Platform Overview */}
        <h3 className="text-center fw-bolder py-4">PLATFORM OVERVIEW</h3>
        <p>
          The 'platform/website/mobile application' is owned, created and
          operated by the Company and is operated under the brand name and
          domain name{" "}
          <span
            className="fw-bolder text-primary"
            style={{ fontStyle: "italic" }}
          >
            “
            <Link href="https://www.myprega.com" target="_blank">
              www.myprega.com
            </Link>
            ”.
          </span>{" "}
          The Company is a leading online one-stop healthcare
          'platform/website/mobile application' providing a wide range of
          medical services to its user’s such as Complete Pregnancy Care (Which
          Includes 8 Medical Experts Consultations, Pathology Lab Tests, &
          Sonography/Ultrasound Scans), Blood availability at a rapid pace (Only
          Showing of Availability), Online Doctor consultation and Veterinary
          care.
        </p>
        {/* Services Offered */}
        <h6>The 'platform/website/mobile application' offers:</h6>
        <ol>
          <li>
            Total Pregnancy cares for the whole term of Nine months (excluding).
            All details and confidential information shared between the patient
            and consulting doctor(s) are only available to them, and not to the
            company or the domain.
          </li>
          <li>
            Availability of Blood and contacts of Blood Banks at a rapid pace.
          </li>
          <li>Veterinary consultancy and related services.</li>
          <li>
            Diagnostic services being offered by various third-party diagnostic
            centres <span className="fw-bolder">(“Third Party Labs”);</span>
          </li>
          <li>
            Online medical consultancy services/second opinion being offered by
            third-party independent Doctors or Registered Medical Practitioners
            <span className="fw-bolder">(“Medical Experts”);</span>
          </li>
          <li>
            Online appointment booking for leading Doctors (by Doctor Himself,
            By Nursing home or a Hospital{" "}
            <span className="fw-bolder">
              (“Third Party Appointment booking”);
            </span>
          </li>
          <li>
            Online advertisements of various sponsors advertising and marketing
            their goods and services{" "}
            <span className="fw-bolder">(“Third Party Advertisers”)</span>
          </li>
          <li>
            The Third Party Pharmacies, Third-Party Labs, Medical Experts,
            <span className="fw-bolder">(“Nursing Experts”)</span>,{" "}
            <span className="fw-bolder"></span>(“Third Party Health Food
            Providers”),{" "}
            <span className="fw-bolder">
              (“Third Party Appointment booking”)
            </span>{" "}
            and the Third Party Advertisers are collectively referred to as the{" "}
            <span className="fw-bolder">“Third Party Service Providers”</span>.
            Further, the platform/website/mobile application also serves as an
            information platform providing health and wellness related
            information to the Users accessing the Platform (The services of
            Third Party Services Provider and the information services is
            collectively referred to as the{" "}
            <span className="fw-bolder">“Services”</span>).
          </li>
          <li>
            <span className="fw-bolder">Lab Test </span>– Registered users can
            avail of lab tests services from the vendors through the platform by
            sitting at home and can view and receive the results online on the
            account created on the platform.
          </li>
          <li>
            <span className="fw-bolder">Book Online Doctor’s Appointment </span>
            - The platform shall additionally provide online medical
            consultation to its registered users. Medical consultation services
            shall be provided by Registered and Reputed Medical practitioners
            who are specialised in the area of the medical issue as specified by
            the User. The users may however apart from an online consultation as
            well as avail online appointment and doctor booking facility from
            leading and reputed doctors in their locality.
          </li>
        </ol>
        {/* Advertising Guidelines */}
        <br></br>
        <h6 className="fw-bolder d-inline">
          Advertising Guidelines for the Platform
        </h6>
        <p className="d-inline">
          As part of the Services provided by Us; We facilitate and allow
          Third-Party Advertisers to place advertisements on the Platform.
          Accordingly, there are guidelines (as listed hereinbelow) which the
          Third Party Advertisers have to follow for placing such advertisements
          (the “Advertising Policy”).
        </p>
        <br></br>
        <h6 className="fw-bolder d-inline">
          Advertising Guidelines for the Platform
        </h6>
        <p className="d-inline">
          As part of the Services provided by Us; We facilitate and allow
          Third-Party Advertisers to place advertisements on the Platform.
          Accordingly, there are guidelines (as listed hereinbelow) which the
          Third Party Advertisers have to follow for placing such advertisements
          (the “Advertising Policy”).
        </p>
        {/* Prohibited Content */}
        <h6 className="fw-bolder d-inline">For the Users</h6>
        <p>
          Company clearly distinguishes between the editorial content and
          content that is created or provided by one of Our Third-Party
          Advertisers. The advertisements will be labelled as "sponsored", "from
          our Advertisers" or "advertisement". This content will not be reviewed
          by Our in-house editorial staff and shall not be subject to Our
          editorial policy (as set out herein below) but shall be subject to the
          Advertising Policy, these Terms of Use (except the editorial policy)
          and the Privacy Policy.
        </p>
        <h6 className="fw-bolder d-inline">For the Third-Party Advertisers:</h6>
        <p>
          The Third-Party Advertisers must be honest about the products or
          services their advertisements promote; the advertisement shall not
          create unrealistic expectation and must not be misleading or
          offending; must be responsible and of the highest standards and
          without compromising the consumer protection. The Advertising Policy
          applies to all the advertisements, listed or sought to be listed, on
          the Platform.
        </p>
        <h6 className="fw-bolder d-inline">General Rules:</h6>
        <p>
          All the advertisements must comply with the Advertising Policy, the
          terms of these Terms of Use (except the editorial policy) and the
          Privacy Policy. Company may, at any time and without having to serve
          any prior notice to the Third Party Advertisers, (i) upgrade, update,
          change, modify, or improve the Platform or a part thereof in a manner
          it may deem fit, and (ii) change the content of the Advertising Policy
          and/ or these Terms of Use and/ or the Privacy Policy. It is the
          responsibility of the Third Party Advertisers, in such cases, to
          review the terms of the Advertising Policy and/ or these Terms of Use
          and/ or the Privacy Policy, from time to time. Such changes shall be
          made applicable when they are posted. Companies may also alter or
          remove any content from the Platform without notice and liability. The
          Third-Party Advertisers are also responsible for ensuring that their
          advertisements comply with all applicable law(s) in India and any
          other jurisdiction that such Third Party Advertiser(s) are based out
          of, industry codes, rules and regulations in each geographic area
          where the advertisements will run. All disclosures in the
          advertisements must be clear and conspicuous.
        </p>
        <h6 className="fw-bolder d-inline">Review:</h6>
        <p>
          All the advertisements are subject to the review and approval of the
          Company. The company reserves the right to reject or remove any
          advertisement at its sole discretion for any reason. Further, the
          Company also reserves the right to request modifications to any
          advertisement and to require factual substantiation for any claim made
          in an advertisement.
        </p>
        <h6 className="fw-bolder d-inline">Prohibited Content</h6>
        <p>
          The advertisements must not infringe the intellectual property,
          privacy, publicity, copyright, or other legal rights of any person or
          entity. The advertisements must not be false, misleading, fraudulent,
          defamatory, or deceptive. The following advertisement content is
          prohibited:
        </p>
        {/* Prohibited Advertisements */}
        <ul className="ps-4">
          <li>
            content that demeans, degrades, or shows hate toward a particular
            race, gender, culture, country, belief, or toward any member of a
            protected class;
          </li>
          <li>
            content depicting nudity, sexual behaviour, or obscene gestures;
          </li>
          <li>content depicting drug use; </li>
          <li>
            content depicting excessive violence, including the harming of
            animals;
          </li>
          <li>shocking, sensational, or disrespectful content;</li>
          <li>
            deceptive, false or misleading content, including deceptive claims,
            offers, or business practices;
          </li>
          <li>
            content that directs users to phishing links, malware, or similarly
            harmful codes or sites; and
          </li>
          <li>
            content that deceives the Users into providing personal information
            without their knowledge, under pretences, or to companies that
            resell, trade, or otherwise misuse that personal information.
          </li>
          <li>
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resources.
          </li>
          <li>
            threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation;
          </li>
          <li>harm’s minors in any way;</li>
          {/* Include other list items as needed */}
        </ul>
        <br></br>
        <br></br>
        <h6 className="fw-bolder d-inline">Prohibited Advertisements</h6>
        <p>
          Advertisements for the following products and services are prohibited:
        </p>
        {/* Prohibited Advertisements */}
        <ul className="ps-4">
          <li>
            Adult products and services (other than contraceptives; see below);
          </li>
          <li>
            Cigarettes (including e-cigarettes), cigars, smokeless tobacco, and
            other tobacco products; and electronic Nicotine Delivery System
            (ENDS) & like products that enable nicotine delivery except for the
            purpose & in the manner and to the extent, as may be approved under
            the Drugs and Cosmetics Act, 1940 and Rules made thereunder;
          </li>
          <li>
            Products or services that bypass copyright protection, such as
            software or cable signal descramblers;
          </li>
          <li>
            Products or services principally dedicated to selling counterfeit
            goods or engaging in copyright piracy;
          </li>
          <li>
            Get-rich-quick or pyramid schemes or offers or any other deceptive
            or fraudulent offers;
          </li>
          <li>Illegal or recreational drugs or drug paraphernalia;</li>
          <li>
            Counterfeit, fake or bootleg products, or replicas or imitations of
            designer products
          </li>
          <li>Firearms, weapons, ammunition, or accessories;</li>
          <li>
            Advertisements that promote particular securities or that provide or
            allege to provide insider tips;
          </li>
          <li>Any illegal conduct, product, or enterprise;</li>
          <li>Unapproved pharmaceuticals and supplements</li>
          <li>Prescription drugs;</li>
          <li>
            Products that have been subject to any government or regulatory
            action or warning;
          </li>
          <li>
            Products with names that are confusingly similar to an unapproved
            pharmaceutical or supplement or controlled substance; and
          </li>
          <li>
            Material that directly advertises products to or is intended to
            attract children under the age of 13.
          </li>
          {/* Include other list items as needed */}
        </ul>
        <br></br>
        <h6 className="fw-bolder">
          Diagnostic (Pathology tests & Ultrasounds scans) Services –
        </h6>
        <p className="py-3">
          As a condition of Your use of and access to the diagnostic services
          provided through the Platform and Your acceptance of these Terms of
          Use, You are subject to the following rules/ guidelines:
        </p>
        <br></br>
        <br></br>
        <h6 className="fw-bolder">General terms of use:</h6>
        <ol>
          <li>
            1. In case any prescription is being provided to the User by the
            Medical Expert, the same is being provided basis the online
            consultation, however, it may vary when examined in person, hence,
            in no event shall the prescription provided by Practitioners be
            relied upon as a final and conclusive solution.
          </li>
          <li>
            2. The Users agree to use the advice from Medical Expert on the
            Website pursuant to:
          </li>
          <ul className="ps-4">
            <li>ongoing treatment with their medical practitioner;</li>
            <li>
              a condition which does not require emergency treatment, physical
              examination or medical attention;
            </li>
            <li>
              medical history available as records with them for reference;
            </li>
            <li>
              a record of physical examination and report thereof with them,
              generated through their local medical practitioner;
            </li>
            <li>
              consultation with their medical practitioner before abandoning or
              modifying their ongoing treatment.
            </li>
          </ul>
          <li>
            3. The User agrees that by using Consult, the Medical Expert on
            Consult will not be conducting a physical examination of the Users,
            hence, they may not have or be able to derive important information
            that is usually obtained through a physical examination. User
            acknowledges and agrees that the User is aware of this limitation
            and agrees to assume the complete risk of this limitation.
          </li>
          <li>
            4. The User understands that Consult shall not form a substitute for
            treatment that otherwise needs physical examination/immediate
            consultation. Further, the User understands that the advice provided
            by the Practitioner is based on general medical conditions and
            practices prevalent in India, to the best of his knowledge and
            ability, and not for conditions which are territory specific for
            regions other than India, irrespective of where the User is
            procuring medical services or engaging in communication with the
            Medical Expert.
          </li>
          <li>
            5. During the consultation and thereafter, the Medical Experts may
            upload the prescription/health records of the User on the account of
            the User on the Website for access of the User. However, it is
            expressly clarified that for Users not located within India and
            using Consult, the Practitioner may or may not issue a prescription,
            at his sole discretion.
          </li>
          <li>
            6. Notwithstanding anything contained herein, RMSPT is not in any
            manner responsible for any drug/medicines prescribed or the therapy
            prescribed by the Medical experts.
          </li>
          <li>
            7. If the Practitioner responds to the User’s query, the system
            could trigger communications to the User, in the form of
            notification/text/email/others. The User further understands that
            RMSPL may send such communications like text messages/email/calls
            before and/or after Practitioner’s consultation (physical or online)
            to the User’s mobile number, based on the Practitioner’s settings
            (through the Website). However, and notwithstanding anything to the
            contrary in this Agreement, RMSPL does not take responsibility for
            the timeliness of such communications.
          </li>
          <li>
            8. User shall refrain from raising any personal queries or advice on
            the Consult platform which are not related to a specific
            disease/medicine.
          </li>
          <li>
            9. Users shall not use abusive language on the Consult platform. In
            the event of abuse from the User is reported by a Practitioner,
            RMSPL reserves the right to block such Users from the Consult
            platform and RMSPL is not responsible for honouring any refund
            request towards his/her consultation on the Consult platform.
          </li>
          <li>
            10. Users may share images or videos of the affected areas of their
            body parts with the Practitioner only if it is necessary for
            diagnosing his/her condition and if he/she is personally comfortable
            in sharing such images or videos. RMSPL shall not be responsible for
            any such images or videos shared by the Users with the
            Practitioners.
          </li>
          <li>
            11. Users shall ensure that any interaction/communication with the
            Practitioners, including sharing images or videos of the body parts,
            shall be only through the Consult platform. The Users shall not rely
            on any other external modes of communication for
            interacting/communicating with the Medical Expert.
          </li>
          <li>
            12. Users shall be prepared to share all relevant documents or
            reports to the Practitioner promptly upon request.
          </li>
          <li>
            13. For every paid consultation on the Consult platform, the Users
            shall not obtain a consultation for more than one User. In the
            event, the Users attempt to obtain a consultation for more than one
            User through a single paid consultation on the Consult platform,
            such consultations will not be addressed by the relevant
            Practitioner.
          </li>
          <li>
            14. Users shall not persuade Practitioners to prescribe drugs
            (including higher dose strength) that do not conform to the Consult
            prescription policy. Restricted drugs are as follows: • Medication
            for Medical Termination Pregnancy (MTP) • Drugs under the following
            pharmaceutical classifications such as; sedatives, hypnotics,
            opioids, schedule X drugs, or fourth-generation antibiotics.
          </li>
          <li>
            15. If restricted drugs are indicated for treatment or management of
            a disease or condition by a Practitioner, the User shall physically
            visit the Practitioner of their choice to confirm the
            requirements/necessity for prescribing such restricted drugs.
          </li>
          <li>
            16. User understands and agrees to provide accurate information and
            will not use the Consult platform for any acts that are considered
            to be illegal in nature.
          </li>
          <li>
            17. If a User decides to engage with a Practitioner to procure
            medical services or engages in communication, exchange of money for
            services outside of Consult platform, User shall do so at their own
            risk. RMSPL shall not be responsible for any breach of Service or
            Service deficiency by any Practitioner.
          </li>
          <li>
            18. The User agrees and understands that the transaction with the
            Practitioner is subject to the jurisdiction of Indian laws and that
            any claim, dispute or difference arising from it shall be subject to
            the jurisdiction provision as contained in the Terms and Conditions
            hereunder, at all times. The User further agrees and understands
            that the Practitioner is a medical practitioner who is licensed to
            practice medicine in India and the onus is on the User to determine
            if he/she is eligible to consult with the Practitioners via the
            Website. It is expressly clarified that at no point in time can it
            be construed that the Practitioner is practising medicine in a
            territory other than India, irrespective of where the user is
            located and procures medical services or engages in communication
            with the Practitioner, in any manner whatsoever.
          </li>
          <li>
            19. The User shall indemnify and hold harmless RMSPL and its
            affiliates, subsidiaries, directors, officers, employees and agents
            from and against any and all claims, proceedings, penalties,
            damages, loss, liability, actions, costs and expenses (including but
            not limited to court fees and attorney fees) arising due to or
            concerning the use of Website by the User, by breach of the Terms or
            violation of any law, rules or regulations by the User, or due to
            such other actions, omissions or commissions of the User that gave
            rise to the claim.
          </li>
          <li>
            20. By clicking on the check box - the user will agree with terms
            and conditions, disclaimer and privacy policy; the user agrees to
            share his/her medical reports of pathology lab tests,
            sonography/ultrasound scan reports, prescriptions given by any
            medical experts with medical expert or group of medical experts for
            pregnancy care service and doctor online consultancy service on
            online platform ‘ www.myprega.com ‘ which is only viewable to
            Medical Experts and not to any other person /Company. These reports
            can be generated by any tests/sonography suggested by any medical
            expert for the purpose of treatment or diagnostic.
          </li>
          <li>
            21. The User shall make payment using the third party payment
            gateway on an online platform{" "}
            <Link
              className="text-primary"
              href="https://www.myprega.com"
              target="_blank"
            >
              ‘www.myprega.com’
            </Link>{" "}
            to make payments online, solely at the User's discretion. Should
            there be any issues with regard to the payment not reaching the
            RMSPL account, the User may contact RMSPL's support team via
            <Link className="text-primary" onClick={supportmyprega}>
              support@myprega.com
            </Link>
            , and the company is not responsible for any payment loss or
            deduction of money from the user's account. Only a Third-party
            payment gateway is responsible for the payment related issue.
          </li>
        </ol>
        {/* Include other sections and paragraphs as needed */}
        <h2>Online Doctor Consultancy Services: Terms and Conditions</h2>
        <ol>
          <li>
            The Company is an online health platform that provides a variety of
            online and online-linked health products and services to Users for
            health-related information and resources...
          </li>

          <li>
            The user or patient visiting doctors clinic or hospital by using
            book appointment service should not have waited more than 30 minutes
            of the scheduled time...
          </li>

          <li>
            Video or Audio consultation will be performed only once (Up to 30
            Minutes Time) after booking of consultation for a respective doctor
            by patient...
          </li>

          <li>
            Notwithstanding anything to the contrary contained herein,
            Third-Party Medical Experts alone shall be liable for Third-Party
            Medical Experts’ dealings and interaction with the Users who avail
            the services of the Third Party Labs, Medical Experts or diagnostic
            centres contacted or managed through the Platform and Company shall
            have no liability or responsibility in this regard...
          </li>

          <li>
            The company does not guarantee or make any representation concerning
            the correctness, completeness or accuracy of the tests conducted and
            reports generated by the Third Party Medical Experts, Labs &
            Diagnostic Centre...
          </li>

          <li>
            The Practitioner is and shall be duly registered, licensed and
            qualified to practice medicine/ provide health care, wellness
            services, as per applicable laws/regulations/guidelines set out by
            competent authorities...
          </li>

          <li>
            Where the Practitioner learns that a physical consultation is
            mandatory for accurate diagnosis and resolution of the case, the
            Practitioner shall mandatorily be required to provide a patient
            referral for physical evaluation along with required information via
            the prescription module...
          </li>

          <li>
            The Practitioner further understands that there is a responsibility
            on the Practitioner to provide accurate information to the User, as
            the Practitioner would have done so to any other patient of his,
            however, no doctor-patient relationship is established pursuant to
            interactions on Consult (International)...
          </li>

          <li>
            Practitioners shall ensure that the consultation online is treated
            as an in-clinic consultation and provide advice to the best of
            Practitioners’ knowledge...
          </li>

          <li>
            The Practitioner is not allowed to use any other medium other than
            the Consult platform for the purpose of interacting/communicating
            with the User and any attempt by the Practitioner to interact with
            the Users through any other external means of communication will
            amount to a violation of this Agreement by the Practitioner...
          </li>

          <li>
            In the event the Practitioner learns about physical abuse, sexual
            misconduct (especially in minors), or User self-harm (suicide:
            planned, attempted or completed), the Practitioner agrees to report
            such events to Myprega via the email:
            <Link onClick={supportmyprega}>support@myprega.com</Link> immediately...
          </li>

          <li>
            The Practitioner acknowledges that should myprega.com which is
            registered brand of Remedinart Medico Services Private Limited PVT
            LTD find the Practitioner to violate any of the applicable
            laws/rules/ regulations/guidelines set out by the authorities then
            Myprega shall be entitled to cancel the consultation with such
            Practitioner or take such other legal action as may be required...
          </li>

          <li>
            It is further understood by the Practitioner that the information
            that is disclosed by the User at the time of consultation is
            personal and is subject to all applicable privacy laws, shall be
            confidential in nature and subject to User and Practitioner
            privilege...
          </li>

          <li>
            The Practitioner shall indemnify and hold harmless Myprega and its
            affiliates, subsidiaries, directors, officers, employees and agents
            from and against any and all claims, proceedings, penalties,
            damages, loss, liability, actions, costs and expenses (including but
            not limited to court fees and attorney fees) arising due to the
            Services provided by Practitioner, violation of any law, rules or
            regulations by the Practitioner or due to such other actions,
            omissions or commissions of the Practitioner that gave rise to the
            claim...
          </li>

          <li>
            Practitioners should provide e-prescriptions to the Users only via
            the prescription module...
          </li>

          <li>
            If the Practitioner’s performance on the Consult platform is not
            compliant with the expected guidelines of RMSPL or the Practitioner
            is found to be misusing the Consult platform, the Practitioner may
            result in losing the privilege of using the Consult feature &
            platform...
          </li>

          <li>
            Practitioners shall not prescribe medical termination pregnancy
            medication, sedatives, Hypnotics, opioids, schedule X drugs, or
            fourth-generation antibiotics on the Consult platform. If any such
            drugs are indicated for treatment for a given consultation, the
            Practitioner shall refer the User for a physical consultation...
          </li>

          <li>
            The Practitioners agree not to request see for images or video of
            the User's private body parts in any manner whatsoever until and
            unless all other options have been exhausted to diagnose the User’s
            condition and it is absolutely necessary to arrive at a probable
            diagnosis...
          </li>

          <li>
            For a Practitioner to complete a consult, it is mandatory to provide
            a consultation summary via the e-prescription module to all Users...
            The recommended contents (at least one) of the said consultation
            summary are as follows:
            <ul className="pl-4">
              <li>Summary of presenting illness</li>
              <li>Provisional diagnosis</li>
              <li>Medicine posology including side effects if any</li>
              <li>Diagnostics</li>
              <li>Lifestyle changes</li>
              <li>Other instructions if any</li>
              <li>Referral for physical consultation (if necessary)</li>
            </ul>
          </li>

          <li>
            The Practitioner acknowledges that should RMSPL find the
            Practitioner to be in violation of any of the applicable laws/rules/
            regulations/guidelines set out by the authorities then RMSPL shall
            be entitled to cancel the consultation with such Practitioner or
            take such other legal action as may be required...
          </li>

          <li>
            It is further understood by the Practitioner that the information
            that is disclosed by the User at the time of consultation is
            personal and is subject to all applicable privacy laws, shall be
            confidential in nature and subject to User and Practitioner
            privilege...
          </li>

          <li>
            The Medical Expert understands that RMSPL makes no promise or
            guarantee for any uninterrupted communication and the Practitioner
            shall not hold RMSPL liable if for any reason the communication is
            not delivered to the User(s), or are delivered late or not accessed,
            despite the efforts undertaken by RMSPL...
          </li>

          <li>
            The Practitioner understands that RMSPL makes no promise or
            guarantee for the number of consultations that will be allocated to
            a Practitioner on the Consult platform...
          </li>

          <li>
            It shall be the responsibility of the Practitioner to ensure that
            the information provided by the User is accurate and not incomplete
            and understand that RMSPL shall not be liable for any errors in the
            information included in any communication between the Practitioner
            and User...
          </li>

          <li>
            The Medical Expert shall indemnify and hold harmless RMSPL and its
            affiliates, subsidiaries, directors, officers, employees and agents
            from and against any and all claims, proceedings, penalties,
            damages, loss, liability, actions, costs and expenses (including but
            not limited to court fees and attorney fees) arising due to the
            Services provided by Practitioner, violation of any law, rules or
            regulations by the Medical Experts or due to such other actions,
            omissions or commissions of the Medical Expert that gave rise to the
            claim.
          </li>
          <li>
            {" "}
            In pregnancy care service and doctor online consultancy service
            Doctors/Medical Experts, Sonography Centers, Pathology Lab test
            Centers will only get as much money as the consultancies, tests,
            ultrasounds and other things done by them. They will have to return
            the excess money to the company for unused services provided by them
            within 7 days from intimations received from the company to the
            third-party service providers which include doctors/ medical
            experts, Pathology lab test centres, Sonography/ Ultrasound centres,
            diagnostics centres and others who provide service to the company).
          </li>
        </ol>
        <br></br>
        <br></br>
        <h6>For Diagnostic (Pathology tests & Ultrasounds scans) Services:</h6>
        <ul>
          <li>
            The company provides services through the Platform as a marketplace
            and facilitates the Users to avail diagnostic test/ packages
            facilities offered by Third Party Labs through the Platform. Company
            is not and shall not be responsible for any sample collected, tests
            conducted and reports generated by the Third Party Labs and do not
            deal with any of Third Party Labs’ client or patient managed by
            Third Party Labs through the Platform and only provides facilitation
            Services to the Users through the Platform. Use of the Platform may
            require the Third Party Labs to use software and the Third Party
            Labs have to ensure the procurement of such software from the
            concerned providers. Users and the Third Party Labs agree to use the
            Platform and the materials provided therein only for purposes that
            are permitted by (a) these Terms of Use; and (b) any applicable
            law(s), regulation or generally accepted practices or guidelines in
            the relevant jurisdictions.
          </li>
          <li>
            The Third-Party Labs may not access the Services if the Third-Party
            Labs are the Company's direct competitor, except with Company’s
            prior written consent. Besides, the Third Party Labs may not access
            the Services for purposes of monitoring their availability,
            performance or functionality, or for any other benchmarking or
            competitive purposes.
          </li>
          <li>
            {" "}
            Company will provide to the Third Party Labs basic support for the
            Services at no additional charge, and/or upgraded support if
            purchased separately and will use commercially reasonable efforts to
            make the Services available 24 (twenty-four) hours a day, 7 (seven)
            days a week, except for (i) planned downtime or (ii) any
            unavailability caused by circumstances beyond Company’s reasonable
            control, including without limitation Force Majeure Events (as
            defined hereinbelow). The company will provide the Services only
            following applicable law(s) and government regulations.
          </li>
          <li>
            The Services may be subject to certain limitations, such as limits
            on disk storage space, on the number of calls Third-Party Labs are
            permitted to make against Company’s application programming
            interface, and, other limitations dependent on the Third-Party Labs
            plan, for example, number of SMSes, or number of Users. Any such
            limitations are specified in the Third Party Labs’ plans. The
            Services provide real-time information to enable Third-Party Labs to
            monitor such Third Party Labs’ compliance with such limitations
          </li>
          <li>
            Notwithstanding anything to the contrary contained herein,
            Third-Party Labs alone shall be liable for Third-Party Labs’
            dealings and interaction with the Users who avail the services of
            the Third Party Labs or diagnostic centres contacted or managed
            through the Platform and Company shall have no liability or
            responsibility in this regard. The company does not guarantee or
            make any representation concerning the correctness, completeness or
            accuracy of the information or details provided by such Users,
            Third-Party Labs or any diagnostic centre or any third party through
            the Platform. Services should not be used for emergency appointment
            purposes.
          </li>
          <li>
            The company does not guarantee or make any representation concerning
            the correctness, completeness or accuracy of the tests conducted and
            reports generated by the Third Party Labs.
          </li>
          <p>
            Company may, at its sole discretion, suspend Third Party Labs or
            Users ability to use or access the Platform at any time while
            Company investigates complaints or alleged violations of these Terms
            of Use, or for any other reason. The company has the right to edit
            profiles of Third Party Labs to make them more suitable for package
            searches on the Platform. If Third-Party Labs and/ or Users find any
            wrong information on the Platform concerning such Third Party Labs
            and/ or User, they can correct it themselves or contact the company
            immediately for such corrections. The company shall have no
            liability or responsibility in this regard.
          </p>
        </ul>
        <br></br>
        <h6>For Pregnancy Care Services:</h6>
        <ul>
          <li>
            {" "}
            The women may have to visit physically to medical expert,
            clinics/hospitals, diagnostic center and pathology labs for
            test/scans that cannot be undertaken at any other location
          </li>
          <li>
            Lab test, ultrasounds scans, vaccinations and extra medical expert,
            consultancies suggested by experts other than mentioned in the
            package will be responsible for women. The woman will be responsible
            for payment to the respective person (from where she did the tests,
            not the company) of the same test and she should upload all reports
            of the same on the dashboard.
          </li>
          <li>
            The company will only provide the first 2 doses of prescribed
            medicines by the online consultancy of human doctors. This is only
            applicable for online doctor consultations/consult/chat with doctors
            service
          </li>
          <li>
            In the case of women, pregnancy service subscription of the Company
            may be linked to the services of third parties, affiliates and
            business partner has no control over, and not liable or responsible
            for the content, accuracy validity, reliability, quality of such
            Subscription Services or made available by/through our Subscription
            Services. Inclusion of any link on the Subscription Services does
            not imply or endorse the linked site. Users may use the links and
            these Subscription Services at the User’s own risk.
          </li>
          <li>
            Doctors have to open a consulting platform for the next 3 days from
            the opening of consultancy event dates for free follow up. After
            that chat/audio/video window will be automatically closed for that
            consultancy.
          </li>
          <li>
            {" "}
            The company shall not provide any medicines prescribed by our
            medical experts who are part of pregnancy care groups in pregnancy
            care service packages. Users have to buy prescribed medicines at
            their own cost from their convenient pharmacist.
          </li>
          <li>
            The company Shall provide their vehicles for going to In clinic
            visits, sonography/ultrasound scans and pathology lab tests (which
            can not be possible to do at pregnant patients home). If a user
            refuses to go with a company's vehicle then the company shall not be
            responsible/liable for refunding /providing any travel allowance.
            The company shall allow the only pregnant patient in the vehicle.
          </li>
          <li>
            The company shall not provide any pregnancy surgery/delivery (normal
            or Caesarean -Section ). Company or Medical Experts only suggest but
            not compulsory the options for delivery of the baby and shall not be
            responsible for any pregnancy surgery/delivery (normal or Caesarean
            -Section ). Users have to do a delivery of the baby at their own
            cost and risk. Users have the complete right to choose a
            hospital/maternity home for the delivery of a baby.
          </li>
          <li>
            The company provide the best pregnancy care service which is
            verified by authorised medical experts but shall not be
            responsible/liable for any pregnancy surgery/delivery (normal or
            Caesarean -Section ), miscarriage or spontaneous abortion of the
            baby. Also, Company shall not be responsible for any complication
            that never exists or happens before today’s date; during pregnancy
            or after pregnancy or delivery of the baby.
          </li>
          <li>
            We will not be responsible/liable for any type of medical and
            non-medical complication that occurs/happens during 9-month
            pregnancy care or after pregnancy care or labour/delivery of baby to
            the patient or infant/baby due to services provided by us.
          </li>
          <li>
            We provide the best pregnancy care but if there will be risk/failure
            to the life of patient and baby due to service provided by us which
            is not even guaranteed by the best medical expert. Hence company and
            medical experts provided by the company are not responsible/liable
            to risk/failure(death) to the life of the patient and baby.{" "}
          </li>
          <li>
            The company is not responsible/liable for any problems occurring due
            to past pregnancy history during ongoing pregnancy and after
            pregnancy to the user and baby.
          </li>
          <li>
            All Terms and Conditions of Use are applicable for the services that
            come under pregnancy care service like online and in-clinic doctor
            consultancy, pathology lab tests, diagnostic/ultrasound scan except
            the right to choose a medical expert to user due to system setup.
          </li>
          <li>
            Company Shall / not provide or do sex determination of foetus/baby
            through an ultrasound scan, pathology lab tests and medical experts
            consultancy.
          </li>
          <li>
            For every paid package of women pregnancy care, the user shall not
            obtain service for more than one user. For a single package, the
            Users attempt to obtain pregnancy care for more than one User
            through a single paid pregnancy care package on the company
            platform, such service will not be addressed by the company and
            medical experts.
          </li>
          <li>
            In the case of the double foetus(twins) / triple foetus(triplet)
            company shall charge extra charges from users other than package
            cost due to an increase in the charges of service.
          </li>
          <li>
            Company shall not be responsible/liable to risk/failure to the life
            of anyone foetus from the double foetus(twins) or risk/failure of
            both foetus or risk/failure of both foetus and user.
          </li>
          <li>
            Company shall not be responsible/liable to risk/failure to the life
            of anyone foetus from the triple foetus(triplet) or two foetuses or
            all three foetuses and user.
          </li>
          <li>
            Company shall not be responsible for any loss or damage that happens
            with the user during a travelling to sonography/ultrasound scan
            centre, pathology lab centre and medical expert’s
            clinic/hospital/maternity home.
          </li>
          <li>
            Company shall not be responsible or liable for any damage happening
            to users or infants/baby due to medical & non-medical kit provided
            by (Myprega) Company.
          </li>
          <li>
            The company shall not be responsible for any damage/loss/failure of
            life of patient or infant/baby due to prescribed medicines,
            suggestion, Pathology tests, Ultrasound scan and any other
            care/treatments given/suggested by medical experts.
          </li>
          <li>
            All Terms and Conditions of use for all service providers mentioned
            above (In Terms of use for an online doctor consultation, Terms of
            use for Diagnostic/Pathology Lab & General Term) are also applicable
            for pregnancy care service providers.
          </li>
          <li>
            By clicking on the check box - the user will agree with terms and
            conditions, disclaimer and privacy policy; the user agrees to share
            his/her medical reports of pathology lab tests,
            sonography/ultrasound scan reports, prescriptions given by any
            medical experts with medical expert or group of medical experts for
            pregnancy care service and doctor online consultancy service on
            online platform ‘{" "}
            <Link
              className="text-primary"
              href="https://www.myprega.com"
              target="_blank"
            >
              ‘www.myprega.com’
            </Link>
            ‘ which is only viewable to Medical Experts and not to any other
            person /Company. These reports can be generated by any
            tests/sonography suggested by any medical expert for treatment or
            diagnostic.
          </li>
          <li>
            The User shall make payment using the third party payment gateway on
            an online platform{" "}
            <Link
              className="text-primary"
              href="https://www.myprega.com"
              target="_blank"
            >
              ‘www.myprega.com’
            </Link>{" "}
            to make payments online, solely at the User's discretion. Should
            there be any issues concerning the payment not reaching the RMSPL
            account, the User may contact RMSPL's support team via
            <Link className="text-primary" onClick={supportmyprega}>
              support@myprega.com
            </Link>
            , and the company is not responsible for any payment loss or
            deduction of money from the user's account. Only a Third-party
            payment gateway is responsible for the payment related issue.
          </li>
          <li>
            {" "}
            If a User buys pregnancy care service from Myprega at any time
            between pregnancy and not from the start of the pregnancy then the
            treatment/care starting from that week when the user buys services.
            Users get the refund of money of unused Consultancies, unused Tests
            and unused ultrasounds as per decided by company and company has
            final rights regarding this (Refund of money and other things).
          </li>
          <li>
            In pregnancy care service and doctor online consultancy service
            Doctors/Medical Experts, Sonography Centers, Pathology Lab test
            Centers will only get as much money as the consultancies, tests,
            ultrasounds and other things done by them. They will have to return
            the excess money to the company for unused services provided by them
            within 7 days from intimations received from the company to the
            third-party service providers which include doctors/ medical
            experts, Pathology lab test centres, Sonography/ Ultrasound centres,
            diagnostics centres and others who provide service to the company).
          </li>
          <li>
            In pregnancy care service users will get only those
            services/products which are mentioned in the description of
            package/service bought by the user and the user will have to pay
            extra for extra services/product other than mentioned in the
            package/service to the company if taken by the user.
          </li>
          <li>
            We are not liable or responsible for providing any prescribed
            medicines given by medical experts
          </li>
        </ul>
        <br></br>
        <br></br>
        <h6 className="text-center fw-bolder">REGISTRATION</h6>
        <p>
          For every user to avail of the services provided by or on the
          platform, the User shall have to register themselves on the platform.
          For the purpose of Registration, the User shall have to provide the
          following information to the Company during the process of the
          Registration and the same shall be stored on the servers and database
          of the Company. The information includes but is not limited to
        </p>
        <ul className="pl-5">
          <li>Name</li>
          <li>Phone Number</li>
          <li>Gender</li>
          <li>Age</li>
          <li>Address (including country and ZIP/ postal code)</li>
          <li>Medical History</li>
          <li>Physiological and Mental Conditions</li>
          <li>History with Allergies (if any )</li>
          <li>Treatment Prescription (optional)</li>
          <li>Valid Payment details</li>
          <li>Aadhar Card, Health Card (Optional)</li>
          <li>Other details as You may volunteer</li>
          <li>Past Medical Prescriptions / Medical Reports </li>
        </ul>
        <p>
          The usage and registration on the platform are free of cost and the
          Company shall not charge any registration charges for the purpose of
          usage or registration on the platform. However, the Platform shall
          charge the User for the purpose of using any services provided by the
          Platform or such other Vendors on the platform. However, registration
          is compulsory for every user in order to avail of the services
          provided by the Platform.
        </p>
        <br></br>
        <br></br>
        <h6 className="text-center fw-bolder">ELIGIBILITY</h6>
        <ul>
          <li>
            The Users jointly represent and warrant that they are competent and
            eligible to enter into legally binding agreements and of 18 years of
            age and that they have the requisite authority to bind themselves to
            these Terms following the Law.
          </li>
          <li>
            The Users further represents that they will comply with this
            Agreement and all applicable local, state, national and
            international laws, rules and regulations.
          </li>
          <li>
            The Users may not use the Platform if they are not competent to
            contract or are disqualified from doing so by any other applicable
            law, rule or regulation currently in force.
          </li>
          <li>
            Family Dependents can use the Platform under the umbrella of “Family
            Tree” whereas the main user agrees on terms & conditions and Payment
            terms for which the platform can be used only for the specific use
            for health-related services or in Medical Emergencies as well, with
            the prior consent or as per the rights given by the primary user of
            the family tree.
          </li>
        </ul>
        <br></br>
        <br></br>
        <h6 className="text-center fw-bolder">
          NO DOCTOR-PATIENT RELATIONSHIP
        </h6>
        <ul>
          <li>
            The company does not replace Your relationship with a physician or
            healthcare provider. The information interpreted{" "}
            <span className="fw-bolder">SHOULD NOT</span> be relied upon as a
            substitute for sound professional medical advice, evaluation or care
            from Your physician or other qualified healthcare providers.
          </li>
          <li>
            You acknowledge that the Medical Experts empanelled with Us are
            independent contractors and thereby Company has an independent
            contractor relationship with such Medical Experts and therefore in
            no event Company will be directly or vicariously liable for any
            advice or medical consultancy or any loss arising therefrom that the
            Medical Experts may provide to You or You may avail as part of the
            Services.
          </li>
          <li>
            The Company is designed to support the health decisions and choices
            that You make. These decisions and choices are Yours, and We believe
            that You, in connection with the advice You receive from Your doctor
            or other professional healthcare providers, are the best decision
            maker about Your health. We cannot make decisions for you. However,
            what We can do is help You find good health information and connect
            with doctors for in-person information. On Company You can ask and
            find informational questions and related educational answers by
            Medical Experts. The Platform is not a place for the practice of
            medicine, but Medical Experts on the Platform can be a resource for
            reliable, relevant general health information.
          </li>
          <li>
            Even if Your real-life doctor is on Company, personal medical
            advice, treatment or diagnosis are not permitted through the
            Platform, and by using the Platform You agree not to solicit these
            or use any information as if it were personal advice, treatment, or
            diagnosis. Whenever You want personal medical advice, treatment, or
            diagnosis, You should contact Your physician or professional
            healthcare provider and see them in person.
          </li>
          <li>
            We do not recommend or endorse any specific Medical Expert(s),
            tests, products, procedures, opinions, or other information that may
            be mentioned on the Platform. Reliance on any information provided
            on the Platform is solely at Your own risk. In case of any medical
            emergency, kindly contact Your nearest doctor/hospital or any
            related helpline.
          </li>
          <li>
            The Services are not for use in medical emergencies or critical
            health situations requiring prompt medical attention. The Services
            are not intended to be real-time and may not be the best solution
            when a face-to-face consultation is a must and therefore We strongly
            discourage any delay in seeking advice from Your doctor on account
            of something that You may have heard/viewed on the Platform. You
            take full responsibility for ensuring that the information submitted
            is accurate and the company shall not make any effort to validate
            any information provided by You for using the Services concerning
            the content, correctness or usability. We, to provide the best
            services possible could ask You to share more information as and
            when needed.
          </li>
          <li>
            The opinions, statements, answers and teleconsultation (collectively
            <span>“Consultation”) </span>provided by the Medical Experts through
            the Platform are solely the individual and independent opinions and
            statements of such Medical Experts and do not reflect the opinions
            of the Company, its affiliates or any other organizations or
            institutions to which such Medical Expert or such specialist or
            professional is affiliated or provides services. The company does
            not recommend or endorse any specific tests, physicians, products,
            procedures, opinions, or other information that may be mentioned on
            the Platform or by a licensee of the Company.
          </li>
          <li>
            The inclusion of professionals, specialists and/ or Medical Experts
            on the Platform or in any professional directory on the Platform
            does not imply recommendation or endorsement of such specialists
            and/ or Medical Experts nor is such information intended as a tool
            for verifying the credentials, qualifications, or abilities of any
            specialists and/ or Medical Experts contained therein. Such
            information is provided on an ‘as-is’ basis and Company disclaims
            all warranties, either express or implied, including but not limited
            to the implied warranties of merchantability and fitness for a
            particular purpose.
          </li>
          <li>
            The Company (the owners and the employee staff of Company), Medical
            Experts and third-party professionals who offer the Services through
            the Platform accept no responsibility for any medical, legal or
            financial events or outcomes related to the Services availed through
            the use of the Platform.
          </li>
          <li>
            The exchanges between the Medical Experts and the patient through
            the chat window and the e-prescription would be accessible to the
            Company to monitor the quality of the consultation.
          </li>
          <li>
            The Company makes no warranty that the Services will meet Your
            requirements, or that the Service(s) will be uninterrupted, timely,
            secure, or error-free. This includes loss of data or any service
            interruption caused by its employees, neither is the Company
            responsible for transmission errors, corruption of data.
          </li>
          <li>
            The Website may not be used for illegal purposes. Information and
            Services may not be used for any illegal purpose. You may not access
            our networks, computers, or the Information and Services in any
            manner that could damage, disable, overburden, or impair them, or
            interfere with any other person's use and enjoyment. You may not
            attempt to gain unauthorized access to any Information or Services,
            other accounts, computer systems, or networks connected with the
            Website, the Information, or Services. You may not use any automated
            means (such as a scraper) to access the Website, the Information, or
            Services for any purpose. Such unauthorized access includes, but is
            not limited to, using another person’s login credentials to access
            his or her profile/ account. Any attempt by any individual or entity
            to solicit login information of any other user or Medical Expert or
            to access any such account is an express and direct violation of
            these Terms of Use and of applicable law(s), including relevant
            privacy and security laws and laws prohibiting unfair or unethical
            business practices.
          </li>
          <li>Your right to use the Services is not transferable.</li>
          <li>
            Notwithstanding anything to the contrary contained herein, You alone
            shall be liable for your dealings and interaction with patients or
            Medical Experts (as the case may be) contacted or managed through
            the Website and the Company shall have no liability or
            responsibility in this regard. Myprega does not guarantee or make
            any representation with respect to the correctness, completeness or
            accuracy of the Information or detail provided by such client,
            patient, User, Medical Experts or any third party through the
            Website. Services should not be used for emergency appointment
            purposes.
          </li>
          <li>
            The Company may, at its sole discretion, suspend the User’s or
            Medical Expert’s ability to use or access the Platform at any time
            while the Company investigates complaints or alleged violations of
            these Terms of Use, or for any other reason. The company has the
            right to edit profiles of Medical Experts to make them more suitable
            for patient/ Users searches on the Platform.
          </li>
          <li>
            The Services should not be depended upon and should not be treated
            as a replacement for obtaining consultation for diseases as the
            consultation provided through the Platform is generic in the
            approach and shall not and cannot act as a substitute for physical
            consultation with a doctor. Also, the Consultations provided through
            the Platform are not diagnostic in nature
          </li>
        </ul>
        <h6 className="text-center fw-bolder">CONTENT</h6>
        <p>
          All text, graphics, User interfaces, visual interfaces, photographs,
          trademarks, logos, brand names, descriptions, sounds, music and
          artwork (collectively, <span className="fw-bolder">‘Content’)</span>,
          is generated/provided or based on information provided by the
          interviewer or third parties and the Platform has no control and make
          no guarantees regarding the quality, the accuracy, integrity or
          genuineness of such content or such other information provided on the
          Platform.
        </p>
        <br></br>
        <p>
          All the Content displayed on the Platform is subject to copyright and
          shall not be reused by any party (or a third party) without the prior
          written consent of the Company and the copyright owner. The Users are
          solely responsible for the integrity, authenticity, quality and
          genuineness of the content provided on the Platforms and whilst
          feedback and comments by Users can be made via the Platform, the
          Platform bears no liability whatsoever for any feedback or comments
          made by the Users or made in respect of any of the content on the
          Platform. Further, the Platform reserves its right to suspend the
          account of any User for an indefinite period to be decided at the
          discretion of the Platform or to terminate the account of any User who
          is found to have created or shared or submitted any Content or part
          thereof that is found to be untrue/inaccurate/misleading or
          offensive/vulgar. The User shall be solely responsible for making any
          financial or legal losses incurred through the
          creation/sharing/submission of Content or part thereof that is deemed
          to be untrue/inaccurate/misleading.
        </p>
        <br></br>
        <p>
          The Users have a personal, non-exclusive, non-transferable, revocable,
          limited privilege to access the Content on the Platform. Users shall
          not copy, adapt, and modify any content without written permission of
          the Company.
        </p>
        <h6 className="text-center fw-bolder">INDEMNITY</h6>
        <p>
          The Users of this Platform agree to indemnify, defend and hold
          harmless the Company/Platform, and their respective directors,
          officers, employees and agents (collectively, "Parties"), from and
          against any losses, liabilities, claims, damages, demands, costs and
          expenses (including legal fees and disbursements in connection
          therewith and interest chargeable thereon) asserted against or
          incurred by us that arise out of, result from, or maybe payable by,
          any breach or non-performance of any representation, warranty,
          covenant or agreement made or obligation to be performed according to
          these terms of use. Further, the User agrees to hold the
          Company/Platform harmless against any claims made by any third party
          due to, or arising out of, or in connection with:
        </p>
        <ul className="pl-4">
          <li>User’s use of the Platform,</li>
          <li>User’s violation of these Terms and Conditions;</li>
          <li>User’s violation of any rights of another;</li>
          <li>User’s alleged improper conduct according to these Terms;</li>
          <li>User’s conduct in connection with the Platform;</li>
        </ul>
        <p>
          The user agrees to fully cooperate in indemnifying the Company and the
          Platform at the user’s expense. The user also agrees not to settle
          with any party without the consent of the Company.
        </p>
        <br></br>
        <p>
          In no event shall the Company be liable to compensate the User or any
          third party for any special, incidental, indirect, consequential or
          punitive damages whatsoever, including those resulting from loss of
          use, data or profits, whether or not foreseeable, and whether or not
          the Company/ Platform had been advised of the possibility of such
          damages, or based on any theory of liability, including breach of
          contract or warranty, negligence or other tortuous action, or any
          other claim arising out of or in connection with the User’s use of or
          access to the Platform and/or the Services or materials contained
          therein.
        </p>
        <h6 className="text-center fw-bolder">LIMITATION OF LIABILITY</h6>
        <ul>
          <li>
            The Founders/ Promoters/ Partners/ Associated people of the
            Company/Platform are not responsible for any losses, damages,
            injuries, expenses incurred by the Users or for any consequences
            arising out of the following events.:
          </li>
          <li>
            If the Platform is inoperative/non-responsive due to any
            connectivity errors associated with the internet connection such as
            but not limited to slow connectivity, no connectivity, server
            failure;
          </li>
          <li>
            If the User has fed incorrect information or data or for any
            deletion of data;
          </li>
          <li>If there is an undue delay or inability to communicate</li>
          <li>
            If there is any deficiency or defect in the Services managed by Us;
          </li>
          <li>
            If there is a failure in the functioning of any other service
            provided by the Platform.
          </li>
          <li>
            The Platform accepts no liability for any errors or omissions, on
            behalf of itself, or for any damage caused to the User, the User’s
            belongings, or to any third party, resulting from the use or misuse
            of the Platform or any service availed of by the User through the
            Platform. The service and any Content or material displayed on the
            service is provided without any guarantees, conditions or warranties
            as to its accuracy, suitability, completeness or reliability. The
            Platform will not be liable to you for the unavailability or failure
            of the Platform.
          </li>
          <li>
            Users are to comply with all laws applicable to them or their
            activities, and with all Policies, which are hereby incorporated
            into this Agreement by reference.
          </li>
          <li>
            The Platform expressly excludes any liability for any loss or damage
            that was not reasonably foreseeable by the Platform and which is
            incurred by you in connection with the Platform, including loss of
            profits; and any loss or damage incurred by you as a result of your
            breach of these terms.
          </li>
          <p>
            To the fullest extent permitted by law, the Platform shall not be
            liable to you or any other party for any loss or damage, regardless
            of the form of action or basis of any claim. You acknowledge and
            agree that your sole and exclusive remedy for any dispute with us is
            to terminate your use of the Platform.
          </p>
        </ul>
        <br></br>
        <h6 className="text-center fw-bolder">TERM</h6>
        <ul>
          <li>
            These Terms shall continue to form a valid and binding contract
            between the Parties and shall continue to be in full force and
            effect until the User continues to access and use the Platforms.
          </li>
          <li>
            The Users may terminate their use of the Platform at any time.
          </li>
          <li>
            The Company may terminate these Terms and close any account at any
            time without notice and/or suspend or terminate a User’s access to
            the Platform at any time and for any reason, if any discrepancy or
            legal issue arises.
          </li>
          <li>
            Such suspension or termination shall not limit our right to take any
            other action against you that the Company considers appropriate.
          </li>
          <li>
            It is also hereby declared that the Company may discontinue the
            Services and Platforms without any prior notice.
          </li>
        </ul>
        <br></br>
        <h6 className="text-center fw-bolder">TERMINATION</h6>
        <ul>
          <li>
            The Company reserves the right, in its sole discretion, to
            unilaterally terminate the User’s/ Third Party Service provider’s
            access to the Platform, or any portion thereof, at any time, without
            notice or cause.
          </li>
          <li>
            The Platform also reserves the universal right to deny access to
            particular users/ Third Party Service providers, or to any/all of
            are on its Platform without any prior notice/explanation to protect
            the interests of the Platform and/or other visitors to the Platform.
          </li>
          <li>
            The Platform reserves the right to limit, deny or create different
            access to the Platform and its features concerning different Users,
            or to change any of the features or introduce new features without
            prior notice.
          </li>
          <li>
            The User / Third Party Service provider shall continue to be bound
            by these Terms, and it is expressly agreed to by the Parties that
            the User shall not have the right to terminate these Terms till the
            expiry of the same.
          </li>
          <li>
            Upon termination of these Terms of Use, the company shall have no
            obligation to maintain or provide any of Other Party(s) data and may
            thereafter, unless legally prohibited, delete all of Other Party(s)
            data in its systems or otherwise in its possession or under its
            control, including but not limited to Other Party(s) personal
            information, login ID and password, order details (including any
            prescriptions uploaded) and all related information, files and
            materials associated with or inside Other Party(s) account (or any
            part thereof).
          </li>
        </ul>
        <br></br>
        <h6 className="text-center fw-bolder">COMMUNICATION</h6>
        <ul>
          <li>
            By using this Platform and providing his/her identity and contact
            information to the Company through the Platform, the Users hereby
            agree and consent to receive calls, e-mails, Notifications or SMS
            from the Company and/or any of its representatives at any time.
            (only with users consent)
          </li>
          <br></br>
          <br></br>
          <li>
            Users can report to{" "}
            <Link className="fw-bolder" onClick={supportmyprega}>
              “support@myprega.com”
            </Link>{" "}
            if they find any discrepancy with regard to Platform or
            content-related information and the Company will take necessary
            action after an investigation. The response with resolution (if any
            issues found) shall be dependent on the time is taken for
            investigation.
          </li>
          <br></br>
          <br></br>
          <li>
            The User expressly agrees that notwithstanding anything contained
            hereinabove, it may be contacted by the Company or any
            representatives relating to any services availed by the User on the
            Platform or anything pursuant thereto and the Users agrees to
            indemnify the Company from any harassment claims. It is expressly
            agreed to by the Parties that any information shared by the User
            with the Company shall be governed by the Privacy Policy.
          </li>
        </ul>
        <br></br>
        <br></br>
        <h6>Prohibited Advertisements under the Drugs and Magic Act:</h6>
        <p>
          Subject to the provisions of the Drugs and Magic Act, no person shall
          take any part in the publication of any advertisement referring to any
          drug which suggests or are calculated to lead to the use of that drug
          for –
        </p>
        <ul>
          <li>
            The procurement of miscarriage in women or prevention of conception
            in women; or
          </li>
          <li>
            The maintenance or improvement of the capacity of human beings for
            sexual pleasure; or
          </li>
          <li>The correction of menstrual disorder in women; or</li>
          <li>
            The diagnosis, cure, mitigation, treatment or prevention of any
            disease, disorder or condition specified in the schedule of the
            Drugs and Magic Act, or any other disease, disorder or condition (by
            whatever name called) which may be specified in the rules made under
            the Drugs and Magic Act; or provided that no such rule shall be made
            except, in respect of any disease, disorder or condition which
            requires timely treatment in consultation with a doctor or for which
            there are normally no accepted remedies; or
          </li>
          <li>Prohibition of misleading advertisements relating to drugs;</li>
          <li>
            Subject to the provisions of the Drugs and Magic Act, no person
            shall take any part in the publication of any advertisement relating
            to a drug if the advertisement contains any matters which:
          </li>
          <li>
            Directly or indirectly gives a false impression regarding the true
            character of the drug; or
          </li>
          <li>Makes a false claim for the drug; or</li>
          <li>Is otherwise false or misleading in any material particular.</li>
        </ul>
        <p>
          It is hereby clarified that the Third Party Advertisers will comply
          with all the provisions of the Drugs and Magic Act and the Rules made
          thereunder. Further, it is agreed that the Third Party Advertisers
          shall be solely responsible for any penalty or any action taken by the
          governmental authorities for non-compliance with the Drugs and Magic
          Act and the Rules made thereunder.
        </p>
        <h6 className="fw-bolder d-inline">Restricted Advertisements:</h6>
        <p className="d-inline">
          Advertisements in the following categories are restricted and require
          approval on a case-by-case basis:
        </p>
        <ul className="pl-4">
          <li>Advertisements that promote or reference alcohol;</li>
          <li>Advertisements for online dating service.</li>
          <li>Advertisements for gambling and games of skill;</li>
          <li>Advertisements for lotteries</li>
          <li>Advertisements for financial services;</li>
          <li>Advertisements for contraceptives;</li>
          <li>Advertisements for online pharmacies or pharmaceuticals; and</li>
          <li>Political advertisements.</li>
          <li>
            Advertisement for pornographic, pedophilic, libellous, invasive of
            another's privacy.
          </li>
          <li>Encouraging money laundering</li>
        </ul>
        <br></br>
        <h6 className="fw-bolder">Testimonials & Endorsements:</h6>
        <ul>
          <li>
            Any testimonials and endorsements contained in advertisements must
            comply with all applicable law(s), industry codes, rules, and
            regulations. For example, a clear and conspicuous disclaimer is
            required if an endorser's results were atypical or if the endorser
            was paid;
          </li>
          <li>
            The company recognizes and maintains a distinct separation between
            advertising and sponsored content and editorial content. All
            advertising or sponsored content on the Platform of the Company will
            be clearly and unambiguously identified; and
          </li>
          <li>
            A click on an advertisement may only link the User to the Platform
            of the Third Party Advertiser(s).
          </li>
        </ul>
        <br></br>
        <br></br>
        <h6 className="fw-bolder">Editorial Policy for the Platform –</h6>
        <p>
          As part of the Services, Company provides Company Content on the
          Platform targeted at the general public for informational purposes
          only and does not constitute professional medical advice, diagnosis,
          treatment or recommendations of any kind. Company Content is subject
          to the following rules/ information:
        </p>
        <ul>
          <li>
            Company Content is original and is relevant to the general public;
          </li>
          <li>
            {" "}
            Topics for Company Content are selected by Our board of qualified
            experts consisting of certified medical experts, pharmacist and
            medical professionals;
          </li>
          <li>
            Topics for Company Content are chosen based on current health news,
            drug alerts, new drug launches, latest medical findings published in
            peer-reviewed medical journals, such as ‘The Journal of the American
            Medical Association’, ‘The New England Journal of Medicine, ‘The
            Lancet’, ‘Pediatrics’, ‘Diabetes Care’, and many others;
          </li>
          <li>
            The Editorial Board (as mentioned below) takes into account the
            latest trending health and wellness topics like dengue, swine flu,
            seasonal allergies, new vaccines, public awareness trends like
            breast cancer awareness month," and ‘Healthy Heart Month’; as well
            as emerging health and nutrition trends like health benefits quinoa,
            use of BGR 34 for managing diabetes, alternative medicine like
            Ayurveda, homoeopathy and much more;
          </li>
          <li>
            The company maintains principles of fairness, accuracy, objectivity,
            and responsible, independent reporting;
          </li>
          <p>
            The member of Company has to fully disclose any potential conflict
            of interest with any of the Third-Party Service Providers;
          </p>
        </ul>
        <br></br>
        <br></br>
        <h6 className="fw-bolder">
          USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT
        </h6>
        <p>
          The User agrees and acknowledges that they are a restricted user of
          this Platform and that :
        </p>
        <ul>
          <li>
            They agree to provide genuine credentials during the process of
            registration on the Platform. You shall not use a fictitious
            identity to register. The company is not liable if the User has
            provided incorrect information.
          </li>
          <li>
            The medical opinion is not intended to replace a face-to-face visit
            with a physician and it does replace an actual doctor-patient
            relationship;
          </li>
          <li>
            In case of a second opinion where there is a difference of opinion
            among Our Medical Experts and your physician, You would bear the
            responsibility to decide on online or offline consultation, or
            procedure, and/or treatment;
          </li>
          <li>
            The Medical Expert is reliant on information provided by You and
            hence any information demonstrated to have been falsified,
            misleading or incomplete will immediately render the opinion and all
            details therein null and void;
          </li>
          <li>
            In some cases, the Medical Expert may determine that the transmitted
            information is of inadequate quality and may ask for more
            information, without which he/she may refuse to answer the query;
          </li>
          <li>
            In rare cases, the Medical Experts may feel that the query may not
            be answerable without physically examining the patient/ Users and
            the Consultation may be refused forthwith;
          </li>
          <li>
            In very rare instances, security protocols could fail, causing a
            breach of privacy of personal medical information; and
          </li>
          <li>
            Delays in medical evaluation and answers could occur due to
            deficiencies or failures of the service.
          </li>
          <li>
            Agree to ensure the Name, Email address, Address, Mobile number and
            any such other information provided during account registration is
            valid at all times and shall keep your information accurate and
            up-to-date.
          </li>
          <li>
            Users agree that they are solely responsible for maintaining the
            confidentiality of your account password. You agree to notify us
            immediately of any unauthorized use of your account. The company
            reserves the right to close your account at any time for any or no
            reason
          </li>
          <li>
            Understand and acknowledge that the data submitted is manually
            entered into the database of the Platform. The User also
            acknowledges the fact that data so entered into the database is for
            easy and ready reference for the User, and to streamline the
            Services through the Platform.
          </li>
          <li>
            Authorize the Platform to use, store or otherwise process certain
            personal information and all published Content, Client responses,
            Client locations, User comments, reviews and ratings for
            personalization of Services, marketing and promotional purposes and
            optimisation of User-related options and Services.
          </li>
          <li>
            Understand and agree that, to the fullest extent permissible by law,
            the Platform/Company and their successors and assigns, or any of
            their affiliates or their respective officers, directors, employees,
            agents, licensors, representatives, operational service providers,
            advertisers or suppliers shall not be liable for any loss or damage,
            of any kind, direct or indirect, in connection with or arising from
            the use of the Platform or this terms of use, including, but not
            limited to, compensatory, consequential, incidental, indirect,
            special or punitive damages.
          </li>
          <li>
            Are bound not to cut, copy, modify, recreate, reverse engineer,
            distribute, disseminate, post, publish or create derivative works
            from, transfer, or sell any information or obtained from the
            Platform. Any such use/limited use of the Platform will only be
            allowed with the prior express written permission of the Company.
          </li>
          <li>
            Agree not to access (or attempt to access) the Platform and/or the
            materials or Services by any means other than through the interface
            provided by the Platform. The use of deep-link, robot, spider or
            other automatic devices, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of the Platform or its content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Platform, materials or any content, or to obtain or attempt
            to obtain any materials, documents or information through any means
            not specifically made available through the Platform will lead to
            suspension or termination of the User’s access to the Platform. The
            User acknowledges and agrees that by accessing or using the Platform
            or any of the Services provided therein, it may be exposed to
            content that it may consider offensive, indecent or otherwise
            objectionable. The Company disclaims any liabilities arising
            concerning such offensive content on the Platform.
          </li>
          <li>
            Expressly agree and acknowledge that the Content generated by the
            Users and displayed on the Platform is not owned by the Company and
            that the Company is in no way responsible for the content of the
            same. The User may, however, report any offensive or objectionable
            content, which the Company may then remove from the Platform, at its
            sole discretion.
          </li>
          <li>
            Expressly consents to follow the terms and conditions, and policies
            of the Vendor affiliated with the Company from whom the Users are
            utilizing services. You accept and acknowledge the following:
          </li>
          <li>
            Company shall not be responsible for the mishaps/missed services due
            to no service/no show from the lab for sample collection;
          </li>
          <li>
            The company shall not be responsible for any error in the sample
            collection and/ or reports generated by the Labs/ Diagnostic Centers
          </li>
          <li>
            In no event shall the company be liable for failure on the part of
            the diagnostic centres to provide agreed services or to make
            provisions for the carrying out the Services
          </li>
          <li>
            All the professionals associated with the Company/Platform are well
            trained and Registered with concerned registering authority of
            India/State (like Medical Council, Nursing Council, Paramedical
            Council etc) to discharge his /her services in concerned speciality
            but even though if any injury, harm or loses caused due to Blood
            sample collection, Home nursing services, Home Physiotherapy,
            Doctors Appointment or Online doctors consultation etc, For such of
            any consequences the concerned registered professional is liable for
            the consequences happened during the discharge of his services
            delivered through the Platform, the Company shall not liable for any
            of such consequences.
          </li>
        </ul>
        <br></br>
        <br></br>
        <h6 className="fw-bolder">The User further undertakes not to:</h6>
        <ul>
          <li>
            Engage in any activity that interferes with or disrupts access to
            the Platform or the Services provided therein (or the servers and
            networks which are connected to the Platform);
          </li>
          <li>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent his/her affiliation with a person or entity;
          </li>
          <li>
            Probe, scan or test the vulnerability of the Platform or any network
            connected to the Platform, nor breach the security or authentication
            measures on the Platform or any network connected to the Platform.
            The User may not reverse look-up, trace or seek to trace any
            information relating to any other User of, or visitor to, the
            Platform, or any other viewer of the Platform, including any User
            account maintained on the Platform not operated/managed by the User,
            or exploit the Platform or information made available or offered by
            or through the Platform, in any manner;
          </li>
          <li>
            Disrupt or interfere with the security of, or otherwise cause harm
            to, the Platform, systems resources, accounts, passwords, servers,
            or networks connected to or accessible through the Platform or any
            affiliated or linked Platforms.
          </li>
          <li>
            Use the Platform or any material or content therein for any purpose
            that is unlawful or prohibited by these Terms, or to solicit the
            performance of any illegal activity or other activity which
            infringes the rights of this Platform or any other third party (s);
          </li>
          <li>
            Violate any code of conduct or guideline which may apply for or to
            any particular service offered on the Platform
          </li>
          <li>
            Violate any applicable laws, rules or regulations currently in force
            within or outside India;
          </li>
          <li>
            Violate any portion of these Terms or the Privacy Policy, including
            but not limited to any applicable additional terms of the Platform
            contained herein or elsewhere, whether made by amendment,
            modification, or otherwise;
          </li>
          <li>
            Commit any act that causes the Company to lose (in whole or in part)
            the Services of its Internet Establishment ("ISP") or in any manner
            disrupts the Services of any other supplier/service provider of the
            Company/Platform;
          </li>
        </ul>
        <h6 className="fw-bolder">Further</h6>
        <ul>
          <li>
            The User hereby expressly authorizes the Company/Platform to
            disclose any and all information relating to the User in the
            possession of the Company/Platform to law enforcement or other
            government officials, as the Company may in its sole discretion,
            believe necessary or appropriate in connection with the
            investigation and/or resolution of possible crimes, especially those
            involve personal injury and theft/infringement of intellectual
            property. The User further understands that the Company/Platform
            might be directed to disclose any information (including the
            identity of persons providing information or materials on the
            Platform) as necessary to satisfy any judicial Order, law,
            regulation or valid governmental request.
          </li>
          <li>
            By indicating the User's acceptance to purchase any service offered
            on the site, the user is obligated to complete such transactions
            after making payment. Users shall prohibit from indicating their
            acceptance to avail services where the transactions have remained
            incomplete.
          </li>
          <li>
            The User agrees to use the services provided by the Company, its
            affiliates, consultants and contracted companies, for lawful
            purposes only.
          </li>
          <li>
            The User agrees not to make any bulk purchase to indulge in any
            resale activities. In case of any such instances, the Company
            reserves all rights to cancel the current and future orders and
            block the concerned User account.
          </li>
          <li>
            The User agrees to provide authentic and true information. The
            Company reserves the right to confirm and validate the information
            and other details provided by the User at any point in time. If upon
            confirmation such User details are found to be false, not to be true
            (wholly or partly), the Company shall in its sole discretion reject
            the registration and debar the User from using the Services
            available on its Platform, and/or other affiliated Platforms without
            prior intimation whatsoever.
          </li>
          <li>
            The User agrees not to post any material on the Platform that is
            defamatory, offensive, obscene, indecent, abusive, or needlessly
            distressful, or advertising any goods or services. More
            specifically, the User agrees not to host, display, upload, update,
            publish, modify, transmit, or in any manner share any information
            that:
          </li>
          <ul className="pl-5">
            <li>
              Belongs to another person and to which the User has no right to;
            </li>
            <li>
              Is grossly harmful, harassing, blasphemous, defamatory, obscene,
              pornographic, pedophilic, libellous, invasive of another's
              privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful in any manner whatever;
            </li>
            <li>Is in any way harmful to minors;</li>
            <li>
              Infringes any patent, trademark, copyright or other proprietary
              rights;
            </li>
            <li>Violates any law for the time being in force;</li>
            <li>
              Deceives or misleads the addressee about the origin of such
              messages or communicates any information which is grossly
              offensive or menacing;
            </li>
            <li>
              Abuse, harass, threaten, defame, disillusion, erode, abrogate,
              demean or otherwise violate the legal rights of others;
            </li>
            <li>
              Impersonate any person or entity, or falsely state or otherwise
              misrepresent Your affiliation with a person or entity;
            </li>
            <li>
              Publish, post, disseminate, any grossly harmful information,
              harassing, blasphemous, defamatory, obscene, pornographic,
              pedophilic, libellous, invasive of another's privacy, hateful, or
              racially, ethnically objectionable, disparaging, relating or
              encouraging money laundering or gambling, or otherwise unlawful in
              any manner whatever; or unlawfully threatening or unlawfully
              harassing including but not limited to "indecent representation of
              women" within the meaning of the Indecent Representation of Women
              (Prohibition) Act, 1986;
            </li>
            <li>
              Threatens the unity, integrity, defence, security or sovereignty
              of India, friendly relations with foreign states, or public order
              or causes incitement to the commission of any cognizable offence
              or prevents investigation of any offence or is insulting any other
              nation.
            </li>
          </ul>
          <br></br>
          <br></br>
          <br></br>
          <h6 className="fw-bolder">PAYMENT, FEES AND TAXES</h6>
          <p>
            The User agrees to pay all Package fees, consulting fees and other
            fees applicable to User’s use of Services and the User shall not
            circumvent the fee structure. The fee is dependent on the Package
            that the User purchases and not on the actual usage of the Services.
          </p>
          <br></br>
          <br></br>
          <p>
            Each user/member/Third Party Service Providers are solely
            responsible for payment of all taxes, legal compliances, statutory
            registrations and reporting. The company is in no way responsible
            for any of the taxes except for its income tax.
          </p>
          <br></br>
          <br></br>
          <p>
            The fee has to be paid online through the facility made on a
            third-party website called{" "}
            <span className="fw-bolder">“Razorpay Payment”</span> as mentioned
            on the Website/Platform. Third parties support and services are
            required to process online fee payment. The company is not
            responsible for any loss or damage caused to the User during this
            process as these third parties are beyond the control of the
            Company. To process the payments, Platform/Website or payment
            gateway the third party might require details of the User's bank
            account, credit card number, etc. All fees are exclusive of
            applicable taxes. Taxes will be charged extra as per Government
            rules. The company reserves the right to modify the fee structure by
            updating the fee structure on the Website /Platform at any time
            which shall be considered as valid and agreed on communication.
          </p>
        </ul>
        <br></br>
        <h6 className="fw-bolder">SUSPENSION OF USER ACCESS AND ACTIVITY</h6>
        <p>
          Notwithstanding other legal remedies that may be available, the
          Company may in its sole discretion, limit the User’s access and/or
          activity by immediately removing the User’s access credentials either
          temporarily or indefinitely, or suspend/terminate the User’s
          association with the Platform, and/or refuse to the usage of the
          Platform to the User, without being required to provide the User with
          notice or cause:
        </p>
        <ul className="pl-5">
          <li>
            If the User is in breach of any of these Terms or the Privacy
            Policy.
          </li>
          <li>
            If the User has provided wrong, inaccurate, incomplete or incorrect
            information.
          </li>
          <li>
            If the User’s actions may cause any harm, damage or loss to the
            other Users or the Company, at the sole discretion of the Company.
          </li>
        </ul>
        <br></br>
        <h6 className="fw-bolder">INTELLECTUAL PROPERTY RIGHTS</h6>
        <br></br>
        <p>
          Unless expressly agreed to in writing, nothing contained herein shall
          give the User a right to use any of the Platform’s trade names,
          trademarks, service marks, logos, domain names, information,
          questions, answers, solutions, reports and other distinctive brand
          features, save according to the provisions of these Terms. All logos,
          trademarks, brand names, service marks, domain names, including
          material, designs, and graphics created by and developed by the
          Platform and other distinctive brand features of the Platform are the
          property of the Company or the respective copyright or trademark
          owner. Furthermore, concerning the Platform created by the Company,
          the Company shall be the exclusive owner of all the designs, graphics
          and the like, related to the Platform.
        </p>
        <br></br>
        <p>
          The User may not use any of the intellectual property displayed on the
          Platform in any manner that is likely to cause confusion among
          existing or prospective Users of the Platform, or that in any manner
          disparages or discredits the Company/Platform, to be determined at the
          sole discretion of the Company.
        </p>
        <br></br>
        <p>
          The User is aware of all intellectual property, including but not
          limited to copyrights, relating to said services resides with the
          owners, and that at no point does any such intellectual property stand
          transferred from the aforementioned creators to the Company or any
          other User. The User is aware that the Company merely provides a
          platform through which the Users can communicate and schedule
          meetings, and the Company/Platform does not own any of the
          intellectual property relating to the independent content displayed on
          the Platform, apart from created graphics and specified content.
        </p>
        <br></br>
        <p>
          The User is further aware that any reproduction or infringement of the
          intellectual property of the aforementioned owners by the User will
          result in legal action being initiated against the User by the
          respective owners of the intellectual property so reproduced/infringed
          upon. It is agreed to by the Parties that the contents of this Section
          shall survive even after the termination or expiry of the Terms and/or
          Policy.
        </p>
        <h6 className="fw-bolder">RISKS OF USING COMPANY’S SERVICES</h6>
        <p>
          As with any medical procedure, there are potential risks associated
          with using the Services. By using the Services, You agree to abide by
          these Terms of Use, Privacy Policy and risks described below. These
          risks include, but may not be limited to:
        </p>
        <br></br>
        <p>
          While the Platform is an honest attempt to provide access to the best
          possible medical information to the Users, the Medical Experts will
          not be examining You physically. The Medical Experts may not have
          access to all or some of The medical history that might be critical to
          consult You. The Medical Experts will not have the benefit of
          information that would be obtained by examining You in person,
          observing Your physical condition and by going through Your medical
          records. This means that the Services provided is different from the
          diagnostic and treatment services typically decided by a physician.
          Therefore, the Medical Experts may not be aware of facts or
          information that would affect the opinion of Your diagnosis. To reduce
          the risk of this limitation, the company strongly encourages You to be
          in touch with an on-ground physician and share the Company’s opinion
          with him/her.
        </p>
        <br></br>
        <p>
          By requesting a medical opinion through the Platform, You acknowledge
          and agree that:
        </p>
        <ul>
          <li>
            The advice/information/opinion on diagnosis You may receive could be
            limited and provisional;
          </li>
          <li>
            The medical opinion is not intended to replace a face-to-face visit
            with a physician and it does replace an actual doctor-patient
            relationship;
          </li>
          <li>
            In case of a second opinion where there is a difference of opinion
            among Our Medical Experts and Your physician, You would bear the
            responsibility to decide on online or offline consultation, or
            procedure, and/or treatment;
          </li>
          <li>
            The Medical Expert is reliant on information provided by You and
            hence any information demonstrated to have been falsified,
            misleading or incomplete will immediately render the opinion and all
            details therein null and void;
          </li>
          <li>
            In some cases, the Medical Expert may determine that the transmitted
            information is of inadequate quality and may ask for more
            information, without which he/she may refuse to answer the query;
          </li>
          <li>
            In rare cases, the Medical Experts may feel that the query may not
            be answerable without physically examining the patient/ Users and
            the Consultation may be refused forthwith;
          </li>
          <li>
            In very rare instances, security protocols could fail, causing a
            breach of privacy of personal medical information; and
          </li>
          <li>
            Delays in medical evaluation and answers could occur due to
            deficiencies or failures of the service as per those mentioned in
            these Terms of Use.
          </li>
        </ul>
        <br></br>
        <h6 className="fw-bolder"> DISCLAIMER OF WARRANTIES AND LIABILITIES</h6>
        <ul>
          <li>
            The User agrees and undertakes that they are accessing the Platform
            at their sole risk and are that they are using their best and
            prudent judgment before availing any service listed on the Platform
            or accessing/using any information displayed thereon.
          </li>
          <li>
            The User agrees that any kind of information, resources, activities,
            recommendations obtained/availed from Platform, written or oral,
            will not create any warranty and the Platform disclaims all
            liabilities resulting from these.
          </li>
          <li>
            The Platform does not guarantee that the Services contained in the
            Platform will be uninterrupted or error-free, or that the Platform
            or its server will be free of viruses or other harmful components,
            and the User hereby expressly accepts any associated risks involved
            with the User’s use of the Platform.
          </li>
        </ul>
        <p>
          It is further agreed to by the Parties that the contents of this
          Section shall survive even after the termination or expiry of the
          Terms and/or Policy.
        </p>
        <br></br>
        <h6 className="fw-bolder">FORCE MAJEURE</h6>
        <p>
          Neither the Company nor the Platform shall be liable for damages for
          any delay or failure to perform its obligations hereunder if such
          delay or failure is due to cause beyond its control or without its
          fault or negligence, due to force majeure events including but not
          limited to acts of war, acts of God, earthquake, riot, fire, festive
          activities sabotage, labour shortage or dispute, internet
          interruption, technical failure, breakage of sea cable, hacking,
          piracy, cheating, illegal or unauthorized.
        </p>
        <br></br>
        <h6 className="fw-bolder">DISPUTE RESOLUTION AND JURISDICTION</h6>
        <p>
          It is expressly agreed to by the Parties hereto that the formation,
          interpretation, and performance of these Terms and any disputes
          arising therefrom will be resolved through Arbitration. It is further
          agreed to by the Parties that the contents of this section shall
          survive even after the termination or expiry of the Terms and/or
          Policy.
        </p>
        <p>
          <span className="fw-bolder">Arbitration</span>: The said dispute will
          be referred to arbitration by a sole arbitrator to be appointed by the
          Company, and the award passed by such sole arbitrator will be valid
          and binding on all parties. The Parties shall bear their costs for the
          proceedings, although the sole arbitrator may, in his/her sole
          discretion, direct either Party to bear the entire cost of the
          proceedings. The arbitration shall be conducted in English, and the
          seat of Arbitration shall be the city of{" "}
          <span className="fw-bolder">Pune, Maharashtra, India.</span> The
          Parties expressly agree that the Terms, Policy and any other
          agreements entered into between the Parties are governed by the laws,
          rules, and regulations of India.
        </p>
        <br></br>
        <h6 className="fw-bolder">NOTICES/GRIEVANCES</h6>
        <br></br>
        <p>
          Any and all communication relating to any dispute or grievance
          experienced by the User may be communicated to the Company by the User
          by emailing to <span className="fw-bolder">“info@myprega.com”.</span>
        </p>
        <br></br>
        <br></br>
        <h6 className="fw-bolder">MISCELLANEOUS PROVISIONS</h6>
        <ul>
          <li>
            <span className="fw-bolder"> Entire Agreement:</span>
            <p>
              These Terms, read with the Policy, form the complete and final
              contract between the User and the Company with respect to the
              subject matter hereof and supersedes all other communications,
              representations, and agreements (whether oral, written or
              otherwise) relating thereto.
            </p>
          </li>
          <li>
            <span className="fw-bolder"> Waiver:</span>
            <p>
              The failure of either Party at any time to require performance of
              any provision of these Terms shall in no manner affect such
              Party's right at a later time to enforce the same. No waiver by
              either party of any breach of these Terms, whether by conduct or
              otherwise, in any one or more instances, shall be deemed to be or
              construed as a further or continuing waiver of any such breach, or
              a waiver of any other breach of these Terms.
            </p>
          </li>
          <li>
            <span className="fw-bolder"> Severability:</span>
            <p>
              If any provision/clause of these Terms is held to be invalid,
              illegal or unenforceable by any court or authority of competent
              jurisdiction, the validity, legality, and enforceability of the
              remaining provisions/clauses of these Terms shall in no way be
              affected or impaired thereby, and each such provision/clause of
              these Terms shall be valid and enforceable to the fullest extent
              permitted by law. In such case, these Terms shall be reformed to
              the minimum extent necessary to correct any invalidity, illegality
              or unenforceability, while preserving to the maximum extent the
              original rights, intentions and commercial expectations of the
              Parties hereto, as expressed herein.
            </p>
          </li>
          <li>
            <span className="fw-bolder"> Contact Us: </span>
            <p>
              If you have any questions about this Policy or any other policy,
              the practices of the Platform, or your experience, you can contact
              us at “info@myprega.com” or the address mentioned below.
            </p>
          </li>
        </ul>
        <br></br>
        <h6 className="fw-bolder text-center">Myprega</h6>
        <p className="text-center mb-0">Plot No. 26, Parag Park,</p>
        <p className="text-center mb-0">Malhar Chowk, Station Road,</p>
        <p className="text-center mb-0">Ahmednagar, Maharashtra-414001</p>
      </div>
      <div className="pt-5">
        <Footer />
      </div>
    </div>
  );
}

export default Termsofuse;
