import React, { useState, useEffect, useRef } from 'react'
import MiniDrawer from '../Components/MiniDrawer'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Typography from '@mui/material/Typography'
import configData from '../../config.json'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ReportBox from '../Components/ReportBox'
import ReportDetailsBox from '../Components/ReportDetailsBox'
import Dropdown from "../Components/Categories/Dropdown";

import axios from 'axios'
const doctorTypes = [
  'Sonography Centre',
  'Pathology Lab'
]

const consultationNumbers = [
  'Gynaecologist',
  'Doctor Of Medicine',
  'Sonography Lab',
  'Pathalogy Lab'
]
const pregnancyWeeks = [
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40
  ];


const reports1 = [
  {
    id: 1,
    name: 'TEST 2',
    month: 'Oct',
    week: 13,
    date: 23,
    dr: 'Sunrise Dignostic Center',
    type: 'Sonography Center',
    fullDate: 'Oct 23 2023 10:00:00 AM'
  },
  {
    id: 2,
    name: 'CONSULTATION 2',
    month: 'Oct',
    week: 11,
    date: 11,
    dr: 'Dr. Jane Doe',
    type: 'Doctor of Medicine',
    fullDate: 'Oct 1 2023 10:00:00 AM'
  },
  {
    id: 3,
    name: 'CONSULTATION 2',
    month: 'Sep',
    week: 9,
    date: 28,
    dr: 'Dr. Jane Doe',
    type: 'Gynaecologist',
    fullDate: 'Sep 28 2023 10:00:00 AM'
  },
  {
    id: 4,
    name: 'TEST 1',
    month: 'Sep',
    week: 7,
    date: 13,
    dr: 'Sunrise Dignostic Center',
    type: 'Sonography Center',
    fullDate: 'Sep 13 2023 10:00:00 AM'
  },
  {
    id: 5,
    name: 'CONSULTATION 2',
    month: 'Aug',
    week: 5,
    date: 30,
    dr: 'Dr. Jane Doe',
    type: 'Doctor of Medicine',
    fullDate: 'Aug 30 2023 10:00:00 AM'
  },
  {
    id: 6,
    name: 'CONSULTATION 2',
    month: 'Aug',
    week: 3,
    date: 14,
    dr: 'Dr. Jane Doe',
    type: 'Gynaecologist',
    fullDate: 'Aug 14 2023 10:00:00 AM'
  }
]
function Reports () {
  useEffect(()=>{
    getreports();
},[]);

  const [drType, setDrtype] = useState('')
  const [number, setNumber] = React.useState('')
  const [reports,setReports]=useState([])
  const [week, setWeek] = React.useState('')
 const [filteredReports,setFilteredreports]=useState([]);
  const [clicked, setClicked] = useState(false)
  const [data, setData] = useState([])
  const wrapperRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [desc, setDesc] = useState(true)
  
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);

  const handleChange1 = event => {
    setDrtype(event.target.value)
  }
  const handleChange2 = event => {
    setNumber(event.target.value)
  }
  const handleChange3 = event => {
    setWeek(event.target.value)
  }

  const handleClick = item => {
    setIsVisible(!isVisible)
    setClicked(!clicked)
    setData(item)
    console.log('===data', item)
  }

  const handleSort = () => {
    const data =filteredReports
    data.sort(function (a, b) {
      var c = new Date(a.created_date)
      var d = new Date(b.created_date)
      return desc ?  d - c: c-d;
    })
    setFilteredreports(data);
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false)
    }
  }
  const  getreports=async()=>{
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/patient/getPatientConsultation`, {
        patientId: localStorage.getItem("userId"),
        // patientId:363
      });
    
      if (response.data.success) {
        console.log("getreports", response.data.patientConsultations);
        setReports(response.data.patientConsultations);
      } else {
        console.error(
          "Error:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
    
    }
    useEffect(()=>{
      const filtereddata=drType !== '' ?
       reports?.filter((reports)=>reports?.doctorDetails[0]?.expert_name==drType):reports;
       setFilteredreports(filtereddata)
      console.log("#",week,filtereddata.week);

       const filtereddatabyweek=week!=='' ?
       filtereddata?.filter((reports)=>reports?.week==week):filtereddata;
       setFilteredreports(filtereddatabyweek);

    },[drType,reports,week]);

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
        height: '100%',
        width: '100%'
      }}
    >
      <MiniDrawer />

      <div style={{ width: '100%' }}>
        <div
          style={{
            marginTop: 50,
            // marginLeft: -40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography
            paragraph
            sx={{
              color: configData.THEME_COLORS.PRIMARY
            }}
          >
            <div
              style={{
                fontSize: '32px',
                fontWeight: '600',
                fontFamily: 'Poppins',
                marginLeft: '60px'
              }}
            >
              {'Reports'}
            </div>
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '60px'
            }}
          >
            <NotificationsIcon
              sx={{ width: '25px', height: '25px', color: '#004AAD' }}
            />
            <Dropdown
                  submenu={submenu}
                  onClick={() => showsubmenu()}
                  page="report"
                />
          </div>
        </div>
        <div
          style={{
            width: '87%',
            backgroundColor: 'white',
            marginBottom: '20px',
            borderRadius: '12px',
            marginLeft: '60px'
          }}
        >
          <div
            style={{
              // marginLeft: '10px',

              borderRadius: '12px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <div
                style={{
                  marginLeft: '30px',
                  marginTop: '10px',
                  fontWeight: '600',
                  fontSize: '24px',
                  color: configData.THEME_COLORS.SECONDARY
                }}
              >
                Filters
              </div>
            </div>
            <div style={{}}>
              <Typography
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                  marginTop: '21px',
                  alignSelf: 'flex-end',
                  display: 'flex',
                  marginRight: '20px'
                }}
              >
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    fontFamily: 'Poppins',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setDesc(!desc)
                    handleSort()
                  }}
                >
                  {desc ? 'New - Old' : 'Old - New'}
                </div>

                <img
                  src={'/HealthTracker/sort.png'}
                  style={{
                    marginTop: '8px',
                    resizeMode: 'contain',
                    height: '18px',
                    width: '18px'
                  }}
                  alt="Sort"
                />
              </Typography>
            </div>
          </div>
          <div
            style={{
              marginTop: '12px',
              marginLeft: '20px',
              height: '60px',
              borderRadius: '12px',
              display: 'flex'
            }}
          >
            <Box
              sx={{
                width: isVisible && clicked ? '20%' : '18%',
                marginRight: '3%'
              }}
            >
              <FormControl fullWidth size={'small'}>
                <Select
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }
                  }}
                  style={{
                    backgroundColor: '#F2F2F2',
                    fontSize: isVisible && clicked ? '12px' : '16px',
                    fontFamily: 'Poppins',
                    fontWeight: '500'
                  }}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={drType}
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value=''>Doctor Type</MenuItem>
                  {doctorTypes.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
            {/* <Box
              sx={{
                width: isVisible && clicked ? '27%' : '25%',
                marginRight: '3%'
              }}
            >
               <FormControl fullWidth size={'small'}>
                <Select
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }
                  }}
                  style={{
                    backgroundColor: '#F2F2F2',
                    fontSize: isVisible && clicked ? '12px' : '16px',
                    fontFamily: 'Poppins',
                    fontWeight: '500'
                  }}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={number}
                  onChange={handleChange2}
                  displayEmpty
                >
                  <MenuItem value=''>Consultation Number</MenuItem>
                  {consultationNumbers.map((item, index) => {
                    return <MenuItem value={index}>{item}</MenuItem>
                  })}
                </Select>
              </FormControl> 
            </Box> */}
            <Box
              sx={{
                width: isVisible && clicked ? '24%' : '22%'
              }}
            >
              <FormControl fullWidth size={'small'}>
                <Select
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }
                  }}
                  style={{
                    backgroundColor: '#F2F2F2',
                    fontSize: isVisible && clicked ? '12px' : '16px',
                    fontFamily: 'Poppins',
                    fontWeight: '500'
                  }}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={week}
                  displayEmpty
                  onChange={handleChange3}
                >
                  <MenuItem value=''>Pregnancy Week</MenuItem>
                  {pregnancyWeeks.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div
          style={{
            width: '90%',
            marginLeft: '60px'
          }}
        >
          <div
            className='container'
            style={{ height: '80vh', overflowY: 'scroll' }}
          >
            <div className='row'>
              {/* <div className="col"> */}
              {filteredReports?.map((item, index) => {
                return (
                  <ReportBox
                    onClick={() => handleClick(item)}
                    item={item}
                    reports1={reports}
                    isVisible={isVisible}
                  />
                )
              })}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      {clicked && isVisible && (
        <ReportDetailsBox title={'Report Details'} data={data} />
      )}
    </div>
  )
}

export default Reports