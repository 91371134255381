import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [userid, setUserid] = useState();
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
  return (
    <div>
      <Header userid={userid} />
      <div className="container-fluid about" style={{ paddingTop: "130px" }}>
        <div className="containertt">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h1 className="text-capitalize pb-4 pt-2">about us</h1>
              <p>
                'All the money in the world can't buy you back good health' -
                That's why at Myprega, our team follows a dream to provide a
                healthier tomorrow. We collaborate with the best healthcare
                experts to cater top-notch quality medical services to you, no
                matter wherever you're located.
              </p>
              <p>
                Belonging to the healthcare and technology sector, we work
                towards becoming a simplified solution for a healthy mom-to-be
                journey for women across the globe. With our fully automated
                remote system, you can access different health experts for
                virtual as well as offline consultation from anywhere in the
                world.
              </p>

              <p>
                With an ultra-modern strategy of integrating pregnancy
                requirements with groundbreaking technology, we cultivate the
                vision of people towards pregnancy as per today's requirements.
                From consulting the top healthcare experts to getting the
                essential screening tests done during the gestation period, we
                offer inclusive packages that are tailor-made for the happy
                health of you or your loved ones.
              </p>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <img src="/about.png" className="aboutimg" />
            </div>
          </div>
          <div className="container my-5">
            <div className="row">
              <div className="col-12 col-md-6 mb-5 mb-md-1">
                <div className="oval position-relative">
                  <p
                    className="px-1 pt-5 px-lg-3 fw-bolder text-center"
                    id="fonts"
                  >
                    Reimagine & cultivate perspective of people towards
                    pregnancy and evolve the way pregnancy care have been
                    happening for decades.
                  </p>
                  <div className="circle position-absolute">
                    <img src="/vision.png" className="vision" />
                  </div>
                </div>
              </div>
              <div className="col-6 d-none d-md-block"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6 d-none d-md-block"></div>
              <div
                className="col-12 col-md-6"
                style={{ position: "relative", top: "-70px" }}
              >
                <div className="oval position-relative">
                  <p className="px-1 fw-bolder pt-2 text-center" id="fonts">
                    Organising the unorganised pregnancy care by integrating it
                    with technology
                  </p>
                  <div className="circle position-absolute">
                    <img src="/mission.png" className="vision" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
