import React, { useState ,useEffect } from "react";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import Consultationbelowlist from "./Consultationbelowlist";
import ConsultationCardDetails from "./Categories/ConsultationCardDetails";
// import { PathTable2 } from "../Components/PathologyTest";

export function PathTable2({ consultations }) {
  console.log("!@#$%^&*()",consultations)
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  let i = 1;
  return (
    <table className="spacing-table">
      <thead>
        <tr
          style={{
            fontFamily: "Poppins",
            fontSize: isTabScreen ? "10px" : "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
            backgroundColor: "rgba(243, 246, 249, 1)",
          }}
        >
          <th>NUMBER</th>
          <th>DATE</th>
          <th>STATUS</th>
        </tr>
      </thead>
      {consultations?.map((row) => (
        <tbody>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {row.consultation_number}
            </td>
            <td
              style={{
                fontSize: isTabScreen ? "8px" : "12px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {row.scheduled_date != null ? (
                moment(row.scheduled_date).format("MMM D")
              ) : (
                <div>-</div>
              )}
            </td>
            <td>
              {" "}
              {row.is_completed === 1 ? (
                <img
                  src={"/Pathology/tickmark.png"}
                  style={{ width: "28px", height: "25px",margin:"0px auto" }}
                ></img>
              ) : row.scheduled_date != null ? (
                <div
                  style={{
                    fontSize: isTabScreen ? "8px" : "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    color: "#2564b9",
                  }}
                >
                  SCHEDULED
                </div>
              ) : (
                <div
                  style={{
                    fontSize: isTabScreen ? "8px" : "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    color: "#F92A2A",
                  }}
                >
                  UNSCHEDULED
                </div>
              )}
            </td>
            {/* <td>{row.id}</td> */}
          </tr>
        </tbody>
      ))}
    </table>
  );
}

function Rightsidedrconsultation({ consultations, Pres, doctorInfo,consultationsData,patientdata }) {
  console.log("???????CONSULTATION", consultationsData,doctorInfo);
  const [btnBack,setBtnBack] = useState(!!consultationsData);
  console.log(btnBack,"btnBack",consultationsData)
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  useEffect(() => {
    setBtnBack(!!consultationsData);
  }, [consultationsData]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          paddingTop: "48px",
          fontSize: isTabScreen ? "24px" : "32px",
          color: configData.THEME_COLORS.PRIMARY,
          fontFamily: "Poppins",
          fontWeight: "500",
          color: "#F0588B",
          textAlign: "center",
        }}
      >
        Doctor
      </div>
      <hr className="mb-0 w-100"></hr>

      <div>
        {/* div for inner details below lab details dhande */}
        <div
          className="px-4 d-flex align-items-center"
          style={{ marginTop: "25px" }}
        >
          <img
            // src={"/Pathology/jondoe2.png"}
            src={"https://admin.myprega.com/" + doctorInfo?.profile_pic}
            style={{
              borderRadius: "50%",
              height: "50px",
              width: "50px",
            }}
          ></img>
          <div>
            <div
              style={{
                paddingLeft: "20px",
                fontWeight: "600",
                fontSize: isTabScreen ? "16px" : "20px",
                color: "rgba(0, 74, 173, 1)",
              }}
            >
              {"Dr. " + doctorInfo?.fname + " " + doctorInfo?.lname}
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: isTabScreen ? "14px" : "16px",
                color: "#333333",
                paddingLeft: "20px",
              }}
            >
              {doctorInfo?.medicalExpertise}
            </div>
          </div>
        </div>
        {/* inner details below lab details closed */}
        <div className="d-flex px-4" style={{ paddingTop: "25px" }}>
          {" "}
          {/* div for lab address */}
          <div>
            <img alt="nt found"
              src={"/Pathology/clinic.png"}
              style={{
                height: "20px",
                width: "20px",
                marginTop: "5px",
              }}
            ></img>
            <div
              style={{
                fontSize: "8px",
                fontWeight: "400",
                color: "rgba(112, 112, 112, 1)",
              }}
            >
              Clinic
            </div>
          </div>
          <div
            style={{
              fontSize: isTabScreen ? "14px" : "16px",
              fontWeight: "400",
              paddingLeft: "21px",
              color: "rgba(51, 51, 51, 1)",
            }}
          >
           {patientdata?.pathology?.clinic_address?.address_line_1}
          </div>
        </div>

        <div
          className="d-flex px-4 align-items-center"
          style={{ paddingTop: "19px" }}
        >
          <img alt="nt found"
            src={"/Pathology/map.png"}
            style={{
              height: "20px",
              width: "15px",
              marginTop: "5px",
            }}
          ></img>
          <div
            style={{
              color: "rgba(0, 82, 204, 1)",
              fontSize: isTabScreen ? "12px" : "16px",
              fontWeight: "400",
              paddingLeft: "25px",
            }}
          >
            Check on map
          </div>
        </div>
        <hr className="mt-1 w-100"></hr>

        <div
          className="text-center"
          style={{
            marginTop: "25px",
            color: "rgba(240, 88, 139, 1)",
            fontSize: isTabScreen ? "20px" : "24px",
            fontWeight: "500",
          }}
        >
        { btnBack && <button className="btn btn-default" onClick={()=>setBtnBack(false)} style={{float : "inline-start"}}>
        <img alt="nt found" src={"/GarbhaSanskar/back.png"}
          style={{
            height: "15px",
            width: "23.09px",
            cursor: "pointer",
          }}
        />
        </button> }
        {doctorInfo?.medicalExpertise != 'Yoga Expert' &&
         ( btnBack ?'Consultation Details' :'Consultation Summary')
        }
        </div>
        <div>
          {doctorInfo?.medicalExpertise != 'Yoga Expert' &&
          !btnBack ?(<PathTable2 consultations={consultations}  
           />) :
          ( consultationsData && 
            <>
            <ConsultationCardDetails consultationsData = {consultationsData} />
            </>
             )
            }
          {/* {consultationsData && 
            <ConsultationCardDetails consultationsData = {consultationsData} /> } */}
        </div>
      </div>
    </div>
  );
}

export default Rightsidedrconsultation;
