import React, { useState } from "react";
import configData from "../../../config.json";
import { useMediaQuery } from "react-responsive";
function DailyStory({ setExpand, setSelected }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
    overflow: "scroll",
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={wrapper}>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.PRIMARY,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelected(false);
            setExpand(true);
          }}
        >
          Back
        </div>

        <div
          style={{
            // marginLeft: '50px',
            marginTop: "10px",
            fontSize: isTabScreen ? "20px" : "24px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
          }}
        >
          Daily Story
        </div>

        <div
          style={{
            fontSize: isTabScreen ? "28px" : "32px",
            fontWeight: "600",
            alignSelf: "center",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          Swami Vivekananda
        </div>

        <div
          style={{
            marginTop: "30px",
            alignSelf: "center",
            fontSize: isTabScreen ? "15px" : "20px",
            fontWeight: "400",
            width: "95%",
            overflow: "scroll",
          }}
        >
          Vivekananda, original name Narendranath Datta, Datta also spelled
          Dutt, (born January 12, 1863, Calcutta [now Kolkata]—died July 4,
          1902, near Calcutta), Hindu spiritual leader and reformer in India who
          attempted to combine Indian spirituality with Western material
          progress, maintaining that the two supplemented and complemented one
          another. His Absolute was a person’s own higher self; to labour for
          the benefit of humanity was the noblest endeavour. Always stressing
          the universal and humanistic side of the Vedas, the oldest sacred
          texts of Hinduism, as well as belief in service rather than dogma,
          Vivekananda attempted to infuse vigour into Hindu thought, placing
          less emphasis on the prevailing pacifism and presenting Hindu
          spirituality to the West. He was an activating force in the movement
          to promote Vedanta philosophy (one of the six schools of Indian
          philosophy) in the United States and England. In 1893 he appeared in
          Chicago as a spokesman for Hinduism at the World’s Parliament of
          Religions and so captivated the assembly that a newspaper account
          described him as “an orator by divine right and undoubtedly the
          greatest figure at the Parliament.” Thereafter he lectured throughout
          the United States and England, making converts to the Vedanta
          movement.On his return to India with a small group of Western
          disciples in 1897, Vivekananda founded the Ramakrishna Mission at the
          monastery of Belur Math on the Ganges (Ganga) River near Calcutta (now
          Kolkata). Self-perfection and service were his ideals, and the order
          continued to stress
        </div>
      </div>
    </div>
  );
}

export default DailyStory;
