import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { webUrlLogin } from "../Utility/config";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import configData from "../../config.json";
let paymentid;
let orderid;
let razorpaysignature1;
function Pricing() {
  const navigate = useNavigate();
  const pricingparacontent= {
    color: "#7A8B94",
    fontSize: "14px",
    lineHeight: "150%"
  }
const pricecherishcare=
  {
    color: "#3C4852",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "120%",  
  }
  const imgrad={
    borderRadius:"10px",
  }
  const headerRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const [areas, setAreas] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [areaData, setAreaData] = useState(false);
  const [packageDetails, setPackageDetails] = useState();
  const [nextpg,setNextpg]=useState(false);
  const [pricing, setPricing] = useState("");
  const [patientdata, setPatientdata] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [razorpaysignature, setRazorpaySignature] = useState(null);
 const [test,setTest]=useState(false);
  const [userid, setUserid] = useState(0);
  const [Razorpay] = useRazorpay();
  useEffect(() => {
    window.scrollTo(0, 0);
    getAreas();
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
    getsingleuserdetail();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setIsAtTop(window.scrollY === 0);
  };
useEffect(()=>{
    console.log("PRICING",pricing);
},[pricing,test])
  const getsingleuserdetail = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: localStorage.getItem("userId"),
      });

      if (response.data.success) {
        setPatientdata(response.data.user);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const calling = async () => {
    const pr = pricing * 100;
    console.log("@price",pricing,pr);
    try {
      const response = await axios.post(`{configData.SERVER_API_URL}/checkout/getorderId`, {
        amount: pr,
        currency: "INR",
      });
      const data = response.data;
      return data;
    } catch {
      console.log("Error");
    }
  };

  const handlePayment = useCallback(async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: userId,
      });
      if (response.data.success) {
        const user = response.data.user;
          console.log("USER",response.data.user);
        const order = await calling();
        console.log("order", order);
        const options = {
          key: "rzp_live_5FkwM0dSiSrFis",
          key_secret: "YZPhYx4f6silMc8BHobIudxD",
          amount: pricing*100,
          currency: "INR",
          name: "Myprega",
          description: "Test Transaction",
          image: "https://myprega.com/logo.png",
          order_id: order?.order_details.id,
          handler: (res) => {
            paymentid = res.razorpay_payment_id;
            orderid = res.razorpay_order_id;
            razorpaysignature1 = res.razorpay_signature;

            setPaymentId(paymentid);
            setOrderId(orderid);
            setRazorpaySignature(razorpaysignature);
            
            console.log("Payment ID:", paymentid, paymentId);
            console.log("Order ID:", orderid, orderId);
            console.log(
              "Razorpay Signature:",
              razorpaysignature1,
              razorpaysignature
            );
         
            paymentdetail(paymentid, orderid, razorpaysignature1);
          },
          prefill: {
            name: user?.fname + " " + user?.lname, //user deytails
            email: user?.email,
            contact: user?.phone,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      }
    } else {
      alert("Please SignUp");
      navigate("/patient/Signup");
    }
  }, [selectedValue, Razorpay,packageDetails]);

  const options = {
    key: "rzp_test_mel87ldPGvyLYj", // Replace with your Razorpay Key ID
    amount: 100, // Amount in paise
    currency: "INR",
    name: "Acme Corp",
    description: "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: "order_MyNFHL1FQ6cjgh", // Replace with your Order ID
    callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
    prefill: {
      name: "Gaurav Kumar",
      email: "gaurav.kumar@example.com",
      contact: "9000090000",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };
  const paymentdetail = async (paymentid, orderid, razorpaysignature1) => {
    try {
      const userId = localStorage.getItem("userId");
      const p = selectedValue;

      const response = await axios.post(
        `${configData.SERVER_API_URL}/checkout/store_payment_details`,
        {
          user_id: userId,
          razorpay_payment_id: paymentid,
          razorpay_order_id: orderid,
          payment_status: "SUCCESS",
          razorpay_signature: razorpaysignature1,
          amount: p,
        }
      );
      if (response.data.success) {
        console.log("sent detail successfully");
        getsingleuserdetail();
        if (patientdata.payment_status == true) {
          window.location.href = `${webUrlLogin}`;
        }
      }
    } catch {
      console.log("error");
    }
  };

  const getAreas = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/users/get_areas_list`,
        {}
      );
      if (response.data.success) {
        setAreas(response.data?.list);
      }
    } catch (error) {
      console.error("Error get areas:", error);
    }
  };

  const handleChangeGetDetails = async (e) => {
    const areaId = e.target.value;
    setSelectedValue(e.target.value);
    if (areaId) {
      try {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/common/getAreaDetailsById`,
          {
            area_id: areaId,
          }
        );

        if (response.data.success) {
          setPackageDetails(response.data.Area_Details[0]);
          console.log("PPP",response.data.Area_Details[0],e.target.value);
          const p = response.data.Area_Details[0]?.pricing;
          setPricing(p);
          setAreaData(true);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    } else {
      setAreaData(false);
    }
  };
  const proceedtonext=()=>{
    if(selectedValue!="")
    setNextpg(true);
  else
  alert("Please Select Area first");
  }
  const handleShare = () => {
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: "Myprega",
          text: "Check out this link!",
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback if Web Share API is not supported
      alert("Web Share API is not supported in your browser.");
      // You can provide a fallback mechanism here, such as copying the link to the clipboard
    }
  };

 
const subbtn={
backgroundColor:"#3C4852",
color:"white",
width:"200px",
borderRadius:"12px",  
height:"55px",
marginRight:"20px",
}
const sharebtn={
  backgroundColor:"white",
  border:"1px solid gray",
  height:"55px",
  borderRadius:"12px",
  width:"60px",
}
const yellowclr={
  height:"20px",
  width:"20px",
  backgroundColor:"yellow",
  borderRadius:"50%",
}
const democlass={
  width:"85%"
}
const demoimg = {
  borderRadius:"6px",
}
const elemental={
  border:"1px solid #F5F5F5",
  fontSize:"9px",
  borderRadius:"10px",
}
const month={
  color:"#3C4852",
  fontSize:"12px",
  fontWeight:"600",
}
const monthno={
  color:"#3C4852",
  fontSize:"16px",
  fontWeight:"bolder",
}
const monthhead={
  color:"#3C4852",
  fontSize:"12px",
  fontWeight:"600",
  lineHeight:"150%",
}
  return (
    <div style={{backgroundColor:nextpg===true?"#f5f5f5":"white"}}>
      <Header userid={userid} style={{zIndex:"1030"}}/>
     
     {nextpg===true?
     (  
     <div className="">
      <div className="container p mt-5" >
      <div
        id="myHeader"
        style={{
          backgroundColor: isAtTop ? "white" : "white",
          width: "90%",
          margin: "0px auto",
          // position: "sticky",
          // top: "50px",
          // zIndex: "9999",
        }}
      >
        
        <div className="container pt-5 p-0" style={{boxShadow:"0px 16px 32px 0px rgba(233, 238, 242, 0.4)"}}>
          <div className="row pt-5">
            <div className="col-5">
            <img src="/pricingimage.png" className="img-fluid" id="pricingimg" 
            style={imgrad} alt=""/>
            </div>
            <div className="col-7">
            <h1 style={pricecherishcare} className="ps-4">Cherish Care</h1>
            <p className="text-left mb-3 mt-4 " style={pricingparacontent}>
              Finding the right pregnancy care that is not heavy on your pocket
              is more like a task. To reduce your burden we help anytime anyday
              with the best all inclusive pregnancy care at an affordable rate.
            </p>
            </div>
          </div>
          <hr className="w-100 my-3"></hr>
          <div className="p-0" ref={headerRef} 
          style={{
          position: !isAtTop? "fixed":"",
          top: !isAtTop&&"90px",
          zIndex: "103",
       
          width:!isAtTop && "83%",
          // paddingBottom:!isAtTop && "30px",
          boxShadow:"0px 16px 32px 0px rgba(233, 238, 242, 0.4)",
          }}>
            <div className="container-fluid bg-white">
            <div className="row py-3 align-items-center">
              <h1 className="py-4 ps-5" style={{display:isAtTop?"none":"block",
              color: "#3C4852",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "120%",  }} 
             >Cherish Care</h1>
             {!isAtTop && <hr className="mx-auto py-4" style={{width:"90%"}}></hr>}
              <div className="col-12 col-md-6 text-left fs-1 p-0">
                <div className="d-flex ps-5 align-items-center">    
                <div><i class="bi bi-currency-rupee"></i></div>
                <div>
                <h2>{packageDetails?.pricing}</h2>
                </div>
                </div>
              </div>
              <div className="col-12 col-md-6 text-left text-md-right">
                  {/* <button className="me-3" >Buy Now</button> */}
                  <button
                    href="#"
                    style={subbtn}
                    className="me-3 text-white"
                    onClick={handlePayment}
                   >
                   BUY NOW
                </button>
                  <button style={sharebtn} onClick={handleShare}> 
                  <i class="bi bi-share"></i></button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
<div className="container mt-5 mx-auto" style={democlass}>
<div className="row">
    <div className="col-4">
      <h6 className="pt-3">About</h6>
      <p className="text-secondary py-2">
      Complete 10 month pregnancy care package from day 1 of your pregnancy till delivery of your baby
      </p>
    </div>
    <div className="col-8">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i1.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    10 Medical Specialists
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i2.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    44 In-Clinic & Online Consultations
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i3.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    17 Pathology Blood & Genetics lab test
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i5babyind.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    09 Ultrtasound (Sonography) Scans
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i1.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    117 Yoga Sessions
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i1.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    18 Meditation Sessions
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i6babycap.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    Garbhasanskar Acess for 9 Month
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i7watch.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    Daily Pregnancy Health Tracking
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i824x7.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    24/7 Emergency
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i1.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    Education on Pregnancy
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i9pregnantlady.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    Pregnancy Care Kit
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i10gift.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    Monthly Premium Exclusive Giveaway Basket

                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
          <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                    <div>
                      <img src="/i11.jpg" className="icon"></img>
                    </div>
                    <div className="ps-3">
                    Complete Transparency Over Care Through Dashboard
                    </div>
              </div>
          </div>
         
        
        </div>
      </div>
    </div>
  </div>
</div>
<div className="container mt-5 mx-auto" style={democlass}>
<div className="row">
    <div className="col-4">
      <h6 className="pt-3">
Medical Specialist 
</h6>
     
    </div>
    <div className="col-8">
      <div className="container">
        <div className="row">
       
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Gynecologist
</div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">General Physician </div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Paediatrician</div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Yoga Expert</div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Dietitian</div>
                    </div>
              </div>
          </div>        
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Psychiatrist</div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Breastfeeding Consultant</div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Meditation Expert</div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Diagnostics Lab Center</div>
                    </div>
              </div>
          </div>
          <div className="col-12 col-md-6 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="ps-3">
                      <div className=" text-secondary">Sonography Center (Radiologist)
</div>
                    </div>
              </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div className="container mt-5 mx-auto" style={democlass}>
<div className="row">
    <div className="col-3 col-md-4">
      <h6 className="pt-3">Schedule </h6>
      <p className="text-secondary py-2">
     Daily
     </p>
    </div>
    <div className="col-9 col-md-8">
      <div className="container">
        <div className="row">
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>1</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        7 Diagnostic lab tests, 1 Sonography, 1 Gynecologist consultation, 1 General Physician consultation, 1 Dietitian consultation, 1 Psychiatrist consultation, 13 Yoga sessions, and 2 Meditation sessions.
                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>2</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        1 Gynecologist consultation, 1 Psychiatrist consultation, 13 Yoga sessions, and 2 Meditation sessions.  
                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>3</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        1 Gynecologist consultation, 1 General Physician consultation, 1 Dietitian consultation, 1 Psychiatrist consultation, 13 Yoga sessions, and 2 Meditation sessions.

                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>4</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        5 Diagnostic lab tests, 2 Sonography, 2 Gynecologist consultation, 2 General Physician consultation, 1 Psychiatrist consultation, 13 Yoga sessions, and 2 Meditation sessions.

                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>5</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        1 Sonography, 1 Gynecologist consultation, 1 Dietitian consultation, 1 Psychiatrist consultation, 13 Yoga sessions, and 2 Meditation sessions.

                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>6</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        1 Sonography, 1 Gynecologist consultation, 1 Psychiatrist consultation, 13 Yoga sessions, and 2 Meditation sessions.

                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>7</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        4 Diagnostic lab tests, 1 Sonography, 2 Gynecologist consultation, 2 General Physician consultation, 1 Dietitian consultation, 1 Psychiatrist consultation, 1 Pediatrician consultation, 13 Yoga sessions, and 2 Meditation sessions. 
                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>8</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        2 Gynecologist consultation, 1 General Physician consultation, 1 Psychiatrist consultation, 13 Yoga sessions, and 2 Meditation sessions.

                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>9</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        1 Diagnostic lab tests, 2 Sonography, 3 Gynecologist consultation, 3 General Physician consultation, 1 Dietitian consultation, 1 Psychiatrist consultation, 1 Pediatrician consultation, 13 Yoga sessions, and 2 Meditation sessions. 
                        </h3>
                      </div>
                    </div>
              </div>
          </div>
          <div className="col-12 my-1">
              <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center mb-5"> 
                    <div className="d-flex flex-column align-items-center">
                      <div><h6 style={month}>Month</h6></div>
                      <div><h4 style={monthno}>10</h4></div>
                    </div>
                    <div className="ps-5">
                      <div className=" text-secondary">
                        <h3 style={monthhead}>
                        1 Sonography, 1 Gynecologist consultation, 1 General Physician consultation
                        </h3>
                      </div>
                    </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      {/* {areaData && (
        <div className="text-center pt-5">
          <p>Area Name : {packageDetails?.area_name} </p>
          <p>City Name : {packageDetails?.city_name}</p>
          <h2>Pricing : {packageDetails?.pricing} </h2>
        </div>
      )} */}

     
      
      </div>):(<div className="mt-5">
        <div className="mt-5">
        <div className="container p pt-5">
          <div className="row">
            <div className="col-6">
            <img src="/savemoney.png" className="img-fluid" id="pricingimg" style={imgrad} alt=""/>
            </div>
            <div className="col-6">
            <p className="text-left mb-3 mt-4 fw-bolder" id="A" >
              Finding the right pregnancy care that is not heavy on your pocket
              is more like a task. To reduce your burden we help anytime anyday
              with the best all inclusive pregnancy care at an affordable rate.
            </p>
            </div>
            </div>
            </div>

      <div className="text-center">
        <h4 className="mb-2 fw-bold text-center text-secondary mobunderline">
          Tell us about your Area
        </h4>
      </div>
      <br></br>
      <div className="text-center dropdowns">
        <form className="my-3">
          <select
            name="subject"
            id="subject"
            className="custom-select"
            value={selectedValue}
            onChange={handleChangeGetDetails}
          >
            <option value="">Select Area</option>
            {areas?.map((item) => (
              <option key={item.id} value={item.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </form>
      </div>
      <div className="mx-3">
        <div className="my-2 buy">
          <Link
            href="#"
            className="mx-auto text-white"
            onClick={()=>proceedtonext()}
          >
            PROCEED
          </Link>
        </div>
      </div>
        </div>
        </div>)}
    
      <Footer />
    </div>
  );
}

export default Pricing;
