import React, { useEffect } from "react";
import { useState } from "react";
import Calendar from "react-calendar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { fontSize } from "@mui/system";
import { useMediaQuery } from "react-responsive";

let showDateObjects;
let idConsultant;

function Times(props) {
  console.log("MY UPDATED TIMING", props);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen2 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });
  const [event, setEvent] = useState(props?.timingid);
  const [time, setTime] = useState();
  const [time2, setTime2] = useState(props?.timingsafter);

  const [info, setInfo] = useState(false);
  const [schedulingtest, setschedulingtest] = useState();
  const [isHover, setIsHover] = useState(1000);
  showDateObjects = props.dateObjects &&  props.dateObjects;
  idConsultant = showDateObjects.find(item => item.date === props.date)
  console.log("showDateObjects", showDateObjects,idConsultant);
  const handleMouseEnter = (e) => {
    setIsHover(e);
  };
  const handleMouseLeave = () => {
    setIsHover(1000);
  };

  function displayInfo(time) {
    console.log("@@@@@@@@@@", time);
    setTime(time?.start_time);

    if (!event?.includes(time?.id)) {
      setEvent((prevTimes) => {
        if (!Array.isArray(prevTimes)) {
          prevTimes = [];
        }
        return [...prevTimes, String(time?.id)];
      });
    }

    if (!time2?.includes(time?.id)) {
      setTime2((prevTimes) => {
        if (!Array.isArray(prevTimes)) {
          prevTimes = [];
        }
        return [...prevTimes, time?.start_time];
      });
    }
  }
function toggleTimeSelection(time) {
  const timeId = String(time.id);
  const timeId1=time.start_time;
  console.log("timeId:", timeId);
  console.log("time2 before:", time2);

  // Toggle event array
   setEvent((prevEvent) => {
     if (!Array.isArray(prevEvent)) {
       prevEvent = [];
     }

     if (prevEvent.includes(timeId)) {
       return prevEvent.filter((id) => id !== timeId); // Remove the timeId if it's already in the event array
     } else {
       if (!prevEvent.includes(timeId)) {
         return [...prevEvent, timeId]; // Add the timeId if it's not in the event array
       }
     }
   });

   setTime2((prevTime2) => {
     if (!Array.isArray(prevTime2)) {
       prevTime2 = [];
     }

     console.log("time.start_time:", time.start_time);

     if (prevTime2.includes(timeId1)) {
       return prevTime2.filter((time) => time !== timeId1); // Remove the timeId1 if it's already in the time2 array
     } else {
       return [...prevTime2, timeId1]; // Add the timeId1 if it's not in the time2 array
     }
   });


  console.log("time2 after:", time2);
}



  const handleclick = (props) => {
    // setschedulingtest(!schedulingtest);
  };
  const setBack = () => {
    setInfo(false);
  };

  useEffect(
    () => {
      console.log("SSSS", event);
      console.log("SSSStime", time2);
      props.getTime(event,idConsultant?.id);
    },
    [event],
    [time2]
  );
  useEffect(
    () => {
     setEvent(props.timingid);

    },
    [ props.timingid ],
  );

  return (
    <>
      {info ? (
        schedulingtest === true ? (
          <>
            <div
              style={{
                paddingTop: "55px",
                textAlign: "center",
                fontSize: isTabScreen ? "18px" : "24px",
                fontWeight: "400px",
                color: "#F0588B",
              }}
            >
              {props.trimesternm}
            </div>
            <div
              style={{
                border: "0.5px solid #E8E7E7",
                height: isTabScreen ? "200px" : "280px",
                width: "95%",
                margin: isTabScreen ? "10px auto" : "20px auto",
                borderRadius: "12px",
              }}
            >
              <div
                style={{
                  paddingTop: isTabScreen ? "10px" : "20px",
                  fontSize: isTabScreen ? "14px" : "20px",
                  fontWeight: "500",
                  color: "#004AAD",
                  textAlign: "center",
                }}
              >
                {props.date}
              </div>
              <div
                className="d-flex justify-content-between px-1"
                style={{ paddingTop: "17px" }}
              >
                <div className="text-center pt-3 px-md-1 px-lg-2">
                  <img
                    src={"/Arrow.png"}
                    style={{ width: "37px", height: "56px" }}
                    alt="Arrow"
                  ></img>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: isTabScreen ? "12px" : "16px",
                      fontWeight: "600",
                    }}
                  >
                    {props.drpost}
                  </div>
                  <div
                    style={{
                      paddingTop: "5px",
                      fontSize: isTabScreen ? "8px" : "10px",
                      fontWeight: "500",
                      color: "#B5B5C3",
                    }}
                  >
                    {props.drstatus}
                  </div>
                </div>
                <div className="px-2 px-lg-3">
                  <div>   
                  
              <img
                src={"/Arrow.png"}
                style={{ width: "30px", height: "20px", cursor: "pointer" }}
                onClick={() => setBack()}
                alt="Arrow"
              ></img>
                    <div
                      style={{
                        fontSize: isTabScreen ? "12px" : "16px",
                        fontWeight: "500",
                      }}
                    >
                      Tests Included
                    </div>
                    <div
                      style={{
                        fontSize: isTabScreen ? "12px" : "16px",
                        fontWeight: "400",
                        color: "#868686",
                      }}
                    >
                      HB, GTT, CBC, Urine Test
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: isTabScreen ? "12px" : "16px",
                      fontWeight: "500",
                      paddingTop: "60px",
                    }}
                  >
                    {" "}
                    Timing
                  </div>
                  <div
                    style={{
                      fontSize: isTabScreen ? "12px" : "16px",
                      fontWeight: "400",
                      color: "#868686",
                    }}
                  >
                    {" "}
                    {"event"}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex align-items-center px-3"
              style={{ paddingTop: "55px" }}
            >
              <img
                src={"/Arrow.png"}
                style={{ width: "30px", height: "20px", cursor: "pointer" }}
                onClick={() => setBack()}
                alt="Arrow"
              ></img>
              <div
                style={{
                  fontSize: isTabScreen ? "18px" : "22px",
                  fontWeight: "400",
                  color: "#F0588B",
                  paddingLeft: isTabScreen ? "22px" : "25px",
                }}
              >
                Update Date & Timings
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                border: "0.5px solid #E8E7E7",
                height: isTabScreen ? "140px" : "auto",
                width: "95%",
                margin: "10px auto",
              }} >
            {showDateObjects &&
             Array.isArray(showDateObjects) && showDateObjects.map((i) => ( i.timing_id &&
                <div key={i} >
                  <span  style={{
                  paddingTop: "20px",
                  fontSize: isTabScreen ? "16px" : "20px",
                  fontWeight: "500",
                  color: "#004AAD",
                  textAlign: "center",
                }} >{i.date}{"   -"}</span>
                  {props.timings &&
                    Array.isArray(props.timings) &&
                    props.timings
                      .filter(({ id }) => i.timing_id.includes(id))
                      .map(({ start_time },index,array) => (
                        <React.Fragment key={index}>
                        <span   style={{
                          fontSize: isTabScreen ? "16px" : "20px",
                          fontWeight: "600",
                          color: "#59bb8f",
                          flexWrap: "wrap",
                          width: "92%",
                          overflowWrap: "break-word",
                          alignItems: "center",
                        }}>{start_time}  {index < array.length -1 && <span>, </span>}</span>
                       
                        </React.Fragment>
                      ))}
                </div>
              ))}
              {(showDateObjects && Array.isArray(showDateObjects) && props.isUpdatedDate === true ) ? (
                <button
                style={{
                  marginTop: "12px",
                  width: isTabScreen ? "70px" : "100px",
                  height: "32px",
                  backgroundColor: "#004AAD",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "6px",
                  border: "none",
                  fontSize: isTabScreen ? "9px" : "12px",
                  fontWeight: "600",
                  color: "white",
                }}
                 onClick={() => {
                props.newUpdateSchedule();
              }}
              >
                Update
              </button>
              ):(<button
                style={{
                  marginTop: "12px",
                  width: isTabScreen ? "70px" : "100px",
                  height: "32px",
                  backgroundColor: "#004AAD",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "6px",
                  border: "none",
                  fontSize: isTabScreen ? "9px" : "12px",
                  fontWeight: "600",
                  color: "white",
                }}
                 onClick={() => {
                props.updateSchedule();
              }}
              >
                Add
              </button>)}
              </div>
          </>
        )
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "20px",
              paddingLeft: "22%",
              paddingBottom: "25px",
              width: "100%",
            }}
          >
            <div
              style={{
                //   paddingTop: "20px",
                fontSize: isTabScreen ? "16px" : "20px",
                fontWeight: "500",
                color: "#004AAD",
                textAlign: "center",
                // marginRight: "40px",
                //   paddingBottom: "25px",
              }}
            >
              Select Timings
              {/* Timings on {props.date} */}
            </div>
            <button className="me-2"
              style={{
                width: "65px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "15px",
                background: "#004AAD",
                border: "1px solid rgba(0, 74, 173, 0.5)",
                fontSize: isTabScreen ? "8px" : "12px",
                color: "white",
                boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
                height: "35px",
                borderRadius: "5px",
              }}
              onClick={() => setInfo(true)}
            >
              Next
            </button>
          </div>
          <div className="mt-1" style={{ width: "96%", margin: "0px auto" }}>
            <div className="container">
              <div className="row">
                {props.timings?.map((times, index) => {
                  const isTimeAfter = props?.timingsafter?.includes(
                    times.start_time
                  );
                  // const formattedTime = moment(times.start_time, 'HH:mm').format('h:mm a');
                  const timeId = String(times.id);

                  const isSelected = event?.includes(timeId); // Check if the time slot is selected

                  // );
                  return (
                    <div className="times col-3 text-center">
                      <button 
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                        style={{
                          width: "100%",
                          height: isTabScreen ? "30px" : "52px",
                          border: "none",
                          padding: "0px",
                          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
                          marginBottom: isTabScreen ? "12px" : "25px",
                          borderRadius: "4px",
                          backgroundColor: isSelected
                            ? "green"
                            : "#EEEEEE",
                          color: isSelected
                            ? "white"
                            : "black",
                          fontSize: isTabScreen ? "11px" : "14px",
                        }}
                        onClick={() => toggleTimeSelection(times)}
                      >
                        {times?.start_time}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Times;
